<template>
  <div v-if="show" :class="{backdrop}" @click="backdropClick">
    <div class="dialog-div" style="height:75%;width:700px">
      <v-toolbar elevation="0" color="white" style="width:100%" class="dialog-header">
        <v-row style="width:100%" align="center">
          <v-toolbar-title style="margin-left:20px">{{ $t('text.validateGuestcard') }}</v-toolbar-title>
          <v-spacer/>
          <v-btn class="btn-close" elevation="0" @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider style="border-color:#dedede"/>

		<!-- Guestcard Overview -->
      	<v-layout row wrap class="scrollable dialog-content">
			<v-col class="d-flex overflow-x-auto ma-0" style="padding:10px;">
				<v-container fluid class="pa-0 mb-12">
					<div class="pa-5" style="width:100%">
						<span class="generalLabel">{{ ticketServiceResponse.guestcard.guestcardInfo.guestcardName }}  #{{ ticketServiceResponse.guestcard.guestcardInfo.identificationId}}</span><br/>
						<span class="nameLabel">{{ ticketServiceResponse.guestcard.guestcardInfo.firstname }} {{ ticketServiceResponse.guestcard.guestcardInfo.lastname }}</span>
						<br/><br/>

						<v-row class="pa-0" style="width:100%">
							<v-col cols="6" class="pa-0" style="width:50%">
								<span class="generalLabel">{{ $t('text.birthDate') }}</span><br/>
								<span>{{ formatDate(ticketServiceResponse.guestcard.guestcardInfo.birthdate) }}</span>
							</v-col>
							<v-col cols="6" class="pa-0" style="width:50%" align="right">
								<span class="generalLabel">{{ $t('text.validityPeriod') }}</span><br/>
								<span>
									{{ formatDate(ticketServiceResponse.guestcard.guestcardInfo.validityStartDate) }}
                  -
                  {{ formatDate(ticketServiceResponse.guestcard.guestcardInfo.validityEndDate) }}
								</span>
							</v-col>
						</v-row>
						<br/>
						<div v-if="ticketServiceResponse.identifications && ticketServiceResponse.identifications.length > 1">
							<span class="helpLabel">{{ $t('text.multiplePermissions') }}</span>
							<br/><br/>
						</div>

						<!-- Available Identifications -->
            <div v-if="ticketServiceResponse.identifications">
              <v-row v-for="identification of ticketServiceResponse.identifications" :key="identification.ticketInfo.identificationId" @click="viewTicketDetails(identification)"
                class="ticketRow" align="center" justify="center" style="width:100%;padding-left:0;padding-right:0;">
                <v-col cols="9"  style="padding-left:0">
                  <span>{{ identification.ticketInfo.productName }}</span><br/>
                  <span class="generalLabel">{{ $store.state.selectedServiceProvider.fields.title[selectedLocale] }}</span>
                </v-col>

                <v-col cols="3" align="right" style="padding-right:0">
                  <v-row justify="end">
                    <span v-if="identification.contingentInfo.free === 0" class="redeemedLabel">{{ $t('text.redeemed')}}</span>
                    <v-spacer v-if="identification.contingentInfo.free > 0"></v-spacer>
                    <img src="@/assets/icons/expand-arrow-right.svg" style="width:32px;height:32px"/>
                  </v-row>
                </v-col>
                <v-divider/>
              </v-row>
            </div>

            <!-- Available TicketInfo -->
            <div v-if="!ticketServiceResponse.identifications">
              <v-row @click="viewTicketDetails(ticketServiceResponse)"
                class="ticketRow" align="center" justify="center" style="width:100%;padding-left:0;padding-right:0;">
                <v-col cols="9"  style="padding-left:0">
                  <span>{{ ticketServiceResponse.ticketInfo.productName }}</span><br/>
                  <span class="generalLabel">{{ $store.state.selectedServiceProvider.fields.title[selectedLocale] }}</span>
                </v-col>

                <v-col cols="3" align="right" style="padding-right:0">
                  <v-row justify="end">
                    <span v-if="ticketServiceResponse.contingentInfo.free === 0" class="redeemedLabel">{{ $t('text.redeemed')}}</span>
                    <v-spacer v-if="ticketServiceResponse.contingentInfo.free > 0"></v-spacer>
                    <img src="@/assets/icons/expand-arrow-right.svg" style="width:32px;height:32px"/>
                  </v-row>
                </v-col>
                <v-divider/>
              </v-row>
            </div>
					</div>
				</v-container>
			</v-col>
      	</v-layout>

      	<v-divider/>

	  <!-- Footer -->
      <v-toolbar elevation="0" color="white" style="width:100%" height="60" class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn class="gradientButton" theme="dark" elevation="0" 
          @click="closeDialog()"
          data-cy="gc-cancel"
        >
          {{ $t('text.cancel') }}
        </v-btn>
        
        <v-btn class="greenButton" theme="dark" elevation="0" 
          @click="closeDialog()"
          data-cy="gc-confirm"
        >
          {{ $t('text.done') }}
        </v-btn>
      </v-toolbar>
    </div>
  </div>
</template>


<script>
import Common from '@/mixins/Common.vue'
import MessageDialog from './MessageDialog.vue'

export default {
	name: 'GuestcardOverview',
	components: {MessageDialog},
	mixins: [Common],

	props: {
		gateId: String,
		ticketServiceResponse: Object,
		show: Boolean
	},

	data() {
		return {
			backdrop: true,
			closeOnOuterClick: true,
			ticket: null
		}
	},

	methods: {
		viewTicketDetails(identification) {
			this.$emit('view-ticket', identification)			
		},
		closeDialog() {
			this.$emit('close-dialog')
		},
		backdropClick() {
			if (this.closeOnOuterClick) {
				this.closeDialog();
			}
		}
	},
}
</script>

<style scoped>
.btn-close {
	background-color: #ffffff !important; 
	height:40px !important;
	margin-right:0px !important;
	color: #999999 !important;
}

.title { font-size: 24pt; color: #000000; font-weight: bold; }
.generalLabel { color: #999999 !important; font-size: 12pt;}
.fieldLabel { font-weight: bold; font-size: 12pt; line-height: 2;}
.fieldValue { font-size: 12pt; line-height: 2;}
.helpLabel { font-size: 10.5pt;}
.nameLabel { font-weight: bold; font-size: 1.25rem;}
.ticketRow {  cursor: pointer }
.ticketRow:hover { background-color: #f4f4f4; }
.theme--light.v-divider { border-color: #999999 !important; }
.redeemedLabel {
  height: 32px;
  background: linear-gradient(0deg, #0ba7e1 0%, #00adee 100%);
  border: thin #088ec0 solid;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-size: 11pt;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: none;
  color: #ffffff;
  padding: 10px;
}
</style>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  height: calc(100vh - 64px);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 64px;
  left: 0;
}

.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1100px;
  height: min(700px, calc(100vh - 20px));
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  i {
    margin-right: 5px;
  }

  .dialog-header {
    max-height: 64px;
  }

  .dialog-footer {
    max-height: 60px;

    .gradientButton {
      height: 40px !important;
      min-height: 40px !important;
      margin-right: 10px;
      color: #000;
    }

    .greenButton {
      height: 40px !important;
      margin-right: 10px;
    }

    .redButton {
      height: 40px !important;
      margin-right: 10px;
    }
  }
}
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
