<template>
	<img :src="flagIcon" />
</template>

<script>
export default {
	name: 'LanguageFlag',
	props: {
		modelValue: { type: String, default: null },
	},
	data() { return {
		userSpecificGerman: 'de',
	}},
	computed: {
		flagIcon() {
			const images = import.meta.glob('@/assets/flags/*.svg', { eager: true, import: 'default' })
			const flagAssetsPath = '/src/assets/flags/'

			if (this.modelValue == "de") {
				if (this.userSpecificGerman)
					return images[`${flagAssetsPath}${this.userSpecificGerman}.svg`]
				return images[`${flagAssetsPath}de.svg`]
			}
			if (this.modelValue)
				return images[`${flagAssetsPath}${ this.modelValue }.svg`]
			return images[`${flagAssetsPath}en.svg`]
		},
	},
	async mounted() {
		this.userSpecificGerman = await this.$store.state.userSpecificGerman
	}
}
</script>

<style scoped>
img { width: 20px; height: 20px; }
</style>