<template>
	<div class="TablePaginator">
		<v-spacer />
		<v-btn variant="text" @click="previousPage()" :disabled="modelValue == 0"><v-icon>mdi-chevron-left</v-icon></v-btn>
		<div class="count" v-if="count">{{ modelValue + 1 }} - {{ pageCount }}</div>
		<span style="margin-right: 10px"> of {{ total }}</span>
		<v-btn data-cy="nextPage" variant="text" @click="nextPage()" :disabled="!hasMore"><v-icon>mdi-chevron-right</v-icon></v-btn>
	</div>
</template>

<script>
export default {
	name: 'TablePaginator',
	props: {
		modelValue: { type: Number, default: 0 },
		limit: { type: Number, default: 15 },
		total: Number,
		results: Array,
	},
	data: () => ({
		hasMore: null,
		count: null,
	}),
	computed: {
		pageCount() {
			const modelValue = Math.min(this.modelValue + this.count, this.modelValue + this.limit)
			return modelValue > this.total ? this.total : modelValue
		},
	},
	watch: {
		results(v) {
			this.handleResults(v)
		},
	},
	methods: {
		nextPage() {
			this.$emit('update:modelValue', this.modelValue + this.limit)
		},
		previousPage() {
			this.$emit('update:modelValue', Math.max(0, this.modelValue - this.limit))
		},
		handleResults(results) {
			this.count = Math.min(this.limit, results?.length)
			this.hasMore = Math.min(this.modelValue + this.count, this.modelValue + this.limit) < this.total
			
			return results
		},
	},
	mounted() {
		if (this.results) this.handleResults(this.results)
	},
}
</script>

<style scoped>
.TablePaginator { text-align: right; background: #f4f4f4; border-top: 1px solid #ddd; padding: 10px; }
.count { display: inline-block; line-height: initial; }
</style>