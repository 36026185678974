<template>
	<div>
		<Disclosure
			:title="disclosureTitle"
			:error="sectionMessage.error"
			:message="sectionMessage.message"
			data-cy="skischoolSettings"
			ref="SECTION_skischoolSettings"
            :lock="skischoolSettingsLocked"
		>
			<div class="field left-border">
				<TextField
					:title="$t('text.skischoolBookingDeadline')"
					:suffix="suffixBookingDeadline"
				/>
				<p class="helpText" v-html="$t('text.skischoolBookingDeadlineHelptext')"/>
			</div>

			<div class="field left-border">
				<div style="display: flex; gap: 15px;">
					<TextField
						style="flex-grow: 1;"
						:title="$t('text.skischoolMeetingPoint')"
					/>
					<TextField
						style="flex-grow: 1;"
						:title="$t('text.skischoolMeetingTime')"
					/>
				</div>
				<p class="helpText" v-html="$t('text.skischoolMeetingPointAndTimeHelptext')"/>
			</div>

			<div class="field left-border">
				<div style="display: flex; gap: 15px;">
					<div style="flex-grow: 1;">
						<v-label>{{$t('text.skischoolSportType')}}</v-label>
						<v-select
							variant="outlined" density="compact" return-object
							:hide-details="skischoolSportTypeErrors.length === 0"
							:error-messages="skischoolSportTypeErrors"
							:items="skischoolSportType"
							:item-title="item => item"
							data-cy="sportType"
						/>
					</div>
					<div style="flex-grow: 1;">
						<v-label>{{$t('text.skischoolSkillLevel')}}</v-label>
						<v-select
							variant="outlined" density="compact" return-object
							:hide-details="skischoolSkillLevelErrors.length === 0"
							:error-messages="skischoolSkillLevelErrors"
							:items="skischoolSkillLevel"
							:item-title="item => item"
							data-cy="skillLevel"
						/>
					</div>
				</div>
				<p class="helpText" v-html="$t('text.skischoolSportTypeAndSkillLevelHelptext')"/>
			</div>

			<div class="field left-border">
				<div style="display: flex; gap: 15px;">
					<div style="flex-grow: 1;">
						<v-label>{{$t('text.skischoolDuration')}}</v-label>
						<v-select
							variant="outlined" density="compact" return-object
							:hide-details="skischoolDurationErrors.length === 0"
							:error-messages="skischoolDurationErrors"
							:items="skischoolDuration"
							:item-title="item => item"
							data-cy="duration"
						/>
					</div>
					<div style="flex-grow: 1;">
						<v-label>{{$t('text.skischoolFromTime')}}</v-label>
						<v-select
							variant="outlined" density="compact" return-object
							:hide-details="skischoolFromTimeErrors.length === 0"
							:error-messages="skischoolFromTimeErrors"
							:items="skischoolFromTime"
							:item-title="item => item"
							data-cy="reduction"
						/>
					</div>
					<div style="flex-grow: 1;">
						<v-label>{{$t('text.skischoolUntilTime')}}</v-label>
						<v-select
							variant="outlined" density="compact" return-object
							:hide-details="skischoolUntilTimeErrors.length === 0"
							:error-messages="skischoolUntilTimeErrors"
							:items="skischoolUntilTime"
							:item-title="item => item"
							data-cy="reduction"
						/>
					</div>
					<div style="flex-grow: 1;">
						<v-label>{{$t('text.skischoolHoursPerDay')}}</v-label>
						<v-select
							variant="outlined" density="compact" return-object
							:hide-details="skischoolHoursPerDayErrors.length === 0"
							:error-messages="skischoolHoursPerDayErrors"
							:items="skischoolHoursPerDay"
							:item-title="item => item"
							data-cy="reduction"
						/>
					</div>
				</div>
				<p class="helpText" v-html="$t('text.skischoolDurationFromUntilTimeHoursPerDayHelptext')"/>
			</div>

			<div class="field left-border">
				<v-label>
					{{$t('text.skischoolAvailableLanguages')}}
				</v-label>

				<div v-for="(item, index) of availableLanguages"
				     :key="index"
				     :class="['availableLanguagesItem', {'rounded-top': index === 0}, {'rounded-bottom': index === availableLanguages.length - 1}]"
				>
					<div style="display: flex; align-items: center;">
						<v-checkbox color="#00aeef" hide-details v-model="item.checked" style="flex-grow: 0; margin: 0;"/>
						<span>{{ item.text }}</span>
					</div>
				</div>
			</div>

			<div class="field left-border">
				<TextField
					:title="$t('text.skischoolMaximumAmountOfPeople')"
					:suffix="suffixMaximumAmountOfPeople"
				/>
				<p class="helpText" v-html="$t('text.skischoolMaximumAmountOfPeopleHelptext')"/>
			</div>
		</Disclosure>
	</div>
</template>

<script>

import Disclosure from "@/components/common/Disclosure.vue";
import TextField from "@/components/common/TextField.vue";

export default {
	name: "SkischoolSettings",
	components: {TextField, Disclosure},
	// mixins: [Common],

	props: {
		disclosureTitle: String,
		skischoolSettingsLocked: Boolean,
	},

	data: () => ({
		sectionMessage: {
			error: false,
			message: '',
		},
		suffixBookingDeadline: 'days',
		suffixMaximumAmountOfPeople: 'people',
		skischoolSportTypeErrors: [],
		skischoolSkillLevelErrors: [],
		skischoolFromTimeErrors: [],
		skischoolDurationErrors: [],
		skischoolUntilTimeErrors: [],
		skischoolHoursPerDayErrors: [],
		skischoolSportType: ['Sport1', 'Sport2', 'Sport3'],
		skischoolSkillLevel: ['Red', 'Blue', 'Black'],
		skischoolDuration: ['1 day', '2 days', '3 days', '4 days'],
		skischoolFromTime: ['09:00', '09:30', '10:00', '10:30'],
		skischoolUntilTime: ['09:00', '09:30', '10:00', '10:30'],
		skischoolHoursPerDay: ['1 hour', '2 hours', '3 hours', '4 hours'],
		availableLanguages: [{
			checked: true,
			text: 'English',
		}, {
			checked: true,
			text: 'German'
		}, {
			checked: true,
			text: 'Italian'
		}, {
			checked: false,
			text: 'French'
		}, {
			checked: false,
			text: 'Spanish'
		}, {
			checked: false,
			text: 'Dutch'
		}]

	}),
	watch: {

	},
	methods: {

	}
}
</script>

<style>
.availableLanguagesItem {
	border: solid 1px rgba(0, 0, 0, 0.1); /* Change the color or style as needed */
	padding: 5px;
	display: flex;
	align-items: center;
}
.rounded-top {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}
.rounded-bottom {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}
.checkbox {
	color: #00aeef;
}
</style>