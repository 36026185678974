<template>
	<div class="payment-plan-overview">
		<!-- <v-row class="field left-border" align="center">
			<v-text-field variant="outlined" density="compact" clearable hide-details
				prepend-inner-icon="mdi-magnify"
				placeholder='Search'
				style="max-width: 35%;"
				class="mr-2 mt-0"
				v-model="searchString"
				@click:clear="clear()"
				@keyup.enter="search()"
			/>
			<v-btn class="gradientButton" elevation="0" @click="search()">{{$t('text.search')}}</v-btn>
		</v-row> -->
		<CommonTable v-model:offset="offset" v-model:limit="limit" v-model:sortBy="sortBy" v-model:expanded="expanded"
			show-expand :items="paymentPlans" :total="total" :headers="headers" :loading="isLoading" no-data-text="No Data"
			data-cy="paymentPlansOverview">
			<template #[`item.name`]="{ item }">
				<v-row no-gutters align="center">
					<div class="payment-plan-selected">
						<v-checkbox hide-details v-model="item.selected" color="blue" />
					</div>
					<div class="payment-plan-name pl-3" :title="getLocalizedText(item.name, serviceLocale)">
						{{ getLocalizedText(item.name, serviceLocale) }}
					</div>
				</v-row>
			</template>

			<template #[`item.patternGroupIds`]="{ item }">
				<div class="payment-plan-product-types">
					<div v-for="{ name, id } in getProductTypes(item).slice(0, 3)" :key="id" :title="name">
						<Chip :title="name" :color="primary" />
					</div>

					<Chip v-if="getProductTypes(item).length > 3"
						:title="'+ ' + (getProductTypes(item).length - 3) + ' more'" />
				</div>
			</template>

			<template #expanded-row="{ item }">
				<h4>
					{{ $t('text.bpStepGeneral') + ":" }}
				</h4>
				<div class="policy-info-wrapper">
					<div class="policy-info-header">
						<div class="subtitle policy-info-item">
							<h5>{{ $t('text.pricing_mys') + ":" }}</h5>
							<h5 style="font-weight: normal;">{{ getPricingText(item) }}</h5>
						</div>
						<div class="subtitle policy-info-item">
							<h5>{{ $t('text.noShowFee') + ":" }}</h5>
							<h5 style="font-weight: normal;" v-if="item.noShowFee">{{ item.noShowFee }}%</h5>
						</div>
					</div>
				</div>
				<h3>
					{{ $t("text.milestonesAtAGlanceLabel") + ":" }}
				</h3>
				<div class="payment-plan-milestones">
					<MilestonesPreview :payment-plan="item" />
				</div>
			</template>
		</CommonTable>
	</div>
</template>

<script>
import CommonTable from '@/components/common/tables/Table.vue'
import Chip from '@/components/common/controls/Chip.vue'
import Alert from '@/components/common/Alert.vue'
import MilestonesPreview from './MilestonesPreview/MilestonesPreview.vue'
import Common from '@/mixins/Common.vue'


export default {
	components: { CommonTable, Chip, Alert, MilestonesPreview },
	mixins: [Common],
	emits: ['updateData'],
	props: {
		data: Array,
		billingDomainId: String,
		updateData: Function,
		getProductTypes: Function,
	},
	data() {
		return {
			paymentPlans: [],
			limit: 10,
			total: 0,
			offset: 0,
			sortBy: [
				{
					key: 'name',
					order: 'asc'
				}
			],
			expanded: [],
			search: '',
			isLoading: false,
		}
	},
	watch: {
		data(newData) {
			this.paymentPlans = newData
		},
		async offset() {
			await this.loadPaymentPlans()
		},
		async limit() {
			if (this.offset > 0) {
				this.offset = 0
			} else {
				await this.loadPaymentPlans()
			}
		},
		async sortBy(sortBy) {
			if (sortBy.key === this.sortBy.key && sortBy.order === this.sortBy.order) return

			await this.loadPaymentPlans()
		},
		search(search) {
			window.clearTimeout(this.timeout)

			this.timeout = window.setTimeout(() => {
				if (this.search === search) return

				// TODO load filtered data

				if (this.offset > 0) {
					this.offset = 0
				}
			}, 300)
		}
	},
	computed: {
		headers() {
			return [
				{
					title: this.$t('text.planName'),
					value: 'name',
					key: 'name',
					sortable: true,
				},
				{
					title: this.$t('text.productTypes'),
					value: 'patternGroupIds',
					key: 'patternGroupIds',
					sortable: false,
				},
			]
		}
	},
	methods: {
		getPricingText(paymentPlan) {
			const priceAdjustment = Number(paymentPlan.priceAdjustmentPercent)
			const isAdjusted = Number.isNaN(priceAdjustment) || priceAdjustment !== 0
			if (isAdjusted) {
				const prefix = priceAdjustment > 0 ? "+" : "-"
				const value = Math.abs(priceAdjustment)
				const text = this.$t("text.manipulatePricesBy", { value: `${prefix} ${value} %` })
				return text
			}
			return this.$t("text.useOriginalPrice")
		},
	},
	mounted() {
		this.paymentPlans = this.data
	},
}
</script>

<style lang="scss">
.payment-plan-overview {
	.v-table>.v-table__wrapper>table>tbody>tr>td {
		.v-input {
			background-color: transparent;
		}

		>.payment-plan-selected {
			display: flex;
			align-items: center;
		}

		>.payment-plan-name {
			max-width: 120px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			@media screen and (min-width: 1500px) {
				max-width: 350px
			}
		}

		>.payment-plan-product-types {
			display: flex;
			gap: 8px;
		}

		>.payment-plan-actions {
			display: none;

			>.v-btn+.v-btn {
				margin-left: 12px;
			}
		}

		&.common-table-expanded-row {
			>.payment-plan-milestones {
				margin-top: 15px;
				margin-bottom: 24px;
				max-width: 100%;
				height: 144px;
				display: flex;
				align-items: center;
				padding: 0 24px;
				box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
				border: solid 1px rgba(0, 0, 0, 0.1);
				background-color: #fff
			}

			>.subtitle+.payment-plan-milestones {
				margin-top: 24px;
			}
		}

	}

	.v-table>.v-table__wrapper>table>tbody>tr:hover>td {
		>.payment-plan-actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
		}
	}

	.policy-info-wrapper {
		padding-top: 10px;
		padding-bottom: 20px;
	}

	.policy-info-header {
		display: flex;
		justify-content: space-between;
		width: 80%;
		gap: 30px;
	}

	.policy-info-item {
		display: flex;
		gap: 100px;
		padding-bottom: 5px;
	}
}
</style>
