<template>
	<Application v-model:errorTitle="errorTitle" :errorDetail="errorDetail" :loading="loading" :successDetail="successDetail" v-model:successTitle="successTitle"
		:style="{ 'padding-left': isSideBarFixed ? '315px' : '15px' }"
	>
		<template #navbar>
			<template v-if="itemType !== 'marketplaceSettings' && itemType !== 'checklist' && itemType !== 'commissions'">
				<v-text-field variant="outlined" density="compact" clearable hide-details
					v-if="!serviceProviderClicked"
					prepend-inner-icon="mdi-magnify"
					:placeholder="$t('text.search')"
					v-model="searchString" 
					@keyup.enter="offset=0;search()"
					@click:clear="clearSearch()"
					data-cy="search"
				/>
				<v-spacer/>
				<v-btn v-if="itemType !== 'serviceProviders' && itemType !== 'faq'" data-cy="addOptionButton" size="small" class="gradientButton" elevation="0" @click="startAdd()">
					<v-icon>mdi-plus</v-icon>
					<span class="d-none d-md-inline">{{ addOptionTitle }}</span>
				</v-btn>
			</template>
		</template>

		<SideBar :navigation="sections" @action="handleAction($event)" />

		<div :style="showImpersonationInfo ? 'padding-top: 24px !important;' : 'padding-top:0px'">
			<ServiceOptionsForProvider 
				v-if="serviceProviderClicked && isOperator" 
				:title="currentServiceProviderTitle" 
				@go-back="goBack()"
			/>

			<!-- Service Providers (for operator) -->
			<template v-if="serviceProviderView()">
				<v-card class="tableCard">
				<v-data-table fixed-header disable-sort
					:headers="optionHeaders"
					:items="serviceProviders"
					:items-per-page="limit"
					:item-value="item => item"
				>
					<template v-slot:item="{ item }">
						<tr @click="showProviderOptions(item)">
							<td>{{ item.fields.title[selectedLocale] }}</td>
							<td><ApprovalStatus :status="getClientStatus(item)" /></td>
						</tr>
					</template>
					<template #bottom>
						<TablePaginator v-model="offset" :limit="limit" :total="serviceProviderCount" @update:modelValue="getServiceProviders()" :results="serviceProviders" />
					</template>
				</v-data-table>
				</v-card>
			</template>

			<!-- Service Options for operator / tickettypes -->
			<template v-if="serviceOptionsView('operator')">
				<v-card class="tableCard">
					<v-data-table fixed-header disable-sort
						:headers="[ ...optionHeaders]"
						:items="serviceOptions"
						:items-per-page="limit"
						:item-value="item => item"
					>
						<template v-slot:item.item_type="{ item }">
							<div @click="editItem = item">
								{{ item.fields.title[selectedLocale] }}
							</div>
						</template>
						<template v-slot:item.status="{ item }">
							<mys-switch
								v-model="item.defaultAssignment"
								:label="$t('text.default')"
								@update:modelValue="isChangeDefault = true; changeDefault(item)"
								style="width: 150px;"
							/>
						</template>
						<template #bottom>
							<TablePaginator v-model="offset" :limit="limit" :results="serviceOptions" :total="optionCount" @update:modelValue="getServiceOptions()"/>
						</template>
					</v-data-table>
				</v-card>
			</template>

			<!-- Service Options for service provider / tickettypes -->
			<template v-if="serviceOptionsView('serviceProvider')">
				<v-card class="tableCard">
					<v-data-table fixed-header disable-sort
						:headers="optionHeaders"
						:items="serviceOptions"
						:items-per-page="limit"
						:item-class="clientOption"
						@click:row="(e, o) => editItem = o.item"
						:item-value="item => item"
					>
						<template v-slot:item.item_type="{ item }">
							{{ item.fields.title[selectedLocale] }}
						</template>
						<template v-slot:item.status="{ item }">
							<v-chip v-if="item.fields.status" style="margin-right:10px" :color="getColor(item.fields.status)" dark>{{$t(`text.${item.fields.status}`)}}</v-chip>
							<v-chip v-else style="margin-right:10px" :color="getColor('active')" dark>{{$t(`text.active`)}}</v-chip>
						</template>
						<template #bottom>
							<TablePaginator v-model="offset" :limit="limit" :results="serviceOptions" :total="optionCount" @update:modelValue="getServiceOptions()"/>
						</template>
					</v-data-table>
				</v-card>
			</template>

			<ChecklistSettingsView v-if="itemType==='checklist'" />

			<template v-if="itemType === 'categories'">
				<!-- TODO -->
			</template>

       <template v-if="itemType==='marketplaceSettings'">
        <div class="pa-3 mainContainer">
          <Disclosure :title="$t('text.marketplaceSettings')" :expanded="true" :error="sectionMessage.marketplaceSettings.error" :message="sectionMessage.marketplaceSettings.message">
            <div class="field left-border">
              <p class="helpText" v-html="$t('text.marketplaceSettingsHelp')"/>
              <br/><br/>
              <mys-switch v-model="spSalesChannelSelectionEnabled" :label="$t('text.spSalesChannelSelectionEnabled')" />
            </div>
          </Disclosure>
        </div>
       </template>

       <template v-if="itemType==='commissions'">
        <div class="pa-3 mainContainer">
          <Disclosure :title="$t('text.commissions')" :expanded="true" :error="sectionMessage.commissionSettings.error" :message="sectionMessage.commissionSettings.message">
            <div class="field left-border">
              <p class="helpText" v-html="$t('text.commissionSettingsHelp')"/>
              <br/><br/>
              <mys-switch v-model="defaultCommissionEnabled" :label="$t('text.marketplaceCommissionEnabled')" />
              <br/>
              <v-row align="center" class="pa-3">
                <v-label>{{$t('text.overallDefault')}}</v-label>
                <v-text-field variant="outlined" density="compact" hide-details class="pl-3 pr-3" style="width:100px;max-width:100px" 
                  :disabled="!defaultCommissionEnabled"
                  v-model="defaultCommissionAmount" 
                  @keypress="checkPercentage(defaultCommissionAmount, $event)"
                  @keyup="checkPercentage(defaultCommissionAmount, $event)" 
                /> 
                <h2>%</h2>
              </v-row>
              <br/>
              <v-btn data-cy="btn-default-commission-save" size="small" class="gradientButton" elevation="0" @click="$refs.editDialog.show=true">
                <span class="d-none d-md-inline">{{$t('text.saveChanges')}}</span>
              </v-btn>
              
            </div>
          </Disclosure>
        </div>
       </template>

		<template v-if="itemType === 'faq'">
			<FAQView ref="faqView" />
		</template>

		<template v-if="itemType === 'generalSettings'">
			<GeneralSettings ref="generalSettings" />
		</template>
	   
      <br/>
    </div>

		<I18nStringDialog v-if="editItem"
			:title="editOptionTitle"
			:canDelete="!editItemIsNew"
			v-model="editItem.fields.title"
			@cancel="editItem = null"
			@confirm="confirm"
			@delete="del"
		/>

		<!-- Confirm Edit Commission -->
		<Dialog ref="editDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="onEditCommissionConfirmed"
			:cancel-handler="onEditCommissionCancelled"
			:showClose="true"
			:title="$t('text.editCommission')" 
			height="350px"
			width="550px"
		>
			<template #content>
				<v-row align="center" justify="center">
					<v-col cols="4" style="text-align:center">
						<v-icon size="80" color="#FDB537">mdi-alert-rhombus</v-icon>
					</v-col>
					<v-col cols="8" class="pr-5">
						<p>{{ $t('text.editCommissionText') }}</p>
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</Application>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import Common from '@/mixins/Common.vue'
import SettingsMixin from '@/mixins/SettingsMixin.vue'
import ServiceOptionsForProvider from './ServiceOptionsForProvider.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import ApprovalStatus from '@/components/common/ApprovalStatus.vue'
import SideBar from "@/components/common/SideBar.vue"
import Disclosure from '@/components/common/Disclosure.vue'
import ChecklistSettingsView from './ChecklistSettingsView.vue'
import Application from '../Application.vue'
import I18nStringDialog from '../../../components/I18nStringDialog.vue'
import FAQView from './faq/FAQView.vue'
import GeneralSettings from './GeneralSettings.vue'

export default {
	name: 'SettingsView',
	components: { SideBar, Dialog, TablePaginator, ApprovalStatus, Disclosure, ServiceOptionsForProvider, ChecklistSettingsView, Application, I18nStringDialog, FAQView, GeneralSettings },
	mixins: [ Common, SettingsMixin ],
	props: {
		viewType: {
			type: String
		}
	},
	data() { return {
		editItem: null,
		fullPage: true,
		itemType: 'serviceProviders',
		serviceOptions: [],
		isChangeDefault: false,
		optionCount: 0,

		serviceProviders: [],
		serviceProviderCount: 0,
		currentServiceProviderTitle: '',
		searchSubmitted: false,

		headers: [
			{ title: 'Name', key: 'name', align: 'left', sortable: false },
		],

		//Marketplace Settings
		spSalesChannelSelectionEnabled: this.$store.state.selectedClient.fields.marketplaceSettings?.de?.find(x => x.id === 'spSalesChannelSelectionEnabled')?.enabled,

		//Commission Settings
		defaultCommissionEnabled: this.$store.state.selectedClient.fields.defaultCommissionEnabled?.de,
		defaultCommissionAmount: this.$store.state.selectedClient.fields.defaultCommissionAmount?.de,

		sectionMessage: {
			marketplaceSettings: {error:false,warning:false,message:''},
			commissionSettings: {error:false,warning:false,message:''},
		},

		// ATT: sections is defined in SettingsMixin!
	}},

	computed: {
		optionHeaders () {
			let typeText = this.itemType !== 'serviceProviders' ? this.$t('text.'+this.itemType+'s') : this.$t('text.serviceProviders')
			let headers = [
				{ title: typeText, sortable: true, key: 'item_type' }
			]
			if (this.isOperator && this.state !== "serviceProviders") {
				headers.push({ title: '', width:'100px', key: "status" })
			}
			else {
				headers.push({ title: 'Status', width:'100px', key: "status" })
			}
			return headers
		},
		addOptionTitle() {
			if (this.itemType == 'ticketType') return this.$t('text.addTicketType')
			if (this.itemType == 'reduction') return this.$t('text.addReduction')
			return ''
		},
		editOptionTitle() {
			if (this.itemType == 'ticketType') return this.$t('text.editTicketType')
			if (this.itemType == 'reduction') return this.$t('text.editReduction')
			return ''
		},
		errorMessages() {
			return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
		},
		successMessages() {
			return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
		},
		editItemIsNew() {
			return !this.editItem.sys
		},
	},
	watch: {
		state() {
			this.getServiceOptions()
		},
		spSalesChannelSelectionEnabled() {
			this.updateMarketplaceSettings('spSalesChannelSelectionEnabled', this.spSalesChannelSelectionEnabled)
		}
	},
	methods: {
		startAdd() {
			this.editItem = {
				defaultAssignment: false,
				fields: {
					title: null,
				},
			}
		},
		async del() {
			await this.deleteOption(this.editItem)
			this.editItem = null
			await this.load()
		},
		async confirm() {
			if (this.editItemIsNew)
				await this.createOption(this.editItem)
			else
				await this.updateOption(this.editItem)
			this.editItem = null
			await this.load()
		},
		async load() {
			await this.getServiceProvider()
			if (this.isOperator) await this.getClientOptions()
			else await this.getProviderOptions()

			if (this.isOperator) {
				if (this.itemType === 'ticketType') this.$store.state.selectedClient.fields.ticketTypes.de = this.serviceOptions
				else this.$store.state.selectedClient.fields.reductions.de = this.serviceOptions
			}
			else {
				if (this.itemType === 'ticketType') this.$store.state.selectedServiceProvider.fields.ticketTypes.de = this.serviceOptions
				else this.$store.state.selectedServiceProvider.fields.reductions.de = this.serviceOptions
			}
		},
		async createOption(option) {
			this.loading = true
			try {
				const data = {
					itemType: this.itemType,
					name: option.fields.title,
					clientId: this.$store.state.selectedClient.sys.id,
					serviceProviderId: this.isOperator ? undefined : this.$store.state.selectedServiceProvider.sys.id,
				}
				await this.$httpPost(`/option`, data)
				this.offset = 0
			}
			catch (error) {
				this.errorAlertVisible = true

				this.errorTitle = this.$t('text.ERROR')

				if (error?.message === 'You already have this option (client default)') {
					this.errorDetail = this.$t('text.optionAlreadyAssignedError')
				}
				else if (error?.message === 'This service option is already assigned to your profile') {
					this.errorDetail = this.$t('text.alreadyAssignedError')
				}
				else {
					this.errorDetail = error.response?.data?.error ?? error.message
				}
			}
			this.loading = false
		},
		async updateOption(option) {
			this.loading = true

			const data = {
				item: option,
				clientId: this.$store.state.selectedClient.sys.id,
				serviceProviderId: this.isOperator ? undefined : this.$store.state.selectedServiceProvider.sys.id,
			}

			try {
				await this.$httpPut(`/option`, data)
				this.offset = 0

				this.successTitle = this.$t('text.SERVICE_OPTION_UPDATED')
				this.successDetail = this.$t('text.serviceOptionUpdatedSuccess')
			}
			catch(e) {
				if (e.response?.status == 401) return this.$emit("show-login")
				this.showError(e.response ? e.response?.data.error : e)
			}
			this.loading = false
		},
		async deleteOption(option) {
			this.loading = true
			const data = {
				option: option,
				clientId: this.$store.state.selectedClient.sys.id,
				serviceProviderId: this.isOperator ? undefined : this.$store.state.selectedServiceProvider.sys.id,
			}
			try {
				const res = await this.axios.delete(`/option`, { headers: this.requestHeaders(), data })
				this.loading = false
			
				if (res?.data?.message === 'cannot delete option') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.serviceOptionInUseError')
				}
				else {
					this.successTitle = this.$t('text.OPTION_REQUEST')
					this.successDetail = this.$t('text.optionRequestDeleteSuccess')
				}
			}
			catch (e) {
				if (e.response?.status == 401) return this.$emit("show-login")
				this.showError(e.response ? e.response?.data.error : e)
			}
			this.loading = false
		},
		async updateMarketplaceSettings(setting, enabled) {
			this.loading = true

			const data = {
				clientId: this.$store.state.selectedClient.sys.id,
				enabled: enabled,
				setting: setting
			}

			try {
				const client = await this.$httpPost(`/marketplaceSettings`, data)
				await this.$store.commit("setSelectedClient", client)
				
				this.successTitle = this.$t('text.marketplaceSettings')
				this.successDetail = this.$t('text.marketplaceSettingSuccessTitle')
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}

			this.loading = false
		},
		getClientStatus(serviceProvider) {
			if (!serviceProvider.fields) return undefined
			const currentCA = serviceProvider.fields.clientAssignments.de.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
			return currentCA?.fields?.status?.de
		},
		serviceProviderView() {
			return this.isOperator && !this.serviceProviderClicked && this.state==='serviceProviders'
		},
		serviceOptionsView(userType) {
			if (userType === 'operator') {
				return this.isOperator && (this.state === 'ticketType' || this.state === 'reduction')
			}
			else if (userType === 'serviceProvider') {
				return !this.isOperator && (this.state === 'ticketType' || this.state === 'reduction')
			}
		},
		clientOption(item) {
			if (item.source === 'CLIENT') {
				return 'clientOption'
			}
		},
		goBack() {
			this.serviceProviderClicked = false
		},
		getColor (status) {
			if (status === "pending") return '#ff9e21'
			else if (status === "active") return '#66BB6A'
		},
		async showProviderOptions(item) {
			await this.$store.commit('setSelectedServiceProvider', item)
			this.serviceProviderClicked = true
			this.currentServiceProviderTitle = item.fields.title[this.selectedLocale]
		},
		showError(detail, title) {
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
		getServiceOptions() {
			// anything was searched
			if (this.searchString?.length > 0) {
				this.search()
			} 
			// client clicks the Service Providers tab
			else if (this.state === 'serviceProviders') {
				this.getServiceProviders()
			}
			// client clicks the Ticket Type or Ticket Category tab
			else if (this.isOperator) {
				this.getClientOptions() 
			}
			// service provider clicks the Ticket Type or Ticket Category tab
			else if(this.state === 'ticketType' || this.state === 'reduction') {
				this.getProviderOptions()
			}
		},
		async getServiceProviders() {
			if (this.searchSubmitted) {
				return this.getSearchedServiceProviders()
			}
			this.loading = true
			try {
				let url = `/serviceproviders?clientId=${this.$store.state.selectedClient.sys.id}&skip=${this.offset}&limit=${this.limit + 1}`
				if (this.$store.state.isImpersonation === true && this.$store.state.selectedServiceProvider.fields.tags?.de) {
					url += `&tags=${this.$store.state.selectedServiceProvider.fields.tags.de}`
				}
				
				const res = await this.$httpGet(url)
				this.serviceProviders = res.serviceProviders
				this.serviceProviderCount = res.total
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.loading = false
		},
		async getSearchedServiceProviders() {
			this.loading = true
			try {
				const res = await this.$httpPost(`/search`, {
					clientId: this.$store.state.selectedClient.sys.id,
					contentType: 'serviceProvider',
					skip: this.offset,
					limit: this.limit + 1,
					searchString: this.searchString
				});
				
				this.serviceProviders = res?.serviceProviders;
				this.serviceProviderCount = res?.total ? res.total : 0;
			}
			catch (error) {
				if (error.response?.status == 400) {
					this.serviceProviders = []
				}
				else {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response?.error ?? error
				}
			}
			this.loading = false
		},
		async getClientOptions() {
			this.loading = true

			try {
				const res = await this.$httpGet(`/client-option?client=${this.$store.state.selectedClient.sys.id}&type=${this.itemType}&locale=${this.selectedLocale}&skip=${this.offset}&limit=${this.limit + 1}`)
				this.serviceOptions = res.options ?? []
				this.optionCount = res.total
				this.loading = false
				this.isChangeDefault = false
			}
			catch (error) {
				this.loading = false
				this.errorAlertVisible = true
				this.errorTitle = this.$t('text.ERROR')
			
				if (error.response) {
					this.errorDetail = error.response.data.error
				}
				else {
					this.errorDetail = error
				}
			}
		},
		async getProviderOptions() {
			this.loading = true
			try {
				const url = `/provider-option?id=${this.$store.state.selectedServiceProvider.sys.id}&clientId=${this.$store.state.selectedClient.sys.id}&itemType=${this.itemType}&locale=${this.selectedLocale}&skip=${this.offset}&limit=${this.limit + 1}`

				const res = await this.$httpGet(url)
				this.serviceOptions = res.options ?? []

				this.optionCount = res.total
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				if (error.response) {
					this.errorDetail = error.response.data.error
				}
				else {
					this.errorDetail = error
				}
			}
			this.loading = false
		},
		async changeDefault(option) {
			this.loading = true

			if (!option.defaultAssignment) {
				option.defaultAssignment = false
			}

			var data = {
				clientId: this.$store.state.selectedClient.sys.id,
				optionId: option.sys.id,
				defaultAssignment: option.defaultAssignment,
			}

			try {
				const res = await this.$httpPost('/default-assignment', data)
				this.$store.state.selectedClient.fields.defaultAssignments.de = res.client.fields.defaultAssignments.de
				
				if (this.searchString.length > 0) {
					this.search()
				}
				// else {
					// MYS-4339: this causes an 'infinite' call where we cannot scroll through pages
					// this.offset = 0
					// this.searchString = ''
					// this.getClientOptions()
				// }
			}
			catch (error) {
				if (error.response && error.response.status === 401) {
					this.$emit('show-login')
				}
				this.errorTitle = this.$t('text.ERROR')
				if (error.response) {
					this.errorDetail = error.response.data.error
				}
				else {
					this.errorDetail = error
				}
				this.alertVisible = true
			}
			this.loading = false
		},
		async getServiceProvider() {
			var id = this.$store.state.selectedServiceProvider?.sys?.id
			if (!id) return

			try {
				const res = await this.$httpGet(`/serviceprovider/${id}`)
				await this.$store.commit('setSelectedServiceProvider', res.serviceProvider)
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit('show-login')
				console.error(error)
				this.showError(error.response ? error.response.data.error : error)
			}
			this.loading = false
		},
    async search() {
      if (this.state === "serviceProviders") {
        this.offset = 0
        if (this.searchString && this.searchString !== '') {
          this.searchSubmitted = true
          this.getSearchedServiceProviders()
        }
        else {
          this.searchSubmitted = false
          this.searchString = ''
          this.getServiceProviders()
        }
      } else if (this.isOperator) {
        let url = `/search-options?clientId=${this.$store.state.selectedClient.sys.id}`
        url += `&type=${this.itemType}`
        url += `&locale=${this.selectedLocale}`
        url += `&skip=${this.offset}`
        url += `&limit=${this.limit + 1}`
        url += `&searchString=${encodeURIComponent(this.searchString)}`
        
        let res = await this.$httpGet(url)

        this.serviceOptions = res?.options
			  this.optionCount = res?.total ? res.total : 0
      }
	  else if (this.state == 'faq') {
		console.log('searching faq - searchstring:', this.searchString)
		this.$refs.faqView.searchString = this.searchString
	  }
      // get options for service provider
      else {
        let url = `/search-options?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}`
        url += `&type=${this.itemType}`
        url += `&locale=${this.selectedLocale}`
        url += `&searchString=${encodeURIComponent(this.searchString)}`

        let res = await this.$httpGet(url)

        this.serviceOptions = res?.options
			  this.optionCount = res?.total ? res.total : 0
      }
			
    },
    clearSearch() {
      this.searchString = ""
      this.offset = 0

      if (this.state === "serviceProviders") {
        this.searchSubmitted = false
        this.getServiceProviders()
      } else if (this.isOperator) {
        this.getClientOptions()
      }
	  else if (this.state == 'faq') {
		console.log('clearing faq - searchstring:', this.searchString)
		this.$refs.faqView.searchString = this.searchString
	  }
      //get options for service provider
      else {
        this.getProviderOptions()
      }
    },
    showServices() {
      this.$router.push("/services")
    },
    showFAQs() {
      this.$router.push("/faq-list")
    },
		onEditCommissionConfirmed() {
			this.upsertDefaultCommission()
			this.$refs.editDialog.show = false
		},
		onEditCommissionCancelled() {
			this.selectedItem = null
		},
		async upsertDefaultCommission() {
			if (!this.defaultCommissionAmount) {
				this.defaultCommissionAmount = 0
			}

			const data = {
				clientId: this.$store.state.selectedClient.sys.id,
				defaultCommissionEnabled: this.defaultCommissionEnabled,
				defaultCommissionAmount: this.defaultCommissionAmount,
			}

			this.loading = true
			const client = await this.$httpPost('/client-commission', data)
			await this.$store.commit('setSelectedClient', client);
			this.loading = false

			this.successTitle = this.$t('text.commissions')
			this.successDetail = this.$t('text.commissionSettingSuccessTitle')

		},
	},
	async mounted() {
		this.serviceProviderClicked = false

		if (this.isOperator) {
			this.viewType ? this.setActive(this.viewType) : this.setActive('serviceProviders')
			this.getServiceOptions()
		}
		else if (this.viewType) {
			this.setActive(this.viewType)
			this.itemType = this.viewType
		}
		else {
			this.setActive('ticketType')
			this.itemType = 'ticketType'
			this.getProviderOptions()
		}

		this.setSections()
	},
}
</script>

<style scoped>
.tableCard { border-radius: 5px; background-color:#808080 !important; top: 0; }
.clientOption { cursor: not-allowed; background-color: #f2f2f2 !important; }
.dialogDiv { position: absolute; right: 70px; top: 55px; width: 465px; max-width: calc(100vw - 20px); background: #ffffff; border-radius: 5px; border: thin solid #cfcfcf; box-shadow: 1px 1px 15px 0 rgba(0,0,0,0.2); padding: 20px; padding-bottom: 0px; color: #acacac; z-index: 999; }
.optionRow { min-height: 42px; border-radius: 4px; border: solid 1px rgba(0, 0, 0, 0.1); background-color: #f2f2f2; margin-top: 10px; margin-bottom: 10px; }
</style>