<template>
	<Disclosure :title="$t('text.contingentMonitorTitle')" :error="sectionMessage.error" :message="sectionMessage.message" data-cy="contingentMonitor" ref="SECTION_contingentMonitor">
		<p class="helpText" v-html="$t('text.contingentMonitorHelp')"/>
      
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn variant="outlined" class="mr-4" color="grey-darken-2" @click="setToday">{{$t('text.today')}}</v-btn>
              
              <v-spacer/>
              <v-btn class="rounded-circle" width="36" height="36" elevation="0"  @click="prev"><v-icon size="small">mdi-chevron-left</v-icon></v-btn>
              <v-spacer style="max-width:30px"/>
              <v-toolbar-title style="min-width:175px;text-align:center" v-if="$refs.calendar"> {{ $refs.calendar.title }} </v-toolbar-title>
              <v-spacer style="max-width:30px"/>
              <v-btn class="rounded-circle" width="36" height="36" elevation="0"  @click="next"><v-icon size="small">mdi-chevron-right</v-icon></v-btn>
              <v-spacer/>

              <v-menu location="end">
                <template v-slot:activator="{ props }">
                  <v-btn variant="outlined" color="grey-darken-2" v-bind="props">
                    <span>{{ $t('text.'+typeToLabel[type]) }}</span>
                    <v-icon end>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>{{ $t('text.day') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>{{ $t('text.week') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>{{ $t('text.month') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="600">
			<!-- TODO: v-calendar is not on vuetify labs yet either.
				so we will have to re-implement. can we use the business hours component?
			-->
            <!-- TODO implement v-calendar
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="green-lighten-3"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              :weekdays="weekday"
              :locale="$store.state.selectedLocale"
              @click:more="viewDay"
              @update:modelValue="viewDay"
              @change="buildCalendar"
            >
              <template v-slot:event="{ event }">
                <div class="event-label">
                  <strong>{{ event.name }} {{ $t('text.booked')}}</strong><br>
                </div>
              </template>
            </v-calendar>
            TODO implement v-calendar -->

            <!-- Contingent Detail View-->
            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement">
              <v-card color="grey-lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark :title="selectedEvent.name">
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn variant="text" color="secondary" @click="selectedOpen = false">
                    {{ $t('text.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>

		<!-- Day View -->
		<Dialog ref="dayDialog"
			:showClose="true"
			width="75%"
			height="50%"
			:title="contingentDetailTitle"
		>
			<template #content>
				<ContingentDetails :product="product" :eventDate="selectedDate" :contingents="selectedContingents" @switch-view="dayDialogTitle"/>
			</template>
		</Dialog>
	</Disclosure>
</template>

<script>
import Disclosure from '@/components/common/Disclosure.vue'
import Dialog from '@/components/common/Dialog.vue'
import ContingentDetails from './ContingentDetails.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ContingentCalendar',
	components: { Disclosure, Dialog, ContingentDetails },
	mixins: [ Common ],
	props: {
		product: Object,
	},
	data: () => ({
		sectionMessage: {
			error: false,
			message: '',
		},

		client: this.$store.state.selectedClient,
		contingents: [],

		focus: '',
		type: 'month',
		typeToLabel: {
			month: 'month',
			week: 'week',
			day: 'day',
		},
		weekday: [1, 2, 3, 4, 5, 6, 0],

		selectedEvent: {},
		selectedDate: null,
		selectedElement: null,
		selectedOpen: false,
		events: [],

		selectedContingents: [],
		eventsByDate: {},
		contingentDetailTitle: '',
	}),
  methods: {
    dayDialogTitle(value) {
      if (value.view === 'orders') { 
        this.contingentDetailTitle = `${this.$t('text.orders')}: ${this.selectedDate} ${value.time}`
      } else {
        this.contingentDetailTitle = `${this.$t('text.cmTimeslots')}: ${this.selectedDate}`
      }
    },
    viewDay ({ date }) {
      this.focus = date
      this.selectedContingents = this.eventsByDate[date]
      this.selectedDate = date
      this.contingentDetailTitle = `${this.$t('text.cmTimeslots')}: ${this.selectedDate}`
      this.$refs.dayDialog.show = true
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    async getProductContingents() {
      try {
        this.contingents = await this.$httpGet(`/contingents/${this.client.fields.clientId.de}/${this.product.sys.id}`)
      } catch (error) {
        this.showError(error)
      }
    },
    buildCalendar() {
      const events = []
      this.eventsByDate = {}

      if (this.contingents?.space) {
        for (const availability of this.contingents.availabilities) {
          const availabilityDateTime = availability.from
          const availabilityDate = availabilityDateTime.split("T")[0]
          const availabilityTime = availabilityDateTime.split('T')[1]
          const allDay = availabilityTime === '00:00:00.000Z' ? true : false
          const bookedTotal = (availability.total - availability.free) + (availability.overbookFree * -1)
          const date = new Date(availabilityDate)
          
          if (!this.eventsByDate[availabilityDate]) { this.eventsByDate[availabilityDate] = [] }
          this.eventsByDate[availabilityDate].push(availability)

          let eventName = this.formatTime(availabilityDateTime) !== '00:00' ? `${this.formatTime(availabilityDateTime)} ${bookedTotal}/${availability.total}` : `${bookedTotal}/${availability.total}`
          
          events.push({
            name: eventName,
            start: date,
            end: date,
            color: bookedTotal === 0 ? 'green' : bookedTotal > availability.total ? 'red' : 'orange',
            timed: !allDay,
          })
        }
      }
      
      this.events = events
    },
    formatTime(date) {
      return date.split('T')[1].substring(0,5)
    }
  },
	async mounted() {
		if (this.product?.fields?.ticket?.de?.fields?.ticketOptions?.de?.[0]?.fields?.spaceId?.de) {
			this.getProductContingents()
		}
	},
}
</script>

<style scoped>
.event-label { font-size: 14px; }
</style>