<template>
    <div :class="{
        'control-chip': true,
        [color]: !!color,
        'disabled': disabled,
    }">
        <div>{{ title }}</div>
        <v-icon
            v-if="closeable"
            class="closeable-icon"
            icon="mdi-close"
            @click="$emit('remove')"
        />
        <v-overlay
            v-if="loading"
            contained
            class="align-center justify-center loading-overlay"
            scroll-strategy="block"
            persistent
            :scrim="true"
            :model-value="loading"
        >
            <v-progress-circular
                color="primary"
                indeterminate
                size="12"
                width="2"
            />
        </v-overlay>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        closeable: Boolean,
        loading: Boolean,
        disabled: Boolean,
        color: {
            type: String,
            validator(value) {
                return ['white', 'lightblue', 'blue', 'red', 'lightgreen', 'green', 'yellow'].includes(value)
            }
        }
    }
}
</script>

<style lang="scss">
.control-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 24px;
    padding: 3px 8px;
    border-radius: 6px;
    background-color: #dbdbdb;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.09px;
    color: #000;
    width: fit-content;
    white-space: nowrap;

    &.disabled {
        background-color: #dbdbdb;
        color: #8f8f8f;
        pointer-events: none;
    }

    &:not(.disabled) {
        &.blue {
            background-color: #00aeef;
            color: #fff;
        }

        &.lightblue {
            background-color: #aae4fa;
        }

        &.red {
            background-color: #f34545;
            color: #fff;
        }

        &.yellow {
            background-color: #ffda80;
        }

        &.green {
            background-color: #cbedb6;
        }

        &.lightgreen {
            background-color: #e5f6da;
        }

        &.white {
            background-color: #fff;
            border: 1px solid #000;
        }
    }



    .loading-overlay.v-overlay {
        background-color: rgba(255, 255, 255, 0.70) !important;

        .v-progress-circular__overlay {
            stroke: #00aeef !important;
        }
    }

    .closeable-icon {
        font-size: 18px;
        margin-left: 8px;
    }
}
</style>