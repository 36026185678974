<template>
	<Application :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :loading="loading" :name="'Tag Manager'">
		<template #navbar>
			<v-text-field variant="outlined" density="compact" clearable hide-details
				data-cy="search"
				prepend-inner-icon="mdi-magnify"
				:placeholder="$t('text.search')"
				v-model="searchString"
				@keyup.enter="offset=0;search()"
				@click:clear="searchString='';offset=0;load()"
			/>

			<v-btn
				size="x-small"
				class="gradientButton mx-2"
				elevation="0"
				data-cy="bulkSet"
				v-if="selected.length"
				@click="bulkSet()">
				{{ $t('text.bulkSetTags') }} {{ selected.length }}
			</v-btn>

			<v-btn-toggle>
				<v-btn :class="[tagMode==='UNTAGGED' ? 'firstButton_active' : 'firstButton']" elevation="0" data-cy="untagged" @click="switchMode('UNTAGGED')">
					<v-icon class="d-md-none">mdi-tag-off</v-icon>
					<span class="d-none d-md-inline">{{ $t('text.untagged') }}</span>
				</v-btn>
				<v-btn :class="[tagMode==='MANAGE' ? 'lastButton_active' : 'lastButton']" elevation="0" data-cy="tagged" @click="switchMode('MANAGE')">
					<v-icon class="d-md-none">mdi-tag</v-icon>
					<span class="d-none d-md-inline">{{ $t('text.tagged') }}</span>
				</v-btn>
			</v-btn-toggle>

			<!-- Filters -->
			<FilterMenu v-model="this.$store.state.filter" @clear="clearFilter" @apply="applyFilter" openOnHover>
				<!-- Tag Filter -->
				<FilterFieldWrap v-if="filterMode === 'tags'" dataCy="tagFilter">
					<span style="width:88%">{{$t('text.tags')}}</span>
					<v-select
						v-model="selectedTags"
						:items="tags"
						:item-title="item => item"
						:item-value="item => item"
						:placeholder="$t('text.allLabel')"
						multiple
						chips
						hide-details
						density="compact"
						variant="outlined"
					>
						<template #selection="{ item }">
							<v-chip :style="getTagStyle(item.substring(0,3).toUpperCase())">{{item.substring(0,3).toUpperCase()}}</v-chip>
						</template>
					</v-select>
				</FilterFieldWrap>

				<!-- Status Filter -->
				<FilterFieldWrap dataCy="statusFilter">
					<div>{{$t('text.status')}}</div>
					<v-select
						v-model="selectedStatuses"
						:items="statuses"
						:item-title="item => $t('text.' + item.id)"
						:item-value="item => item.id"
						:placeholder="$t('text.allLabel')"
						multiple
						chips
						hide-details
						density="compact"
						variant="outlined"
					>
						<template #selection="{ item }">
							<v-chip>{{$t('text.' + item.id)}}</v-chip>
						</template>
					</v-select>
				</FilterFieldWrap>
			</FilterMenu>
		</template>

		<v-card class="tableCard">
			<v-data-table fixed-header disable-sort show-select
				v-model="selected"
				:mobile-breakpoint="700"
				:headers="headers"
				:items="items"
				:item-value="item => item"
				:items-per-page="limit"
			>
<!-- ATT: now item is a wrapper object, so bindings have to reference item  -->
				<template v-slot:item.title="{ item }">
					<div @click="select(item)" class="d-flex fill-height align-center">
						<template v-if="item.fields?.title">{{ item.fields?.title?.de }}</template>
						<template v-if="!item.fields?.title">{{ item.sys?.id }}</template>
					</div>
				</template>
				<template v-slot:item.tags="{ item }">
					<div @click="select(item)">
						<Tag v-for="tag of item.fields?.tags?.de ?? []" :key="tag" :value="tag"></Tag>
					</div>
				</template>
				<template v-slot:item.status="{ item }">
					<ApprovalStatus :status="item?.addl?.statusClient" />
				</template>
				<template #bottom>
					<TablePaginator v-model="offset" @update:modelValue="load()" :limit="limit" :results="items" :total="total" />
				</template>
			</v-data-table>

			<Dialog ref="dialog"
				:title="selectedItem && selectedItem.fields.title ? selectedItem.fields.title.de : ''"
				:confirm-label="$t('text.save')"
				:confirm-handler="dialogConfirm"
				:cancel-label="$t('text.cancel')"
				:showClose="false"
				:cancel-handler="dialogCancel"
			>
				<template #content>
					<div>
						<v-row v-for="tag of canManageTags" :key="tag">
							<input type="checkbox"
								v-model="selectedItem.tags[tag]"
								:id="'tag-' + tag"
								style="width: 20px; height: 20px; margin: 4px 10px 10px;"
							/>
							<label :for="'tag-' + tag" style="font-size: larger">{{ tag }}</label>
						</v-row>
					</div>
				</template>
			</Dialog>
		</v-card>
	</Application>
</template>

<script>
/* VUE3 MIGRATION GUIDE
Menu
- event has to happen on button, v-row, etc dont cast events
Data Table
- headers now has { title, key } instead of { text, value }
- we need item-value on table to identify rows
- replacing the footer has to be done with a template #bottom
	- add <template #bottom><TablePaginator...></template>
	- remove hide-default-footer
- item is not bound to the raw item, instead we must use item
*/

import Dialog from '@/components/common/Dialog.vue'
import Loading from 'vue-loading-overlay'
import ApprovalStatus from '@/components/common/ApprovalStatus.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Alert from '@/components/common/Alert.vue'
import Application from '../Application.vue'
import Tag from '@/components/common/Tag.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'

export default {
	name: 'TagManagerView',
	components: { ApprovalStatus, Loading, Dialog, TablePaginator, Alert, Application, Tag, FilterMenu, FilterFieldWrap },
	data: () => ({
		locale: 'de', // TODO
		loading: false,
		items: [],
		selected: [],
		selectedItem: null, // contains a copy of the selected item (or an artificial model in case of bulk edit)
		selectedItemsOriginal: null, // contains a reference to the selected item for update
		limit: 15,
		offset: 0,
		errorTitle: null,
		errorDetail: null,
		currentTagMode: null,
		tagColors: ['#00bcd4', '#f44336', '#8bc34a', '#e81e63', '#9c27b0', '#ffc107','#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#009688', '#4caf50', '#cddc39',  '#ff9800', '#ff5722' ],
		uniqueTags: [],
		tagMode: 'UNTAGGED',
		searchString: '',
		tags: [],
		selectedTags: [],
		total: 0,
		filterModes: [ 'tags', 'serviceProviders' ],
		filterMode: 'serviceProviders',
		selectedStatuses: {id:'ALL', color:'grey'},
		statuses: [
			{ id: 'pending', color: '#ff9e21', dark: true },
			{ id: 'reapprove', color: '#ffb400' },
			{ id: 'approved', color: '#64c823', dark: true },
			{ id: 'deactivated', color: '#f34545', dark: true },
			{ id: 'declined', color: '#f34545', dark: true },
		],
	}),
	computed: {
		headers() {
			return [
				{ title: this.$t('text.serviceProvider'), key: 'title', sortable: false },
				{ title: this.$t('text.tags'), key: 'tags', width: '33%', sortable: false },
				{ title: this.$t('text.status'), key: 'status', width: '200', align: 'center', sortable: false },
			]
		},
		canManageTags() {
			const tags = this.$store.state.loggedInUser.fields.canManageTags?.de ?? []
			return tags.filter(tag => tag != 'ALL')
		},
	},
	watch: {
		selectedItem(item) {
			this.$refs.dialog.show = !!item
		},
	},
	methods: {
		switchMode(mode) {
			if (mode === "UNTAGGED") {
				this.filterMode = 'serviceProviders'
			} else {
				this.filterMode = 'tags'
			}

			this.$store.state.filter = null
			this.selectedStatuses = []
			this.selectedTags = []

			this.tagMode = mode
			this.offset = 0
			this.searchString = ''
			this.load()
		},
		async load(url) {
			this.loading = true
			try {
				if (!url) url = `/serviceproviders?clientId=${this.$store.state.selectedClient.sys.id}&skip=${this.offset}&limit=${this.limit + 1}&tagMode=${this.tagMode}`

				if (this.$store.state.filter?.tags && this.selectedTags.length > 0) {
					url += `&tags=${this.selectedTags}`
				}
				else {
					if (!this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
						url += `&tags=${this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de}`
					}
				}

				if (this.$store.state.filter?.statuses) {
					url += `&filter=${JSON.stringify(this.$store.state.filter)}`
				}

				if (this.searchString) url+= `&searchString=${this.searchString}`

				const data = await this.$httpGet(url)
				let items = data.serviceProviders
				this.total = data.total

				// we add some ui information
				if  (items?.length > 0) {
					items = this.setItemInformation(items)
					this.items = items
				}
				else {
					this.items = []
				}
			}
			catch (e) {
				if (e.response?.status === 401) {
					this.$emit("show-login")
				}
				else if (e.response?.status == 400) {
					this.items = []
				}
				else {
					console.error(e)
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = e.response?.data.error ?? e
				}
			}
			this.loading = false
		},
		setItemInformation(items) {
			for (const item of items) {
				// set status to the status of the clientAssignment of the calling clientId
				item.addl = {
					statusClient: 'INVALID',
					isHomebase: false,
					clientAssignmentInfos: [],
				}
			
				for (let ca of item.fields.clientAssignments?.de ?? []) {
					if (!ca.fields?.client?.de?.fields?.clientId?.de) {
						continue
					}

					//Move Homebase to front of clientassignments for UI
					if (ca.fields?.isHomebase?.de === true) {
						item.addl.clientAssignmentInfos.unshift({
							clientId: ca.fields.client.de.fields.clientId.de,
							status: ca.fields.status.de,
						})
					} else {
						item.addl.clientAssignmentInfos.push({
							clientId: ca.fields.client.de.fields.clientId.de,
							status: ca.fields.status.de,
						})
					}

					if (ca.fields.client.de.sys.id != this.$store.state.selectedClient.sys.id) continue
					item.addl.statusClient = ca.fields.status.de
					item.addl.isHomebase = !!ca.fields?.isHomebase?.de
					item.fields.tags = {de: ca.fields.tags?.de ? ca.fields.tags.de : []}
				}
				
				if (!item.fields.tags) item.fields.tags = { de: [] }
			}
			return items
		},
		select(item) {
			this.selectedItemsOriginal = [ item ]
			item = JSON.parse(JSON.stringify(item))
			item.tags = {}
			// TODO: remove! we repair broken data
			if (item.fields?.tags?.de && !Array.isArray(item.fields.tags.de)) item.fields.tags.de = []
			for (const tag of item.fields?.tags?.de ?? []) {
				item.tags[tag] = true
			}
			this.selectedItem = item
		},
		bulkSet() {
			this.selectedItemsOriginal = this.selected

			// we have to add all possible tags to the model, because we have to distinguish even false values from "not-my-values"
			const tags = {}
			for (const tag of this.$store.state.loggedInUser.fields.canManageTags.de) {
				tags[tag] = false
			}
			this.selectedItem = {
				fields: {
					title: { de: this.$t('text.bulkSetTags') },
				},
				// TODO: consolidate the current array from all selected items?
				tags: tags,
			}
		},
		async dialogConfirm() {
			this.loading = true
			this.selectedItem.fields.title = undefined
			for (const item of this.selectedItemsOriginal) {
				try {
					// we merge the tags from the dialog into the item tags
					const tagsMap = {}
					for (const tag of item.fields?.tags?.de ?? []) {
						tagsMap[tag] = true
					}
					for (const tag in this.selectedItem.tags) {
						if (!this.selectedItem.tags[tag])
							tagsMap[tag] = undefined
						else
							tagsMap[tag] = true
					}
					const tags = []
					for (const tag in tagsMap) {
						if (!tagsMap[tag]) continue
						tags.push(tag)
					}

					await this.$httpPut(`/serviceProvider/${item.sys.id}/tags/${this.$store.state.selectedClient.sys.id}`, tags)

					// ATT: we update the ui without loading new
					item.fields.tags = { de: tags }

					if (this.searchString==='') {
						this.load()
					}
				}
				catch (e) {
					console.error(e)
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = e.response?.data.error ?? e
				}
			}
			this.selectedItem = null
			this.selectedItemsOriginal = []
			this.selected = []
			this.loading = false
			return true
		},
		dialogCancel() {
			this.selectedItem = null
			this.selectedItemsOriginal = []
			this.selected = []
		},
		applyFilter() {
			if (this.filterMode === 'tags') {
				this.tagMode = "MANAGE"
				this.$store.state.filter = {tags: this.selectedTags, statuses: this.selectedStatuses}

				// if user deselects all filters and clicks apply filter -> act the same as if user clicked clear filter
				if (this.$store.state.filter.statuses?.length === 0 && this.$store.state.filter.tags?.length === 0) {
					this.clearFilter()
				}
			} else {
				this.tagMode = "UNTAGGED"
				this.$store.state.filter = {statuses: this.selectedStatuses}

				// if user deselects all filters and clicks apply filter -> act the same as if user clicked clear filter
				if (this.$store.state.filter.statuses?.length === 0) {
					this.clearFilter()
				}
			}

			this.offset = 0
			this.load()
		},
		clearFilter() {
			this.offset = 0
			this.selectedTags = []
			this.selectedStatuses = []
			this.searchString = ""
			this.$store.state.filter = null
			this.load()
		},
		async search() {
			try {
				this.loading = true

				const data = {
					clientId: this.$store.state.selectedClient.sys.id,
					contentType: 'serviceProvider',
					skip: this.offset,
					limit: this.limit + 1,
					searchString: this.searchString,
					tagMode: this.tagMode,
					filter: this.$store.state.filter,
				}
	
				if (this.$store.state.filter?.tags && this.selectedTags.length > 0) {
					data.tags = this.selectedTags
				} else if (!this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de.find(x => x === "ALL")) {
					data.tags = this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de
				}
	
				const response = await this.$httpPost('/search', data)
	
				let items = response.serviceProviders ?? []
				this.total = response.total ?? 0
	
				// we add some ui information
				if (items?.length > 0) {
					items = this.setItemInformation(items)
				}
	
				this.items = items
			} catch (e) {
				if (e.response?.status === 401) {
					this.$emit("show-login")
				}
				else if (e.response?.status == 400) {
					this.items = []
				}
				else {
					console.error(e)
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = e.response?.data.error ?? e
				}
			} finally {
				this.loading = false
			}
		},
		getColor(tag) {
			return this.uniqueTags.find(uniqueTag => uniqueTag.label === tag)?.colour
		},
		getTagStyle(tag) {
			const colour = this.uniqueTags.find(uniqueTag => uniqueTag.label === tag)?.colour
			return `border:solid 3px ${colour} !important; color:${colour} !important;`
		},
	},
	mounted() {
		this.load()
	
		this.tags = this.$store.state.loggedInUser.fields?.canManageTags?.de
		this.selectedTags = this.$store.state.filter ? this.$store.state.filter.tags : []
		this.selectedStatuses = this.$store.state.filter ? this.$store.state.filter.statuses : []

		if (!this.uniqueTags.length && this.tags?.length) {
			this.uniqueTags = []
			for (let i=0; i < this.tags.length; i++) {
				this.uniqueTags.push({
					label: this.tags[i].substring(0,3).toUpperCase(),
					colour: this.tagColors[i]
				})
			}
		}
	},
}
</script>

<style scoped>
.dot-container { width: 75px; height: 75px; position: absolute; top: 0; right: 0; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: -1px; right: -1px; }
</style>