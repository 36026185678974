<template>
	<table class="table">
		<tr>
			<td>
			<th>
				<p>{{ $t('text.mainCategory') }}</p>
			</th>
			</td>
			<td></td>
			<td>
			<th>
				<p>{{ $t('text.subCategory') }}</p>
			</th>
			</td>
		</tr>
		<tr v-for="item of items" :key="item.id + item.mainCategory">
			<td>
				<div class="category-chip" style="width: fit-content;">
					<p>{{ item.mainCategory.charAt(0).toUpperCase() + item.mainCategory.slice(1) }}</p>
				</div>
			</td>

			<td>
				<v-icon>mdi-chevron-right</v-icon>
			</td>

			<td>
				<div class="chip-container">
					<div v-for="subCategory of item.subCategory" :key="item.id + subCategory" class="category-chip">
						<p>{{ subCategory }}</p>
					</div>
				</div>
			</td>
		</tr>
	</table>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'CategoriesTable',
	mixins: [Common],
	props: {
		items: {
			type: Object,
			default: () => { },
		},
	},
}
</script>

<style scoped lang="scss">
.table {
	width: 100%;
	border-collapse: collapse;

	tr {
		cursor: default;
	}

	tr:first-child {
		border-bottom: 1px solid grey;

		td {
			padding-bottom: 8px;
		}
	}

	tr:not(:first-child) td {
		padding-top: 8px;
	}

	.chip-container {
		display: flex;
		gap: 5px;
	}

	.category-chip {
		background-color: #DADADA;
		padding: 2px 5px;
		border-radius: 5px;
	}
}
</style>
