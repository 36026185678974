<template>
	<Application :loading="loading">
		<template #navbar>
			<v-spacer />
			<v-btn-toggle>
				<v-btn id="btnShowServices" class="firstButton" elevation="0" @click="showServices">
					<v-icon class="d-lg-none">mdi-cart</v-icon>
					<span class="d-none d-lg-inline">{{$t('text.services')}}</span>
				</v-btn>
				<v-btn id="btnShowFAQs" class="lastButton_active" elevation="0" @click="showFAQs">
					<v-icon class="d-lg-none">mdi-frequently-asked-questions</v-icon>
					<span class="d-none d-lg-inline">{{$t('text.faqs')}}</span>
				</v-btn>
			</v-btn-toggle>
			<v-btn id="addFAQ" class="gradientButton" size="small" elevation="0" @click="showAddFAQ">
				<v-icon>mdi-plus</v-icon>
				<span class="d-none d-md-inline">{{$t('text.addFAQ')}}</span>
			</v-btn>
		</template>

		<v-card class="tableCard">
			<v-data-table
				:headers="headers"
				:items="faqGroups"
				fixed-header
				hide-default-footer
				:items-per-page="50"
				:item-value="item => item"
			>
				<template v-slot:item="{ item }">
					<tr @click="showFAQDetail(item)">
						<td>{{ item.fields?.title?.[selectedLocale] ?? '' }} </td>
						<td>{{ item?.sys?.createdAt?.split?.('T')?.[0] ?? '' }}</td>
						<td><v-chip :color="getColor()" dark>{{ $t(`text.${ status.toLowerCase() }`) }}</v-chip></td>
					</tr>
				</template>
				<template #bottom></template>
			</v-data-table>
		</v-card>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay';
import Common from '@/mixins/Common.vue'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js';
import eventBus from '@/utils/eventBus.js';
import Application from '../Application.vue';

export default {
	mixins: [Common],
	components: { Loading, Application },
	data: () => ({
		startRecCount: 0,
		endRecCount: 25,
		pageRecCount: 25,
		totalRecCount: 0,
		loading: false,
		faqGroups: [],
		status: 'published',
		searchString: ''
	}),

  computed: {
    headers () {
      return [
        { title: this.$t('text.FAQTitle'), key: 'title' },
        { title: this.$t('text.createDate'), key: 'createDate', width: "200" },
        { title: this.$t('text.status'), key: 'status', width: "200" }
      ]
    }
  },

  created () {
    FreshdeskWidget.methods.prefill('MyServices')
  },

  async mounted() {
    this.getFAQs()

    await this.$store.commit('setSelectedFAQGroup', {});
  },

  methods: {
    getColor () {
      return '#66BB6A'
    },

    /* Server Functions */
    getFAQs() {
      this.loading = true;

      this.$httpGet(`/faqs?serviceProviderId=${this.$store.state.selectedServiceProvider.sys.id}&skip=${this.startRecCount}&limit=${this.pageRecCount}`).then(res => {
        this.faqGroups = res.faqGroups;
        this.loading = false
      }).catch(error => {
        this.errorAlertVisible = true;
        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
      });
    },

    showFAQDetail(item) {
      this.loading = true;

      this.$httpGet(`/faq?faqId=${item.sys.id}`).then(async res => {
        this.loading = false
        await this.$store.commit('setSelectedFAQGroup', res.faqGroup);
        this.$router.push('/faq-detail');
      }).catch(error => {
        this.errorAlertVisible = true;
        this.errorTitle = this.$t('text.ERROR');

        if (error.response) {
          this.errorDetail = error.response.data.error;
        } else {
          this.errorDetail = error;
        }
      });
    },
    search() {
      eventBus.$emit('search', this.searchString);
    },
    clear() {
      eventBus.$emit('search', "");
    },
    async showAddFAQ() {
      await this.$store.commit('setSelectedFAQGroup', null);
      this.$router.push('/faq-detail');
    },
    showServices() {
      this.$router.push('/services');
    },
    showFAQs() {
      this.$router.push('/faq-list');
    },
  },
}
</script>

<style scoped>
.container {
  padding: 0 !important;
  width:100% !important;
}
.tableCard {
  border-radius:5px;
  background-color:#808080 !important;
  top: 0;
}
.v-text-field.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 12px !important;
}
.v-text-field.v-text-field--enclosed .v-input__append-inner {
  margin-top: 12px !important;
}
</style>