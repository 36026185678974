<template>
	<DialogV2
		ref="dialog" 
		:height="height"
		:width="width"
		:title="title"
		:confirmLabel="confirmLabel || $t('text.confirm')"
		:onConfirm="handleConfirm"
		:isValid="!error"
	>
		<template v-if="$slots.sidebar" #sidebar>
			<slot name="sidebar" :model="model" :wrap="wrap" :updateModel="updateModel" />
		</template>
		<template #content>
			<slot name="content" :model="model" :wrap="wrap" :updateModel="updateModel" />
		</template>
		<template #buttons>
			<div class="error" v-if="error">
				{{ error }}
			</div>
		</template>
	</DialogV2>
</template>

<script>
// TODO: this comp is basically the same as DataDialog - we should refactor to remove the need for it.
import DialogV2 from './DialogV2.vue'

export default {
	name: 'ServerDataDialog',
	components: { DialogV2 },
	props: {
		modelValue: Object,
		title: String,
		height: String,
		width: String,
		validator: Function,
		cancelLabel: String,
		confirmLabel: String,
		onConfirm: Function,
		// if switched on, out-binding will use wrap instead of model
		// this is useful in cases where the integrator would have to use v-model="model" (which does not work)
		// in such cases use it like this:
		// <DataDialog v-model="wrap.model" :useWrap="true">
		//    <template #content="{ wrap }">
		//       <v-checkbox v-model="wrap.model" />
		//    </template>
		// </DataDialog>
		useWrap: Boolean,
	},
	data: () => ({
		model: null,
		error: null,
		wrap: { model: null },
	}),
	watch: {
		modelValue(n) {
			if (!n) return
			this.model = JSON.parse(JSON.stringify(n))
			this.wrap.model = this.model
		},
		model: {
			deep: true,
			handler(n) {
				this.error = this.validator?.(n) ?? null
			},
		},
	},
	methods: {
		updateModel() {
			if (this.useWrap)
				this.$emit('update:modelValue', this.wrap.model)
			else
				this.$emit('update:modelValue', this.model)
		},
		cancel() {
			this.$refs.dialog.open()
		},
		async handleConfirm() {
			this.updateModel()
			await this.onConfirm?.()
		},
		open() {
			this.model = JSON.parse(JSON.stringify(this.modelValue))
			this.wrap.model = this.model
			this.$refs.dialog.open()
		},
		close() {
			this.$refs.dialog.close()
		},
	},
}
</script>

<style scoped>
.error { color: darkred; }
</style>