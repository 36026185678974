<template class="justify-center">
	<div>
		<div class="field left-border">
			<v-menu v-if="index > 0">
				<template v-slot:activator="{ props }">
					<div v-bind="props" style="float: right;">
						<v-icon color="#737373" style="cursor:pointer">mdi-dots-horizontal</v-icon>
					</div>
				</template>
				<v-list class="pa-0">
					<v-list-item style="cursor:pointer !important;" @click="removeQuestion">
						<v-list-item-title>{{$t('text.delete')}}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-label>{{$t('text.question')}}</v-label>
			<LanguageFlag v-model="serviceLocale" class="langFlag" />
			<v-text-field variant="outlined" required density="compact"
				v-if="faqComponent.fields.question"
				v-model="faqComponent.fields.question[serviceLocale]"
				@keydown="changeStatus"
			/>
		</div>
		<div class="field left-border">
			<v-label>{{$t('text.answer')}}</v-label>
			<LanguageFlag v-model="serviceLocale" class="langFlag" />
			<v-textarea variant="outlined"
				v-if="faqComponent.fields.answer"
				v-model="faqComponent.fields.answer[serviceLocale]"
				@keydown="changeStatus"
			/>
		</div>
	</div>
</template>

<script>
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Common from '@/mixins/Common.vue'

export default {
	props: {
		faqComponent: Object,
		index: Number,
	},
	mixins: [ Common ],
	components: { LanguageFlag },
	methods: {
		changeStatus() {
			this.$emit("status-changed")
		},
		removeQuestion() {
			this.$emit("remove-question")
		},
	},
	mounted() {
		if (!this.faqComponent.fields.question?.de) this.faqComponent.fields.question = { de: '' }
		if (!this.faqComponent.fields.answer?.de) this.faqComponent.fields.answer = { de: '' }
		this.$forceUpdate()
	},
}
</script>

<style scoped>
.enabled-text-style .v-input__control > .v-input__slot {
	background: #ffffff !important;
}
.langFlag { position: absolute; margin-top: 29px; margin-left: -29px; zoom: 0.8; }
</style>
