export default {
  computed: {
    milestones() {
      let allItems = this.paymentPolicyItems.concat(this.cancellationPolicyItems)

      /*
                Sort all payment and cancellation policy items like this: 
                {
                    "DAYS_AFTER_PURCHASE": {
                        "15": {
                            payment: {....},
                            cancellation: {...,}
                        },
                        "30": { 
                            ... 
                        }
                    },
                    ...
                }
            */

      const milestoneTree = allItems.reduce((milestoneTree, item) => {
        const type = item.type
        const milestone = item.time
        const timingType = milestone.type
        const days = milestone.value

        if (!timingType || !days) {
          return milestoneTree
        }

        if (!milestoneTree?.[timingType]) {
          milestoneTree[timingType] = {}
        }
        if (!milestoneTree?.[timingType]?.[days]) {
          milestoneTree[timingType][days] = {}
        }
        milestoneTree[timingType][days][type] = item
        return milestoneTree
      }, {})

      const calculateMilestones = (timingType) => {
        const milestones = milestoneTree?.[timingType] ?? {}
        return Object.keys(milestones).map((days) => {
          const payment = milestoneTree[timingType][days]?.payment
          const cancellation = milestoneTree[timingType][days]?.cancellation
          return {
            type: timingType,
            days: Number(days),
            payment,
            cancellation
          }
        })
      }

      /*
        Gather all milestones per timing type in arrays with this structure:
        [
            {
                type: <TimingType> (one of"DAYS_AFTER_PURCHASE" / "DAYS_BEFORE_CONSUMPTION" / "PAY_AT_SERVICE_PROVIDER")
                days: <Integer>
                payment: <PaymentPolicyItem>
                cancellation: <CancellationPolicyItem>
            }
        ]
    */
      const afterPurchase = calculateMilestones('DAYS_AFTER_PURCHASE').sort(
        (itemA, itemB) => itemA.days - itemB.days
      )
      const beforeConsumption = calculateMilestones('DAYS_BEFORE_CONSUMPTION').sort(
        (itemA, itemB) => itemB.days - itemA.days
      )
      const payAtServiceProvider = calculateMilestones('PAY_AT_SERVICE_PROVIDER')

      let milestones = afterPurchase
        .concat({ type: 'divider' })
        .concat(beforeConsumption)
        .concat(payAtServiceProvider)

      const hasAtPurchase = afterPurchase.some((item) => item?.days === 0)
      if (!hasAtPurchase) {
        milestones.unshift({
          days: 0,
          icon: 'mdi-cart',
          type: 'purchase'
        })
      }

      milestones.push({
        icon: 'mdi-calendar-today',
        type: 'consumption'
      })

      return milestones
    },

    subtotals() {
      //Calculate the subtotals between the payment milestones
      return this.milestones
        .filter((milestone) => !!milestone.payment)
        .reduce((subtotals, { payment }) => {
          const amount = Number(payment?.amount?.valuePercent)
          if (subtotals.length === 0) {
            subtotals.push(amount)
          } else {
            const index = subtotals.length - 1
            const previousAmount = subtotals[index]
            const subtotal = previousAmount + amount
            if (subtotal < 100) {
              subtotals.push(subtotal)
            }
          }
          return subtotals
        }, [])
    },

    paymentTotal() {
      return this.milestones.reduce((total, milestone) => {
        const payment = milestone.payment
        if (!payment) {
          return total
        }

        const time = payment.time
        if (!time.type || !time.value) {
          return total
        }

        const amount = Number(payment?.amount?.valuePercent)
        return total + amount
      }, 0)
    },

    paymentPolicyItems() {
      const items = this.paymentPlan?.paymentPolicy?.items ?? []
      return items.map((item) => {
        return {
          ...item,
          icon: 'mdi-cash-multiple',
          type: 'payment'
        }
      })
    },

    cancellationPolicyItems() {
      const items = this.paymentPlan?.cancellationPolicy?.items ?? []
      return items.map((item) => {
        return {
          ...item,
          icon: 'mdi-close-circle',
          type: 'cancellation'
        }
      })
    }
  }
}
