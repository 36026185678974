<template>
	<v-timeline class="stepper" direction="horizontal" truncate-line="both" density="compact" :line-thickness="4"
		:style="{ maxWidth, 'min-width': maxWidth }">
		<v-timeline-item v-for="(step, i) in displayedSteps" :key="i" :class="{
			active: step.active,
			error: step.error,
			warning: step.warning,
			clickable: step.clickable,
			'is-next-active': isNextActive(step)
		}" fill-dot density="compact" :size="24" :max-height="24" @click="activateStep(step)">
			<template #icon>
				<v-icon :icon="step.icon" size="x-small" />
			</template>
			<span class="step-title">{{ step.title }}</span>
		</v-timeline-item>
	</v-timeline>
</template>

<script>
export default {
	name: 'StepperV2',
	props: {
		modelValue: Number,
		steps: Array,
	},
	emits: ["update:modelValue", "update:step"],
	computed: {
		maxWidth() {
			const count = this.displayedSteps.length || 1;
			return (count * 130) + "px";
		},
		displayedSteps() {
			const currentStep = this.modelValue;
			return this.steps.map((step) => {
				const active = step.active ?? currentStep >= step.stepNr;
				return {
					...step,
					active
				}
			});
		},
	},
	methods: {
		getColor({ active }) {
			return active ? "secondary" : "grey"
		},
		isNextActive({ stepNr }) {
			//Check if next step is active
			const nextStep = this.displayedSteps.find(step => {
				return step.stepNr === (stepNr + 1)
			});
			return !!nextStep?.active
		},
		activateStep({ stepNr }) {
			this.$emit("update:step", stepNr);
		}
	},
}
</script>

<style lang="scss">
.stepper.v-timeline--horizontal {
	padding: 4px 0;

	.v-timeline-item {
		cursor: pointer;

		.v-timeline-item__body {
			display: flex !important;
			padding-block-start: 0px !important;
			margin-top: 5px !important;
		}

		.v-timeline-divider__inner-dot>.v-icon {
			font-size: 12px;
		}

		.step-title {
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: #999;
		}

		&:not(.active) .v-timeline-divider__before,
		&:not(.active.is-next-active) .v-timeline-divider__after {
			background: #ccc;
		}

		&:not(.active) .v-timeline-divider__inner-dot {
			--v-theme-overlay-multiplier: 1;
			background-color: #ccc !important;
			color: #ccc !important;

			>.v-icon {
				color: #737373;
			}
		}

		&.active {

			.v-timeline-divider__before,
			&.is-next-active .v-timeline-divider__after {
				background: #64c823;
			}

			.v-timeline-divider__inner-dot {
				--v-theme-overlay-multiplier: 1;
				background-color: #64c823 !important;
				color: #64c823 !important;

				>.v-icon {
					color: #fff;
				}
			}

			.step-title {
				color: #64c823;
			}

			&.error {
				.v-timeline-divider__inner-dot {
					--v-theme-overlay-multiplier: 1;
					background-color: #f34545 !important;
					color: #f34545 !important;

					>.v-icon {
						color: #fff;
					}
				}

				.step-title {
					color: #f34545;
				}
			}

			&.warning {
				.v-timeline-divider__inner-dot {
					--v-theme-overlay-multiplier: 1;
					background-color: #ffb400 !important;
					color: #ffb400 !important;

					>.v-icon {
						color: #fff;
					}
				}

				.step-title {
					color: #ffb400;
				}
			}

		}

		&:not(.clickable) {
			pointer-events: none;
		}
	}
}
</style>
