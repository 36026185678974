<template>
	<div class="ProFeatureTag" :class="{ inline }">
		<v-tooltip :text="$t('text.proFeature_help')" v-if="inline">
			<template v-slot:activator="{ props }">
				<div class="chip" v-bind="props">
					<v-icon size="small">mdi-alert-decagram</v-icon>
					{{ $t('text.proFeature') }}
				</div>
			</template>
		</v-tooltip>
		<div v-else class="chip">
			<v-icon size="small">mdi-alert-decagram</v-icon>
			{{ $t('text.proFeature') }}
		</div>
		<HelpIcon v-if="!inline" keyPrefix="proFeature" />
	</div>
</template>

<script lang="ts">
import HelpIcon from './HelpIcon.vue'

export default {
	components: { HelpIcon },
	props: {
		inline: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style scoped>
.ProFeatureTag { font-size: 12pt; display: flex; align-items: center; gap: 12px; color: #0b222f; }
.ProFeatureTag.inline { font-size: 10pt; gap: 4px; }
.chip { 
	display: flex;
	gap: 4px;
	align-items: center;
	padding: 2px 7px;
	border-radius: 4px;
	background: rgb(162, 226, 249);
	font-size: smaller;
	white-space: nowrap;
}
</style>