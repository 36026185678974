<template>
	<v-expansion-panels v-model="model">
		<v-expansion-panel elevation="0" :class="{ 'secondary': variant === 'secondary' }">
			<v-expansion-panel-title>
				{{ title }}
			</v-expansion-panel-title>
			<v-expansion-panel-text>
				<IfFeatureFlag flag="FEATURE_TRANSLATION_WIZARD">
					<v-btn v-if="displayEditTranslations" @click="$emit('edit:translations')" prepend-icon="mdi-translate" data-cy="editTranslationsBtn" block variant="outlined"
					color="blue" class="mb-6">
						{{ $t("text.editTranslations") }}
					</v-btn>
				</IfFeatureFlag>
				<div v-for="translation of translations" :key="translation.locale.code">
					<v-row style="cursor:pointer !important" @click="$emit('switch-locale', translation.locale.code)"> 
						<v-col>{{ $t('text.' + translation.locale.name) }}</v-col>
						<v-col class="icon-container">
							<v-row>
								<v-spacer/>
								<v-icon :color="color(translation.percentage)">{{ icon(translation.percentage) }}</v-icon>
							</v-row>
						</v-col>
					</v-row>
				</div>
			</v-expansion-panel-text>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import IfFeatureFlag from "@/components/ifFeatureFlag/IfFeatureFlag.vue"

export default {
	components: { IfFeatureFlag },
	props: {
		title: String,
		translations: Array,
		expanded: Boolean,
		variant: String,
		displayEditTranslations: Boolean,
	},
	data() {
		return {
			model: -1
		}
	},
 
	mounted() {
		if (this.expanded) {
			this.model = 0
		}
	},

	methods: {
		open() {
			this.model = 0
		},
		close() {
			this.model = -1
		},
		toggle() {
			if (this.model === -1) {
				this.open()
			} else {
				this.close()
			}	
		},
		color(percentage) {
			if (percentage === 100) return '#66BB6A'
			if (percentage < 100) return '#f34545'
		},
		icon(percentage) {
			if (percentage === 100) return 'mdi-check'
			if (percentage < 100) return 'mdi-close'
		}
	}
}
</script>

<style scoped lang="scss">
.theme--light.v-divider { border-color: #999999 !important; }

.icon-container { display: flex; align-items: center; }

.secondary { background-color: #f4f4f4; }
</style>