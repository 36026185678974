<template>
	<div style="margin: 10px 0">
		<div class="add-button">

			<v-menu >
				<template v-slot:activator="{ props }">
					<v-btn
					class="gradientButton"
					elevation="0"
					data-cy="add-button-list"
					style="margin-left: 10px"
					v-bind="props"
					:disabled="disabled"
					>
						<v-icon>{{ update ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
						{{ $t('text.' + (update ? 'btnEdit' : 'btnAdd'), { x: $t('text.' + buttonText) }) }}
						 <div class="divider"></div>
						<v-icon data-cy="add-button-list-dropdown">mdi-chevron-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="option in options" :key="option" :disabled="optionDisabled[option]" @click="handleOptionClick(option)">
						<v-row class="option" :data-cy="'offer-type-' + option.toLowerCase()">
							<v-col cols="1"> <v-icon>mdi-plus</v-icon> </v-col>
							<v-tooltip :text="$t('text.' + option + 'Info')">
								<template v-slot:activator="{ props }">
									<v-col cols="9">
										<v-list-item-title>{{ $t('text.' + option) }}</v-list-item-title>
										<v-list-item-subtitle v-if="option && $t('text.' + option + 'Info') != 'text.' + option + 'Info'" v-bind="props">{{ $t('text.' + option + 'Info') }}</v-list-item-subtitle>
									</v-col>
								</template>
							</v-tooltip>
						</v-row>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		buttonText: { type: String, default: '' },
		update: { type: Boolean, default: false },
		options: { type: Array, default: () => [] },
		disabled: { type: Boolean, default: false },
		optionDisabled: { type: Object, default: () => {} },
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
		handleOptionClick(option) {
			this.$emit('option-click', option)
		},
	},
}
</script>

<style scoped>
.add-button {
	display: flex;
	align-items: center;
	float: right;
	padding-bottom: 10px;
}
.option {
	cursor: pointer;
	display: flex;
}
.divider {
	border-left: 1px solid #000;
	height: 38px;
	margin: 0 10px;
}
</style>
