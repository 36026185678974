<template>
	<v-select class="mys-tags" multiple
		:class="{
			selected: !!model,
			nolabel: !label,
		}"
		density="compact"
		variant="outlined"
		v-model="model"
		:hide-details="hideDetails"
		:label="label"
		:disabled="disabled"
		@update="$emit('update', $event)"
		@update:modelValue="$emit('update:modelValue', $event)"
		data-cy="dataCy"
		:items="items"
		:placeholder="$t('text.' + emptyLabel)"
	>
		<template #selection="{ item }">
			<v-chip size="small">{{ getItemLabel(item) }}</v-chip>
		</template>
	</v-select>
</template>

<script>
export default {
	props: {
		modelValue: Array,
		options: Array,
		disabled: Boolean,
		label: String,
		hideDetails: { type: Boolean, default: true },
		dataCy: String,
		itemLabel: Function,
		emptyLabel: { type: String, default: 'allLabel' },
	},
	data() { return {
		model: false,
	}},
	computed: {
		items() {
			if (!this.options) return []
			return this.options.map(item => {
				if (typeof item == 'string')
					return { value: item, title: this.$t('text.' + item) }
				return item
			})
		},
	},
	watch: {
		model() {
			this.$emit('update:modelValue', this.model)
		},
		modelValue(modelValue) {
			this.model = modelValue
		},
	},
	methods: {
		getItemLabel(item) {
			if (typeof this.itemLabel == 'function') return this.itemLabel(item.raw?.value ?? item.raw)
			if (typeof this.itemLabel == 'string') return item[this.itemLabel]
			return this.item
		},
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style>
.mys-tags { margin: 0 !important; }
.mys-tags .v-switch__track { background-color: #ccc; opacity: 1; }
.mys-tags.selected .v-switch__track { background-color: #00aeef; }
</style>