<template>
	<div class="TicketOption" v-if="modelValue"
		:class="{ error: !valid }"
	>
		<v-btn class="removeRow" v-if="allowDelete" @click="$emit('delete', modelValue)">
			<v-icon color="white">mdi-delete</v-icon>
		</v-btn>
		<div class="row">
			<!-- TODO: hasField? -->
			<!-- TODO: no left border - does field have a mode for this? -->
			<Field :title="$t('text.ticketType')" required>
				<TicketTypeField v-model="modelValue.fields.ticketType.de"
					:error="ticketTypeError" 
					:disabled="productIsExternal"
					:data-cy="'ticketType_' + dataCyIndex"
				/>
			</Field>
			<Field :title="$t('text.reduction')" required>
				<ReductionField v-model="modelValue.fields.reduction.de"
					:disabled="productIsExternal"
					:error="reductionError" 
					:data-cy="'reduction_' + dataCyIndex"
				/>
			</Field>
		</div>
		<div class="row">
			<!-- TODO: model driven field on price -->
			<Field :title="$t('text.price')" required>
				<v-text-field v-model="modelValue.fields.price.de"
					type="number" hide-details variant="outlined" density="compact"
					:min="0"
					:error-messages="priceErrors"
					:disabled="productIsExternal"
					:data-cy="'price_' + dataCyIndex"
					style="background-color: #ffffff;"
				/>
			</Field>
			<mys-switch
				style="margin-top: 15px !important;"
				:label="$t('text.fromPrice')"
				v-model="modelValue.fields.fromPriceOption.de"
			/>
		</div>
		<div class="row">
			<Field v-if="hasField('ticketInfo', 'minimumTickets', modelValue)" :title="$t('text.minTickets')" required>
				<!-- TODO: model driven field -->
				<v-text-field v-model="modelValue.fields.minimumTickets.de"
					type="number" variant="outlined" density="compact"
					:min="1"
					:hide-details="!minimumTicketsErrors.length"
					:error-messages="minimumTicketsErrors"
					:disabled="productIsExternal"
				/>
				<!--
					@keypress="isNaturalNumber($event)"
				-->
			</Field>
			<Field v-if="hasField('ticketInfo', 'maximumTickets', modelValue)" :title="$t('text.maxTickets')" required>
				<v-text-field v-model="modelValue.fields.maximumTickets.de"
					hide-details variant="outlined" density="compact"
					type="number"
					min="1"
					:error-messages="maximumTicketsErrors"
					:disabled="productIsExternal"
					@keypress="isNaturalNumber($event)"
				/>
			</Field>
		</div>
		<div class="row">
			<Field v-if="hasField('ticketInfo', 'contingentQuantity', modelValue)" :title="$t('text.noOfPeople')"
				:helpText="dataCyIndex == 0 ? $t('text.ticketsDesc') : undefined"
				required
			>
				<v-text-field v-model="modelValue.fields.contingentQuantity.de"
					variant="outlined" density="compact" hide-details style="background-color:#ffffff"
					type="number" min="1"
					:disabled="productIsExternal"
					:error-messages="contingentQuantityErrors"
					@keypress="isNaturalNumber($event)"
				/>
			</Field>
		</div>
		<div v-if="externalSystem" class="externalSystem">
			<TicketOptionExternalSystemSkidata v-if="externalSystem === 'Skidata'" :ticketOption="modelValue" ref="extSkidata" />
			<TicketOptionExternalSystemAxess v-if="externalSystem === 'Axess'" :ticketOption="modelValue" ref="extAxess" />
			<TicketOptionExternalSystemSbbv3 v-if="externalSystem === 'SBBv3'" :ticketOption="modelValue" ref="extSbbv3" />
		</div>
	</div>
</template>

<script lang="ts">
import Common from '../../mixins/Common.vue'
import TicketTypeField from './TicketTypeField.vue'
import ReductionField from './ReductionField.vue'
import TicketOptionExternalSystemSkidata from './TicketOptionExternalSystemSkidata.vue'
import TicketOptionExternalSystemAxess from './TicketOptionExternalSystemAxess.vue'
import TicketOptionExternalSystemSbbv3 from './TicketOptionExternalSystemSbbv3.vue'

export default {
	mixins: [ Common ],
	components: { TicketTypeField, ReductionField, TicketOptionExternalSystemSkidata, TicketOptionExternalSystemAxess, TicketOptionExternalSystemSbbv3 },
	props: {
		modelValue: Object, // TicketOption
		product: Object,
		// TODO: is this needed or is this basically a full disable?
		productIsExternal: Boolean,
		dataCyIndex: Number,
		allowDelete: Boolean,
		externalSystem: String,
	},
	computed: {
		ticketTypeError() {
			if (this.productIsExternal) return false
			return !this.modelValue?.fields?.ticketType?.de?.fields
				|| !Object.keys(this.modelValue.fields.ticketType.de).length
		},
		reductionError() {
			if (this.productIsExternal) return false
			return !this.modelValue?.fields?.reduction?.de?.fields
				|| !Object.keys(this.modelValue.fields.reduction.de).length
		},
		// TODO: instead of these validations we should use model driven fields and ChildErrorReceiver
		minimumTicketsErrors() {
			if (this.productIsExternal) return []
			const errors = []
			if (!this.modelValue?.fields?.minimumTickets?.de) errors.push(this.$t('text.minTicketsRequired'))
			if (this.modelValue?.fields?.minimumTickets?.de > this.modelValue?.fields?.maximumTickets?.de) errors.push(this.$t('text.minMaxTicketsError'))
			return errors
		},
		maximumTicketsErrors() {
			if (this.productIsExternal) return []
			const errors = []
			if (!this.modelValue?.fields?.maximumTickets?.de) errors.push(this.$t('text.maxTicketsRequired'))
			return errors
		},
		priceErrors() {
			if (this.productIsExternal) return []
			const errors = []
			if (!this.modelValue?.fields?.price?.de) errors.push(this.$t('text.priceRequired'))
			return errors
		},
		contingentQuantityErrors() {
			if (this.productIsExternal) return []
			const errors = []
			if (!this.modelValue?.fields?.contingentQuantity?.de) errors.push(this.$t('text.noOfPeopleRequired'))
			return errors
		},
		valid() {
			// TODO: we should change this whole validation to model driven fields
			// with this trick we retrigger on child input
			this.modelValue?.fields?.external?.dtaProductId
			this.modelValue?.fields?.external?.ticketTypeNumber
			this.modelValue?.fields?.external?.id1
			return true
				&& !this.ticketTypeError
				&& !this.reductionError
				&& !this.minimumTicketsErrors.length
				&& !this.priceErrors.length
				&& !!this.modelValue?.fields?.minimumTickets?.de
				&& !!this.modelValue?.fields?.maximumTickets?.de
				&& !!this.modelValue?.fields?.contingentQuantity?.de
				&& (this.$refs.extSkidata?.valid ?? true)
				&& (this.$refs.extAxess?.valid ?? true)
				&& (this.$refs.extSbbv3?.valid ?? true)
		},
	},
	methods: {
		// TODO: i have just taken this over from the old TicketInfo.vue - is this ok?
		isDecimal: function (evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode

			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
				evt.preventDefault()
				return false
			}
			return true
		},
	},
	mounted() {
		// TODO: expired products seem to have prices like "15,00" set and our number fields do not accept this
		if (this.modelValue?.fields?.price?.de) {
			this.modelValue.fields.price.de = this.modelValue.fields.price.de.replace(',', '.')
		}
	},
}
</script>

<style scoped>
.TicketOption { position: relative; display: flex; flex-direction: column; gap: 8px; background: #fafafa; padding: 12px; border-radius: 8px; border: 1px solid #ddd; }
.row { display: flex; gap: 10px; width: 100%; }
.row > * { flex: 1 0; }
.row > .Field { border-left: none; padding: 0; }
.externalSystem { border-top: 1px solid #ddd; padding-top: 12px; }
.error { outline: 2px solid red; outline-offset: -1px; }
.v-text-field { background-color: #ffffff; }
</style>