<template>
	<Disclosure :title="$t('text.guestCardSettingsInfoTitle')" :error="sectionMessage.error"
		:message="sectionMessage.message" :lock="!userMayEditFields || productIsExternal" data-cy="guestCardSettingsInfo"
		ref="SECTION_guestCardSettingsInfo">

		<Field v-if="hasField('title')" typeName="GuestCard" fieldName="title" v-model="model.fields.title" :error-messages="titleErrors"
			@update:modelValue="validateTitle()" dataCy="title" />

		<div v-if="hasField('availability')" class="field left-border">
			<div>
				<v-label>{{ $t('text.guestCardAvailabilities') }}</v-label>
				<p class="helpText">{{ $t('text.guestCardAvailabilitiesHelpText') }}</p>
				<div class="col2 mt-4">
					<DatePicker v-if="model.fields.availability.fields.validFromDate"
						v-model="model.fields.availability.fields.validFromDate.de"
						:errors="validFromToError(model.fields.availability.fields, 'validFromDate')"
						dataCy="validFromDate">
						<template #label>{{ $t('text.validFrom') }} <span class="error-text">({{ $t('text.required')
						}})</span></template>
					</DatePicker>

					<DatePicker v-if="model.fields.availability.fields.validToDate"
						v-model="model.fields.availability.fields.validToDate.de"
						:min="model.fields.availability.fields.validFromDate.de"
						:errors="validFromToError(model.fields.availability.fields, 'validToDate')" dataCy="validToDate">
						<template #label>{{ $t('text.validUntil') }} <span class="error-text">({{ $t('text.required')
						}})</span></template>
					</DatePicker>
				</div>
			</div>
		</div>

		<div v-if="hasField('durationOfStay')" class="field left-border">
			<v-label>{{ $t('text.guestCardDurationOfStay') }}</v-label>
			<p class="helpText">{{ $t('text.guestCardDurationOfStayHelpText') }}</p>
			<div class="col2 mt-4">
				<div>
					<div style="width: fit-content;">
						<v-label>{{ $t('text.guestCardMinStay') }}</v-label>
						<v-text-field type="number" :rules="minMaxRules" min="0" max="100" variant="outlined"
							density="compact" data-cy="minStay" required v-model="model.fields.durationOfStay.fields.min.de"
							:suffix="$t('text.days').toLowerCase()" />
					</div>
				</div>

				<div style="width: fit-content;">
					<v-label>{{ $t('text.guestCardMaxStay') }}</v-label>
					<v-text-field type="number" :rules="minMaxRules" min="0" max="100" variant="outlined" density="compact"
						data-cy="maxStay" required v-model="model.fields.durationOfStay.fields.max.de"
						:suffix="$t('text.days').toLowerCase()" />
				</div>
			</div>
		</div>

	</Disclosure>
</template>

<script>
import AvailabilityExceptionSelector from './AvailabilityExceptionSelector.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Common from "@/mixins/Common.vue"
import isEqual from 'lodash/isEqual'
import DatePicker from "@/components/datePicker/DatePicker.vue"
import Field from '@/components/fields/Field.vue'

export default {
	name: 'GuestCardSettingsInfo',
	components: { AvailabilityExceptionSelector, Disclosure, DatePicker, Field },
	mixins: [Common],
	props: {
		product: Object,
		updateModel: Boolean,
		disabled: Boolean,
		productIsExternal: Boolean,
	},
	data: () => ({
		model: {},
		initData: {},
		isNewProduct: false,
		sectionMessage: {
			error: false,
			message: ''
		},
		titleErrors: [],
		minMaxRules: [
			v => (v && v >= 0 && v <= 100) || 'Invalid Input. Please enter a positive number between 0 and 100',
		],
	}),
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.product)
			this.setInitData()
		},
	},
	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))

			if (this.hasField('title')) {
				this.initData.title = initModel.fields.title[this.serviceLocale]
			}
			if (this.hasField('availability')) {
				this.initData.availability = initModel.fields.availability
			}
			if (this.hasField('durationOfStay')) {
				this.initData.durationOfStay = initModel.fields.durationOfStay
			}
		},
		sendData() {
			const data = {}

			if (this.hasField('title')) {
				data.title = this.model.fields.title
			}
			if (this.hasField('availability')) {
				data.availability = this.model.fields.availability
			}
			if (this.hasField('durationOfStay')) {
				data.durationOfStay = this.model.fields.durationOfStay
			}

			data.changed = !isEqual(data, this.initData)

			return data
		},
		validateAllFields() {
			let allFieldsAreValid = true

			this.resetSectionError(this.sectionMessage)

			if (this.productIsExternal) return true

			if (this.hasField('title') && !this.validateTitle()) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.titleRequiredError'))
			}
			if (this.hasField('availability')) {
				allFieldsAreValid = this.validateAvailability()

			}
			if (this.hasSection('durationOfStay')) {
				allFieldsAreValid = this.validateDurationOfStay()
			}

			return allFieldsAreValid
		},
		validateDurationOfStay() {
			let isValid = true
			const min = parseFloat(this.model.fields.durationOfStay.fields.min.de)
			const max = parseFloat(this.model.fields.durationOfStay.fields.max.de)

			if (min > max) {
				isValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.genericMinMaxError', min, max))
			}

			return isValid
		},
		validateAvailability() {
			let haveAvailability = false
			let isValid = true
			const validFrom = this.model.fields.availability.fields.validFromDate.de
			const validTo = this.model.fields.availability.fields.validToDate.de

			if (validFrom?.length && validTo?.length) {
				haveAvailability = true

				if (new Date(validFrom) > new Date(validTo)) {
					isValid = false
					this.setSectionError(this.sectionMessage, this.$t('text.invalidTimeFrameError'))
				}
			}

			if (!haveAvailability) {
				isValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
			}

			return isValid
		},
		validateTitle() {
			this.titleErrors = []

			let isValid = true
			const atLeastOneLocaleValueIsSet = this.validateTextLocales(this.model.fields.title)

			if (!atLeastOneLocaleValueIsSet) {
				isValid = false
				this.titleErrors.push(this.$t('text.nameRequired'))
			}
			if (isValid && this.sectionMessage.message === this.$t('text.titleRequiredError')) this.resetSectionError(this.sectionMessage)

			this.$emit('update-product-title', this.model.fields.title)
			return isValid
		},
		validFromToError(availability, prop) {
			if (!availability[prop]?.de?.length) return this.$t('text.missingFieldsError')
			return ''
		},
		hasField(fieldName) {
			return this.model.fields?.serviceType?.de?.fields?.template?.de?.guestCardSettingsInfo?.[fieldName]
		},
	},
	created() {
		this.model = this.valueToModel(this.product)
		this.setInitData()
	},
	mounted() {
		this.isNewProduct = this.product.sys.id === ''
	},
}
</script>

<style scoped>
h4 {
	color: #000000;
	font-size: 14pt;
	text-align: left;
	font-weight: bold;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.error-container {
	background-color: #f34545;
	border-radius: 10px;
	padding: 10px;
	color: #ffffff
}

.error-container #errors {
	color: #ffffff;
	font-size: 14pt;
}

div.col2 {
	display: flex;
	gap: 10px;
	align-items: center;
}

div.col2>* {
	width: 50%;
}
</style>