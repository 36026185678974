<template>
	<div class="SeatPlanEditor">
		<div>
			<!--<v-icon color="black">mdi-select-drag</v-icon>-->
			<button class="active"><v-icon>mdi-cursor-default-outline</v-icon> select objects</button>
			<button><v-icon>mdi-cursor-default</v-icon> select seats</button>
			<!--<button><v-icon>mdi-seat</v-icon> select seats</button>-->
			<button><v-icon>mdi-checkbox-blank-outline</v-icon> add seat</button>
			<button><v-icon>mdi-table-row</v-icon> add row</button>
			<button><v-icon>mdi-table-large</v-icon> add block</button>
			<button><v-icon>mdi-vector-curve</v-icon> geometry</button>
			<button><v-icon>mdi-brush</v-icon> category</button>
			<button><v-icon>mdi-undo</v-icon> undo</button>
			<button><v-icon>mdi-redo</v-icon> redo</button>

			<button v-if="selection.length == 1" @click="duplicate"><v-icon>mdi-content-copy</v-icon> duplicate</button>
			<button v-if="selection.length == 1" @click="flip"><v-icon>mdi-flip-vertical</v-icon> flip</button>
			<button v-if="selection.length == 1" @click="remove"><v-icon>mdi-trash-can-outline</v-icon> delete</button>
		</div>
		<template v-for="object, o of plan.objects">
			<SeatRow v-if="object.type == 'row'" :key="'r-' + o" :row="object" v-model:selection="selection" @step="onStep" />
			<SeatImage v-else-if="object.type == 'image'" :key="'i-' + o" :modelValue="object" v-model:selection="selection" @step="onStep" />
			<!-- TODO: support other types -->
		</template>
	</div>
</template>

<script lang="ts">
// PROOF POINTS
// OK curve as primitive for eat row
// OK curve editing with drag & drop
// OK data structure
// OK selection system for objects
// OK background image as template
// TODO concept contingent service -> talk to Harald
//      - is each seat a space?
//      - how to efficiently save all seats?
//      - how can we retrieve the whole plan efficiently?
// TODO concept timeslot data
//      - do we have an availability editor per timeslot (ts instance actually)?
//      - block specific seats?, ..
// TODO concept Peak integration
//      - how to get seat availabilities via B2C API?
//      - how to set seats in B2C API?
// TODO concept JAMES integration
//      - can we reuse ÖV+ views?
// TODO persist effective seats and rotations in data structure for server
// TODO: marquee selection for seats

import SeatRow from './SeatRow.vue'
import History, { HistoryAction } from './History'
import SeatImage from './SeatImage.vue'

// TODO: modes
//       - select object
//         - modify control points
//         - sidebar: edit seat / row / block properties
//         - quick operations to modify seat count
//         - move all CPs at once / whole object
//         - clone object
//         - delete object
//       - select seats (for mass edit)
//       - add row
//         - draw new row by click and drag operation 
//       - add single seat
//       - add block
// TODO: allow to drop background image + scale + move (only client side?)
// TODO: automatic numbering from start #
// TODO: category with color (ticket category?)
// TODO: define a schema for the whole seat plan
// TODO: undo / redo - see History.ts

import { seatDirection, Point } from './seat-util'

// TODO: can we auto detect changes from a deep watch?
const history: History = new History()
let a = new HistoryAction('SET')
history.add(a)

export default {
	components: { SeatRow, SeatImage },
	data: () => ({
		plan: {
			objects: [
				{
					type: 'image',
					pos: { x: 100, y: 150 },
					src: 'https://images.contenthub.dev/def6345e/a4fa2c1a99ea085b4fe7cb65a6f1d6cd/Screenshot%202024-07-01%20at%2021.41.15.png',
					// TODO: width + rotation instead?
					cp: { x: 800, y: 0 },
				},
				{
					type: 'row',
					name: 'A3',
					cps: [
						{ x: 100, y: 150, name: 'P1' },
						{ x: 50, y: 0, name: 'P2' },
						{ x: 0, y: -50, name: 'P3' },
						{ x: 200, y: 250, name: 'P4' },
					],
					count: 7,
					seatCategory: 'A',
					seatNamePrefix: 'A3-',
					seatNumberStart: '1',
					seats: [
						// TODO: does flip-operation also need to reverse seat order?
						// contingent service ids
						{ id: 1234 },
						{ id: 1235 },
						{ id: 1236 },
						{ id: 1237 },
						{ id: 1238 },
						{ id: 1239, name: 'AA1', category: 'AA', pos: { x: 0, y: 10 }, a: 0.1 },
						{ id: 1240, name: 'AA2', category: 'AA', pos: { x: 0, y: 10 }, a: 0.1 },
					],
				},
				{
					type: 'row',
					cps: [
						{ x: 100, y: 300, name: 'P1' },
						{ x: 0, y: 0, name: 'P2' },
						{ x: 0, y: 0, name: 'P3' },
						{ x: 200, y: 300, name: 'P4' },
					],
					count: 5,
					category: 'B',
				},
			],
		},
		selection: [],
	}),
	watch: {
		plan: {
			deep: true,
			handler(n) {
//				history.auto(n)
			},
		},
	},
	methods: {
		// TODO: move this to the SeatRow component somehow - as exported functions?
		moveSeat(seat: Point, dx: number, dy: number) {
			const dir = seatDirection(seat)
			console.log('dir', dir)
			seat.x += dy * dir.x
			seat.y += dy * dir.y
		},
		afterCloneRow(clone) {
			this.moveSeat(clone.cps[0], 0, -30)
			this.moveSeat(clone.cps[3], 0, -30)
		},
		duplicate() {
			const clone = JSON.parse(JSON.stringify(this.selection[0]))
			if (clone.type == 'row') this.afterCloneRow(clone)
			this.plan.objects.push(clone)
			this.selection = [ clone ]
		},
		flip() {
			const row = this.selection[0]
			const p1 = row.cps[0]
			const p2 = row.cps[1]
			const p3 = row.cps[2]
			const p4 = row.cps[3]
			row.cps = [ p4, p3, p2, p1 ]
		},
		remove() {
			this.plan.objects = this.plan.objects.filter(o => !this.selection.includes(o))
			this.selection = []
		},
		onStep() {
			// TODO: add to History
			console.log('step')
		},
	},
}
</script>

<style scoped>
.SeatPlanEditor { position: relative; }
button { background: #ffffff99; border: 1px solid gray; border-radius: 5px; margin-right: 5px; padding: 2px 6px; }
button.active { background: #666; }
</style>