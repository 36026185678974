<script>
export default {
	computed: {
		languageNavigationItems() {
			return [...this.$store.state.locales, { code: "all", name: "allLanguages" }]
		},
	},
	mounted() {
		this.$store.commit('setActiveTranslation', this.languageNavigationItems.find(locale => locale.default)?.code ?? 'de')
	},
	methods: {
		async setActiveTranslation(value) {
			this.$store.commit('setActiveTranslation', value)
		},
	},
}
</script>