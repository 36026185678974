<script lang="ts">
import eventBus from '../utils/eventBus.js'

// see LoadingOverlay for the message receiver

export default {
	methods: {
		// ATT: when using this, dont forget to await the call!
		//		await this.loadingOverlay(async () => {
		//			// your code here
		//		})
		async loadingOverlay(func: Function, key: string = undefined, messageKey: string = undefined) {
			if (!key) key = Math.random().toString(36).substring(7)
			this.startLoading(key, messageKey)
			try {
				await func()
			}
			catch (e) {
				this.stopLoading(key)
				throw e
			}
			this.stopLoading(key)
		},
		// ATT: use of these functions is discouraged!
		//		use at your own risk in situations where loadingOverlay is not applicable
		startLoading(key: string, messageKey: string = undefined) {
			eventBus.$emit('startLoading', this._uid, key, messageKey)
		},
		stopLoading(key: string) {
			eventBus.$emit('stopLoading', this._uid, key)
		},
	},
	beforeDestroy() {
		this.stopLoading('*')
	},
}
</script>