<template>
	<div>
		<Disclosure :title="$t('text.offerMenuTitle')" data-cy="offerMenu" ref="SECTION_offerMenu">
			<p class="subTitle" v-html="$t('text.offerMenuDesc')"/>
			<v-card flat style="background: #eee; padding: 0 10px; margin-top: 10px; display: flex; gap: 10px;">
				<v-label style="flex-grow: 1;">{{ $t('text.showOfferMenu') }}</v-label>
				<mys-switch
					style="flex-grow: 0;"
					v-model="showOfferMenu" 
					:disabled="products.length === 0"
				/>
			</v-card>
			<p v-html="$t('text.showOfferMenuDesc')" style="margin-left:0px"></p>

			<v-tabs style="margin-bottom: 15px;">
				<v-tab @click="showProductsForOfferMenu()">{{ $t('text.showProducts') }}</v-tab>
				<v-tab @click="showTicketsForOfferMenu()">{{ $t('text.showTickets') }}</v-tab>
			</v-tabs>

			<v-card flat class="tableCard" style="margin-top: 0;">
				<div class="tableHeader">
					{{ $t('text.title') }}
				</div>
				
				<div v-if="loadingOfferMenu">
					<v-progress-linear indeterminate color="primary"></v-progress-linear>
				</div>

				<!-- products -->
				<div style="padding-top: 0px;" v-if="showProducts">
					<div v-if="!products || products.length == 0" style="margin: 10px;">{{ $t('text.noPublishedProducts') }}</div>
					<draggable v-model="products" group="products" @start="drag=true" @end="drag=false" v-if="products && products.length > 0">
						<OfferMenuItem v-for="product in products" :key="product.id"
							:offer="product" :offerMenuItemType="'product'" @input="checkSelectedOfferItems"
						/>
					</draggable>
				</div>

				<!-- tickets -->
				<div style="padding-top: 0px;" v-if="showTickets">
					<div v-if="!tickets || tickets.length == 0" style="margin: 10px;">{{ $t('text.noPublishedProducts') }}</div>
					<draggable v-model="tickets" group="tickets" @start="drag=true" @end="drag=false" v-if="tickets && tickets.length > 0">
						<OfferMenuItem v-for="ticket in tickets" :key="ticket.id"
							:offer="ticket" :offerMenuItemType="'ticket'" @input="checkSelectedOfferItems"
						/>
					</draggable>
				</div>
			</v-card>
		</Disclosure>
	</div>
</template>

<script>
import OfferMenuItem from './OfferMenuItem.vue'
import { VueDraggableNext as draggable } from 'vue-draggable-next'
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import isEqual from 'lodash/isEqual'

export default {
	name: 'OfferMenu',
	components: { OfferMenuItem, draggable, Disclosure },
	mixins: [ Common ],
	props: {
		updateModel: Boolean
	},
	data() {
		return {
		offerMenu: {},
		initData: {},
		// ATT: this field is read from externally (BusinessProfile)
		showProducts: true,
		// ATT: this field is read from externally (BusinessProfile)
		showTickets: false,
		products: [],
		tickets: [],
		showOfferMenu: false,
		loadingOfferMenu: false,
	}},
	watch: {
		selectedLocale() {
			if (this.products) {this.products.sort(this.compare)}
			if (this.tickets) {this.tickets.sort(this.compare)}
		},
		offerMenu() {
			this.showProducts = true
			this.showTickets = false
			this.getProducts()
		}
	},
	methods: {
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.offerMenu))
			
			if (initModel?.fields?.offerMenuItems?.de?.length) {
				for (let offerMenuItem of initModel.fields.offerMenuItems.de) {
					delete offerMenuItem.fields
				}
			}
			this.initData = initModel
		},
		sendData() {
			// order data.offerMenu.fields.offerMenuItems.de to have the same order as this.products
			if (this.offerMenu?.fields?.offerMenuItems?.de) {
				this.offerMenu.fields.offerMenuItems.de.sort(
					(a, b) => this.products.findIndex(x => x.sys.id === a.fields.offer.de.sys.id) - this.products.findIndex(x => x.sys.id === b.fields.offer.de.sys.id)
				)
			}

			let data = {
				offerMenu: JSON.parse(JSON.stringify(this.offerMenu))
			}

			if (data.offerMenu?.fields?.offerMenuItems?.de?.length) {
				for (let offerMenuItem of data.offerMenu.fields.offerMenuItems.de) {
					delete offerMenuItem.fields
				}
			}

			console.log('data', data)

			data.changed = !isEqual(data.offerMenu, this.initData)

			console.log('changed', data)
			
			return data
		},
		async showProductsForOfferMenu() {
			this.showProducts = true
			this.showTickets = false
		},
		async showTicketsForOfferMenu() {
			this.showTickets = true
			this.showProducts = false
		},
		async getProducts() {
			try {
				this.loadingOfferMenu = true
				if (!this.$store.state.selectedServiceProvider) return
				
				const res = await this.$httpGet(`/products/${this.$store.state.selectedServiceProvider.sys.id}?clientId=${this.$store.state.selectedClient.sys.id}&limit=1000`)
				let products = []
				let ticketMap = new Map()
	
				for (const product of res.products) {
					const currentAssignment = product.fields.clientAssignments?.de?.find(x => x.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
					if (currentAssignment?.fields?.status?.de === 'active') {
						products.push(product)
	
						if (product.fields?.ticket?.de) {
							ticketMap.set(product.fields.ticket.de.sys.id, product.fields.ticket.de)
						}
					}
				}
	
				if (this.offerMenu?.fields?.offerMenuItems?.de?.length > 0) {
					for (const offerMenuItem of this.offerMenu.fields.offerMenuItems.de) {
						if (products?.length > 0) {
							for (let product of products) {
								if (product.sys.id === offerMenuItem.fields.offer.de.sys.id) {
									product.selected = true
									this.showOfferMenu = true
									this.$emit("show-offer-menu")
									break
								}
							}
						}
					}
				}
	
				this.products = products
				// order this.products to have the same order as this.offerMenu.fields.offerMenuItems.de (unmentioned ones should remain stable). additionally selected products (product.selected) ones should be on top
				this.products.sort((a, b) => {
					if (a.selected && !b.selected) return -1
					if (!a.selected && b.selected) return 1
					if (a.selected && b.selected) {
						const aIndex = this.offerMenu.fields.offerMenuItems.de.findIndex(item => item.fields.offer.de.sys.id === a.sys.id)
						const bIndex = this.offerMenu.fields.offerMenuItems.de.findIndex(item => item.fields.offer.de.sys.id === b.sys.id)
						return aIndex - bIndex
					}
					return 0
				})
	
				this.tickets = Array.from(ticketMap.values())
				this.tickets.sort(this.compare)
				if (this.offerMenu?.fields?.offerMenuItems?.de?.length && this.tickets?.length) {
					for (const offerMenuItem of this.offerMenu.fields.offerMenuItems.de) {
						let ticket = this.tickets.find(t => t.sys.id === offerMenuItem.fields.offer.de.sys.id)
						if (!ticket) continue
						
						ticket.selected = true
					}
				}
	
				if (this.products?.length > 0) {
					this.$emit("show-offer-menu")
				}
				this.loadingOfferMenu = false
			} catch (error) {
				this.showError(error)
			}
		},
		async checkSelectedOfferItems(eventPayload) {
			console.log('eventPayload', eventPayload)
			if (!eventPayload.sys) return
			var haveSelectedItems = false

			for (const item of this.products) {
				if (!item?.selected) continue
				haveSelectedItems = true
			}

			for (const item of this.tickets) {
				if (!item?.selected) continue
				haveSelectedItems = true
			}

			this.showOfferMenu = haveSelectedItems

			//Update OfferMenu
			if (!this.offerMenu) {
				this.offerMenu = {
					sys: {id:''},
					fields: {
						offerMenuItems: {de:[]}
					}
				}
			}
			if (eventPayload.selected) {
				const data = {
					offerMenuItem: {id: eventPayload.sys.id, name: eventPayload.fields.title}
				}
				const res = await this.$httpPost('/offer-menu-item', data)
				
				if (res?.offerMenuItem) {
					this.offerMenu.fields.offerMenuItems.de.push(res.offerMenuItem)
				}
			} else {
				//Remove from offer menu
				if (this.offerMenu && this.offerMenu.fields.offerMenuItems && this.offerMenu.fields.offerMenuItems.de.length > 0) {
					let index = this.offerMenu.fields.offerMenuItems.de.findIndex(x => x.fields.offer.de.sys.id === eventPayload.sys.id);

					if (index > -1) {
						this.offerMenu.fields.offerMenuItems.de.splice(index, 1)
					}
				}
			}
			
		}
	},
	async mounted() {
		this.offerMenu = this.$store.state.selectedServiceProvider?.fields?.offerMenu?.de ?? null
		this.setInitData()
		this.showProductsForOfferMenu()
	},
}
</script>

<style scoped>
.tableCard {
	border-radius:5px;
	background-color:#ffffff !important;
	border: 1px solid #9e9e9e !important;
}
.tableHeader {
	width: 100%;
	padding: 10px;
	padding-left: 80px;
	color: white;
	font-size: 13pt;
	border-top-left-radius:5px;
	border-top-right-radius:5px;
	border-bottom-left-radius:0px !important;
	border-bottom-right-radius:0px !important;
	background-color: #737373 !important;
	top: 0px;
	height: 48px;
}
.card {
	background-color: transparent !important;
	min-width: 100%;
	max-height: 48px;
	margin-top: 12px;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
}
.card--content {
	margin-top: auto;
	margin-bottom: auto;
	font-size: 18px !important;
	color: #ffffff !important;
}
</style>
