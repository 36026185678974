<template>
	<DataDialog ref="dialogRef" :title="$t('text.personalisationFields')" v-model="selected" :onConfirm="onConfirm">
		<template #content>
			<div class="field left-border">
				<v-label>{{ $t('text.fixedPersonalisationFields') }}</v-label>
				<p class="helpText">{{ $t('text.fixedPersonalisationFieldsHelpText') }}</p>

				<div v-for="option in allFields" :key="`checkbox-${option}`" class="checkbox-wrap">
					<v-checkbox v-model="selected" :value="option" :disabled="disabledFields.includes(option)" :label="$t(`text.${camelCase(option)}Personalisation`)"
						hide-details color="blue" />
				</div>
			</div>
		</template>
	</DataDialog>
</template>

<script>
import camelCase from 'lodash/camelCase'
import DataDialog from '@/components/common/DataDialog.vue'

export default {
	name: 'PersonalisationsDialog',
	components: { DataDialog },
	emits: ['update:modelValue'],
	props: {
		modelValue: Object,
	},
	data() {
		return {
      disabledFields: ['first_name', 'last_name', 'birth_date'], // TODO refactor model instead hardcoding preset fields in PackageDesignerService
      selected: Object.keys(this.modelValue),
      allFields: ['first_name', 'last_name', 'birth_date', 'phoneNumber', 'gender', 'street', 'streetNumber', 'zipCode', 'city', 'country', 'email', 'photo'],
		}
	},
	methods: {
		open() {
			this.$refs.dialogRef.open()
		},
		onConfirm() {
      const obj = this.allFields.reduce((acc, field) => {
        acc[field] = this.selected.includes(field);
        return acc;
      }, {});
			this.$emit('update:modelValue', obj)
		},
	},
	setup() {
		return {
			camelCase,
		}
	},
}
</script>

<style lang="scss" scoped>
.checkbox-wrap {
	border-bottom: 1px solid #cfd9e0;
}
</style>

<style lang="scss">
.v-label {
	color: black !important;
}
</style>
