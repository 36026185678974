<template>
	<div class="ToastMessages">
		<v-overlay v-model="overlay" persistent class="ToastMessagesOverlay" scroll-strategy="none">
			<div class="messages" @mouseover="suspendClose = true" @mouseout="endSuspend">
				<div v-for="toastMessage of toastMessages" :key="toastMessage.id" class="toastMessage" :class="toastMessage.type">
					<v-icon @click="close(toastMessage, true)" style="float: right; margin-right: -5px;">mdi-close</v-icon>
					{{ clip(toastMessage.message, 200) }}
				</div>
			</div>
		</v-overlay>
	</div>
</template>

<script>
// NOTE copied over from content-editor and adapted
import eventBus from '../../utils/eventBus.js'

// use like this:
// import eventBus from '../../../utils/eventBus.js'
// eventBus.$emit('addToastMessage', 'This is a toast message', 'info')

export default {
	data: () => ({
		toastMessages: [],
		suspendClose: false,
		overlay: true,
	}),
	methods: {
		addToastMessage(message, type = 'info', expires = 7000) {
			if (typeof message == 'string')
				message = message.replace(/com\.alturos\S*/g, '')

			if (type == 'error') console.error('TOAST', message)
			else if (type == 'warn') console.warn('TOAST', message)
			else console.log('TOAST', message)

			const toastMessage = { message, type, id: Date.now() }
			this.toastMessages.push(toastMessage)
			setTimeout(() => { this.close(toastMessage) }, expires)
		},
		removeToastMessageMatching(substring) {
			this.toastMessages = this.toastMessages.filter(t => !t.message?.includes?.(substring))
		},
		close(toastMessage, force = false) {
			toastMessage.closed = true
			if (this.suspendClose && !force) return
			this.toastMessages = this.toastMessages.filter(t => !t.closed)
		},
		endSuspend() {
			this.suspendClose = false
			window.setTimeout(() => {
				if (this.suspendClose) return
				this.toastMessages = this.toastMessages.filter(t => !t.closed)
			}, 50)
		},
		onEntryApiError(e) {
			this.addToastMessage(e.detail.message, 'error')
		},
		test() {
			window.setTimeout(() => {
				const type = ['info', 'success', 'warning', 'error'][Math.floor(Math.random() * 4)]
				this.addToastMessage('com.alturos.some.package This is a toast message. The text may also be very long.', type)
				this.test()
			}, Math.random() * 3000)
		},
		clip(text, length) {
			if (text.length <= length) return text
			return text.substr(0, length - 3) + '...'
		},
	},
	async mounted() {
		document.body.addEventListener('EntryApiError', this.onEntryApiError)
		eventBus.$on('addToastMessage', this.addToastMessage)
		eventBus.$on('removeToastMessageMatching', this.removeToastMessageMatching)
		//this.test()
	},
	beforeDestroy() {
		document.body.removeEventListener('EntryApiEvent', this.onEntryApiError)
		eventBus.$off('addToastMessage', this.addToastMessage)
		eventBus.$off('removeToastMessageMatching', this.removeToastMessageMatching)
	},
}
</script>

<style scoped>
.messages { position: fixed; right: 0; bottom: 0; display: flex; flex-direction: column; gap: 4px; }
.ToastMessages { position: fixed; bottom: 10px; left: 50%; transform: translate(-50%, 0); z-index: 999999; display: flex; flex-direction: column; gap: 5px; }
.toastMessage { transition: 0.3s; cursor: pointer; font-size: 15px; width: 350px; padding: 10px 15px; border-radius: 3px 0 0 3px; background: white; border-left: 5px solid var(--col); box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.1), inset 2px 2px 10px -5px white; word-break: break-all; }
.toastMessage.info { --col: #b9b9b9; }
.toastMessage.success { --col: var(--col-success); }
.toastMessage.warn,
.toastMessage.warning { --col: var(--col-warn); }
.toastMessage.error { --col: var(--col-error); }
</style>

<style>
.ToastMessagesOverlay { z-index: 9999 !important; }
.ToastMessagesOverlay .v-overlay__scrim { display: none; }
.ToastMessagesOverlay .v-overlay__content { inset: 0; pointer-events: none; }
.ToastMessagesOverlay .v-overlay__content > * { pointer-events: all; }
</style>