<template>
	<Application :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail" v-model:successTitle="successTitle" :loading="loading"
		:class="{ ServiceDesigner: true, showImpersonationInfo, isSideBarFixed }"
	>
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color: transparent; min-width: 40px;" @click="goback()"><v-icon>mdi-chevron-left</v-icon></v-btn>
			<h1>{{ productTitle }}</h1>
		</template>

		<SideBar ref="sidebar"
			v-if="productIsLoaded" 
			:actions="sidebarActions" :translations="translationStatuses" :clientStatuses="clientStatuses" 
			:navigation="sections" :notifications="sideBarNotifications" :disabled="loading"
			@action="handleAction($event)"
			@edit:translations="$refs.translationWizardDialog?.open"
			displayEditTranslations
		/>

		<div class="content" style="margin-top: 60px;">
			<StepBar :steps="steps" @step="handleStep($event)" :clickable="true" :showDividers="false" class="step-bar" />

			<!-- Step 1 -->
			<div style="width: 100%" v-show="currentStep === 1">
				<v-row style="margin-top: 50px;">
					<v-col cols="12" :lg="showPreview ? 6 : 12">
						<div v-if="userIsOperator && featureEnabled('service-provider-checklist')">
							<span id="checklistInfo" />
							<Checklist v-if="productIsLoaded" ref="checklistRef" :product="product" component="ServiceDesigner" />
						</div>
		
						<div v-if="hasSection('publicInfo')">
							<span id="publicInfo" />
							<PublicInfo
								ref="publicInfo"
								v-if="productIsLoaded"
								:product="product"
								:productIsExternal="productIsExternal"
								@update-product-title="showProductTitle"
								@change="changed.publicInfoChanged = true"
							/>
						</div>
		
						<div v-if="hasSection('contactInfo')">
							<span id="contactInfo" />
							<ContactInfo ref="contactInfo" v-if="productIsLoaded"
								v-model="product"
								:updateModel="updateModel"
								:serviceProvider="serviceProvider"
								:status="status"
								@change="changed.contactInfoChanged = true"
							/>
						</div>
		
						<div v-if="hasSection('generalInfo')">
							<span id="generalInfo" />
							<GeneralInfo ref="generalInfo" v-if="productIsLoaded"
								:product="product"
								:updateModel="updateModel"
								@isLoading="(val) => showLoader(val)"
							/>
						</div>
		
						<div v-if="hasSection('businessInfo')">
							<span id="businessInfo" />
							<BusinessHoursNew v-if="productIsLoaded"
								ref="businessInfo"
								:showCopyOption="true"
								:businessHours="product.fields.businessHours?.de"
								:copyBusinessHours="product.fields.copyBusinessHours.de"
								:averageDurationOfStayInMinutes="product.fields.averageDurationOfStayInMinutes.de"
								app="ServiceDetail"
								@copy-business-hours="copyBusinessHours(true)"
								@new-business-hours="copyBusinessHours(false)"
								@toggle-business-hours="toggleHaveBusinessHours"
							/>
						</div>
		
						<div v-if="hasSection('categoryInfo')">
							<span id="categoryInfo" />
							<ProductCategoriesSelector v-if="productIsLoaded" app="ServiceDetail"
								ref="categoryInfo" 
								:data-payload="product"
								:service-provider="serviceProvider"
								:workflow-status="getStatusClient(product)"
								:updateModel="updateModel"
								data-cy="productCategories"
							/>
						</div>
		
						<div v-if="hasSection('ticketInfo')">
							<span id="ticketInfo" />
							<TicketInfoNew v-if="productIsLoaded"
								ref="ticketInfo"
								v-model="product" 
								:trustLevel="this.serviceProvider.fields.trustLevel?.de ?? 1"
								:productIsExternal="productIsExternal"
								@change="changed.ticketInfoChanged = true"
							/>
							<!--
							<TicketInfo
								v-if="productIsLoaded"
								ref="ticketInfo"
								:product="product" 
								:serviceProvider="serviceProvider"
								:updateModel="updateModel"
								:productIsExternal="productIsExternal"
							>
							</TicketInfo>
							-->
						</div>

						<IfFeatureFlag flag="FEATURE_SEASONAL_PRICING" v-if="productIsLoaded && hasSection('seasonalPricing') && !productIsExternal">
							<span id="seasonalPricing" />
							<SeasonalPricing
								v-if="productIsLoaded"
								ref="seasonalPricing"
								v-model="product.fields.seasonalPricing"
								:product="product"
								:updateModel="updateModel"
								:productIsExternal="productIsExternal"
							/>
						</IfFeatureFlag>

						<div v-if="hasSection('availabilityInfo')">
							<span id="availabilityInfo" />
							<Availability
								v-if="productIsLoaded"
								ref="availabilityInfo"
								:product="product"
								:updateModel="updateAvailabilityModel"
								:productIsExternal="productIsExternal"
								:csError="csError" 
								:csDeletedValidityError="csDeletedValidityError"
							/>
						</div>

						<div v-if="hasSection('ticketSettingsInfo')">
							<span id="ticketSettingsInfo" />
							<TicketPersonalisations
								ref="ticketSettingsInfo"
								v-if="productIsLoaded && productHasTicket"
								:product="product"
								:updateModel="updateModel"
								:productIsExternal="productIsExternal"
							/>
						</div>
		
						<div v-if="hasSection('salesChannelsInfo')">
							<span id="salesChannelsInfo" />
							<ClientAssignments
								v-if="productIsLoaded"
								ref="salesChannelsInfo"
								data-cy="clientAssignments"
								app="ServiceDetail"
								:propClientAssignments="product.fields.clientAssignments.de"
								:serviceProvider="serviceProvider"
								:clientAssignmentLocked="productIsExternal"
								:disclosureTitle="$t('text.salesChannelsInfoTitle')"
								:updateModel="updateModel"
							/>
						</div>

						<IfFeatureFlag flag="FEATURE_SKIRENTAL_SPECIFIC_SETTINGS">
							<span id="skirentalSettings" />
							<SkirentalSettings
								v-if="productIsLoaded"
								ref="skirentalSettings"
								:disclosureTitle="$t('text.skirentalSpecificSettings')"
								:skirentalSettingsLocked="false"
							/>
						</IfFeatureFlag>

						<IfFeatureFlag flag="FEATURE_SKISCHOOL_SPECIFIC_SETTINGS">
							<span id="skischoolSettings" />
							<SkischoolSettings
								v-if="productIsLoaded"
								ref="skischoolSettings"
								:disclosureTitle="$t('text.skischoolSpecificSettings')"
								:skischoolSettingsLocked="false"
							/>
						</IfFeatureFlag>

						<div v-if="productIsLoaded">
							<span id="systemInfo" />
							<SystemInfo ref="systemInfo" :product="product" :serviceProvider="serviceProvider" :productIsExternal="productIsExternal" :updateModel="updateModel"/>
						</div>
		
						<!-- Audit Log -->
<!--
						<div>
							<span id="auditLog"/><br/>
							<AuditLog v-if="productIsLoaded && showAuditLogs" ref="auditLog" :entryId="product.sys.id" contentType="product" :reloadLogs="reloadLogs" />
						</div>
-->

					</v-col>
		
					<v-col v-if="showPreview" :lg="showPreview ? 6 : 12" :style="showImpersonationInfo ? 'padding-top: 36px !important;' : 'padding-top:10px'">
						<ServicePreview :product="product" :serviceProvider="serviceProvider" :showPreview="showPreview" @scroll-to="scrollToPreview" />
					</v-col>
				</v-row>
			</div>

			<!-- Step 2 -->
			<div style="width: 100%" v-show="currentStep === 2">
				<v-row style="margin-top: 50px;">
					<v-col cols="12" :lg="showPreview ? 6 : 12">

						<IfFeatureFlag flag="FEATURE_BILLINGPLANS">
							<div v-if="hasSection('paymentAndCancellationInfo')">
								<span id="paymentAndCancellationInfo" />
								<PaymentAndCancellation v-if="productIsLoaded" app="ServiceDetail"
									ref="paymentAndCancellationInfo" 
									:data-payload="product"
									:service-provider="serviceProvider"
									:workflow-status="getStatusClient(product)"
									:updateModel="updateModel"
									:showError="showError"
									:showLoader="showLoader"
									data-cy="paymentAndCancellation"
								/>
							</div>
						</IfFeatureFlag>

					</v-col>
				</v-row>
			</div>

			<!-- Confirm Delete -->
			<Dialog ref="deleteDialog"
				:confirmLabel="$t('text.delete')"
				:cancelLabel="$t('text.cancel')"
				:confirm-handler="onDeleteConfirmed"
				:cancel-handler="onDeleteCancelled"
				:showClose="false"
				:title="$t('text.deleteService')"
				height="360px"
				width="540px"
			>
				<template #content>
					<v-row justify="center" align="center" style="padding: 10px">
						<v-col class="warning-icon-col">
							<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
						</v-col>
						<v-col class="text-col">
							<span class="dialogText">{{$t('text.confirmServiceDelete')}}</span>
						</v-col>
					</v-row>
				</template>
			</Dialog>

			<!-- Confirm going back -->
			<Dialog ref="unsavedChangesDialog"
				data-cy="unsavedChangesDialog"
				:confirmLabel="$t('text.confirm')"
				:cancelLabel="$t('text.cancel')"
				:confirm-handler="confirmUnsavedChanges"
				:cancel-handler="cancelUnsavedChanges"
				:showClose="false"
				:title="$t('text.unsavedChanges')"
				height="360px"
				width="540px"
			>
				<template #content>
					<v-row justify="center" align="center" style="padding: 10px">
						<v-col class="warning-icon-col">
							<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
						</v-col>
						<v-col class="text-col">
							<span class="dialogText">{{$t('text.unsavedChangesText')}}</span>
						</v-col>
					</v-row>
				</template>
			</Dialog>

			<!-- Confirm Duplicate Product -->
			<Dialog ref="duplicateProductDialog"
				:confirmLabel="$t('text.viewDuplicateService')"
				:cancelLabel="$t('text.viewCurrentService')"
				:confirm-handler="onViewDuplicateConfirmed"
				:cancel-handler="onViewDuplicateCancelled"
				:showClose="false"
				:title="$t('text.duplicateService')" 
				height="360px"
				width="540px"
			>
				<template #content>
					<v-row justify="center">
						<span class="text-h6">{{$t('text.duplicateServiceText')}}</span>
					</v-row>
				</template>
			</Dialog>

			<IfFeatureFlag flag="FEATURE_TRANSLATION_WIZARD">
				<TranslationsIncompleteDialog ref="translationsIncompleteDialog"
					:translations="translationStatuses"
					:handleConfirm="confirmMissingTranslations"
					:handleCancel="handleEditTranslations"
				/>
				<TranslationWizardDialog ref="translationWizardDialog" :fields="translatableFields" />
			</IfFeatureFlag>
		</div>
	</Application>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Loading from 'vue-loading-overlay'
import ProductCategoriesSelector from '../../../components/categories/ProductCategoriesSelector.vue'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Availability from '../../../components/serviceDesigner/Availability.vue'
import ClientAssignments from '../../../components/clientAssignment/ClientAssignments.vue'
import Disclosure from '../../../components/common/Disclosure.vue'
import Common from '@/mixins/Common.vue'
import ServicePreview from './ServicePreview.vue'
import LanguageFlag from '../../../components/common/LanguageFlag.vue'
import Alert from '../../../components/common/Alert.vue'
import Dialog from '../../../components/common/Dialog.vue'
import TicketPersonalisations from '../../../components/serviceDesigner/TicketPersonalisations.vue'
import TicketInfo from '../../../components/serviceDesigner/TicketInfo.vue'
import TicketInfoNew from '../../../components/serviceDesigner/TicketInfoNew.vue'
import BusinessHours from '../../../components/businesshours/BusinessHours.vue'
import BusinessHoursNew from '../../../components/businesshours/BusinessHoursNew.vue'
import SideBar from '../../../components/common/SideBar.vue'
import Checklist from '../../../components/checklist/Checklist.vue'
import PublicInfo from '../../../components/serviceDesigner/PublicInfo.vue'
import ContactInfo from '../../../components/serviceDesigner/ContactInfo.vue'
import GeneralInfo from '../../../components/serviceDesigner/GeneralInfo.vue'
import ContingentMonitor from '@/components/serviceDesigner/contingentMonitor/ContingentCalendar.vue'
import SystemInfo from '../../../components/serviceDesigner/SystemInfo.vue'
import Application from '../Application.vue'
import AuditLog from '../../../components/auditLog/AuditLog.vue'
import StepBar from '../../../components/common/StepBar.vue'
import PaymentAndCancellation from '../../../components/paymentAndCancellation/PaymentAndCancellation.vue'
import SkirentalSettings from '../../../components/serviceDesigner/SkirentalSettings.vue'
import IfFeatureFlag from '../../../components/ifFeatureFlag/IfFeatureFlag.vue'
import SkischoolSettings from '../../../components/serviceDesigner/SkischoolSettings.vue'
import TranslationsIncompleteDialog from '../../../components/TranslationsIncompleteDialog.vue'
import TranslationWizardDialog from '../../../components/TranslationWizardDialog.vue'
import SeasonalPricing from './SeasonalPricing.vue'
import AiContext from '../../../mixins/AiContext.vue'
import TranslateableField from '../../../components/fields/TranslateableField.vue'

export default {
	name: 'ServiceDesigner',
	components: {
		SideBar, Loading, Disclosure, ServicePreview, LanguageFlag, Alert, Dialog,
		Checklist, PublicInfo, ContactInfo, GeneralInfo, ProductCategoriesSelector, ClientAssignments, BusinessHours, BusinessHoursNew, Availability,
		TicketInfo, TicketInfoNew, TicketPersonalisations, ContingentMonitor, SystemInfo, Application, AuditLog, StepBar, PaymentAndCancellation, IfFeatureFlag,
		SkirentalSettings, SkischoolSettings, TranslationsIncompleteDialog, TranslationWizardDialog, SeasonalPricing, TranslateableField
	},
	mixins: [ Common, AiContext ],
	provide: {
		activePage: 'ServiceDesigner',
	},
	data() {
		return {
			activeLink: '',
			gobackToDashboard: false,
			selectedServiceType: {},

			status: null,
			statusMap: {}, // statusName => true
			statusClientHasMultiple: false,

			counter: 0,

			loading: false,
			errorTitle: null,
			errorDetail: null,
			successTitle: null,
			successDetail: null,
			runValidation: false,
			sections: [],

			productIsLoaded: false,
			updateModel: false,
			updateAvailabilityModel: false,

			csError: null,
			csDeletedValidityError: false,

			product: {
				addl: {},
				sys: { id: '' },
				fields: {
					title: { de:'', en:'' },
					longDescription: {de:''},
					shortDescription: {de:''},
					importantInformation: {de:''},
					faqGroup: {de:''},
					images: {de:[]},
					taxClass: {de:{}},
					productCategories: {de:[]},
					clients: {de:[]},
					clientAssignments: {de:[]},
					meetingPoint: {de:''},
					vouchers: {de: [{
						sys: {id: 'id_v_0'},
						fields:{generalInfo: {de:''}}
					}]},
					averageDurationOfStayInMinutes: {de:0},
					startAdvertisingDate: {de:''},
					leadTimeDays: {de: 0},
					leadTimeHours: {de: 0},
					leadTimeWeeks: {de: 0},
					leadTimeMonths: {de: 0},
					personalisationType: { de: 'No personalisation' },
					location: {de: {lat:'',lon:''}},
					notificationEmails: {de: ['']},
					topProduct: { de: false },
					freeProduct: { de: false },
					ticket: {
						de: {
							sys: { id: '' },
							fields: {
								ticketOptions: {
									de:[{
										sys: { id: '' },
										fields: {
											ticketType: { de: null },
											reduction: { de: null },
											minimumTickets: { de: 1 },
											maximumTickets: { de: 6 },
											contingentQuantity: { de: 1 },
											price: { de: '' },
											fromPriceOption: { de: false },
											ticketPersonalisations: { de: [] },
											selected: false,
										}
									}]
								}
							}
						}
					},
					productAvailabilities: {
						de: [{
							sys: {id: 'id_a_1'},
							fields: {
								validFromDate: { de: '' },
								validToDate: {de:''},
								productContingents: {
									de: [{
									sys: { id: 'id_c_1' },
									fields: {
										minimumContingent: { de: 1 },
										maximumContingent: { de: 0 },
										timeSlot: { de: '' }
									}
									}]
								},
								exceptions: {
									de: [],
									type: ''
								}
							}
						}]
					},
					copyContactInfos: { de: true },
					copyBusinessHours: { de: true},
					businessHours: { de:[] },
				},
			},

			serviceProvider: this.$store.state.selectedServiceProvider,

			businessHours: [],

			productCategories: [],
			duplicateProductId: '',

			showPreview: false,
			showLongDescription: false,
			showMediaViewer: false,

			productTitle: '',
			availabilityToReplace: null,

			reloadLogs: false,
			currentStep: 1,
			steps: [
				{key: 1, label: this.$t('text.bpStepGeneral'), icon: 'mdi-cog', status: 'complete', datacy: 'bp-step-general'}
			],
			changed: {},
		}
	},

	computed: {
		showActivate() {
			return this.serviceProvider?.addl?.statusClient === 'approved' && this.status !== 'active'
		},
		spaceId() {
			return this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.[0]?.fields?.spaceId?.de
		},
		productIsExternal() {
			return this.product?.addl?.isExternal
		},
		appIsServiceManager() {
			// TODO: this.activePage is null - the provide/inject pattern from App.vue is not working as expected and the warning is omitted in main.js
			return this.componentName == 'ServiceDesigner'
		},
		productHasTicket() {
			return this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.length
		},
		//Sidebar Values
		sideBarNotifications() {
			let notifications = []

			if ((!this.userIsOperator) && this.serviceProvider?.addl?.statusClient !== 'approved') {
				notifications.push({ type: 'warn', content: this.$t('text.accountPendingInfo') })
			}
			
			if (this.serviceProvider?.addl?.statusClient !== 'approved') {
				notifications.push({ type: 'warn', content: this.$t('text.accountPendingInfoOp') })
			}
			
			return notifications
		},
		sidebarActions() {
			let actions = []

			//Service Provider View
			if (this.userIsServiceProviderOrImpersonating) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (this.showActivate) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishService'), function: 'submit', params: 'activate', datacy: "activate" })
				}
				
				if (this.product?.sys?.id?.length) {
					actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.$t('text.deleteService'), function: 'confirmDelete', datacy: "delete" })
				}

				if (this.statusMap['active']) {
					actions.push({ icon: 'mdi-eye-off', style: 'outline', dark: false, title: this.$t('text.deactivateService'), function: 'deactivateProduct', params: 'deactivate', datacy: "deactivate" })
				}

				if (!this.productIsExternal && this.$store.state.selectedServiceProvider?.sys?.id && this.$store.state.selectedProduct?.sys?.id) {
					actions.push({ icon: 'mdi-plus-box-multiple', style: 'none', dark: false, title: this.$t('text.duplicateService'), function: 'duplicateService', datacy: "duplicate" })
				}

				actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}
			
			//Home Operator View
			if (this.userIsHomeOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (this.status != 'active' && (!this.serviceProvider.addl || this.serviceProvider.addl.statusClient == 'approved')) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishService'), function: 'submit', params: 'approve', datacy: "approve" })
				}

				// MYS-3927: only the home operator can delete a product
				if (this.userIsHomeOperator && Object.keys(this.statusMap).length>0 && this.product?.sys?.id?.length) {
					actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.$t('text.deleteService'), function: 'confirmDelete', datacy: "delete" })
				}

				// MYS-3927: only the home operator can deactivate a products
				if (this.userIsHomeOperator && this.status == 'pending' || this.status == 'active') {
					actions.push({ icon: 'mdi-eye-off', style: 'outline', dark: false, title: this.$t('text.deactivateService'), function: 'deactivateProduct', params: 'deactivate', datacy: "deactivate" })
				}

				// MYS-3927: only the home operator can archive a product
				if (this.userIsHomeOperator && this.status!='pending' && this.status!='archived') {
					actions.push({ icon: 'mdi-archive', style: 'outline', dark: false, title: this.$t('text.archiveService'), function: 'deactivateProduct', params: 'archive', datacy: "archive" })
				}

				actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}

			//External Operator View
			if (this.userIsExternalOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit', params: 'save', datacy: "save" })
				
				if (this.status != 'active' && (!this.serviceProvider.addl || this.serviceProvider.addl.statusClient == 'approved')) {
					actions.push({ icon: 'mdi-arrow-up-circle', style: 'green', dark: true, title: this.$t('text.publishService'), function: 'submit', params: 'approve', datacy: "approve" })
				}

				actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			}

			return actions
		},
		clientStatuses() {
			let statuses = []
			if (this.product?.fields?.clientAssignments?.de?.length) {
				for (const ca of this.product.fields.clientAssignments.de) {
					statuses.push({
						title: ca.fields.client.de.fields.clientId.de,
						status: ca.fields.status.de,
						isHomebase: ca.fields.isHomebase?.de ?? false,
					})
				}
			}
			return statuses
		},
		translationStatuses() {
			return this.getTranslationStatus2(this.translatableFields, 'model')
		},
		translatableFields() {
			return [
				{ typeName: 'Service', fieldName: 'title', value: this.product.fields.title },
				{ typeName: 'Service', fieldName: 'longDescription', value: this.product.fields.longDescription },
				{ typeName: 'Service', fieldName: 'shortDescription', value: this.product.fields.shortDescription },
				{ typeName: 'Service', fieldName: 'importantInformation', value: this.product.fields.importantInformation },
				{ typeName: 'Service', fieldName: 'meetingPoint', value: this.product.fields.meetingPoint },
				this.product.fields.vouchers?.de?.[0] ? { typeName: 'Voucher', fieldName: 'generalInfo', value: this.product.fields.vouchers?.de?.[0]?.fields?.generalInfo } : undefined,
			].filter(f => !!f)
		},
	},

	created() {
		FreshdeskWidget.methods.prefill('MyServices')
		this.initSteps()
	},

	async mounted () {
		this.showLoader(true)

		this.currency = this.$store.state.selectedClient.fields.currency.de
		this.selectedServiceType = this.$store.state.selectedServiceType
		
        if (!this.$store.state.coreConfig) {
          await this.loadCoreConfig()
        }

		if (!this.$store.state.locales?.length) {
			await this.$root.app.getLocales()
		}

		// we have an url param or we have the product in store
		if (this.$router.currentRoute.value?.params?.id
			|| Object.keys(this.$store.state.selectedProduct).length > 0
		) {
			await this.getProduct()
		}
		else if (!this.$store.state.selectedProduct) {
			this.$router.push('/dashboard')
			return
		}

		if (Object.keys(this.$store.state.selectedProduct).length == 0) {
			// New Product
			this.status = "new"
			this.statusMap = { new: true }

			const product = Object.assign({}, this.product)
			this.product = product

			this.product.fields.serviceProvider = { de: this.serviceProvider }
			this.product.fields.serviceType = { de: this.$store.state.selectedServiceType }

			// Remove fields depending on service type
			// TODO: remove the fields more elegantly
			if (!this.hasSection('ticketInfo')) {
				delete this.product.fields.ticket
				delete this.product.fields.taxClass
			}
			if (!this.hasSection('availabilityInfo')) {
				delete this.product.fields.productAvailabilities
			}
			if (!this.hasField('availabilityInfo','startAdvertisingDate', this.product)) {
				delete this.product.fields.startAdvertisingDate
			}
			if (!this.hasField('availabilityInfo','leadTime', this.product)) {
				delete this.product.fields.leadTimeDays
				delete this.product.fields.leadTimeHours
				delete this.product.fields.leadTimeMonths
				delete this.product.fields.leadTimeWeeks
			}
			if (!this.hasField('contactInfo','notificationEmails', this.product)) {
				delete this.product.fields.notificationEmails
			}
			if (!this.hasField('contactInfo','meetingPoint', this.product)) {
				delete this.product.fields.meetingPoint
			}
			if (!this.hasField('contactInfo','postPurchaseInfo', this.product)) {
				delete this.product.fields.vouchers
			}
			if (!this.hasField('ticketSettingsInfo','personalisations', this.product)) {
				delete this.product.fields.personalisationType
			}
			this.setAllChanged(true)
			this.showLoader()
		}

		// we need this flag because we need to only render the child components when the product is loaded, otherwise they do not get the data
		this.productIsLoaded = true
		this.showProductTitle()
		this.setSections()
	},
	methods: {
		initSteps() {
			// TODO implement logic to decide which steps to display based on combination of selectedServiceType and template
			if (this.$store.state.featureFlags.FEATURE_BILLINGPLANS && this.hasSection('paymentAndCancellationInfo')) {
				this.steps.push({key: 2, label: this.$t('text.bpStepMarketplace'), icon: 'mdi-storefront', status: 'incomplete', datacy: 'bp-step-marketplace'})
				return
			}
		},
		handleStep(selectedStep, serviceProviderUpdated = false) {
			this.updateProductFromChildren()
			this.currentStep = selectedStep
			this.setSections()
			
			for (let step of this.steps) {
				if (step.key === this.currentStep && step.status !== 'error') {
					step.status = 'complete'
				} else if (step.key !== this.currentStep && step.status !== 'error') {
					step.status = 'incomplete'
				}

				if (step.key === this.currentStep && serviceProviderUpdated) {
					step.status = 'complete'
				} else if (step.key !== this.currentStep && serviceProviderUpdated) {
					step.status = 'incomplete'
				}
			}
		},
		showProductTitle(val) {
			if (val && typeof val === 'object' && Object.keys(val).length) {
				this.productTitle = val[this.serviceLocale]
			} else {
				this.productTitle = this.product?.fields?.title?.[this.serviceLocale] ? this.product.fields.title[this.serviceLocale] : this.$refs.publicInfo?.model?.fields?.title?.[this.serviceLocale] ? this.$refs.publicInfo.model.fields.title[this.serviceLocale] : ''
			}
		},
		setAllChanged(v) {
			this.changed = {
				publicInfoChanged: v,
				contactInfoChanged: v,
			}
		},
		updateProductFromChildren() {
			const generalInfoData = this.$refs['generalInfo']?.sendData()
			const businessHoursData = this.$refs['businessInfo']?.sendData()
			const categoryInfo = this.$refs['categoryInfo']?.sendData()
			const availabilityInfo = this.$refs['availabilityInfo']?.sendData()
			const salesChannelsInfo = this.$refs['salesChannelsInfo']?.sendData()
			
			// add to data array when the child component is enabled
			const data = [ generalInfoData, businessHoursData, salesChannelsInfo, availabilityInfo ].filter(data => data !== undefined)

			// loop over the data array and loop over all its keys
			for (const el of data) {
				Object.keys(el).forEach(key => {
					this.product.fields[key] = JSON.parse(JSON.stringify(el[key]))
				})
			}

			if (this.$refs?.checklistRef?.checklist) {
				this.product.fields.checklist = this.$refs.checklistRef.checklist
			}

			//Set changed sections
			this.product.addl = {
				...this.product.addl,
				generalInfoChanged: generalInfoData?.changed || this.product.sys.id === '' ? true : false,
				businessHoursChanged: businessHoursData?.changed || this.product.sys.id === '' ? true : false,
				categoryInfoChanged: categoryInfo?.changed || this.product.sys.id === '' ? true : false,
				availabilityInfoChanged: availabilityInfo?.changed ? true : false,
				salesChannelsChanged: salesChannelsInfo?.changed || this.product.sys.id === '' ? true : false,
				...this.changed,
			}
			
			//Set ClientAssignment productCategories
			if (categoryInfo?.productCategories?.de?.length) {
				const clientCA = salesChannelsInfo.clientAssignments.de.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
				if (clientCA) {
					clientCA.fields.productCategories = {de: categoryInfo.productCategories.de }
				} else {
					//New product without clientAssignments
					this.product.fields.productCategories = {de: categoryInfo.productCategories.de}
				}
			}
		},
		handleAction(event) {
			this[event.function](event.params)
		},
		setLocale(code) {
			this.serviceLocale = code
			this.showProductTitle(code)
		},
		//Sidebar Values
		setSections() {
			//TODO: refactor
			// TODO extend logic to change displayed sections based on current step
			this.sections = []
			if (this.userIsOperator) {
				if (this.featureEnabled('service-provider-checklist')) {
					this.sections.push({ id:'checklistInfo', state: 'valid' })
				}
				this.setSectionsForAllUserTypes()
	
			} else {
				this.setSectionsForAllUserTypes()
			}
		},
		setSectionsForAllUserTypes() {
			if (this.hasSection('publicInfo')) {
				this.sections.push({ id:'publicInfo', state: 'valid' })
			}
			if (this.hasSection('contactInfo')) {
				this.sections.push({ id:'contactInfo', state: 'valid' })
			}
			if (this.hasSection('generalInfo')) {
				this.sections.push({ id:'generalInfo', state: 'valid' })
			}
			if (this.hasSection('businessInfo')) {
				this.sections.push({ id:'businessInfo', state: 'valid' })
			}
			if (this.hasSection('categoryInfo')) {
				this.sections.push({ id:'categoryInfo', state: this.product.addl.missingCategories ? 'error' : 'valid' })
			}
			if (this.hasSection('ticketInfo')) {
				this.sections.push({ id:'ticketInfo', state: 'valid' })
			}
			if (this.hasSection('availabilityInfo')) {
				this.sections.push({ id:'availabilityInfo', state: 'valid' })
			}
			if (this.hasSection('ticketSettingsInfo')) {
				this.sections.push({ id:'ticketSettingsInfo', state: 'valid' })
			}
			if (this.hasSection('salesChannelsInfo')) {
				this.sections.push({ id:'salesChannelsInfo', state: this.product.addl.missingSalesChannels ? 'error' : 'valid' })
			}

			if (!!this.$store.state.featureFlags['FEATURE_SKIRENTAL_SPECIFIC_SETTINGS']) {
				this.sections.push({id: 'skirentalSettings', state: 'valid'})
			}

			if (!!this.$store.state.featureFlags['FEATURE_SKISCHOOL_SPECIFIC_SETTINGS']) {
				this.sections.push({id: 'skischoolSettings', state: 'valid'})
			}

			this.sections.push({ id:'systemInfo', state: 'valid' })
		},
		confirmDelete() {
			this.$refs.deleteDialog.show = true
		},
		scrollToPreview() {
			if (this.showPreview && !this.isSideBarFixed) {
				this.$refs.sidebar.closeSidebar();
				this.$nextTick(() => { document.getElementById('preview').scrollIntoView({ behavior: 'smooth'}) })
			}
		},
		hasSection(sectionName) {
			//New Product
			if (this.product.sys.id === '') {
				if (this.selectedServiceType?.fields?.template?.de) {
					if (this.selectedServiceType.fields.template.de[sectionName]) {
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			} else {
				//Existing Product
				if (this.product.fields.serviceType?.de?.fields?.template?.de) {
					if (this.product.fields.serviceType.de.fields.template.de[sectionName]) {
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			}
		},
		goback(gobackToDashboard) {
			this.gobackToDashboard = !!gobackToDashboard
// TODO: remove as we refactor sub components to do change watching
			const generalInfoData = this.$refs['generalInfo']?.sendData()
			const businessHoursData = this.$refs['businessInfo']?.sendData()
			const categoryInfo = this.$refs['categoryInfo']?.sendData()
			const availabilityInfo = this.$refs['availabilityInfo']?.sendData()
			const salesChannelsInfo = this.$refs['salesChannelsInfo']?.sendData()

// TODO: do this all in a computed field instead!
			if (this.changed.publicInfoChanged === true ||
				this.changed.contactInfoChanged === true ||
				generalInfoData?.changed === true ||
				businessHoursData?.changed === true ||
				categoryInfo?.changed === true ||
				salesChannelsInfo?.changed === true ||
				availabilityInfo?.changed === true
			) {
				this.toggleLeaveDialog()
			}
			else {
				this.$router.push('/services')
			}
		},
		toggleLeaveDialog() {
			this.$refs.unsavedChangesDialog.show = true
		},
		toggleHaveBusinessHours(event) {
			if  (!event  || event === false) {
				this.product.fields.businessHours = { de:[] }
				this.businessHours = []
			} else {
				this.product.fields.businessHours = { de: [this.$refs.businessInfo.getNewBusinessHours(false)] }
				this.businessHours  = [this.$refs.businessInfo.getNewBusinessHours(false)]
			}
		},
		getNewBusinessHours(counter) {
			if (!counter) counter = Math.random()
			return {
				sys: { id: 'id_bh_' + counter },
				fields: {
					validFromDate: { de: new Date().toISOString().split('T')[0] },
					validToDate: { de: new Date().toISOString().split('T')[0] },
					businessTimes: {
						de: {
							monday:    { times: [{ openTime: '', closeTime: '' }] },
							tuesday:   { times: [{ openTime: '', closeTime: '' }] },
							wednesday: { times: [{ openTime: '', closeTime: '' }] },
							thursday:  { times: [{ openTime: '', closeTime: '' }] },
							friday:    { times: [{ openTime: '', closeTime: '' }] },
							saturday:  { times: [{ openTime: '', closeTime: '' }] },
							sunday:    { times: [{ openTime: '', closeTime: '' }] },
							holidays:  { times: [{ openTime: '', closeTime: '' }] },
						},
					},
				},
			}
		},
		copyBusinessHours(isCopy) {
			this.product.fields.copyBusinessHours.de = isCopy
			if (isCopy) {
				// TODO: i think we should not actually copy them, but link them instead
				this.businessHours = JSON.parse(JSON.stringify(this.serviceProvider.fields.businessHours.de))

				for (var businessHours of this.businessHours) {
					if (businessHours.fields) {
						businessHours.fields.validFromDate.de = businessHours.fields.validFromDate.de.split("T")[0]
						businessHours.fields.validToDate.de = businessHours.fields.validToDate.de.split("T")[0]
					}
				}
				this.product.fields.businessHours = this.businessHours
			}
			else {
				this.businessHours = [this.getNewBusinessHours()]
			}
		},
		onDeleteConfirmed() {
			this.$refs.deleteDialog.show = false
			this.deleteService()
			return true
		},
		onDeleteCancelled() {
			this.$refs.deleteDialog.show = false
		},
		confirmUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false
			this.$refs.translationsIncompleteDialog?.close()

			if (this.userIsOperator) {
				this.$store.commit('setSelectedProduct', null)
			}

			if (this.gobackToDashboard === true) {
				this.$router.push('/')
				this.$emit('show-dashboard')
			}
			else {
				this.$router.push('/services')
			}

			return true
		},
		cancelUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false
			this.gobackToDashboard = false
		},
		confirmMissingTranslations() {
			this.submit(this.translationsIncompleteOriginalAction, undefined, true)
			this.$refs.translationsIncompleteDialog?.close()
		},
		handleEditTranslations() {
			this.$refs.translationsIncompleteDialog?.close()
			this.$refs.translationWizardDialog?.open()
		},
		setActive(key) {
			this.activeLink = key
			if (this.$refs['SECTION_' + key]) {
				this.$refs['SECTION_' + key].toggle()
				this.$refs.sidebar.closeSidebar()
      		} else if (this.$refs[key]) {
				// open the disclosures from child components
				this.$refs[key].$refs['SECTION_' + key].toggle()
				this.$refs.sidebar.closeSidebar()
			}
		},
		togglePreview() {
			this.showPreview = !this.showPreview
		},
// TODO
		setChanged() {
			this.submitButtonTheme = "blueButton white--text"
		},
		async submit(action, updatedModel, force = false) {
			// cancel any AI context loading items before submit
			this.aiContext_cancel()

			const updateFromChildren = !updatedModel
			if (updatedModel) this.product = updatedModel

			this.updateModel = !this.updateModel
			this.updateAvailabilityModel = false

			// we need to sync the product with the data in the children components
			if (updateFromChildren) this.updateProductFromChildren()

			// dialog for incomplete languages
			/* ATT: disabled this logic as it was causing the change tracking to fail when we run submit a second time.
			if (!force && this.$store.state.featureFlags.FEATURE_TRANSLATION_WIZARD && this.$refs.translationsIncompleteDialog?.isIncomplete?.length) {
				this.translationsIncompleteOriginalAction = action
				return this.$refs.translationsIncompleteDialog.open()
			}
			*/

			if ((action === 'save' && this.status === 'active') && (((this.userIsOperator && !this.userIsImpersonating) ||
				this.serviceProvider.fields.trustLevel.de === 3) || (this.serviceProvider?.fields?.trustLevel?.de === 2 && this.product.sys.id !== ''))) {
				action = 'activate'
			}

			if (this.product.fields?.importantInformation?.de) {
				this.product.fields.importantInformation.de = this.convertLink(this.product?.fields?.importantInformation?.de)
			}

			if (this.product.fields.topProduct || this.product.fields.freeProduct) {
				this.product.fields.topProduct = {de: this.product.fields.topProduct.de }
				this.product.fields.freeProduct = {de: this.product.fields.freeProduct.de }
			}

			//Remove unnecessary data before submit
			if (this.product.fields.faqGroup?.de?.fields) {
				this.product.fields.faqGroup.de.fields.faqs = [];
			}

			if (!this.productIsExternal) {
				if (this.hasField('availabilityInfo','leadTime', this.product)) {
					if (!this.product.fields.leadTimeHours.de) this.product.fields.leadTimeHours.de = 0
					if (!this.product.fields.leadTimeDays.de) this.product.fields.leadTimeDays.de = 0
					if (!this.product.fields.leadTimeWeeks.de) this.product.fields.leadTimeWeeks.de = 0
					if (!this.product.fields.leadTimeMonths.de) this.product.fields.leadTimeMonths.de = 0
				}

				this.product.fields.ticket?.de?.fields?.ticketOptions?.de?.forEach(el => {
					el.fields.maximumTickets.de = parseInt(el.fields.maximumTickets.de)
					el.fields.minimumTickets.de = parseInt(el.fields.minimumTickets.de)
					el.fields.contingentQuantity.de = parseInt(el.fields.contingentQuantity.de)
				})
			}

			this.product.fields.productCategories = { de: [] }

			//Check Validation
			try {
				if (!this.validate(action, updateFromChildren)) {
					return this.showError(this.$t('text.missingFieldsError'))
				}
				//Always check title even if runValidation is false
				if (!this.validateRefOnSave('publicInfo', this.$refs, this.runValidation)) {
					return this.showError(this.$t('text.titleRequiredError'))
				}
			} catch (e) {
				console.error(e)
				return this.showError(e)
			}

			if (this.product.sys.id !== "") {
				await this.updateProduct(action)
			}
			else {
				await this.createProduct(action)
			}
		},
		async updateProduct(action) {
			this.showLoader(true)
			this.csError = null

			try {
				const data = {
					product: this.product,
					serviceProvider: this.$store.state.selectedServiceProvider,
					clientId: this.$store.state.selectedClient.sys.id,
					action: action,
					isOperator: this.$store.state.loggedInUser.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation,
				}

				const res = await this.$httpPut('/product', data)

				this.successTitle = "SERVICE DESIGNER"
				this.successDetail = this.$t('text.serviceUpdateSuccessMsg')

				await this.$store.commit('setSelectedProduct', {sys: {id: res.productId}})
				await this.getProduct()

				this.updateAvailabilityModel = true
				this.reloadLogs = !this.reloadLogs
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")

				if (error.code === 447) {
					this.handleContingentServiceError(error)
				} else {
					this.showError(error)
				}
			}
			this.showLoader()
		},
		handleContingentServiceError(error) {
			this.showLoader()

			let detail = error?.messageForEndUser[this.selectedLocale]
			
			this.csError = error
			this.errorDetail = detail
			this.errorTitle = this.$t('text.ERROR')

			if (!this.product.fields.productAvailabilities.de.find(pa => pa.sys.id === this.csError.data.availabilityId)) {
				this.csDeletedValidityError = true

				//Replace first new availabiity with deleted availability
				this.availabilityToReplace = this.$refs.availabilityInfo.initData.productAvailabilities.de.find(init => init.sys.id === this.csError.data.availabilityId)
				let index = this.product.fields.productAvailabilities.de.findIndex(pa => pa.sys.id.indexOf('id_') > -1) 
				this.product.fields.productAvailabilities.de[index] = this.availabilityToReplace

				this.updateAvailabilityModel = true
			}
		},
		async createProduct(action) {
			this.showLoader(true)
			try {
				const data = {
					product: this.product,
					serviceProvider: this.$store.state.selectedServiceProvider,
					clientId: this.$store.state.selectedClient.sys.id,
					action: action,
					isOperator: this.$store.state.loggedInUser.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation
				}
				let res = await this.$httpPost('/product', data)
				
				this.successTitle = "SERVICE DESIGNER"
				this.successDetail = this.$t('text.serviceCreateSuccessMsg')

				await this.$store.commit('setSelectedProduct', {sys: {id: res.productId}})
				await this.getProduct()

				this.updateAvailabilityModel = true
				this.reloadLogs = !this.reloadLogs
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				this.showError(error)
			}
			this.showLoader()
		},
		async deactivateProduct(action) {
			this.showLoader(true)
			try {
				const data = {
					productId: this.product.sys.id,
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
					action: action,
					clientId: this.$store.state.selectedClient.sys.id,
				}
				const res = await this.$httpPost('/deactivate-product', data)

				await this.getProduct('update')
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				if (error.response) {
					this.showError(error.response.data?.error)
				}
				else {
					this.successTitle = "SERVICE DESIGNER"
					this.successDetail = this.$t('text.serviceUpdateSuccessMsg')
					this.showLoader()
				}
			}
			this.showLoader()
		},
		async duplicateService() {
			this.showLoader(true)

			try {
				const res = await this.$httpPost(`/duplicate-product/${this.$store.state.selectedServiceProvider.sys.id}/${this.$store.state.selectedProduct.sys.id}`, {})
				this.duplicateProductId = res.productId
				this.$refs.duplicateProductDialog.show = true
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				this.showError(error)
			}
			this.showLoader()
		},
		async onViewDuplicateConfirmed() {
			await this.$store.commit('setSelectedProduct', { sys: { id: this.duplicateProductId } })
			this.$refs.duplicateProductDialog.show = false
			// MYS-3202: reload page to get new product properly because the client assignment in the duplicated product is still the original one
			window.location.reload()
		},
		onViewDuplicateCancelled() {
			this.$refs.duplicateProductDialog.show = false
		},
		async deleteService() {
			this.showLoader(true)

			try {
				const resDelete = await this.axios.delete(`/product`,
				{
					headers: this.requestHeaders(),
					data: {
						productId: this.$store.state.selectedProduct.sys.id,
						serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
						dryRun: false
					}
				})

				this.successTitle = this.$t('text.SERVICE_DELETED')
				this.successDetail = this.$t('text.serviceDeletedSuccess')

				this.showLoader()

				this.sleep(2000).then(() => { this.$router.push("/services") });
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.showLoader()
		},
		validate(action, updateFromChildren) {
			this.runValidation = false

			//Check if Validation should run
			if (action === 'activate' || action === 'approve') {
				this.runValidation = true
			}
			else {
				if (this.product.sys.id !== '') {
					//Updating an existing product
					for (const clientAssignment of this.product.fields.clientAssignments.de) {
						if (clientAssignment.fields?.status?.de === 'pending' || clientAssignment.fields?.status?.de === 'active') {
							this.runValidation = true
							break
						}
					}
				}
			}

			const allValid = this.runValidation === true ? this.validateAllFields() : true

			if (allValid && updateFromChildren) {
				this.updateProductFromChildren()
			}

			return allValid
		},
		validateRefOnActivate(refName, refs) {
			let isValid = true
			if (this.hasSection(refName)) {
				isValid = refs[refName]?.validateAllFields()
			}
			return isValid
		},
		validateRefOnSave(refName, refs, runValidation) {
			let isValid = true
			if (this.hasSection(refName)) {
				isValid = refs[refName]?.validateTitle(runValidation)
			}
			return isValid
		},
		validateAllFields() {
			if (this.runValidation === false) return true

			// the first parameter of the validateRefOnActivate function needs to be the same as the ref on the target custom component being rendered
			const isValidPublicInfo = this.validateRefOnActivate('publicInfo', this.$refs)
			const isValidContactInfo = this.validateRefOnActivate('contactInfo', this.$refs)
			const isValidGeneralInfo = this.validateRefOnActivate('generalInfo', this.$refs)
			const isValidCategoryInfo = this.validateRefOnActivate('categoryInfo', this.$refs) 
			const isValidTicketInfo = this.validateRefOnActivate('ticketInfo', this.$refs) 
			const isValidAvailabilityInfo = this.validateRefOnActivate('availabilityInfo', this.$refs) 
			const isValidSalesChannelsInfo = this.validateRefOnActivate('salesChannelsInfo', this.$refs)

			let allValid =
				isValidPublicInfo &&
				isValidContactInfo &&
				isValidGeneralInfo &&
				isValidCategoryInfo &&
				isValidTicketInfo &&
				isValidAvailabilityInfo &&
				isValidSalesChannelsInfo

			//MYS-3231: Set error indicator in sidebar
			let publicInfo = this.sections.find(section => section.id === 'publicInfo')
			if (publicInfo) { publicInfo.state = !isValidPublicInfo ? 'error' : 'valid' }

			let contactInfo = this.sections.find(section => section.id === 'contactInfo')
			if (contactInfo) { contactInfo.state = !isValidContactInfo ? 'error' : 'valid' }

			let generalInfo = this.sections.find(section => section.id === 'generalInfo')
			if (generalInfo) { generalInfo.state = !isValidGeneralInfo ? 'error' : 'valid' }

			let categoryInfo = this.sections.find(section => section.id === 'categoryInfo')
			if (categoryInfo) { categoryInfo.state = !isValidCategoryInfo ? 'error' : 'valid' }

			let ticketInfo = this.sections.find(section => section.id === 'ticketInfo')
			if (ticketInfo) { ticketInfo.state = !isValidTicketInfo ? 'error' : 'valid' }

			let availabilityInfo = this.sections.find(section => section.id === 'availabilityInfo')
			if (availabilityInfo) { availabilityInfo.state = !isValidAvailabilityInfo ? 'error' : 'valid' }

			let salesChannelsInfo = this.sections.find(section => section.id === 'salesChannelsInfo')
			if (salesChannelsInfo) { salesChannelsInfo.state = !isValidSalesChannelsInfo ? 'error' : 'valid' }

			return allValid
		},
		async getProduct() {
			this.showLoader(true)
			try {
				const productId = this.$router.currentRoute.value?.params?.id ?? this.$store.state.selectedProduct.sys.id
				// TODO: actually we should not need this info - we should determine that from the product at the server..
				//       once we do we would have to load that sp (or take it from the product) and commit to store
				const serviceProviderId = this.$store.state.selectedServiceProvider.sys.id
				const clientId = this.$store.state.selectedClient.sys.id
				const res = await this.$httpGet(`/product/${ productId }?serviceProviderId=${ serviceProviderId }&clientId=${ clientId }`)
				this.setProductDetails(res.product)
				this.setAllChanged(false)
			}
			catch (error) {
				if (error.response && error.response.status === 401) this.$emit("show-login")
				this.showError(error)
			}
		},
		async setProductDetails(product) {
			if (!product.fields.clientAssignments) product.fields.clientAssignments = { de: [] }
			if (!product.fields.notificationEmails) product.fields.notificationEmails = this.notificationEmails
			if (!product.fields.importantInformation) product.fields.importantInformation = { de: '', en: '', fr: '', it: '', nl: '' }
			if (!product.fields.businessHours?.de) product.fields.businessHours = { de: [] }
			this.businessHours = product.fields.businessHours.de
			
			this.statusMap = this.getStatusMap(product)
			this.statusClientHasMultiple = Object.keys(this.statusMap).length > 1
			if (!this.userIsOperator) {
				// the service provider gets a list of all status
				this.status = Object.keys(this.statusMap).join(',') ?? null
			}
			else {
				// the operator gets the status for his client only
				this.status = this.getStatusClient(product)
			}
			
			this.product = product
			this.serviceProvider = product.fields.serviceProvider.de
			
			if (this.hasSection('ticketInfo') && (!this.product.fields.ticket || this.product.fields.ticket?.de?.sys?.id?.length === 0)) {
				this.product.fields["ticket"] = {
					de: {
						sys: { id: 0 },
						fields: {
							ticketOptions: {
								de:[{
								sys: { id: 0 },
								fields: {
									ticketType: { de: null },
									reduction: { de: null },
									minimumTickets: { de: 1 },
									maximumTickets: { de: 6 },
									contingentQuantity: { de: 1 },
									price: { de: '' },
									fromPriceOption: { de: false },
									ticketPersonalisations: { de: [] },
									selected: false,
								},
								}]
							}
						}
					}
				}
			}

			this.updateModel = !this.updateModel
			this.showLoader()

			await Promise.all([
				this.$store.commit('setSelectedServiceProvider', cloneDeep({
					...this.product.fields.serviceProvider.de, fields: { ...this.$store.state.selectedServiceProvider.fields, ...this.product.fields.serviceProvider.de.fields } })),
				this.$store.commit('setSelectedProduct', cloneDeep(this.product)),
			])

			this.productIsLoaded = true
			this.showProductTitle()

			this.sleep(1000).then(() => {
				this.validateAllFields()
			})
		},
		showError(error) {
			this.showLoader()
			const detail = error?.response?.data?.error ?? error

			this.errorTitle = this.$t('text.ERROR')
			this.errorDetail = detail
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		showLoader(value = false) {
			this.loading = value
		},
	}
}
</script>

<style>
hr { color: #dddddd; opacity: 0.3 !important; }
.v-input--switch { padding-top: 10px; padding-left:10px; }
.v-text-field__slot .v-label { color: #999999 !important; font-size: 12pt !important; }
.v-select__slot .v-label { color: #999999 !important; font-size: 12pt !important; }
</style>

<style scoped lang="scss">
[v-cloak] {
  display: none;
}
a { text-decoration: none; }
hr { color: #efefef; }
.theme--light.v-card {  box-shadow: none !important; }
.v-input__slot {
  border: none !important;
}
.roundButton {
  border-radius: 30px !important;
  border: solid 1px rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;
  background: #f2f2f2 !important;
  font-size: 14pt !important;
  color: rgba(0,0,0,.38) !important;
  height: 32px !important;
  margin: 5px;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.oval {
  width: 16px;
  height: 16px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
  margin-right: 3px !important;
  margin-left: -10px !important;
}
.ovalActive {
  width: 16px;
  height: 16px;
  border: solid 1px #008ec3;
  background-color: #009dd7;
  margin-right: 3px !important;
  margin-left: -10px !important;
}
.v-input__control {
  background-color: #ffffff !important;
}
.v-application .success--text {
    color: #00aeef !important;
    caret-color: #00aeef !important;
}
.dialogDiv {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width:1100px;
    height:700px;
    max-width: calc(100vw - 20px);

    background-color: #ffffff;
    border-radius: 10px;
    box-shadow:1px 1px 15px 0 rgba(0,0,0,0.2);
    z-index: 999;
}
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }

.warning-icon-col {
  flex: 1;
  text-align: center;
}

.text-col {
  flex: 2;
  text-align: center;
  
  width: 268px;
  height: 110px;
  margin: 8px 0 0;
  font-size: 17px;
  font-weight: normal;
  font-stretch:normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.42px;
  color: #9da0a5;

  text-align: left;
}

h1 { flex-shrink: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 13pt; font-weight: bold; }
.content { padding-top: 1px; width: 100%; }
.step-bar { position: fixed; right: 0; left: 0; top: 76px; background: white; }
</style>