import TestTechnical from '../api/src/TestTechnical'
import Executive, { BadRequestError } from './Executive'
import { Entry, I18n, ResultMessage, ResultStream } from './entry'
import { Field } from './entry-decorators'

// TODO: we want to use Entry classes for client communication
//       this will likely not work out of the box, but we'll
//       have to do some magic in the framework:
//       the server uses a proxy for this which will of course
//       be lost when serializing for the client..
//       probably at the client we'll need to read the Entry
//       the Entry proxy will have to move to shared
//       and also all subclass definitions.

// TODO: @SharedModel? @Entry?
export class PackageTest extends Entry<PackageTest> {
	@Field({ type: 'Symbol', localized: false, required: true })
	clientId: I18n<string>

	@Field({ type: 'Array', localized: false, required: true })
	fsTest: I18n<string[]>
}

export default class TestExecutive extends Executive {

	public clientId: string
	technical: TestTechnical

	initServer() {
		if (!this.technical)
			this.technical = new TestTechnical()
	}

	async getPackage(id: string): Promise<PackageTest> {
		console.log('TestExecutive.getPackage', this.clientId, id)
		const r = new PackageTest(id)
		r.clientId.de = this.clientId
		r.fsTest.de = this.technical.fsTest()
		const a = JSON.stringify(r)
		return r
		// TODO: support better shorthand with options?
		/*. {
			// TODO: make CT.sys.id optional?
			sys: { id, content_type: { sys: { id: 'PackageTest' } } },
			fields: {
				clientId: this.clientId,
				fsTest: this.technical.fsTest(),
			},
		})*/
	}

	async testExceptionBRE() {
		console.log('TestExecutive.testException', this.clientId)
		throw new BadRequestError({ message: 'testException' })
	}

	async testExceptionGen() {
		console.log('TestExecutive.testException', this.clientId)
		throw new Error('testException')
	}

	async createPackage(pk: PackageTest) {
		console.log('TestExecutive.createPackage', this.clientId, pk)
		return {
			error: false,
		}
	}

	async createThread() {
		this.technical.createThread(this.clientId)
		return {
			error: false,
		}
	}

	async testStream(cb: Function) {
		cb(new ResultMessage('five'))
		await new Promise((resolve) => setTimeout(resolve, 1000))
		cb(new ResultMessage('four'))
		await new Promise((resolve) => setTimeout(resolve, 1000))
		cb(new ResultMessage('three'))
		await new Promise((resolve) => setTimeout(resolve, 1000))
		cb(new ResultMessage('two'))
		await new Promise((resolve) => setTimeout(resolve, 1000))
		cb(new ResultMessage('one'))
		await new Promise((resolve) => setTimeout(resolve, 1000))
		return 'DONE'
	}
}