<template>
	<div v-if="show" :class="{backdrop}">
    <loading v-model:active="loading" :is-full-page="true" color="#4caf50" data-cy="loading" />

		<div class="dialog-div" style="height:80%;width:70%">
			<!-- Header -->
			<v-toolbar elevation="0" color="white" style="width:100%" class="dialog-header">
				<v-row style="width:100%;padding:0px" align="center">
          <div class="toolbar-title"><v-toolbar-title>{{$t('text.editCommission')}}</v-toolbar-title></div>
          <v-spacer/>
          <div class="close"><v-btn class="none" elevation="0" @click="closeDialog()"><v-icon color="#646464">mdi-window-close</v-icon></v-btn></div>
				</v-row>
				<v-spacer></v-spacer>
			</v-toolbar>
			<v-divider style="border-color:#646464"/>

			<!-- Content -->
			<v-layout row wrap class="scrollable dialog-content">
				<div class="overflow-x-auto ma-0 pa-5" style="width:100%">
          <!-- Sales Channel Specific Commission -->
          <div class="pa-0" style="width:100%">
            <p>{{ editSectionTitle }}</p>
            <p class="text" v-html="editSectionHelp"/>
          
			<div style="margin-left: -15px; margin-right: -15px;">
				<v-col class="table mt-5 pa-0">
					<v-row class="pa-0" style="background-color: #F4F4F4;">
						<span class="text pa-3 pl-5">{{ sectionName }}</span>
						<v-spacer/>
						<v-btn class="none mt-0" elevation="0" @click="addRow()"><v-icon color="#646464">mdi-plus</v-icon></v-btn>
					</v-row>
					<v-divider/>
					<v-row class="pa-3 mb-0" style="background-color: #F4F4F4; border-bottom: 1px solid #dddddd;">
						<span style="width: 30%;" class="pl-1">{{ $t('text.salesChannel') }}</span>
						<span style="width: 25%;" class="pl-3">{{ $t('text.validFrom') }}</span>
						<span style="width: 25%;" class="pl-3">{{ $t('text.validUntil') }}</span>
						<span style="width: 15%; text-align: right;" class="pr-3">{{ $t('text.commission') }}</span>
						<span style="width: 5%;"></span>
					</v-row>
					<v-row v-if="!model || model.length === 0" class="comms-row pa-3">
						{{ $t('text.noChannelBasedCommissions') }}
					</v-row>
					<div v-for="commission of model" :key="commission.sys.id" class="pt-2">
						<CommissionLineItem
							:commission="commission"
							:commissionErrors="commissionErrors"
							:datesDisabled="datesDisabled"
							@delete-row="deleteRow"
							@input="updateModel"
							:isEdit="true"
						/>
					</div>
				</v-col>
			</div>

            <div v-if="errorMessage" class="inline-error">{{errorMessage}}</div>
          </div>

          <!-- Default Commission -->
          <div v-if="!isDefault" class="pa-0 pt-10" style="width:100%">
            <p>{{ defaultSectionTitle }}</p>
            <p class="text" v-html="defaultSectionHelp"/>
          
            <v-col class="table mt-5 pa-0">
              <v-row class="pa-3" style="background-color:#F4F4F4;border-bottom: 1px solid #dddddd;">
                <span style="width:30%" class="pl-1">{{$t('text.type')}}</span>
                <span style="width:25%" class="pl-3">{{$t('text.validFrom')}}</span>
                <span style="width:25%" class="pl-3">{{$t('text.validUntil')}}</span>
                <span style="width:15%;text-align:right" class="pr-3">{{$t('text.commission')}}</span>
              </v-row>
              <v-row v-if="!defaultCommission" class="comms-row pa-3">
                <span v-if="commission.fields.type === 'product'">{{$t('text.noProductDefault')}}</span>
                <span v-if="commission.fields.type === 'attribute_set'">{{$t('text.noCategoryDefault')}}</span>
                <span v-if="commission.fields.type === 'service_provider'">{{$t('text.noChannelDefault')}}</span>
              </v-row>
              <v-row v-if="defaultCommission" class="comms-row pa-3">
                <span style="width:30%" class="pl-1">{{$t(`text.${commission.fields.type}`)}}</span>
                <span style="width:25%" class="pl-3">
                  <span v-if="commission.fields.validFrom?.length">{{ formatDate(commission.fields.validFrom) }}, {{ commission.fields.validFrom.split('T')[1] }}</span>
								  <span v-if="!commission.fields.validFrom?.length">-</span>
                </span>
                <span style="width:25%" class="pl-3">
                  <span v-if="commission.fields.validTo?.length">{{ formatDate(commission.fields.validTo) }}, {{ commission.fields.validTo.split('T')[1] }}</span>
								  <span v-if="!commission.fields.validTo?.length">-</span>
                </span>
                <span style="width:15%;text-align:right" class="pr-3">{{commission.fields.percentage}}%</span>
              </v-row>
            </v-col>
          </div>
				</div>
			</v-layout>

			<v-divider style="border-color:#646464"/>
      <!-- Actions -->
			<v-toolbar elevation="0" color="white" style="width:100%" class="dialog-footer">
				<v-spacer></v-spacer>
				<v-btn class="default mr-3 mb-3" elevation="0" data-cy="comms-cancel" @click="cancel()">{{$t('text.discardChanges')}}</v-btn>
				<v-btn class="default mr-3 mb-3" elevation="0" data-cy="comms-confirm-selection" @click="confirm()">{{$t('text.confirmSelection')}}</v-btn>
			</v-toolbar>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import CommissionLineItem from './CommissionLineItem.vue'
import { CommissionType } from '@/plugins/enum.js'
import Common from '@/mixins/Common.vue'
import { Commission } from '@/models/commission.ts'
import { CommissionError } from '@/models/commission.ts'
import moment from 'moment'

export default {
  name: "CommissionDetail",
  components: { Loading, CommissionLineItem },
  mixins: [Common],
  props: {
    serviceProvider: {
      type: Object,
      required: true
    },
    commission: {
      type: Object
    },
    defaultCommissions: {
      type: Array
    },
    existingCommissions: {
      type: Array
    },
    datesDisabled: Boolean
  },
  data() {
    return {
      show: false,
      backdrop: true,
      closeOnOuterClick: false,
      loading: false,
      isValid: false,

      editSectionTitle: '',
      editSectionHelp: '',
      sectionName: '',

      defaultSectionTitle: '',
      defaultSectionHelp: '',
      isDefault: false,
      defaultCommission: null,
      commissionErrors: {},
      errorMessage: '',

      model: []
    }
  },
	watch: {
		show(n) {
			this.fixZindexes(n)
		},
    commission: {
      handler() {
        this.reset()
      }
    }
	},

  beforeUnmount () {
		this.fixZindexes(false)
	},

  methods: {
    valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? []))
		},
    updateModel(commission) {
			const index = this.model.findIndex(m => m.sys.id === commission.sys.id) 
			if (index > -1) {
				this.model[index] = Object.assign({}, commission)
			} else {
				this.model.push(commission)
			}
		},
    async confirm() {
      const isValid = this.validateCommissions()

      if (isValid === true) {
        this.loading = true
       
       try {
          const currentCA = this.serviceProvider.fields.clientAssignments.de.find(ca => ca?.fields?.client?.de?.fields?.shopClientId?.de === this.$store.state.selectedClient.fields.shopClientId.de)
          if (!currentCA?.sys?.id?.length) return this.showError('No client assignment')

          const data = {
            commissions: this.model,
            clientAssignmentId: currentCA.sys.id
          }

          const res = await this.$httpPost('/commissions', data)
          this.$emit('update', res)
          this.closeDialog()
          this.loading = false
          
        } catch (error) {
          this.showError(error)
        }
      }
    },
    validateCommissions() {
      let valid = true
      this.commissionErrors = {}

      let commissions = [...this.model, ...this.existingCommissions]

      for (const commission of commissions) {
        let haveError = false
        const percentageError = commission.fields.percentage.length === 0 ? 'error' : ''
            
        let matchedChannelValidFromValidTo = commissions.filter(comm => 
          comm.sys.id !== commission.sys.id &&
          comm.fields.peakWebsiteId === commission.fields.peakWebsiteId &&
          comm.fields.validFrom === commission.fields.validFrom &&
          comm.fields.validTo === commission.fields.validTo && 
          comm.fields.peakEntity?.id === commission.fields.peakEntity?.id && 
          comm.fields.attributeSet?.id === commission.fields.attributeSet?.id)

        if (matchedChannelValidFromValidTo?.length) {
         //commission matches another sales channel, valid from and valid to dates
            this.commissionErrors[commission.sys.id] = new CommissionError('error', 'error', 'error', percentageError)
            valid = false
            this.errorMessage = this.$t('text.commissionMissing')
            continue
        } 

        if (commission.fields.validFrom.length || commission.fields.validTo.length) {
          //commission has overlapping dates
          const validFromDate = moment(`${commission.fields.validFrom.split("T")[0]} ${commission.fields.validFrom.split("T")[1]}`, "YYYY-MM-DD HH:mm")
          const validToDate = moment(`${commission.fields.validTo.split("T")[0]} ${commission.fields.validTo.split("T")[1]}`, "YYYY-MM-DD HH:mm")
          
          for (const comm of commissions) {
            if (comm.sys.id === commission.sys.id) continue
            if (comm.fields.peakWebsiteId !== commission.fields.peakWebsiteId) continue
            if (comm.fields.peakEntity?.id !== commission.fields.peakEntity?.id) continue
            if (comm.fields.attributeSet?.id !== commission.fields.attributeSet?.id) continue

            if (comm.fields.validFrom.length || comm.fields.validTo.length) {
              const commValidFromDate = moment(`${comm.fields.validFrom.split("T")[0]} ${comm.fields.validFrom.split("T")[1]}`, "YYYY-MM-DD HH:mm")
              const commValidToDate = moment(`${comm.fields.validTo.split("T")[0]} ${comm.fields.validTo.split("T")[1]}`, "YYYY-MM-DD HH:mm")
          
              if (validFromDate.isBetween(commValidFromDate, commValidToDate) || 
                  validToDate.isBetween(commValidFromDate, commValidToDate)) {
                  this.commissionErrors[commission.sys.id] = new CommissionError('', 'error', 'error', percentageError)
                  valid = false
                  haveError = true
                  break
              }
            }
          }

          if (haveError) {
            this.errorMessage = this.$t('text.overlappingDatesError')
            continue
          }
        }

        if (!haveError && percentageError.length) {
          this.commissionErrors[commission.sys.id] = new CommissionError('', '', '', percentageError)
          this.errorMessage = this.$t('text.commissionMissing')
          valid = false
        }
      }

      return valid
    },
		addRow() {
			let newCommission = new Commission()
			newCommission.sys.id = 'id_' + (this.model?.length ? this.model.length : '0')
			newCommission.fields.type = this.commission.fields.type
			newCommission.fields.peakEntity = this.commission.fields.peakEntity ? this.commission.fields.peakEntity : null
			newCommission.fields.attributeSet = this.commission.fields.attributeSet ? this.commission.fields.attributeSet : null
			newCommission.fields.peakWebsiteId = this.commission.fields.peakWebsiteId
			this.model.push(newCommission)
		},
		deleteRow(id) {
			const index = this.model.findIndex(comm => comm.sys.id === id)
			
			if (index > -1) {
				this.model.splice(index, 1)
			}
		},
    showChannelCommission() {
        this.editSectionTitle = this.$t('text.editChannelCommission')
        this.editSectionHelp = this.$t('text.editChannelCommissionHelp')
       
        const clientSalesChannels = this.$store.state.selectedClient.fields.salesChannels.de
        this.sectionName = clientSalesChannels.find(sc => sc.fields.peakId.de === this.commission?.fields?.peakWebsiteId)?.fields?.title?.de
        this.channelCommsVisible = true
    },
    showCategoryCommission() {
        this.editSectionTitle = this.$t('text.editCategoryCommission')
        this.editSectionHelp = this.$t('text.editCategoryCommissionHelp')
        this.defaultSectionTitle = this.$t('text.categoryDefault')
        this.defaultSectionHelp = this.$t('text.categoryDefaultHelp')
        this.sectionName = this.commission?.fields?.attributeSet?.name
        this.categoryCommsVisible = true
    },
    showProductCommission() {
        this.editSectionTitle = this.$t('text.editProductCommission')
        this.editSectionHelp = this.$t('text.editProductCommissionHelp')
        this.defaultSectionTitle = this.$t('text.productDefault')
        this.defaultSectionHelp = this.$t('text.productDefaultHelp')
        this.sectionName = this.commission?.fields?.peakEntity?.name
        this.productCommsVisible = true
    },
    commissionDefault() {
      if (this.defaultCommissions?.length) {
        const commissionsForType = this.defaultCommissions.filter(dc => dc.fields.type === this.commission.fields.type)

        if (commissionsForType?.length) {
          if (this.commission.fields.type === CommissionType.PRODUCT) {
            this.defaultCommission = commissionsForType.find(ct => ct.fields.peakEntity?.id === this.commission.fields.peakEntity.id)
          } else if (this.commission.fields.type === CommissionType.CATEGORY) {
            this.defaultCommission = commissionsForType.find(ct => ct.fields.attributeSet?.id === this.commission.fields.attributeSet.id)
          } else if (this.commission.fields.type === CommissionType.SALES_CHANNEL) {
            this.defaultCommission = commissionsForType.find(ct => ct.fields.peakWebsiteId === this.commission.fields.peakWebsiteId)
          }
        }
      }
    },
    reset() {
      this.model = [this.valueToModel(this.commission)]

      if (this.commission?.fields) {
        if (this.commission.fields.type === CommissionType.PRODUCT) {
          this.showProductCommission()
        } else if (this.commission.fields.type === CommissionType.CATEGORY) {
          this.showCategoryCommission()
        } else {
          this.showChannelCommission()
        }
      }
      
      this.isDefault = this.commission?.fields?.peakWebsiteId ? false : true
      this.commissionDefault()

      this.isValid = false
      this.errorMessage = ''
      this.commissionErrors = {}
    },
    cancel() {
      if (this.cancelHandler && typeof this.cancelHandler === 'function') {
        this.cancelHandler();
      }
      this.closeDialog();
    },
    closeDialog() {
      this.reset()

      if (this.closeHandler && typeof this.closeHandler === 'function') {
        this.closeHandler();
      }
      this.show = false;
    },
    backdropClick() {
      if (this.closeOnOuterClick) {
        this.closeDialog();
      }
    },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style?.['z-index-backedup']) 
						el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  height: calc(100vh - 64px);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 64px;
  left: 0;
}

.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1100px;
  height: min(700px, calc(100vh - 20px));
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 5px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  i {
    margin-right: 5px;
  }

  .dialog-header {
    max-height: 72px;
  }

  .dialog-content {
    height: 100%;
  }

  .dialog-footer {
   
    .gradientButton {
      height: 40px !important;
      min-height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
      color: #000;
    }

    .greenButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }

    .redButton {
      height: 40px !important;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.scrollable { overflow-y: auto; overflow-x: hidden; }
.v-label {
  color: black !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 13pt !important;
}
.toolbar-title { position:absolute; float:left; margin-left:20px; margin-top: 15px; }
.text { color: black !important; font-family: 'Inter', sans-serif !important; font-size: 13pt !important; }
.table { width: 100%; }
.close { position:absolute; right: 0px; } 
.comms-row { background-color: #ffffff; border-bottom: 1px solid #dddddd;}
.inline-error {
	background-color: transparent;
  color: #F34545;
	font-family: 'Inter', sans-serif;
	font-size: 12pt;
}
</style>
