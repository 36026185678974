<template>
	<Application :loading="loading" v-model:errorTitle="errorTitle" :errorDetail="errorDetail">
		
		<div v-if="!loading">
			<div class="d-flex justify-end">
				<v-btn v-if="editMode" @click="removeAssignment()" class="mr-4" variant="text" :disabled="serviceProviders.filter(sp => sp.selected).length === 0">
					<v-icon left>mdi-trash-can</v-icon>
					{{ $t('text.delete') }}
				</v-btn>
				<FilterMenu @clear="clearFilter" @apply="applyFilter">
					<FilterFieldWrap>
						<div>{{ $t('text.region') }}</div>
						<v-select multiple chips hide-details
							ref="vSelectRegion"
							v-model="selectedRegions"
							:items="regions"
							:item-title="item => item.region_name"
							:item-value="item => item.region_name"
							:placeholder="$t('text.allLabel')"
							variant="outlined"
							density="compact"
						>
							<template #selection="{ item }">
								<v-chip :color="item.color" dark>{{ item.region_name }}</v-chip>
							</template>
						</v-select>
					</FilterFieldWrap>

					<FilterFieldWrap>
						<div>{{ $t('text.City') }}</div>
						<v-select multiple chips hide-details
							ref="vSelectCity"
							:placeholder="$t('text.allLabel')"
							variant="outlined"
							density="compact"
							v-model="selectedCities"
							:items="cities"
							:item-title="item => item.serviceprovider_city"
							:item-value="item => item.serviceprovider_city"
						> 
							<template #selection="{ item }">
								<v-chip :color="item.color" dark>{{ item.serviceprovider_city }}</v-chip>
							</template>
						</v-select>
					</FilterFieldWrap>

					<FilterFieldWrap>
						<div>{{ $t('text.connector') }}</div>
						<v-select multiple chips hide-details
							ref="vSelectConnector"
							:placeholder="$t('text.allLabel')"
							variant="outlined"
							density="compact"
							v-model="selectedConnectors"
							:items="connectors"
							:item-title="item => item.connector_name"
							:item-value="item => item.connector_name"
						>
							<template #selection="{ item }">
								<v-chip :color="item.color" dark>{{ item.connector_name }}</v-chip>
							</template>
						</v-select>
					</FilterFieldWrap>
				</FilterMenu>
			</div>

			<div class="my-4">
				<div class="headline">{{ $t('text.chooseServiceProvider') }}</div>
				<div class="subheading mb-4">{{ $t('text.chooseServiceProviderDesc') }}</div>
			</div>

			<div class="my-8 d-flex align-center">
				<div class="flex-grow-1">
					<v-text-field variant="outlined" density="compact" clearable hide-details
						prepend-inner-icon="mdi-magnify"
						:placeholder="$t('text.searchPeakSpPlaceholder')"
						v-model="searchString"
						@keyup.enter="search()"
						@click:clear="clear()"
						data-cy="search"
					/>
				</div>
				<div class="ml-4 mt-2 d-flex align-center">
					<v-btn class="blueButton" theme="dark" elevation="0" @click="search()" data-cy="searchButton">
						<v-icon>mdi-magnify</v-icon>
						{{ $t('text.search') }}
					</v-btn>
				</div>
			</div>
		</div>

		<v-card v-if="!loading">
			<v-card-title>
				<span class="headline">{{ $t('text.chooseServiceProvider') }}</span>
			</v-card-title>
			<v-card-text>
				<v-data-table fixed-header v-model="selected" :headers="headers" :items="serviceProviders"
					:items-per-page="limit" :mobile-breakpoint="700" item-key="name" class="elevation-0"
					:item-value="item => item"
					@update:sort-by="sort">
					<template v-slot:item="{ item }">
						<tr>
							<td>
								<v-checkbox v-model="item.selected" hide-details color="blue"></v-checkbox>
							</td>
							<td>
								{{ item.serviceprovider_name }}
							</td>
							<td>
								{{ item.serviceprovider_peak_id }}
							</td>
							<td>
								{{ item.region_name }}
							</td>
							<td>
								{{ item.serviceprovider_city }}
							</td>
							<td>
								{{ item.connector_name }}
							</td>
						</tr>
					</template>
					<template #bottom>
						<TablePaginator v-model="offset" :limit="limit" :total="serviceProviderCount"
							@update:modelValue="getPeakServiceProviders()" :results="serviceProviders" />
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>

	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'
import Application from '../Application.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'

export default {
	components: {
		TablePaginator,
		Loading,
		Alert,
		Application,
		FilterMenu,
		FilterFieldWrap,

	},
	mixins: [Common],
	inject: ['injectedData'],
	props: {
		selectedServiceProviders: {
			type: Array,
			default() {
				return []
			}
		},
		editMode: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			serviceProviders: [],
			serviceProviderCount: 0,

			limit: 5,
			offset: 0,
			
			searchString: '',
			selectedRegions: [],
			selectedCities: [],
			selectedConnectors: [],

			connectors: [],

			selected: [],
			filter: false,

			initialServiceProviderCount: 0,
			// TODO: not used at the moment. The server needs to support sorting first if server-side sorting is needed.
			sortBy: '',
			sortDesc: false,
		}
	},
	computed: {
		headers() {
			return [
				{ title: '', key: "selected", width: '50px', align: 'center', sortable: false },
				{ title: this.$t('text.providerName'), key: "serviceprovider_name", width: '40%', sortable: true, cellClass: 'truncate' },
				{ title: this.$t('text.id'), key: "serviceprovider_peak_id", width: '5%', sortable: false, cellClass: 'truncate' },
				{ title: this.$t('text.region'), key: "region_name", width: '20%', sortable: true },
				{ title: this.$t('text.City'), key: "serviceprovider_city", width: '15%', sortable: true },
				{ title: this.$t('text.connector'), key: "connector_name", width: '20%', align: 'center', sortable: true },
			]
		},
		regions() {
			return this.injectedData.regions
		},
		cities() {
			return this.injectedData.cities
		},
	},
	methods: {
		async getPeakServiceProviders() {
			try {
				this.loading = true
				const currentClientId = this.$store.state.selectedClient.sys.id
				let url = `/packageTravel/serviceproviders?clientId=${currentClientId}&limit=${this.limit}&offset=${this.offset}`

				if (this.searchString) {
					url += `&searchString=${this.searchString}`
				}
				// URI encode this and URI decode it in the API code (encodeURIComponent)
				if (this.selectedRegions.length) {
					url += `&regionNames=${JSON.stringify(this.selectedRegions)}`
				}
				if (this.selectedCities.length) {
					url += `&cityNames=${JSON.stringify(this.selectedCities)}`
				}
				if (this.selectedConnectors.length) {
					url += `&connectorNames=${JSON.stringify(this.selectedConnectors)}`
				}
				if (this.sortBy) {
					url += `&sortBy=${this.sortBy}&sortDesc=${this.sortDesc}`
				}
				
				const response = await this.$httpGet(url)
				this.serviceProviders = response.serviceProviders.map(sp => ({ ...sp, selected: false }))
				this.serviceProviderCount = response.serviceProviderCount - this.selectedServiceProviders.length
				
				// Remove selectedServiceProviders from this.serviceProviders to prevent double-assignment that might cause data inconsistencies
				this.serviceProviders = this.serviceProviders.filter(sp => !this.selectedServiceProviders.some(selectedSP => selectedSP.serviceprovider_peak_id === sp.serviceprovider_peak_id))
				
			}
			catch (e) {
				if (e.response?.status == 401) return this.$emit("show-login")
				// TODO: showError seems to have been removed from the project. It is still referenced by many files. That means it will throw a runtime error when we get to the catch block.
				this.showError(e.response ? e.response?.data.error : e)
			}
			finally {
				this.loading = false
			}
		},
		async getConnectors() {
			try {
				const currentClientId = this.$store.state.selectedClient.sys.id
				const response = await this.$httpGet(`/packageTravel/connectors?clientId=${currentClientId}`)
				this.connectors = response.connectors
			}
			catch (e) {
				this.showError(e.response ? e.response?.data.error : e)
				if (e.response?.status == 401) return this.$emit("show-login")
			}
		},
		search() {
			this.offset = 0
			if (this.editMode) {
				this.serviceProviders = this.selectedServiceProviders.filter(
					sp => {
						const nameMatch = sp.serviceprovider_name.toLowerCase().includes(this.searchString.toLowerCase())
						const idMatch = sp.serviceprovider_peak_id.toString().includes(this.searchString)
						const regionMatch = sp.region_name?.toLowerCase().includes(this.searchString.toLowerCase())
						const cityMatch = sp.serviceprovider_city?.toLowerCase().includes(this.searchString.toLowerCase())

						return nameMatch || idMatch || regionMatch || cityMatch
					}
				).map(sp => ({ ...sp, selected: false })
				)
				this.serviceProviderCount = this.serviceProviders.length
			} 
			else {
				this.getPeakServiceProviders()
			}
		},
		clear() {
			this.searchString = ''
			this.offset = 0
			if (this.editMode) {
				this.serviceProviders = this.selectedServiceProviders.map(sp => ({ ...sp, selected: false }))
				this.serviceProviderCount = this.serviceProviders.length
			}
			else {
				// we only want to re-fetch the data if the user has already searched for something
				if (this.serviceProviderCount !== this.initialServiceProviderCount) {
					this.getPeakServiceProviders()
				}
			}
		},
		clearFilter() {
			this.selectedRegions = []
			this.selectedCities = []
			this.selectedConnectors = []
			this.offset = 0
			if (this.editMode) {
				this.serviceProviders = this.selectedServiceProviders.map(sp => ({ ...sp, selected: false }))
				this.serviceProviderCount = this.serviceProviders.length
			}
			else {
				// we only want to re-fetch the data if the user has already filtered on something
				if (this.serviceProviderCount !== this.initialServiceProviderCount) {
					this.getPeakServiceProviders()
				}
			}
		},
		applyFilter() {
			this.offset = 0
			if (this.editMode) {
				this.serviceProviders = this.selectedServiceProviders.filter(sp => {
					const regionMatch = !this.selectedRegions.length || this.selectedRegions.includes(sp.region_name)
					const cityMatch = !this.selectedCities.length || this.selectedCities.includes(sp.serviceprovider_city)
					const connectorMatch = !this.selectedConnectors.length || this.selectedConnectors.includes(sp.connector_name)
					return regionMatch && cityMatch && connectorMatch
				}).map(sp => ({ ...sp, selected: false }))
				this.serviceProviderCount = this.serviceProviders.length
			}
			else {
				this.getPeakServiceProviders()
			}
		},
		sort(column) {
			column = column[0]
			if (column.key === this.sortBy) {
				this.sortDesc = !this.sortDesc
			} else {
				this.sortBy = column.key
				this.sortDesc = false
			}
			if (this.editMode) {
				// TODO: this can be a computed property that returns this
				this.serviceProviders.sort((a, b) => {
					const fieldA = a[column.key]
					const fieldB = b[column.key]
					if (typeof fieldA === 'string' && typeof fieldB === 'string') {
						return this.sortDesc ? fieldB.localeCompare(fieldA) : fieldA.localeCompare(fieldB)
					}
					else {
						return this.sortDesc ? fieldB - fieldA : fieldA - fieldB
					}
				})
			}
			else {
				this.getPeakServiceProviders()
			}
		},
		removeAssignment() {
			// TODO: this can be a computed property that returns this
			this.serviceProviders = this.serviceProviders.filter(sp => !sp.selected)
		}
	},
	async mounted() {
		if (this.editMode) {
			this.serviceProviders = this.selectedServiceProviders.map(sp => ({ ...sp, selected: false }))
			this.serviceProviderCount = this.serviceProviders.length
		} 
		else {
			await this.getPeakServiceProviders()
			// we use this to check when should we re-fetch the data (when the user has searched or filtered)
			this.initialServiceProviderCount = this.serviceProviderCount
		}
		
		await this.getConnectors()
	},
}
</script>
