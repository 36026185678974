<template>
	<div class="SkuMultiSelect">
		<v-autocomplete
			v-model="model"
			v-model:search="search"
			@input="loadDebounced"
			:min-search="2"
			:items="items"
			multiple
			chips
			closable-chips
			variant="outlined"
			:loading="loading"
			:hide-no-data="false"
			density="compact"
		/>
	</div>
</template>

<script>
export default {
	props: {
		type: { type: String, default: 'package-ov-skipass' },
		modelValue: Array,
	},
	data: () => ({
		model: [],
		search: '',
		items: [],
		loadTimeout: null,
	}),
	watch: {
		modelValue(n) { this.model = n },
		model(n) { this.$emit('update:modelValue', n) },
	},
	methods: {
		async loadDebounced() {
			this.loading = true
			if (this.loadTimeout) clearTimeout(this.loadTimeout)
			this.loadTimeout = setTimeout(() => this.load(), 500)
		},
		async load() {
			// TODO: implment server mode to only fetch name and skus for quicker response?
			const clientId = this.$store.state.selectedClient.sys.id
			const r = await this.$httpPost(`/packageTravel/products?clientId=${clientId}`, {
				searchString: this.search,
				limit: 20,
				offset: 0,
				type: this.type,
			})
			this.loading = false
			this.items = r.products.map(p => p.product_sku)
		},
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style>
.SkuMultiSelect .v-chip__content { font-size: smaller; color: #333; }
.SkuMultiSelect .v-chip.v-chip--density-default { height: auto; }
.SkuMultiSelect .v-input--density-compact .v-field__input { row-gap: 0; }
</style>