<template>
	<Disclosure :title="$t('text.checklistSettings')" :expanded="true"
		:error="sectionMessage.checklistSettings.error"
		:message="sectionMessage.checklistSettings.message"
		style="margin-top: 65px;"
	>
<!-- TODO: this should not have its own alerts! -->
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading v-if="loading" :active="loading" :is-full-page="true" color="#4caf50" data-cy="loading"></loading>

		<div class="field left-border">
			<p class="helpText" v-html="$t('text.enableChecklistHelp')"/>
			<mys-switch
				v-model="enableChecklistFeature"
				:label="$t('text.enableChecklistFeature')"
				@update:modelValue="save()"
			/>
		</div>

		<div class="field left-border" style="width:100%" v-if="checklist.fields && checklist.fields.items">
			<p class="text-h6">{{ $t('text.checklistItems') }}</p>
			<p class="helpText" v-html="$t('text.checklistItemsHelp')" />

			<v-btn block class="gradientButton" elevation="0" @click="startAdd" data-cy="add" style="margin: 10px 0;">
				<v-icon>mdi-plus</v-icon>{{ $t('text.addChecklistItem') }}
			</v-btn>

			<div v-for="(item, index) in checklist.fields.items.de" :key="index" class="itemRow"
				@click="editItem = item"
			>
				<v-checkbox :modelValue="true" hide-details disabled style="flex-grow: 0; margin: 0;"></v-checkbox>
				<div style="flex-grow: 1;">{{ item?.text?.[serviceLocale] }}</div>
			</div>
		</div>

		<I18nStringDialog v-if="editItem"
			:title="$t('text.tasks')"
			v-model="editItem.text"
			:canDelete="true"
			@cancel="editItem = null"
			@confirm="confirm"
			@delete="del"
		/>
	</Disclosure>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import I18nStringDialog from '../../../components/I18nStringDialog.vue'

export default {
	name: 'ChecklistSettingsView',
	mixins: [ Common ],
	components: { Loading, Alert, Disclosure, I18nStringDialog },
	data: () => ({
		checklist: {},
		enableChecklistFeature: false,
		sectionMessage: {
			checklistSettings: { error: false, warning: false, message: '' },
		},
		editItem: null,
	}),
	computed: {
		// TODO: should this be used??
		checklistValid() {
			return !(!this.checkRequiredForAllLocales(this.locales, this.editItem.text))
		},
	},
	methods: {
// TODO: is this needed?
		handleAction(event) {
			this[event.function](event.params)
		},
// TODO: why is there a function for this? how is this called?
		setLocale(code) {
			this.serviceLocale = code
		},
		goback() {
			this.$router.push('/settings')
		},
		loadChecklist() {
			// TODO: we should not get this from the store, but as v-model binding or from a service
			const client = this.$store.state.selectedClient
			// TODO: util in Common like featureEnabled(featureId)?
			this.enableChecklistFeature = client.fields.features?.de?.find(x => x.id === 'service-provider-checklist')?.status === 'enabled'
			this.checklist = client.fields.serviceProviderChecklist?.de ?? {}

			if (!this.checklist?.fields?.items?.de?.length) {
				this.checklist = {
					sys: { id: '' },
					fields: {
						items: { de: [] },
					},
				}
			}
		},
		startAdd() {
			const maxKey = this.checklist.fields.items.de.reduce((max, item) => Math.max(max, item.key), 0)
			this.editItem = {
				key: (maxKey + 1) + '',
				text: null,
				checked: false,
			}
		},
		del() {
			const index = this.checklist.fields.items.de.findIndex(x => x === this.editItem)
			this.checklist.fields.items.de.splice(index, 1)
			this.save()
			this.editItem = null
		},
		confirm() {
			const index = this.checklist.fields.items.de.findIndex(x => x === this.editItem)
			if (index < 0)
				this.checklist.fields.items.de.push(this.editItem)
			this.save()
			this.editItem = null
		},
		async save() {
			this.loading = true
			const data = {
				clientId: this.$store.state.selectedClient.sys.id,
				enabled: this.enableChecklistFeature,
				checklist: this.checklist,
			}

			try {
				const client = await this.$httpPost(`/checklist`, data)
				await this.$store.commit('setSelectedClient', client)
				
				this.successTitle = this.$t('text.checklistSuccessTitle')
				this.successDetail = this.$t('text.checklistSucessMsg')

				this.loadChecklist()
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
		},
	},
	async mounted() {
		this.loadChecklist()
	},
}
</script>

<style scoped>
.itemRow { padding: 0 10px; border-radius: 4px; width: 100%; display: flex; align-items: center; }
.itemRow:hover { background-color: #f2f2f2; cursor: pointer !important; }
</style>