<template>
	<FieldSet id="contactInfo">
		TODO: enter / copy from SP
		<Field typeName="ContactDetails" fieldName="streetAddress" v-model="modelValue.fields.streetAddress" />
		<div style="display: flex; gap: 10px; width: 100%;">
			<Field typeName="ContactDetails" fieldName="zipCode" v-model="modelValue.fields.zipCode" style="flex: 1;" />
			<Field typeName="ContactDetails" fieldName="city" v-model="modelValue.fields.city" style="flex: 1;" />
		</div>
		<Field typeName="ContactDetails" fieldName="country" v-model="modelValue.fields.country" />
		<Field typeName="ContactDetails" fieldName="location" v-model="modelValue.fields.location" />
		<div style="display: flex; gap: 10px; width: 100%;">
			<Field typeName="ContactDetails" fieldName="phone" v-model="modelValue.fields.phone" style="flex: 1;" />
			<Field typeName="ContactDetails" fieldName="email" v-model="modelValue.fields.email" style="flex: 1;" />
		</div>
		<Field typeName="ContactDetails" fieldName="website" v-model="modelValue.fields.website" style="flex: 1;" />
	</FieldSet>
</template>

<script>
// TODO: not sure anymore if this is really the right thing to do
//       SPs still have the "old style" ContactInfos in CM, which will make
//       the function "copy from SP" difficult to implement..
//       if we cant overcome this, that would also force us to store the
//       ContactInfos of the Package in CM..
//       - we would need an additional attribute storing the SP Contact Infos id
//       - on CF sync we need to support both formats (on Peak sync also?)

import FieldSet from './FieldSet.vue'

export default {
	components: { FieldSet },
	props: {
		modelValue: Object,
	},
	provide() {
		return {
			address: this.modelValue,
		}
	},
}
</script>

<style scoped>
</style>