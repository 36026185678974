<template>
	<div v-if="status" class="status"
		:style="{
			color,
		}"
	>
		<v-icon v-if="icon" :color="color">{{ icon }}</v-icon>
		{{ $t('text.' + status) }}
	</div>
</template>

<script>
export default {
	name: 'ApprovalStatus',
	props: {
		status: String,
	},
	computed: {
		color() {
			if (this.status === 'pending') return '#ff9e21'
			if (this.status === 'reapprove') return '#ffb400'
			if (this.status === 'saved') return '#589ae6'
			if (this.status === 'active_saved') return '#589ae6'
			if (this.status === 'active') return '#66BB6A'
			if (this.status === 'deactivated') return '#f34545'
			if (this.status === 'declined') return '#f34545'
			if (this.status === 'approved') return '#64c823'
			if (this.status === 'expired') return '#737373'

			if (this.status === 'available') return '#66BB6A'
			if (this.status === 'overbooked') return '#f34545'
			if (this.status === 'fullybooked') return '#ff9e21'
			return '#cccccc'
		},
		icon() {
			if (this.status === 'pending') return 'mdi-clock-outline'
			if (this.status === 'reapprove') return 'mdi-refresh'
			if (this.status === 'saved') return 'mdi-content-save'
			if (this.status === 'active_saved') return 'mdi-content-save'
			if (this.status === 'active') return 'mdi-check'
			if (this.status === 'deactivated') return 'mdi-close'
			if (this.status === 'declined') return 'mdi-close'
			if (this.status === 'approved') return 'mdi-check'
			if (this.status === 'expired') return 'mdi-clock-outline'

			if (this.status === 'available') return 'mdi-calendar-blank'
			if (this.status === 'overbooked') return 'mdi-calendar-alert'
			if (this.status === 'fullybooked') return 'mdi-calendar-remove'
		},
	}
}
</script>

<style scoped>
.status {
	border-radius: 25px;
	font-size: 11pt;
	text-align: center;
	cursor: default;
	line-height: initial;
	max-width: 380px;
	display: flex;
	gap: 5px;
	align-items: center;
	white-space: nowrap;
}
</style>