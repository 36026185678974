<template>
	<div>
		<article>pk: {{ pk }}</article>
		<article>pk.fsTest.de (without .fields): {{ pk?.fsTest?.de }}</article>
		<button @click="updateModel">updateModel</button>
		<article>cr: {{ cr }}</article>
		<article>stream: {{ stream }}</article>
		<article>streamResult: {{ streamResult }}</article>
		<button @click="load">run</button>
		<button @click="createThread">createThread</button>
		<button @click="getEntityData">getEntityData</button>
		<button @click="message">message</button>
	</div>
</template>

<script lang="ts">
import TestExecutive, { PackageTest } from '../../../../shared/TestExecutive'
import TrackingExecutive from '../../../../shared/TrackingExecutive'

export default {
	data: () => ({
		pk: null,
		cr: null,
		ex: null,
		stream: [],
		streamResult: null,
	}),
	methods: {
		async load() {
			this.pk = null
			let executive = new TestExecutive({ clientId: 'C1' }, this)
			this.pk = await executive.getPackage('P1')
//			this.cr = await executive.createPackage(this.pk)
//			this.cr = await executive.createPackage(new PackageTest({ id: 'P2', name: 'P2' }))
			//try { this.ex = await executive.testExceptionBRE() } catch (e) { console.error('X BRE', e) }
			//try { this.ex = await executive.testExceptionGen() } catch (e) { console.error('X Gen', e) }
			this.stream = []
			this.streamResult = await executive.testStream(r => { this.stream.push(r) })
		},
		async createThread() {
			let executive = new TestExecutive({ clientId: 'C1' }, this)
			await executive.createThread()
		},
		async getEntityData() {
			let executive = new TrackingExecutive({}, this)
			await executive.getEntityData('product', 'MA-2ff78ed1-7a50-42f1-8c3d-e61fe9bf7f64')
		},
		async message() {
			let executive = new TrackingExecutive({}, this)
			await executive.message('T1234567', 'product', 'MA-2ff78ed1-7a50-42f1-8c3d-e61fe9bf7f64', 'Hello World')
		},
		// TODO: adding something does not trigger a re-render - we dont have proper reactivity..
		//       probably this is because we are using the Entry2 proxy
		//       can the proxy implement some functions to make it work? i tried getOwnPropertyDescriptor and ownKeys but it did not work
		async updateModel() {
//			this.pk.fsTest.de.push('NEW_ITEM')
//			console.log('updateModel', this.pk.fsTest.de)
			this.pk.fields.fsTest.de.push('NEW_ITEM')
			console.log('updateModel', this.pk.fields.fsTest.de)
		},
	},
	async mounted() {
		this.load()
	},
}
</script>

<style scoped>
article { padding: 5px; border-bottom: 1px solid silver; }
button { margin: 5px; padding: 4px 8px; background: silver; border: 1px solid gray; }
</style>