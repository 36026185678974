<template>
	<button class="Tag" :style="{ [ '--tag-col' ]: color }">
		{{ value.substr(0, 3) }}
	</button>
</template>

<script>
export const colors = [ '#F85E56', '#74CE2E', '#FFC000', '#0ABFF3', '#FF973E', '#D862C2', '#868686' ]

export default {
	name: 'Tag',
	props: {
		value: String,
	},
	data: () => ({
		color: '#000',
	}),
	mounted() {
		if (!window.tagColorMap) window.tagColorMap = {}
		if (!window.tagColorMap[this.value]) {
			const i = Object.keys(window.tagColorMap).length % colors.length
			window.tagColorMap[this.value] = colors[i]
		}
		this.color = window.tagColorMap[this.value]
	},
}
</script>

<style scoped>
.Tag {
	font-size: 11pt;
	padding: 0 5px;
	--tag-col: gray;
	color: var(--tag-col);
	border: 3px solid var(--tag-col);
	border-radius: 45%;
	white-space: nowrap;
	margin-right: 5px;
	font-weight: bold;
	background-color: transparent;
	text-transform: uppercase;
}
</style>