<template>
	<!-- DO NOT DELETE THIS!
	Because <Teleport to="HTMLelement" /> doesn't allow spawning elements within same component this wrapper div is used to
	hold the table expand/collapse buttons -->
	<div id="table-wrapper">
		<slot></slot>
	</div>
</template>

<style>
#table-wrapper {
	position: relative !important;
}
</style>
