<template>
	<div>
		<VuetifyTiptap
			ref="importantinfo"
			v-model="model"
			:class="{
				'enabled-text-style': true,
				disabledTiptap: disabled,
			}"
			:extensions="extensions"
		/>
		<div v-if="errorMessages" style="color: red;">
			<div v-for="(errorMessage, i) of errorMessages" :key="i">{{ errorMessage }}</div>
		</div>
	</div>
</template>

<script>
// TODO: emit focus event
import { VuetifyTiptap, Heading, Bold, Link} from 'vuetify-pro-tiptap'
import 'vuetify-pro-tiptap/styles/editor.css'

export default {
	name: 'ImportantInfo',
	components: { VuetifyTiptap },
	props: {
		modelValue: String,
		disabled: Boolean,
		errorMessages: Array,
	},
	data() {
		return {
		model: '',
		extensions: [
			Heading.configure({ levels: [6] }),
			Bold,
			Link,
		],
	}},
	watch: {
		modelValue(n) { this.model = n },
		model(n) { this.$emit('update:modelValue', n) },
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style>
.enabled-text-style >>> .v-input__control > .v-input__slot { background: #ffffff !important; }
.enabled-text-style >>> .tiptap-vuetify-editor__content { background: #ffffff !important; height: 300px; }
.tiptap-vuetify-editor { margin: 10px 0; }
.tiptap-vuetify-editor.disabledTiptap { pointer-events: none; }
.tiptap-vuetify-editor__content { padding: 0 !important; }
body .tiptap-vuetify-editor .ProseMirror { margin: 0 !important; min-height: 200px; padding: 20px; }
body .tiptap-vuetify-editor__content p { color: black; margin-top: 0 !important; }
body .tiptap-vuetify-editor__content h1 { color: black; }
body .tiptap-vuetify-editor__content h2 { color: black; }
body .tiptap-vuetify-editor__content h3 { color: black; }
body .tiptap-vuetify-editor__content h4 { color: black; }
body .tiptap-vuetify-editor__content h5 { color: black; }
body .tiptap-vuetify-editor__content h6 { color: black; }
</style>