<template>
	<v-alert :id="`alert_${type}`" prominent flat closable v-model="visible" :type="type" class="notification" style="border-radius: 0; z-index: 999;">
		<div class="text-h6">{{ this.modelValue }}</div>
		<div class="detail">
			<slot />
		</div>
	</v-alert>
</template>

<script>
export default {
	name: 'Alert',
	emits: [],
	props: {
		type: { type: String, default: 'error' },
		modelValue: { type: String },
	},
	data() {
		return {
		visible: false,
	}},
	watch: {
		modelValue(v) {
			this.visible = !!v
		},
		visible(v) {
			if (!v) return this.close()
			setTimeout(this.close, 5000)
		},
	},
	methods: {
		close() {
			this.visible = false
			this.$emit('update:modelValue', null)
		},
	},
	mounted() {
		this.visible = !!this.modelValue
	},
}
</script>

<style scoped>
.title { font-size: 14pt; font-weight: bold; }
</style>