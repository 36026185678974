<template>
	<div v-if="entryData"
		style="display: flex; flex-direction: column; gap: 4px;"
	>
		<div v-if="!threadMessages?.length">No Recent Activities</div>
		<div v-for="message of threadMessages" :key="message.id"
			:style="{ '--thread-color': message.thread?.color ?? 'gray' }"
		>
			<div v-for="date of optionalDate(message.created_at)" :key="message.id + date"
				style="color: #888; font-size: smaller; margin-top: 5px;"
			>
				{{ date }}
			</div>
			<div
				v-if="message.firstOfThread"
				style="padding: 4px 0; font-size: smaller;"
			>
				<b style="color: var(--thread-color);">{{ message.thread?.type }}</b>
				{{ message.thread?.created_by }}
				<span style="color: silver;">{{ message.thread?.id }}</span>
			</div>
			<div
				style="border-left: 2px solid var(--thread-color); padding: 4px 8px; background: #f6f6f6; border-radius: 8px;"
			>
				<b style="color: #999; font-size: smaller;">
					{{ message.type }}
					<span v-if="message.entry_id != message.thread?.entry_id" style="font-weight: normal;">
						{{ message.entry_type }}
						{{ message.entry_id }}
						<br />
					</span>
				</b>
				{{ message.text }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import TrackingExecutive from '../../../../shared/TrackingExecutive'

export default {
	props: {
		entryType: String,
		entryId: String,
	},
	data: () => ({
		entryData: null,
		colors: [ 'red', 'green', 'blue', 'goldenrod', 'orange', 'purple', 'deeppink', 'brown', 'navy' ],
		incrementalTimeLast: null,
	}),
	computed: {
		threadById() {
			const r = {}
			let currentCol = 0
			this.entryData.threads.forEach(thread => r[thread.id] = {
				...thread,
				color: this.colors[currentCol++ % this.colors.length],
			})
			return r
		},
		threadMessages() {
			const r = []
			const started = {}
			this.entryData.messages.forEach(message => {
				// TODO: underscore style here needs to go
				//       we have to map this in the dao!
				const thread = this.threadById[message.thread_id]
				r.push({
					...message,
					thread,
					firstOfThread: thread && !started[thread.id],
				})
				started[message.thread_id] = true
			})
			return r
		},
	},
	methods: {
		async load() {
			let executive = new TrackingExecutive({}, this)
			this.entryData = await executive.getEntityData(this.entryType, this.entryId)
		},
		hash(text) {
			var hash = 0;
			for (var i = 0; i < this.length; i++) {
				var char = this.charCodeAt(i)
				hash = ((hash << 5) - hash) + char
				hash = hash & hash
			}
			return hash
		},
		formatDate(d) {
			return d.substring(0, 10)
		},
		formatTime(d) {
			return d.substring(11, 16)
		},
		formatIncrementalDateTime(d) {
			try {
				// first date
				if (!this.incrementalTimeLast) {
					return this.formatDate(d) + ' ' + this.formatTime(d)
				}

				// within a minute
				const d1 = new Date(d)
				const d2 = new Date(this.incrementalTimeLast)
				const diff = d1.getTime() - d2.getTime()
				if (diff >= 0 && diff < 1000 * 60 * 1) {
					return ''
				}

				// within the same day
				if (d1.getDate() == d2.getDate()) {
					return this.formatTime(d)
				}

				// other day
				return this.formatDate(d) + ' ' + this.formatTime(d)
			}
			finally {
				this.incrementalTimeLast = d
			}
		},
		optionalDate(d) {
			const r = this.formatIncrementalDateTime(d)
			if (!r) return []
			return [ r ]
		},
	},
	async mounted() {
		this.incrementalTimeLast = new Date().toISOString()
		this.load()
	},
}
</script>

<style scoped>
</style>