<template>
	<div class="SeatRow"
		:class="{ selected }"
		@click="select"
	>
		<div v-if="selected && selection.length == 1" class="editForm">
			count: <input type="number" v-model="row.count" />
		</div>
		<div v-for="seat, s in seats" :key="s" class="seat"
			:style="{
				left: seat.x + 'px',
				top: seat.y + 'px',
				transform: 'rotate(' + (seat.a ?? 0) + 'rad)',
			}"
			draggable
			@mousedown="dragStart($event, seat)"
		>
			{{ seat.name ?? 'A22' }}
		</div>
		<!-- TODO: comp for CP? -->
		<!-- TODO: doubleclick does not work because on mousedown we preventDefault.
				detect doubleclick in dragStart
		-->
		<!-- TODO: draw a handle-connect-line -->
		<div v-if="selected && selection.length == 1" class="cp"
			:style="{
				left: curve.p2.x + 'px',
				top: curve.p2.y + 'px',
			}"
			draggable
			@mousedown="dragStart($event, row.cps[1])"
		/>
		<div v-if="selected && selection.length == 1" class="cp"
			:style="{
				left: curve.p3.x + 'px',
				top: curve.p3.y + 'px',
			}"
			draggable
			@mousedown="dragStart($event, row.cps[2])"
		/>
		<div v-if="selected && selection.length == 1" class="line"
			:style="{
				left: curve.p1.x + 'px',
				top: curve.p1.y + 'px',
				width: round(calculateDistance(curve.p2, curve.p1)) + 'px',
				transform: 'rotate(' + angle(curve.p1, curve.p2) + 'rad)',
			}"
		/>
		<div v-if="selected && selection.length == 1" class="line"
			:style="{
				left: curve.p4.x + 'px',
				top: curve.p4.y + 'px',
				width: round(calculateDistance(curve.p3, curve.p4)) + 'px',
				transform: 'rotate(' + angle(curve.p4, curve.p3) + 'rad)',
			}"
		/>
	</div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { calculateSampleEqui, calculateDistance, Curve, Point } from './seat-util'

// TODO: do we want to allow more than 2 control points per row?
// TODO: sidebar to edit details for selected object(s)
// TODO: per-seat offset back/forward
// TODO: per-seat offset along t

export default {
	props: {
		row: Object as PropType<{ cps: Point[], count: number }>,
		selection: Array,
	},
	data: () => ({
	}),
	computed: {
		selected(): boolean {
			return this.selection.includes(this.row)
		},
		curve(): Curve {
			return {
				p1: this.row.cps[0],
				// TODO: the 1 + 2 cps should actually be rotationally relative to the 0->3 vector
				p2: { x: this.row.cps[0].x + this.row.cps[1].x, y: this.row.cps[0].y + this.row.cps[1].y },
				p3: { x: this.row.cps[3].x + this.row.cps[2].x, y: this.row.cps[3].y + this.row.cps[2].y },
				p4: this.row.cps[3],
			}
		},
		seats(): Point[] {
			const seats: Point[] = []
			for (let i = 0; i < this.row.count; i++) {
				const t = i / (this.row.count - 1)
				let p = calculateSampleEqui(this.curve, t)
				const a = p.a
				if (i == 0) { p = this.curve.p1; p.a = a }
				else if (i == this.row.count - 1) { p = this.curve.p4; p.a = a }
				seats.push(p)
			}
			return seats
		},
	},
	methods: {
		calculateDistance,
		round: Math.round,
		angle(p1: Point, p2: Point) {
			return Math.atan2(p2.y - p1.y, p2.x - p1.x)
		},
		select($event: MouseEvent) {
			if ($event.shiftKey || $event.metaKey) {
				if (this.selected)
					this.selection.splice(this.selection.indexOf(this.row), 1)
				else
					this.selection.push(this.row)
			}
			else
				this.$emit('update:selection', [ this.row ])
		},
		dragStart(e: MouseEvent, point: Point) {
			//console.log('dragStart', e)
			const pointEl = e.target as HTMLElement
			const offsetX = e.clientX - pointEl.getBoundingClientRect().left
			const offsetY = e.clientY - pointEl.getBoundingClientRect().top
			const drag = (e: MouseEvent) => {
				// TODO: when scrolling, the offset is wrong
				// TODO: also allow to drag other seats than the first and the last
				//point.x = e.clientX - offsetX
				//point.y = e.clientY - offsetY
				point.x += e.movementX
				point.y += e.movementY
			}
			const dragEnd = () => {
				//console.log('dragEnd', e)
				document.removeEventListener('mousemove', drag)
				document.removeEventListener('mouseup', dragEnd)
				// if point is a curve handle, check distance to its end point
				// if it is too close, set it to 0|0
				if (point == this.row.cps[1] || point == this.row.cps[2]) {
					const d = Math.sqrt(point.x * point.x + point.y * point.y)
					if (d < 15) {
						point.x = 0
						point.y = 0
					}
				}
			}
			document.addEventListener('mousemove', drag)
			document.addEventListener('mouseup', dragEnd)
			e.preventDefault()
		},
	},
}
</script>
<style scoped>
.seat {
	position: absolute;
	width: 20px;
	height: 20px;
	background: tomato;
	font-size: 10px;
	text-align: center;
	color: white;
	border-radius: 3px;
	cursor: move;
	border-top: 5px solid #333;
}
.selected .seat { outline: 2px dashed blue; outline-offset: 1px; }
.cp {
	position: absolute;
	width: 6px;
	height: 6px;
	background: blue;
	outline: 3px solid blue;
	border-radius: 10px;
	cursor: move;
	z-index: 999;
	margin-top: 7px;
	margin-left: 7px;
}
.line { position: absolute; background: blue; height: 2px; transform-origin: 0 0; margin-top: 8px; margin-left: 8px; z-index: 999; pointer-events: none; }
</style>