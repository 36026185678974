<template>
	<div class="Optional" v-show="modelValue[this.id]">
		<slot></slot>
	</div>
</template>

<script lang="ts">
export default {
	emits: [ 'disable' ],
	props: {
		modelValue: Object,
		id: String,
		autoShow: Boolean,
		hidden: Boolean
	},
	watch: {
		autoShow(n) {
			// we automatically switch the modelValue to autoShow value as long as it's not hidden
			if (!this.hidden) this.modelValue[this.id] = n
		},
		hidden(n) {
			if (n) this.modelValue[this.id] = undefined
			else this.modelValue[this.id] = this.autoShow
		}
	},
	mounted() {
		// auto-register in the shared settings model
		if (this.hidden) {
			this.modelValue[this.id] = undefined
		}
		else if (this.autoShow) {
			this.modelValue[this.id] = true
		}
		else {
			this.modelValue[this.id] = this.modelValue[this.id] ?? false
		}

		// we have to watch dynamically because we dont know the id statically
		this.$watch('modelValue.' + this.id + '', (n) => {
			if (!n) {
				this.$emit('disable', this.id)
			}
		})
	},
}
</script>

<style scoped>
</style>