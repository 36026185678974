<template>
  <div style="width:100%" class="pa-3">
    <!-- Timeslots -->
    <div v-if="timeslotView">
      <p class="helpText" v-html="$t('text.cmTimeslotHelp')"/><br/>
    
      <v-data-table
        :headers="timeslotHeaders"
        :items="contingents"
        item-key="from"
        class="elevation-0 "
        :items-per-page="1000"
        fixed-header hide-default-footer disable-sort>

        <template v-slot:item="{item}">
          <tr @click="getOrderItems(item)">
            <td>{{item.from.split("T")[1].substring(0,5)}}</td>
            <td>
              {{(item.total - item.free) + (item.overbookFree * -1)}} / {{item.total}}
              {{ $t('text.booked')}}
            </td>
            <td>
              <ApprovalStatus :status="getStatus(item)" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <!-- Orders -->
    <div v-if="ordersView">
      <p class="helpText" v-html="$t('text.cmOrdersHelp')"/><br/>
    
      <v-data-table
        :headers="ordersHeaders"
        :items="orderItems"
        item-key="from"
        class="elevation-0 "
        :items-per-page="1000"
        fixed-header hide-default-footer disable-sort>

        <template v-slot:item="{item}">
          <tr>
            <td>{{item.order_id}}</td>
            <td>{{ formatDateTime(item.order_date) }}</td>
            <td>{{item.client_name}}</td>
            <td>{{item.customer.firstname}} {{item.customer.lastname}}</td>
            <td>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- Back Button -->
      <div style="position:absolute;bottom:5px;z-index:999">
        <v-btn elevation="0" class="gradientButton" @click="toggleView()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>


<script>
import ApprovalStatus from '@/components/common/ApprovalStatus.vue'

export default {
  name: "ContingentDetails",
  components: { ApprovalStatus },
  props: {
    product: Object,
    eventDate: String,
    contingents: Array
  },

  data() {
    return {
      timeslotView: true,
      ordersView: false,
      selectedTime: '',
      orderItems: []
    }
  },

  computed: {
		timeslotHeaders() {
			return [
				{ text: this.$t('text.timeslot'), value: "timeslot", width: "15%", sortable: false },
				{ text: this.$t('text.contingent') , value: "contingent", width: "15%", sortable: false },
				{ text: this.$t('text.status'), value: "status", width: "5%", sortable: false, align:"center" }
      ]
		},
    ordersHeaders() {
			return [
				{ text: "ID", value: "orderId", width: "15%", sortable: false },
				{ text: this.$t('text.orderDate') , value: "orderDate", width: "15%", sortable: false },
        { text: this.$t('text.marketplace') , value: "marketplace", width: "15%", sortable: false },
        { text: this.$t('text.customerName') , value: "customerName", width: "15%", sortable: false },
				{ text: "", value: "viewOrder", width: "5%", sortable: false, align:"center" }
      ]
		}
  },

  mounted() {
  },

  methods: {
    toggleView() {
      if (this.timeslotView === true) {
        this.timeslotView = false
        this.ordersView = true
        this.$emit('switch-view', {view: 'orders', time: this.selectedTime})
      } else {
        this.timeslotView = true
        this.ordersView = false
        this.$emit('switch-view', {view:'timeslots'})
      }
    },
    getStatus(contingent) {
      if ((contingent.total - contingent.free) + (contingent.overbookFree * -1) > contingent.total) {
        return 'overbooked'
      } else if ((contingent.total - contingent.free) + (contingent.overbookFree * -1) === contingent.total) {
        return 'fullybooked'
      }
      return 'available'
    },
    async getOrderItems(item) {
      try {
        this.selectedTime = `${item.from.split("T")[1].substring(0,5)}:00`
        this.toggleView()

        let sku = ''

        if (this.product?.fields?.clientAssignments?.de?.length) {
          for (const clientAssignment of this.product.fields.clientAssignments.de) {
            if (clientAssignment?.fields?.sku?.de) {
              sku = clientAssignment.fields.sku.de
              break
            }
          }
        }

       const res = await this.$httpGet(`/order-items?sku=${sku}&eventDate=${this.eventDate}&timeslot=${this.selectedTime}`)
        this.orderItems = res.orderItems
      } catch (error) {
        this.showError(error)
      }
    }
  }
}
</script>