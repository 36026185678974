<template>
	<DatePickerCalendar
		class="IsoDatePicker"
		v-model="model"
		:disabled="disabled"
	/>
</template>

<script>
import moment from 'moment-timezone'
import DatePickerCalendar from '../../views/applications/packageDesigner/DatePickerCalendar.vue'

// TODO: i am unsure ATM, but it looks like all of the original concerns of this component stemmed from
//       shortcomings of the Vuetify component and are now not necessary any longer.
//       possibly we could just use DatePickerCalendar instead now.

// this comp is only here to make v-date-picker compatible with iso-dates
// (like 2023-12-31T00:00:00.000Z) instead of js dates (like 2023-12-31)

// TODO: hide the weird headlines

// TODO: timezone handling??
//         picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

export default {
	components: { DatePickerCalendar },
	props: {
		modelValue: String,
		disabled: { type: Boolean, default: false },
		// ASSUMPTION: this timezone is relative to the services local timezone
		// TODO: we will have to add a timezone field to the service and to the service provider and pass it in here
		// TODO: where to get the timezone offset of the service from?
		// TODO: what about DST?
		//tz: { type: String, default: 'America/Los_Angeles' },
		tz: { type: String, default: 'Europe/Vienna' },
		// TODO: warn about mismatch in TZ
	},
	data: () => ({
		model: null,
	}),
	watch: {
		// iso string at target tz -> local Date
		modelValue(n) {
			this.model = moment(n).format('YYYY-MM-DD')
			return

			// the date coming in is an iso date string. it will have a time zone attached, that should match this.tz
			//console.log('IsoDatePicker modelValue 1', n, typeof n, 'tz', this.tz)
			if (!n) return new Date().setHours(0, 0, 0)
			n = moment(n.substring(0, 10)).toDate()
			//console.log('IsoDatePicker modelValue 2', n, typeof n)
			this.model = n//.toISOString().substring(0, 10)
		},
		// local Date -> iso string at target tz
		model(n) {
			this.$emit('update:modelValue', moment(n).toISOString())
			return

			// the date coming in is in browser timezone like Sun Jun 11 2023 00:00:00 GMT-0700 (Pacific Daylight Time)
			//console.log('IsoDatePicker model 1', n, typeof n, 'tz', this.tz)
			// we need to bring it into the services time zone - in our case this is easy, as we dont have time, only date
			n = moment.tz(n, this.tz).format('YYYY-MM-DD')
			//console.log('IsoDatePicker model 2', n, typeof n)
			n = moment.tz(n, this.tz).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
			//console.log('IsoDatePicker model 3', n, typeof n)
			this.$emit('update:modelValue', n)
		},
	},
	beforeMount() {
		this.model = this.modelValue
		return
	},
}
</script>