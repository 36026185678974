<template>
	<div>
		<div v-for="client in clients" :key="client.id">
			<div v-if="app === 'BusinessProfile' || app === 'ServiceDetail'" justify="center">
				<Disclosure :title="`${$t('text.paymentAndCancellationTitleFor')} ${client.title}`"
					:error="sectionMessage.error" :message="sectionMessage.message" data-cy="paymentAndCancellationInfo"
					ref="SECTION_paymentAndCancellation" :expanded="true">
					<div class="field left-border">
						<p class="section-text"><strong>{{ $t('text.paymentAndCancellationEventsTitle') }} ({{
							$t('text.required')
						}})</strong></p>
						<p class="section-text">Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
							Lorem
							ipsum
							Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum</p>
						<div class="field-container">
							<v-row class="pa-5 actions-bar"
								:justify="policies[client.id]?.assignedPolicies.length ? 'end' : 'space-between'">
								<p v-if="!policies[client.id]?.assignedPolicies.length" class="no-data-text">{{
									$t('text.noPaymentPoliciesAdded') }}
								</p>
								<v-btn @click="openAddNewPolicyDialog(client.id)" variant="outlined"
									style="text-transform: none;"><v-icon>mdi-plus</v-icon>
									{{ $t('text.addPaymentPolicy') }}</v-btn>
							</v-row>
							<!-- Policy Table -->
							<div v-if="policies[client.id]?.assignedPolicies.length"
								class="payment-plan-overview pr-5 table-container">
								<CommonTable :sortBy="sortBy" v-model:expanded="expanded" show-expand
									:items="policies[client.id]?.assignedPolicies" :headers="headers" hideFooter
									variant="secondary">
									<template #[`item.selected`]="{ item }">
										<div class="payment-plan-selected">
											<v-checkbox hide-details v-model="item.selected" color="blue" />
										</div>
									</template>

									<template #[`item.name`]="{ item }">
										<div class="payment-plan-name" :title="item.name[serviceLocale]">
											{{ getLocalizedText(item.name, serviceLocale) }}
										</div>
									</template>

									<template #[`item.patternGroupIds`]="{ item }">
										<div class="payment-plan-product-types">
											<div v-for="({ name, id }) in getProductTypes(item).slice(0, 3)" :key="id"
												:title="name">
												<Chip :title="name" :color="primary" />
											</div>

											<Chip v-if="getProductTypes(item).length > 3"
												:title="'+ ' + (getProductTypes(item).length - 3) + ' more'" />
										</div>
									</template>

									<template #[`item.pricing`]="{ item }">
										{{ getPricingText(item) }}
									</template>

									<template #[`item.actions`]="{ item }">
										<div class="payment-plan-actions">
											<v-btn @click="unassignPolicy(client.id, item)" class="outlined-secondary-btn"
												variant="text" icon="mdi-delete"
												:data-cy="'paymentPlanDeleteBtn_' + item.id" />
										</div>
									</template>

									<template #expanded-row="{ item }">
										<h4>
											{{ $t('text.bpStepGeneral') + ":" }}
										</h4>
										<div class="policy-info-wrapper">
											<div class="policy-info-header">
												<div class="subtitle policy-info-item">
													<h5>{{ $t('text.pricing_mys') + ":" }}</h5>
													<h5 style="font-weight: normal;">{{ getPricingText(item) }}</h5>
												</div>
												<div class="subtitle policy-info-item">
													<h5>{{ $t('text.noShowFee') + ":" }}</h5>
													<h5 style="font-weight: normal;" v-if="item.noShowFee">{{ item.noShowFee
													}}%
													</h5>
												</div>
											</div>
										</div>
										<h3>
											{{ $t("text.milestonesAtAGlanceLabel") + ":" }}
										</h3>
										<div class="payment-plan-milestones">
											<MilestonesPreview :payment-plan="item" />
										</div>
									</template>
								</CommonTable>
							</div>
						</div>
					</div>
				</Disclosure>
			</div>
		</div>
		<!-- Add New Policy Dialog -->
		<DataDialog ref="addNewPolicyDialog" v-model="dialogPolicies" @update:modelValue="assignPolicies(selectedClient.id)"
			:title="$t('text.addNewPaymentPolicyTitle')" height="95vh" width="70vw">
			<template #content="{ model, updateModel }">
				<div style="width: 100%;">
					<p class="subTitle text"><strong>{{ $t('text.choosePayPlan') }}</strong></p>
					<p style="{ color: grey; }">{{ $t('text.choosePayPlanHelperText') }}</p>
					<PaymentPlanOverview :data="model" :billingDomainId="selectedClient.billingDomainId"
						@updateData="updateModel" :getProductTypes="getProductTypes" />
				</div>
			</template>
		</DataDialog>
	</div>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import DataDialog from '@/components/common/DataDialog.vue'
import PaymentPlanOverview from '@/components/payplan/PaymentPlanOverview.vue'
import MilestonesPreview from "@/components/payplan/MilestonesPreview/MilestonesPreview.vue"
import Common from '@/mixins/Common.vue'
import CommonTable from '@/components/common/tables/Table.vue'
import Chip from "@/components/common/controls/Chip.vue"

export default {
	name: "PaymentAndCancellation",
	components: { Dialog, Disclosure, DataDialog, PaymentPlanOverview, MilestonesPreview, Chip, CommonTable },
	props: {
		app: String,
		dataPayload: Object,
		updateModel: Boolean,
		serviceProvider: Object,
		workflowStatus: String,
		showError: Function,
		showLoader: Function,
		dataCy: String,
	},
	mixins: [Common],
	data() {
		return {
			sectionMessage: {
				error: false,
				message: ''
			},
			policies: {},
			sortBy: [
				{
					key: 'name',
					order: 'asc'
				}
			],
			expanded: [],
			dialogPolicies: [],
			selectedClient: {},
			patternGroups: [],
		}
	},
	computed: {
		headers() {
			return [
				{
					title: this.$t('text.payPlan'),
					value: 'name',
					key: 'name',
					sortable: false,
				},
				{
					title: this.$t('text.productTypes'),
					value: 'patternGroupIds',
					key: 'patternGroupIds',
					sortable: false,
				},
				{
					title: this.$t("text.pricing_mys"),
					value: 'pricing',
					key: 'pricing',
					sortable: false
				},
				{
					title: '',
					value: 'actions',
					key: 'actions',
					width: 156,
					sortable: false
				}
			]
		},
		clients() {
			const clients = this.dataPayload?.fields?.clientAssignments?.de.map(item => ({
				id: item.fields?.client?.de?.sys?.id,
				billingDomainId: item.fields?.client?.de?.fields?.billingDomainId?.de,
				title: item.fields?.client?.de?.fields?.title?.de,
			}))
			return clients.filter(({ billingDomainId }) => !!billingDomainId)
		},
	},
	methods: {
		openAddNewPolicyDialog(clientId) {
			this.selectedClient = this.clients.find(client => client.id === clientId)
			this.dialogPolicies = this.policies[clientId]?.availablePolicies
			this.$refs.addNewPolicyDialog.open()
		},
		getProductTypes(paymentPlan) {
			const productTypeIds = paymentPlan?.patternGroupIds ?? []
			return this.patternGroups
				.filter(({ id }) => productTypeIds.includes(id))
				.map(productType => {
					return {
						id: productType.id,
						name: productType.name,
					}
				})
		},
		getPricingText(policy) {
			const priceAdjustment = Number(policy.priceAdjustmentPercent)
			const isAdjusted = Number.isNaN(priceAdjustment) || priceAdjustment !== 0
			if (isAdjusted) {
				const prefix = priceAdjustment > 0 ? "+" : "-"
				const value = Math.abs(priceAdjustment)
				const text = this.$t("text.manipulatePricesBy", { value: `${prefix} ${value} %` })
				return text
			}
			return this.$t("text.useOriginalPrice")
		},
		getAllPatternGroupIds(policies) {
			const patternGroupIdsSet = new Set()

			Object.values(policies).forEach(({ templatePolicies, serviceProviderPolicies }) => {
				templatePolicies.forEach(({ patternGroupIds }) => patternGroupIds.forEach(id => patternGroupIdsSet.add(id)))
				serviceProviderPolicies.forEach(({ patternGroupIds }) => patternGroupIds.forEach(id => patternGroupIdsSet.add(id)))
			})

			return Array.from(patternGroupIdsSet.values())
		},
		async getPatternGroupById(id) {
			return this.$httpGet(`/patternGroup/${id}`)
		},
		async getAllPatternGroups(policies) {
			try {
				this.showLoader(true)
				const allPatternGroupIds = this.getAllPatternGroupIds(policies)
				const patternGroups = await Promise.all(allPatternGroupIds.map(id => this.getPatternGroupById(id)))
				this.patternGroups = patternGroups
			} catch (err) {
				this.showError(err)
			} finally {
				this.showLoader()
			}
		},
		async getPolicies(clientId) {
			if (!this.$store.state.selectedProduct.sys?.id) return
			const policies = await this.$httpGet(`/client/${clientId}/product/${this.$store.state.selectedProduct.sys.id}/policy`)
			return [clientId, policies]
		},
		mapPolicy(policy, result, clientId) {
			const isAssigned = policy.serviceAssignments.find(assignment => assignment.serviceId === this.$store.state.selectedProduct.sys.id)

			if (!result[clientId]) result[clientId] = { assignedPolicies: [], availablePolicies: [] }
			if (isAssigned) return result[clientId].assignedPolicies.push(policy)

			result[clientId].availablePolicies.push(policy)
		},
		mapPolicies(data) {
			const result = {}

			Object.entries(data).forEach(([clientId, { templatePolicies, serviceProviderPolicies }]) => {
				templatePolicies.forEach(policy => this.mapPolicy(policy, result, clientId))
				serviceProviderPolicies.forEach(policy => this.mapPolicy(policy, result, clientId))
			})

			return result
		},
		async getAllPolicies() {
			try {
				this.showLoader(true)
				const allPolicies = await Promise.all(this.clients.map(client => this.getPolicies(client.id)))
				const policies = Object.fromEntries(allPolicies)
				await this.getAllPatternGroups(policies)
				this.policies = this.mapPolicies(policies)
			} catch (err) {
				this.showError(err)
			} finally {
				this.showLoader()
			}
		},
		async clonePolicy(policyId, clientId) {
			return this.$httpPut(`/client/${clientId}/product/${this.$store.state.selectedProduct.sys.id}/policy/${policyId}/clone`)
		},
		async assignPolicy(policyId, clientId) {
			return this.$httpPut(`/client/${clientId}/product/${this.$store.state.selectedProduct.sys.id}/policy/${policyId}/assignment`)
		},
		async assignPolicies(clientId) {
			try {
				const selectedPolicies = this.dialogPolicies.filter(({ selected }) => selected)

				if (!selectedPolicies.length) return

				this.showLoader(true)
				await Promise.all(
					selectedPolicies.map(({ id, isTemplate }) => {
						if (!isTemplate) return this.assignPolicy(id, clientId)
						return this.clonePolicy(id, clientId).then(id => this.assignPolicy(id, clientId))
					})
				)
				await this.getAllPolicies()
			} catch (err) {
				this.showError(err)
			} finally {
				this.showLoader()
				this.dialogPolicies = []
			}
		},
		async unassignPolicy(clientId, policy) {
			try {
				this.showLoader(true)
				const policies = await this.$httpDelete(`/client/${clientId}/product/${this.$store.state.selectedProduct.sys.id}/policy/${policy.id}/assignment`)
				await this.getAllPolicies()
			} catch (err) {
				this.showError(err)
			} finally {
				this.showLoader()
			}
		},
	},
	created() {
		this.getAllPolicies()
	},
}
</script>

<style scoped>
.section-text {
	color: black;
}

.no-data-text {
	font-size: 15px;
	color: grey;
}

.field-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.table-container {
	width: 100%;
}

.tableCard {
	margin-top: 0;
}

.actions-bar {
	display: flex;
	align-items: center;
	width: 100%;
}
</style>
