import 'vuetify-pro-tiptap/style.css'
import { VuetifyTiptap, VuetifyViewer, createVuetifyProTipTap, BaseKit } from 'vuetify-pro-tiptap'

const vuetifyProTipTap = createVuetifyProTipTap({
	components: {
		VuetifyTiptap,
		VuetifyViewer,
	},
	extensions: [
		BaseKit.configure({}),
	],
})

export default vuetifyProTipTap
