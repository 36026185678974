
<template>
    <v-container fluid class="pa-0">
      <!-- Render Asset -->
        <div style="width:100%;text-align:center">
          <img :src="entry.fields.file.de.url" height="100px" width="100px" contain/>
        </div>
        <br/>
        {{ entry.sys.id }}
    </v-container>
  </template>
  
  <script>
  import Common from '@/mixins/Common.vue'
  
  
  export default {
      name: "Asset",
      mixins: [ Common ],
      
      props: {
          entry: { type: Object }
      },
  
      data() {
          return {
  
          }
      }
  }
  </script>
