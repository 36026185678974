<template>
	<div style="width:100%">
		<loading v-model:active="loading" :is-full-page="true" color="#4caf50" data-cy="loading" />

		<!-- Select Product -->
		<div class="pa-0" v-if="productListVisible">
			<v-label>{{$t('text.chooseProduct')}}</v-label>
			<p class="text" v-html="$t('text.chooseProductHelp')"/><br/>

				<v-text-field variant="outlined" density="compact" clearable hide-details class="mr-2" 
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					v-model="searchString"
					data-cy="search"
				/>

			<v-data-table show-select 
				@toggle-select-all="toggleSelectAll"
				v-model="selectedProducts"
				:headers="headers"
				:items="filteredPeakProducts" 
				item-key="entity_id"
				style="margin-top:10px;"
				fixed-header
				:item-value="item => item"
			>
				<template v-slot:item="{ item }">
					<tr style="cursor: auto">
						<td><v-checkbox v-model="item.selected" @update:modelValue="toggleSelect"/></td>
						<td>{{ item.name }}</td>
						<td style="max-width: 200px; overflow: hidden; text-overflow: ellipsis;" :title="item.sku">{{ item.sku }}</td>
						<td><v-chip flat class="chip">{{ item.attribute_set_name }}</v-chip></td>
						<td align="right" style="white-space: nowrap;">{{ currency }} {{ formatPrice(item.price) }}</td>
						<td class="pt-3 pb-3">{{ formatWebsites(item.website_ids) }}</td>
						<td align="center">
							<v-icon v-if="item.status === 1" color="#67C927">mdi-check</v-icon>
							<v-icon v-if="item.status === 2" color="#F34747">mdi-cancel</v-icon>
						</td>
					</tr>
				</template>
				<template #bottom></template>
			</v-data-table>
		</div>

		<!-- Commissions -->
		<template v-if="commissionsVisible">
			<v-label>{{ $t('text.productBasedCommission') }}</v-label>
			<p class="text" v-html="$t('text.productCommissionBulkHelp')"/>

			<div v-if="selectedProducts.length > 1">
				<v-radio-group v-model="copyCommissions" class="pt-10">
					<v-radio :label="$t('text.copyProductCommissions')" :value="true" class="pb-5"/>
					<v-divider style="border-color: #646464;"/>
					<v-radio :label="$t('text.setIndividualProductCommissions')" :value="false" class="pt-7 pb-5"/>
				</v-radio-group>
			</div>

			<div v-for="(product, index) of selectedProducts" :key="product.entity_id" class="pb-5" style="margin-left: -15px; margin-right: -15px;">
				<v-col class="table mt-5 pb-2 pa-0">
					<v-row style="background-color: #F4F4F4; padding: 5px 5px 5px 15px;">
						<div style="width: 100%;">
							<span class="text" style="word-break: break-all;">{{ product.name }} | SKU: {{ product.sku }}
								| <nobr>{{ $t('text.price') }}: {{ currency }} {{ formatPrice(product.price) }}</nobr>
							</span>
							<v-btn class="none mt-0" elevation="0" @click="addRow(product)" style="float: right;"><v-icon color="#646464">mdi-plus</v-icon></v-btn>
						</div>
					</v-row>
					<v-divider/>
					<v-row class="pa-3 mb-0" style="background-color: #F4F4F4; border-bottom: 1px solid #dddddd;">
						<span style="width: 30%;" class="pl-1">{{ $t('text.salesChannel') }}</span>
						<span style="width: 25%;" class="pl-3">{{ $t('text.validFrom') }}</span>
						<span style="width: 25%;" class="pl-3">{{ $t('text.validUntil') }}</span>
						<span style="width: 15%; text-align: right;" class="pr-3">{{ $t('text.commission') }}</span>
						<span style="width: 5%;"></span>
					</v-row>
					<v-row v-if="!model || model.length === 0" class="comms-row pa-3 mb-2">
						{{$t('text.noProductBasedCommissions')}}
					</v-row>
					<div v-for="commission of model" :key="commission.sys.id" class="pt-2 pl-2">
						<template v-if="commission.fields.peakEntity.id === product.entity_id">
							<CommissionLineItem
								:disabled="isReadOnly(index)"
								:datesDisabled="datesDisabled" 
								:commission="commission"
								:commissionErrors="commissionErrors" 
								@input="updateModel"
								@delete-row="deleteRow"
							/>
						</template>
					</div>
				</v-col>
			</div>
			<div v-if="errorMessage.length" class="inline-error">{{errorMessage}}</div>
		</template>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import CommissionLineItem from './CommissionLineItem.vue'
import Common from '@/mixins/Common.vue'
import { Commission } from '@/models/commission.ts'
import { CommissionType } from '@/plugins/enum.js'
import isEqual from 'lodash/isEqual'

export default {
	name: 'ProductCommission',
	components: { Loading, CommissionLineItem },
	mixins: [ Common ],
	props: {
		serviceProvider: { type: Object, required: true },
		existingCommissions: Array,
		commissionErrors: Object,
		datesDisabled: Boolean,
	},
	data() { return {
		loading: false,
		errorMessage: '',

		model: [],
		currency : this.$store.state.selectedClient.fields.currency.de,
		peakProducts: JSON.parse(JSON.stringify(this.$store.state.peakProducts)) ?? [],
		attributeSets: this.$store.state.attributeSets ?? [],
		selectedProducts: [],

		productListVisible: true,
		commissionsVisible: false,

		copyCommissions: false,
		searchString: '',

		mainCommissions: [],
	}},
	computed: {
		headers() {
			return [
				{ title: this.$t('text.service'),  		key: "name", 				width:"50%", cellClass: "truncate", sortable: false },
				{ title: 'SKU', 					  	key: "sku", 				width:"30%", cellClass: "truncate", sortable: false },
				{ title: this.$t('text.category'), 		key: "attribute_set_name",	width:"5%", sortable: false },
				{ title: this.$t('text.price'),			key: "price", 				width:"10%", align: "right", sortable: false },
				{ title: this.$t('text.salesChannels'),	key: "website_ids", 		width:"5%", sortable: false },
				{ title: this.$t('text.status'), 		key: "status", 				width:"3%", align: "center", sortable: false },
			]
		},
		filteredPeakProducts() {
			return this.peakProducts.filter(fp => fp.name?.toLowerCase?.()?.indexOf?.(this.searchString.toLowerCase()) > -1)
		},
	},
	watch: {
		copyCommissions() {
			if (this.copyCommissions === false) {
				this.model = JSON.parse(JSON.stringify(this.existingCommissions ?? []))
			}
			else {
				this.copyMainCommissions()
			}
		},
		model: {
			deep: true,
			handler() {
				this.validateCommissions()
			}
		},
	},
	methods: {
		updateModel(commission) {
			const index = this.model.findIndex(m => m.sys.id === commission.sys.id)

			if (index > -1) {
				this.model[index] = Object.assign({}, commission)
			} else {
				this.model.push(commission)
			}

			if (this.selectedProducts?.length > 1 && this.copyCommissions === true) {
				this.copyMainCommissions()
			}

			this.validateCommissions()
		},
		sendData() {
			let commsToDelete = []
			
			if (this.selectedProducts?.length > 1 && this.copyCommissions === true) {
				commsToDelete = this.existingCommissions.filter(ecomm => ecomm.fields.peakEntity.id !== this.selectedProducts[0].entity_id)
			}

			let data = {
				commissions: this.model,
				commsToDelete: commsToDelete,
				mainCommissions: this.mainCommissions
			}

			data.changed = !isEqual(data, this.initData)

			return data
		},
		formatWebsites(websiteIds) {
			let websiteNames = ''
			const websites = websiteIds.split(',')
			if (!websites?.length) return websiteNames

			for (const websiteId of websites) {
				const salesChannelName = this.$store.state.selectedClient.fields.salesChannels.de.find(sc => sc.fields.peakId.de === websiteId)?.fields?.title?.de
				websiteNames += salesChannelName ? salesChannelName + ', ' : '' 
			}
			return websiteNames.substring(0, websiteNames.length-2)
		},
		isSelected(item) {
			return this.selectedProducts.find(selp => selp.entity_id === item.entity_id)
		},
		isReadOnly(index) {
			if (index === 0) return false
			if (this.copyCommissions === false) return false
			return true
		},
		copyMainCommissions() {
			this.mainCommissions = this.model.filter(comm => comm.fields.peakEntity.id === this.selectedProducts[0].entity_id)	
			this.model = [...this.mainCommissions]

			for (let i=1; i < this.selectedProducts.length; i++) {
				let peakProduct = this.selectedProducts[i]

				for (const mainCommission of this.mainCommissions) {
					let copyCommission = new Commission()
					
					copyCommission.fields.peakWebsiteId = mainCommission.fields.peakWebsiteId
					copyCommission.fields.validFrom = mainCommission.fields.validFrom
					copyCommission.fields.validTo = mainCommission.fields.validTo
					copyCommission.fields.percentage = mainCommission.fields.percentage
					copyCommission.fields.peakEntity = {id: peakProduct.entity_id, name: peakProduct.name}
					copyCommission.fields.attributeSet = {id: peakProduct.attribute_set_id, name: peakProduct.attribute_set_name}
					copyCommission.fields.type = CommissionType.PRODUCT
					copyCommission.sys.id = `id_${mainCommission.sys.id}_${i}`
					
					this.model.push(copyCommission)
				}
			}
		},
		addRow(peakProduct) {
			const newCommission = new Commission()
			const nr = this.model?.length ? this.model.length : '0'
			newCommission.sys.id = `id_${ nr }`
			newCommission.fields.type = CommissionType.PRODUCT
			newCommission.fields.peakEntity = {id: peakProduct.entity_id, name: peakProduct.name}
			newCommission.fields.attributeSet = {id: peakProduct.attribute_set_id, name: peakProduct.attribute_set_name}
			this.model.push(newCommission)

			this.validateCommissions()
		},
		deleteRow(id) {
			const index = this.model.findIndex(comm => comm.sys.id === id)
			
			if (index > -1) {
				this.model.splice(index, 1)
			}

			this.validateCommissions()
		},
		validateCommissions() {
			//Check if there are any commissions defined
			if (!this.model.length) {
				this.$emit('enable-confirm', false)
				this.errorMessage = this.$t('text.commissionMissing')
				return
			}

			this.errorMessage = ''
			let isValid = true

			for (const item of this.model) {
				if (!item.fields.percentage.length) {
					isValid = false
				}
			}

			this.$emit('enable-confirm', isValid)
		},
		toggleSelectAll(event) {
			for (let product of this.peakProducts) {
				product.selected = event.value
			}
			this.toggleSelect()
		},
		toggleSelect() {
			this.selectedProducts = this.peakProducts.filter(peak => peak.selected === true)
			this.$emit('select-products', this.selectedProducts)
		},
		setSelectedProducts() {
			this.selectedProducts = this.peakProducts.filter(peak => peak.selected === true)
			this.productListVisible = false
			this.commissionsVisible = true

			this.model = JSON.parse(JSON.stringify(this.existingCommissions ?? []))

			if (this.selectedProducts?.length) {
				for (const selectedProduct of this.selectedProducts) {
					//Check if there are no existing commissions before adding a new row
					if (!this.model.find(existing => existing.fields.peakEntity.id === selectedProduct.entity_id)) {
						this.addRow(selectedProduct)
					}
				}
			}
		},
		reset() {
			this.model = []
			this.selectedProducts = []
			this.productListVisible = true
			this.commissionsVisible = false
		},
		async getPeakProducts() {
			if (this.peakProducts.length === 0) {
				const currentCA = this.serviceProvider.fields.clientAssignments.de.find(ca => ca?.fields?.client?.de?.fields?.shopClientId?.de === this.$store.state.selectedClient.fields.shopClientId.de)

				if (currentCA?.fields?.externalId?.de) {
					this.loading = true
					const response = await this.$httpGet(`/peak/products/${this.$store.state.selectedClient.fields.installationId.de}?serviceProviderId=${currentCA.fields.externalId.de}`)
					this.peakProducts = response?.products
					this.attributeSets = response?.attributeSets

// TODO: its weird that we use storage to persist the products - are there other places where we need them?
//       is this intended as a cache? it currently acts like one. but do we want that?
//       problem: when i switch the service provider or client the products are still there..
					await this.$store.commit('setPeakProducts', this.peakProducts)
					await this.$store.commit('setAttributeSets', this.attributeSets)
					this.loading = false
				}
				else {
					// TODO: why is the externalId not there in my case?? (SP James Avail, ...)
					console.warn('the CA does not have an externalId', currentCA)
				}
			}

			//clear selected indicator
			for (let product of this.peakProducts) {
				product.selected = false
			}
		},
	},
	async mounted() {
		await this.getPeakProducts()
	},
}
</script>

<style scoped>
.text { color: black !important; font-family: 'Inter', sans-serif !important; font-size: 13pt !important; }
.theme--light.v-data-table { border-radius:5px; border:1px solid #DFDFDF; }
.v-data-table td { font-size: 11pt !important }
.table { width: 100%; }
.close { position:absolute; right: 0px; } 
.comms-row { background-color: #ffffff; }
.chip {
	font-family: 'Inter', sans-serif;
	min-height: 32px !important;
	max-height: 32px !important;
	height: 32px !important;
	background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
	border: thin #dddddd solid !important;
	display: flex !important;
	justify-content: space-around !important;
	align-items: center !important;
	border-radius: 5px !important;
	font-size: 11pt !important;
	font-weight: normal !important;
	text-transform: none !important;
	text-align: center;
	letter-spacing: 0px !important;
	cursor: pointer !important;
}
.inline-error {
	background-color: transparent;
  	color: #F34545;
	font-family: 'Inter', sans-serif;
	font-size: 12pt;
}
</style>