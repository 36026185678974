<template>
	<v-container style="padding: 0px;" fluid>
		<div class="pl-6">
			<div class="field left-border">
				<v-label>{{ $t('text.tagAssignment') }}</v-label>
				<p class="helpText" v-html="$t('text.tagAssignmentHelp')" style="margin-left: 0px;"/>

				<table style="width: 100%; margin-top: 10px;">
					<tr>
						<th><h4 class="pl-2">{{ $t('text.tag') }}</h4></th>
						<th style="text-align: right;"><h4>{{ $t('text.canManage') }}</h4></th>
					</tr>
					<tr v-for="tag of availableTags" :key="tag.value">
						<td>
							<Tag :value="tag.value" />
							<span style="font-size: 14pt !important; padding-left:10px">{{tag.value}}</span>
						</td>
						<td style="text-align: right;">
							<v-checkbox v-model="tag.selected" class="bare-checkbox" />
						</td>
					</tr>
				</table>
			</div>
		</div>
	</v-container>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Tag from '../../../components/common/Tag.vue'

export default {
	name: 'TagAssignment',
	mixins: [ Common ],
	components: { Tag },
	props: {
		user: Object,
		availableTags: Array
	},
	mounted() {
		if (this.availableTags?.length && this.user.fields.canManageTags?.de?.length) {
			for (const tag of this.availableTags) {
				tag.selected = this.user.fields.canManageTags.de.find(assignedTag => assignedTag === tag.value) ? true : false
			}
		}
		this.$forceUpdate()
	},
}
</script>

<style scoped>
th { text-align: left; }
th,
td { border-bottom: 1px solid #ddd; padding: 6px; }
.bare-checkbox { height: 30px !important; margin: 0 !important; }
</style>

<style>
.bare-checkbox .v-selection-control { display: block !important; }
</style>