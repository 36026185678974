<template>
	<div>
		<Disclosure :title="$t('text.mainUserAccountTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="mainUserAccount" ref="SECTION_mainUserAccount">
			<div class="field left-border">
				<v-label>{{$t('text.personalName')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field variant="outlined" density="compact" autocomplete="off" required
					id="mainUserAccountName"
					v-model="mainUserAccount.name"
					:error-messages="mainUserAccountNameErrors"
					@update:modelValue="v$.mainUserAccount.name.$touch()"
					@blur="v$.mainUserAccount.name.$touch()"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.email')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field variant="outlined" density="compact" required autocomplete="off"
					readonly onfocus="this.removeAttribute('readonly');"
					id="username"
					v-model="mainUserAccount.email"
					:error-messages="mainUserAccountEmailErrors"
					@update:modelValue="v$.mainUserAccount.email.$touch()"
					@blur="checkEmailExists"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.password')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field required autocomplete="off" variant="outlined" density="compact"
					readonly onfocus="this.removeAttribute('readonly');"
					id="password"
					type="password"
					v-model="mainUserAccount.password"
					:error-messages="mainUserAccountPasswordErrors"
					@update:modelValue="v$.mainUserAccount.password.$touch()"
					@blur="v$.mainUserAccount.password.$touch()"
				/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.repeatPassword')}} <span class="error-text">({{$t('text.required')}})</span></v-label>
				<v-text-field type="password" variant="outlined" density="compact" required sameAs autocomplete="off"
					id="repeatPassword"
					v-model="mainUserAccount.repeatPassword"
					:error-messages="mainUserAccountRepeatPasswordErrors"
					@update:modelValue="v$.mainUserAccount.repeatPassword.$touch()"
					@blur="v$.mainUserAccount.repeatPassword.$touch()"
				/>
			</div>
		</Disclosure>
	</div>
</template>

<script>
	import Common from '@/mixins/Common.vue'
	import Disclosure from '@/components/common/Disclosure.vue'
	import { useVuelidate } from "@vuelidate/core"
	import { required, minLength, maxLength, email, sameAs } from "@vuelidate/validators"
	import isEqual from 'lodash/isEqual'
	
	export default {
	  name: "UserAccount",
	  components: {
		Disclosure
	  },
	  mixins: [Common],
	 
	  props: {
		serviceProvider: {
		  type: Object,
		  required: true
		},
		updateModel: Boolean
	  },
	  setup() {
	  	return {
	  		v$: useVuelidate()
	  	}
	  },
	  data() {
		return {
		  model: {},
		  initData: {},
		  sectionMessage: {
			error: false,
			message: ''
		  },
		  //this is also called in the parent component via $refs
		  mainUserAccount: {
				name:'',
				email:'',
				password:'',
				repeatPassword:'',
		  },
		  emailIsUnique: true,
		}
	  },
	  validations() {
			return {
				mainUserAccount: {
					name: { required, minLength: minLength(4), maxLength: maxLength(128) },
					email: { required, email, minLength: minLength(4), maxLength: maxLength(256) },
					password: { required, minLength: minLength(8) },
					repeatPassword: { required, minLength: minLength(8), sameAsPassword: sameAs(this.mainUserAccount.password) }
				}
			}
		},
		computed: {
		mainUserAccountNameErrors() {
			const errors = []
			const { $dirty, required, minLength, maxLength } = this.v$.mainUserAccount.name

			if (!$dirty) return errors

			if (required.$invalid) errors.push(this.$t('text.nameRequired'))
			if (minLength.$invalid) errors.push(this.$t('text.nameMinChars'))
			if (maxLength.$invalid) errors.push(this.$t('text.nameMaxChars'))

			return errors
		},
		mainUserAccountEmailErrors() {
			const errors = []
			const { $dirty, email, required, minLength, maxLength } = this.v$.mainUserAccount.email

			if (!$dirty) return errors

			if (email.$invalid) errors.push(this.$t('text.emailInvalid'))
			if (required.$invalid) errors.push(this.$t('text.emailRequired'))
			if (minLength.$invalid) errors.push(this.$t('text.emailMinChars'))
			if (maxLength.$invalid) errors.push(this.$t('text.emailMaxChars'))
			if (!this.emailIsUnique) errors.push(this.$t('text.registrationEmailExistsError'))

			return errors
		},
		mainUserAccountPasswordErrors() {
			const errors = []
			const { $dirty, required, minLength } = this.v$.mainUserAccount.password

			if (!$dirty) return errors

			if (required.$invalid) errors.push(this.$t('text.passwordRequired'))
			if (minLength.$invalid) errors.push(this.$t('text.passwordMinChars'))

			return errors
		},
		mainUserAccountRepeatPasswordErrors() {
			const errors = []
			const { $dirty, sameAsPassword, required, minLength } = this.v$.mainUserAccount.repeatPassword

			if (!$dirty) return errors

			if (sameAsPassword.$invalid) errors.push(this.$t('text.repeatPasswordSame'))
			if (required.$invalid) errors.push(this.$t('text.repeatPasswordRequired'))
			if (minLength.$invalid) errors.push(this.$t('text.repeatPasswordMinChars'))

			return errors
		},
	  },
	  watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			this.setInitialUserAccount()
			this.setInitData()
		}
	  },

	  created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.setInitialUserAccount()
		this.setInitData()
	  },
	  methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			const initModel = JSON.parse(JSON.stringify(this.model))
			this.initData = {
				mainUserAccount: {
					name:'',
					email:'',
					password:'',
					repeatPassword:''
				}
			}
		},
		sendData() {
			let data = {
				mainUserAccount: this.mainUserAccount
			}

			data.changed = !isEqual(data, this.initData)
			return data
		},
		setInitialUserAccount() {
			if (!this.model.fields.mainUserAccount) {
				this.mainUserAccount = {
					name:'',
					email:'',
					password:'',
					repeatPassword:''
				}
			}
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)	

			if (this.model.fields.mainUserAccount?.fields?.name) {
				if (!Object.keys(this.model.fields.mainUserAccount.fields).length || !this.objectValues(this.model.fields.mainUserAccount.fields)) {
					allFieldsAreValid = false
				}
			} else {
				if (this.mainUserAccount && Object.keys(this.mainUserAccount).length > 0) {
					if (!Object.keys(this.mainUserAccount).length || !this.objectValues(this.mainUserAccount)) {
						allFieldsAreValid = false
					} else {
						this.v$.mainUserAccount.name.$touch()
						this.v$.mainUserAccount.email.$touch()
						this.v$.mainUserAccount.password.$touch()
						this.v$.mainUserAccount.repeatPassword.$touch()

						if (this.mainUserAccountNameErrors.length ||
							this.mainUserAccountEmailErrors.length ||
							this.mainUserAccountPasswordErrors.length ||
							this.mainUserAccountRepeatPasswordErrors.length) {

							allFieldsAreValid = false
						}
					}
				}
			}
			if (!allFieldsAreValid) {
				this.setSectionError(this.sectionMessage, this.$t('text.userAccountRequiredError'))
			}

			return allFieldsAreValid
		},
		async checkEmailExists() {
			const res = await this.$httpGet(`/check-email?email=${this.mainUserAccount.email}`)
			this.emailIsUnique = !res.emailExists
		},
	  }
	}
	</script>
	
	<style scoped>
	
	</style>
	
	