<template>
	<div>
		<!-- General -->
		<p class="title">{{ $t('text.packageDesignerGeneral') }}:</p>
		<div class="expanded-row">
			<div class="general-row">
				<!-- SKU -->
				<div class="row-item">
					<p>{{ $t('text.sku') }}:</p>
					<p>{{ item.sku.de }}</p>
				</div>
				<!-- Title -->
				<div class="row-item">
					<p>{{ $t('text.title') }}:</p>
					<p>{{ cleanName(item.name.de) }}</p>
				</div>
				<!-- Service Provider -->
				<div class="row-item">
					<p>{{ $t('text.serviceProvider') }}:</p>
					<p>{{ item.serviceProviderName.de }}</p>
				</div>
				<!-- TODO: no type ATM -->
				<!-- Type -->
				<div class="row-item">
					<p>{{ $t('text.type') }}:</p>
					<p>{{ item.packageType.de }}</p>
				</div>
			</div>

			<div class="general-row-second">
				<!-- TODO: no source in the response ATM -->
				<!-- Created/Imported -->
				<div class="row-item">
					<p>{{ $t('text.createdIn') }}/{{ $t('text.importedFrom') }}:</p>
					<p>STATIC-DATA MyServices</p>
					<!-- <p>{{ item.createdIn }}</p> -->
				</div>
				<!-- Created -->
				<div class="row-item">
					<p>{{ $t('text.created') }}:</p>
					<p>{{ formatDate(item.createdAt.de) }} {{ $t('text.by') }} {{ changeLogDetails.createdBy }}</p>
				</div>
				<!-- Last Updated -->
				<div class="row-item">
					<p>{{ $t('text.lastUpdated') }}:</p>
					<p>{{ formatDate(item.ptUpdatedAt.de) }} {{ $t('text.by') }} {{ changeLogDetails.updatedBy }}</p>
				</div>
			</div>
		</div>
		<!-- Marketplace Approval States -->
		<div class="expanded-row">
			<div class="marketplace-row mt-4">
				<p class="title mb-4">{{ $t('text.marketplaceApprovalStates') }}:</p>
				<div>
					<div class="sales-channels-headers pb-2 pl-2 pr-6">
						<p>{{ $t('text.marketplaceSalesChannels') }}</p>
						<p>{{ $t('text.approval') }}</p>
					</div>
					<div class="row-item pt-4 pl-2">
						<div>
							<p class="row-item-title">{{ $store.state.selectedServiceProvider?.fields?.title?.[selectedLocale] }}</p>
							<p class="row-item-subtitle">{{ item?.websites?.de?.map(({ name }) => name)?.join(', ') }}</p>
						</div>
						<ApprovalStatus class="pr-6" :status="mapPeakStatus(item.active.de)" />
					</div>
				</div>
			</div>
			<div class="product-row mt-4">
				<p class="title mb-4">{{ $t('text.includedProducts') }}:</p>
				<IncludedProductsTable :items="item.addedValues.de" :packageId="item.id" />
			</div>
		</div>
		<!-- TODO: no categories ATM in the response, replace staticCategoriesForTesting with the real categories if we find a way -->
		<!-- Categories -->
		<!-- <div class="expanded-row mt-6">
			<div class="categories-row">
				<p class="title mb-4">{{ $t('text.categories') }}:</p>
				<CategoriesTable :items="staticCategoriesForTesting" />
			</div>
			<v-spacer class="flex-2" />
		</div> -->
	</div>
</template>

<script>
import ApprovalStatus from '../../../../components/common/ApprovalStatus.vue'
import Status from '../../../../components/common/tables/Status.vue'
import IncludedProductsTable from './IncludedProductsTable.vue'
import CategoriesTable from './CategoriesTable.vue'
import Common from '../../../../mixins/Common.vue'

export default {
	name: 'PackagesTableDetail',
	mixins: [ Common ],
	components: { ApprovalStatus, Status, IncludedProductsTable, CategoriesTable },
	props: {
		item: Object,
	},
	data() {
		return {
			changeLogDetails: {},
		}
	},
	methods: {
		mapPeakStatus(status) {
			switch (status) {
				case true:
					return 'active'
				case false:
					return 'deactivated'
				default:
					return 'error'
			}
		},
		async getChangeLogDetails() {
			const clientId = this.$store.state.selectedClient?.sys?.id ?? ''
			return await this.$httpGet(`/packageTravel/packages/${this.item.id}/tableDetails?clientId=${clientId}`)
		},
		cleanName(name) {
			return name?.replace(/<.+\/>/g, '')
		},
	},
	async created() {
		this.changeLogDetails = await this.getChangeLogDetails()
	}
}
</script>

<style scoped lang="scss">
.expanded-row {
	display: flex;
	gap: 100px;

	.general-row {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
	}

	.general-row-second {
		display: flex;
		gap: 5px;
		flex-direction: column;
		flex: 1;
		width: 50%;
	}

	.marketplace-row {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.product-row {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.categories-row {
		flex: 1;
	}

	.sales-channels-headers {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid grey;
	}

	.row-item {
		display: flex;
		justify-content: space-between;

		.row-item-title {
			font-size: 15px;
		}

		.row-item-subtitle {
			font-size: 12px;
		}
	}

	.flex-2 {
		flex: 2;
	}
}
</style>