<template>
	<div style="margin-bottom: 10px;">
		<!-- No Commissions -->
		<div v-if="commissions.length===0" style="color: #A1A1A1;">
			{{ $t('text.noSpecificCommissionsSet') }}
		</div>

		<SalesChannelCommission v-if="activeCommissions.length > 0" :commissions="activeCommissions" :isDisabled="isDisabled" type="active" @delete="sendDelete" @edit="sendEdit"/>
		<SalesChannelCommission v-if="upcomingCommissions.length > 0" :commissions="upcomingCommissions" :isDisabled="isDisabled" type="upcoming" @delete="sendDelete" @edit="sendEdit"/>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import SalesChannelCommission from './SalesChannelCommission.vue'
import moment from 'moment'

export default {
  	name: 'SalesChannelSummary',
	components: { SalesChannelCommission },
  	mixins: [Common],
  	props: {
		commissions: Array,
		isDisabled: Boolean
	},
	data() {
		return {
			activeCommissions: [],
			upcomingCommissions: [],
			expanded: 0
		}
	},
	watch: {
		commissions: {
			deep: true,
			handler() {
				this.splitCommissionsByDate()
			}
		}
	},
	mounted() {
		this.splitCommissionsByDate()
	},
	methods: {
		splitCommissionsByDate() {
			this.activeCommissions = []
			this.upcomingCommissions = []
			
			const today = new Date().toISOString()
			if (this.commissions?.length) {
				for (const commission of this.commissions) {
					if (!commission.fields.validFrom.length && !commission.fields.validTo.length) {
						this.activeCommissions.push(commission)
					} else {
						const fromDate = commission.fields.validFrom.length ? commission.fields.validFrom.split('T')[0] : moment(new Date()).subtract(1, 'days').toISOString()
						const toDate = commission.fields.validTo.length ? commission.fields.validTo.split('T')[0] : moment(new Date()).add(1, 'days').toISOString()
					
						if (today > fromDate && today < toDate) {
							this.activeCommissions.push(commission)
						} else if (today < fromDate) {
							this.upcomingCommissions.push(commission)
						}
					}
				}
			}
		},
		sendDelete(commission) {
			this.$emit('delete', commission)
		},
		sendEdit(commission) {
			this.$emit('edit', commission)
		},
		open() {
			this.expanded = 0
		},
		close() {
			this.expanded = -1
		},
		toggle() {
			if (this.expanded === -1) {
				this.open()
			} else {
				this.close()
			}	
		},
	}
}
</script>

<style scoped>
.active-bar { background-color: #D8F1C8; border-radius: 5; color: #64C823 }
.commission-type { color: #A1A1A1; font-size:10pt }
</style>