export default {
	async init(query) {
		if (!query.client_id) return

		const clients = {
			ContentHub: {
				redirect_uri_prefixes: [
					'https://editor.contenthub.dev',
					'https://editor.staging.contenthub.dev',
					'https://editor.green.contenthub.dev',
					'http://localhost:8080',
					'http://localhost:8081',
				],
			},
			'Content-Hub': {
				redirect_uri_prefixes: [
					'https://editor.contenthub.dev',
					'https://editor.staging.contenthub.dev',
					'https://editor.green.contenthub.dev',
					'http://localhost:8080',
					'http://localhost:8081',
				],
			},
		}
		if (![ 'code', 'token' ].includes(query.response_type))
			return { error: 'Invalid response type!' }

		const client = clients[query.client_id]
		if (!client) return { error: 'Invalid client!' }

		const oidc = {
			client_id: query.client_id,
			error: null,
			redirect_uri: null,
			state: query.state,
		}
		for (const redirect_uri of client.redirect_uri_prefixes) {
			if (query.redirect_uri.startsWith(redirect_uri)) {
				oidc.redirect_uri = query.redirect_uri
				break
			}
		}
		if (!oidc.redirect_uri) return { error: 'Invalid redirect uri!' }

		return oidc

		// TODO: are there any errors that we need to communicate back in a redirect?
	},
	async complete(oidc, token) {
		if (!oidc) throw new Error('oidc not set')
		let url = oidc.redirect_uri
		url += url.includes('?') ? '&' : '?'
		// TODO: i would prefer to use the code flow - but then the server needs to generate a code
		//       and then be able to exchange it for a token later - so we could have to create 2 endpoints:
		//       - server/auth(token)
		//       - server/token(code)
		// url += '&code=' ...
		url += '&token=' + token
		url += '&state=' + oidc.state
		window.location.href = url
	},
}