<template>
	<div>
		<v-expansion-panels v-model="expanded" v-if="commissions.length > 0">
			<v-expansion-panel elevation="0" style="border: 1px solid #ddd;">
				<!-- Active Header -->
				<v-expansion-panel-title v-if="type === 'active'" hide-actions data-cy="scSummaryActive" class="active-bar pa-3" >
					<template v-slot:default="{ open }">
						<div>
							<v-icon color="#64C823" class="pr-4">
								<template v-if="open">mdi-menu-down</template>
								<template v-else>mdi-menu-up</template>
							</v-icon>
							<v-icon color="#64C823" class="pr-2">mdi-calendar-check</v-icon> {{$t('text.active')}}		
						</div>
					</template>
				</v-expansion-panel-title>

				<!-- Upcoming Header -->
				<v-expansion-panel-title v-if="type === 'upcoming'" hide-actions data-cy="scSummaryUpcoming" class="upcoming-bar pa-3" >
					<template v-slot:default="{ open }">
						<div>
							<v-icon color="#999999" class="pr-4">
								<template v-if="open">mdi-menu-down</template>
								<template v-else>mdi-menu-up</template>
							</v-icon>
							<v-icon color="#999999" class="pr-2">mdi-calendar-clock</v-icon> {{$t('text.upcoming')}}		
						</div>
					</template>
				</v-expansion-panel-title>

				<!-- Content -->
				<v-expansion-panel-text class="pt-2 pa-0">
					<!-- Table Header -->
					<v-row>
						<v-col cols="4">{{$t('text.type')}}</v-col>
						<v-col cols="3">{{$t('text.validFrom')}}</v-col>
						<v-col cols="3">{{$t('text.validUntil')}}</v-col>
						<v-col cols="2">{{$t('text.commission')}}</v-col>
					</v-row>
					<v-divider/>
					<!-- Commissions -->
					<div v-for="commission of commissions" :key="commission.sys.id" class="pt-3 pb-3 item">
						<v-row v-if="commission.fields.peakEntity || commission.fields.attributeSet" class="pl-3 pt-3">
							<span class="commission-type">
								<span v-if="commission.fields.peakEntity">{{$t('text.product')}}</span>
								<span v-if="commission.fields.peakEntity && commission.fields.attributeSet"> | </span>
								<span v-if="commission.fields.attributeSet">{{$t('text.category')}}</span>
								<span v-if="commission.fields.peakEntity && commission.fields.attributeSet">: {{commission.fields.attributeSet.name}}</span>
							</span>
						</v-row>
						<v-row class="pt-3">
							<v-col cols="4" class="pt-0 mt-0">
								<div>
									<span class="text" v-if="!commission.fields.peakEntity && !commission.fields.attributeSet">{{$t('text.channelDefault')}}</span>
									<span class="text" v-if="!commission.fields.peakEntity && commission.fields.attributeSet">{{commission.fields.attributeSet.name}}</span>
									<span class="text" v-if="commission.fields.peakEntity">{{commission.fields.peakEntity.name}}</span>
								</div>
							</v-col>
							<v-col cols="3" class="pt-0 mt-0">
								<span v-if="commission.fields.validFrom?.length">{{ formatDate(commission.fields.validFrom)  }}, {{ commission.fields.validFrom.split('T')[1] }}</span>
								<span v-if="!commission.fields.validFrom?.length">-</span>
							</v-col>
							<v-col cols="3" class="pt-0 mt-0">
								<span v-if="commission.fields.validTo?.length">{{ formatDate(commission.fields.validTo) }}, {{ commission.fields.validTo.split('T')[1] }}</span>
								<span v-if="!commission.fields.validTo?.length">-</span>
							</v-col>
							<v-col cols="1" class="pt-0 mt-0">{{commission.fields.percentage}}%</v-col>
							<v-col cols="1" class="pt-0 mt-0" style="text-align:right" v-show="!isDisabled">
								<v-menu style="float: right;">
									<template v-slot:activator="{ props }">
										<v-icon v-bind="props" color="#34B8EC">mdi-dots-horizontal-circle-outline</v-icon>
									</template>
									<v-list>
										<v-list-item style="cursor:pointer !important" class="item" link
											@click="showEdit(commission)"
											:title="$t('text.edit')"
											prepend-icon="mdi-pencil"
										/>
										<v-list-item style="cursor:pointer !important" class="item" link
											@click="showDelete(commission)"
											:title="$t('text.delete')"
											prepend-icon="mdi-delete"
										/>
									</v-list>
								</v-menu>
							</v-col>
						</v-row>
					</div>
				</v-expansion-panel-text>
			</v-expansion-panel>
		</v-expansion-panels>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			:confirmLabel="$t('text.delete')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="onDeleteConfirmed"
			:cancel-handler="onDeleteCancelled"
			:isDelete="true"
			:showClose="true"
			:title="$t('text.deleteCommission')" 
			height="350px"
			width="550px"
		>
			<template #content>
				<v-row align="center" justify="center">
					<v-col cols="4" style="text-align:center">
						<v-icon size="80" color="#1EADEA">mdi-delete</v-icon>
					</v-col>
					<v-col cols="8" class="pr-5">
						<h2>{{deleteTitle}}</h2>
						<p>{{$t('text.deleteCommissionHelp')}}</p>
					</v-col>
				</v-row>
			</template>
		</Dialog>

		<!-- Confirm Edit -->
		<Dialog ref="editDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="onEditConfirmed"
			:cancel-handler="onEditCancelled"
			:showClose="true"
			:title="$t('text.editCommission')" 
			height="350px"
			width="550px"
		>
			<template #content>
				<v-row align="center" justify="center">
					<v-col cols="4" style="text-align:center">
						<v-icon size="80" color="#FDB537">mdi-alert-rhombus</v-icon>
					</v-col>
					<v-col cols="8" class="pr-5">
						<h2>{{editTitle}}</h2>
						<p>{{$t('text.editCommissionText')}}</p>
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</div>
</template>

<script>
import Dialog from '@/components/common/Dialog.vue'
import { CommissionType } from '@/plugins/enum.js'
import Common from '@/mixins/Common.vue'

export default {
	name: 'SalesChannelCommission',
	components: { Dialog },
	mixins: [Common],
	props: {
		commissions: Array,
		type: String,
		isDisabled: Boolean
	},
	data() {
		return {
			expanded: 0,
			selectedItem: null,
			deleteTitle: '',
			editTitle: '',
		}
	},

	methods: {
		open() {
			this.expanded = 0
		},
		close() {
			this.expanded = -1
		},
		toggle() {
			if (this.expanded === -1) {
				this.open()
			} else {
				this.close()
			}	
		},
		showEdit(item) {
			this.selectedItem = item
			const salesChannel = this.$store.state.selectedClient.fields.salesChannels.de.find(sc => sc.fields.peakId.de === item.fields.peakWebsiteId)
			const category = item.fields.attributeSet
			const product = item.fields.peakEntity

			this.editTitle = this.$t('text.editCommissionTitle')

			if (product) {
				this.editTitle = this.editTitle.replace('%NAME%', `"${product.name}"`)
			} else if (salesChannel) {
				this.editTitle = this.editTitle.replace('%NAME%', `"${salesChannel.fields.title.de}"`)
			} else if (category) {
				this.editTitle = this.editTitle.replace('%NAME%', `"${category.name}"`)
			} else {
				this.editTitle = ''
			}
			
			this.$refs.editDialog.show = true
		},
		onEditConfirmed() {
			this.$emit('edit', this.selectedItem)
			this.$refs.editDialog.show = false
		},
		onEditCancelled() {
			this.selectedItem = null
		},
		showDelete(item) {
			this.selectedItem = item
			const salesChannel = this.$store.state.selectedClient.fields.salesChannels.de.find(sc => sc.fields.peakId.de === item.fields.peakWebsiteId)
			this.deleteTitle = this.$t('text.deleteCommissionTitle')

			if (item.fields.type === CommissionType.PRODUCT) {
				this.deleteTitle = this.deleteTitle.replace('%NAME%', `"${item.fields.peakEntity.name}"`)
			} else if (item.fields.type === CommissionType.CATEGORY) {
				this.deleteTitle = this.deleteTitle.replace('%NAME%', `"${item.fields.attributeSet.name}"`)
			} else {
				if (salesChannel?.fields?.title) {
					this.deleteTitle = this.deleteTitle.replace('%NAME%', `"${salesChannel.fields.title.de}"`)
				}
			}
			this.$refs.deleteDialog.show = true
		},
		onDeleteConfirmed() {
			this.$emit('delete', this.selectedItem)
			this.$refs.deleteDialog.show = false
		},
		onDeleteCancelled() {
			this.selectedItem = null
		}
	}
}
</script>

<style scoped lang="scss">
.active-bar { background-color: #D8F1C8; border-radius: 5; color: #64C823 }
.upcoming-bar { background-color: #F9F9F9; border-radius: 5; color: #999999 }
.commission-type { color: #A1A1A1; font-size:10pt }
.item:hover { background-color: #F4F4F4; }
h2 { color: #000000; font-size: 15pt; font-weight: normal; line-height: 1.1; }
</style>