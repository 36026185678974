<template>
  <v-data-iterator :items="dataItems" :no-data-text="noDataText" v-model:items-per-page="itemsPerPage" v-model:page="page">
    <template v-slot:default="props">
      <div class="items-container">
        <!-- TODO: :key with item.sys.id is hardcoded and therefore not dynamically when you use the Pagination component with other items structure -->
        <div v-for="item in dataItems ?? []" :key="item.sys.id">
          <slot name="item" :item="item"></slot>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="d-flex align-center justify-space-around pa-4">
        <span class="text--grey">{{ $t('text.itemsPerPage') }}</span>
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn variant="text" class="ml-2" append-icon="mdi-chevron-down" v-bind="props">
              {{ itemsPerPage === dataItems.length ? 'All' : itemsPerPage }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(number, index) in itemsPerPageOptions" :key="index" :title="number"
              @click="handleItemsPerPageChange(number)"></v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>

        <span class="mr-4 text--grey">
          {{ $t('text.pageOfPages', { page, numberOfPages }) }}
        </span>
        <v-btn variant="text" icon size="small" @click="prevPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn variant="text" icon size="small" class="ml-2" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    dataItems: {
      type: Array,
      default: () => []
    },
    noDataText: {
      type: String
    }
  },
  data() {
    return {
      itemsPerPageOptions: [5, 10, 15, 'All'],
      itemsPerPage: 15,
      page: 1,
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.dataItems.length / this.itemsPerPage)
    },
  },
  methods: {
    handleItemsPerPageChange(value) {
      if (value === 'All') {
        this.itemsPerPage = this.dataItems.length
        this.page = 1

        return
      }

      this.itemsPerPage = value
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    prevPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
  },
}
</script>

<style scoped lang="scss">
.items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 35px;

  @media screen and (max-width: 820px) {
    justify-content: center;
  }
}
</style>