<template>
	<div class="TextAreaAutosizing">
		<textarea v-model="model" class="area"></textarea>
		<pre class="area" style="visibility: hidden;">{{ model }}.</pre>
		<div class="actions">
			<slot />
		</div>
	</div>
</template>

<script lang="ts">
export default {
	props: {
		modelValue: String,
	},
	data: () => ({
		model: '',
	}),
	watch: {
		modelValue(n) { this.model = n },
		model(n) { this.$emit('update:modelValue', n) },
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style scoped>
.TextAreaAutosizing { position: relative; }
textarea { position: absolute; inset: 0; resize: none; outline: 1px solid #ccc; }
.area { min-height: 45px; max-height: 300px; padding: 10px; font: inherit; white-space: pre-wrap; overflow-wrap: break-word; }
.actions { position: absolute; right: 10px; bottom: 10px; }
</style>