<template>
	<div class="ApplicationComponent">
		<v-btn id="btnComponentIcon" elevation="0" :style="{'width':'50px !important','border':'0 !important','background':component.fields.colour.de + ' !important'}" disabled>
			<v-img style="height: 50px; width: 30px; opacity: 0.5;" :src="component.fields.icon.de.fields.file.de.url"/>
		</v-btn>
		<v-btn id="btnComponentName" elevation="0" class="labelButton" style="pointer-events: none;">
			{{ component.fields.title[selectedLocale] }}
		</v-btn>
		<v-btn id="btnSelected" elevation="0" class="checkboxButton">
			<v-checkbox v-model="selected" hide-details class="mt-0 pa-0" />
		</v-btn>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	mixins: [ Common ],
	props: {
		component: Object,
		selectedComponents: Array,
	},
	computed: {
		selected: {
			get() {
				return !!this.selectedComponents?.find?.(c => c.sys.id == this.component.sys.id)
			},
			set(v) {
				if (v) {
					this.$emit('add-component', this.component)
				}
				else {
					this.$emit('remove-component', this.component)
				}
			},
		},
	},
}
</script>

<style scoped>
.ApplicationComponent {
	max-width: 500px;
	display: flex;
	border: 1px solid #ccc;
	border-radius: 10px;
	overflow: hidden;
}
button {
	border-radius: 0 !important;
	height: 100% !important;
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
	font-size: 12pt !important;
	font-weight: normal !important;
	letter-spacing: 0 !important;
	text-transform: none !important;
	align-items: left !important;
}
.labelButton {
	flex-grow: 1;
	background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
	border-left: 1px solid #c2c2c2 !important;
	font-size: 14pt !important;
	text-transform: none !important;
	border-radius: 0px;
	height: 50px !important;
}
.theme--light.v-btn.v-btn--disabled, .theme--light.v-btn.v-btn--disabled .v-btn__loading, .theme--light.v-btn.v-btn--disabled .v-icon {
	color: #000000 !important;
}
.checkboxButton {
	background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
	border-left: 1px solid #c2c2c2 !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	border-top-right-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	width: 40px !important;
	height: 50px !important;
}
</style>