<template>
	<div class="InlineEntryEditor" v-if="modelValue">
		<table>
			<tr v-for="field of type.fields" :key="field.id" class="field">
				<th>{{ $t('text.' + field.name) }}</th>
				<td class="subComp">
					<TranslateableField
						:typeName="type.sys.id"
						:fieldName="field.id"
						v-model="modelValue.fields[field.id]"
						:fieldLocale="locale"
						:locales="locales"
					/>
				</td>
			</tr>
		</table>
	</div>
</template>

<script lang="ts">
import TranslateableField from './TranslateableField.vue'
import LanguageFlag from '../common/LanguageFlag.vue'

export default {
	name: 'InlineEntryEditor',
	components: { LanguageFlag, TranslateableField },
	props: {
		modelValue: Object,
		locale: String,
		locales: Array,
	},
	computed: {
		type() {
			const typeId = this.modelValue?.sys?.contentType?.sys?.id
			// TODO: inject the models instead of taking them from the store
			// ATT: we search by lower case because the entry type is lower case for some reason..
			return this.$store.state.fieldModels.find(model => model.sys?.id?.toLowerCase?.() == typeId.toLowerCase())
		},
	},
}
</script>

<style scoped>
td,
th { vertical-align: top; text-align: left; padding: 16px 10px 10px 0; }
th,
.subComp { border-right: 1px solid #ddd; }
table { border-spacing: 0; border-collapse: collapse; }
.localeContainer { padding: 20px 5px 10px 10px; }
.subComp { padding: 10px 10px 10px 35px; width: 100%; }
.localeContainer,
.subComp { background: white; }
</style>

<style>
.InlineEntryEditor .subComp .title { display: none; }
.InlineEntryEditor .subComp .Field { border-left: none; padding-left: 0; }
.InlineEntryEditor .subComp .Field.focus { border-left: none; }
</style>