<template>
	<div class="SeatImage"
		:class="{ selected }"
		@click="select"
	>
		<div v-if="selected && selection.length == 1" class="editForm">
			...
		</div>
		<img class="image"
			:src="modelValue.src"
			:style="{
				left: modelValue.pos.x + 'px',
				top: modelValue.pos.y + 'px',
				transform: 'rotate(' + angle(modelValue.pos, p2) + 'rad)',
				width: calculateDistance({ x: 0, y: 0 }, modelValue.cp) + 'px',
			}"
			:draggable="selected"
			@mousedown="dragStart($event, modelValue.pos)"
		/>
		<div v-if="selected && selection.length == 1" class="cp"
			:style="{
				left: p2.x + 'px',
				top: p2.y + 'px',
			}"
			draggable
			@mousedown="dragStart($event, modelValue.cp)"
		/>
		<div v-if="selected && selection.length == 1" class="line"
			:style="{
				left: modelValue.pos.x + 'px',
				top: modelValue.pos.y + 'px',
				width: round(calculateDistance(p2, modelValue.pos)) + 'px',
				transform: 'rotate(' + angle(modelValue.pos, p2) + 'rad)',
			}"
		/>
	</div>
</template>

<script lang="ts">
import { PropType } from 'vue'

type Point = { x: number, y: number, a?: number, name?: string }

export default {
	props: {
		modelValue: Object as PropType<{ pos: Point, cp: Point }>,
		selection: Array,
	},
	data: () => ({
	}),
	computed: {
		// TODO: move to a mixin
		selected(): boolean {
			return this.selection.includes(this.modelValue)
		},
		p2(): Point {
			return {
				x: this.modelValue.pos.x + this.modelValue.cp.x,
				y: this.modelValue.pos.y + this.modelValue.cp.y,
			}
		},
	},
	methods: {
		round: Math.round,
		angle(p1: Point, p2: Point) {
			return Math.atan2(p2.y - p1.y, p2.x - p1.x)
		},
		select($event: MouseEvent) {
			if ($event.shiftKey || $event.metaKey) {
				if (this.selected)
					this.selection.splice(this.selection.indexOf(this.modelValue), 1)
				else
					this.selection.push(this.modelValue)
			}
			else
				this.$emit('update:selection', [ this.modelValue ])
		},
		calculateDistance(point1: Point, point2: Point) {
			const dx = point2.x - point1.x
			const dy = point2.y - point1.y
			return Math.sqrt(dx * dx + dy * dy)
		},
		dragStart(e: MouseEvent, point: Point) {
			if (!this.selected) return
			const pointEl = e.target as HTMLElement
			const offsetX = e.clientX - pointEl.getBoundingClientRect().left
			const offsetY = e.clientY - pointEl.getBoundingClientRect().top
			const drag = (e: MouseEvent) => {
				// TODO: when scrolling, the offset is wrong
				point.x += e.movementX
				point.y += e.movementY
			}
			const dragEnd = () => {
				document.removeEventListener('mousemove', drag)
				document.removeEventListener('mouseup', dragEnd)
			}
			document.addEventListener('mousemove', drag)
			document.addEventListener('mouseup', dragEnd)
			e.preventDefault()
		},
	},
}
</script>
<style scoped>
.image {
	position: absolute;
	transform-origin: 0 0;
	max-width: none;
	max-height: none;
	opacity: 0.3;
}
.selected .image { outline: 2px dashed blue; outline-offset: 1px; cursor: move; }
.cp {
	position: absolute;
	width: 6px;
	height: 6px;
	outline: 3px solid blue;
	background: blue;
	border-radius: 10px;
	cursor: move;
	z-index: 999;
	margin-top: -1px;
}
.line { position: absolute; background: blue; height: 2px; transform-origin: 0 0; z-index: 999; pointer-events: none; }
</style>