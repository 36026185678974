<template>
  <div class="container pa-3">
    <div class="justify-center container pa-1" :style="cardClass === 'active' ? `border-radius:20px;border:10px solid ${serviceType.fields.color.de}` :`border-radius:10px;border:1px solid ${serviceType.fields.color.de}`">
      <!-- Icon -->
      <div :data-cy="dataCy" class="img" :style="`background-color:${serviceType.fields.color.de}`" @click="selectServiceType()">
        <v-img style="opacity:0.25;display:block;margin:auto;" width="150" height="150" :src="getAssetUrl(serviceType.fields.icon.de)"></v-img>
      </div>

      <!-- Text -->
      <div class="cardText" style="height:55%; width:100%;" @click="selectServiceType()">
          <p style="font-size:14pt;font-weight:bold;color:#000">{{serviceType.fields.title[selectedLocale]}}</p>
          <p style="color:#000">{{serviceType.fields.shortDescription[selectedLocale]}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: "ServiceTypeCard",
	mixins: [ Common ],
	props: {
    serviceType: Object,
    dataCy: String,
	},
  data () {
    return {
    }
  },
  computed: {
    cardClass() {
     return this.$store.state.selectedServiceType?.sys?.id===this.serviceType.sys.id ? 'active' : 'inactive'
    }
  },
  methods: {
    selectServiceType() {
      this.$store.commit('setSelectedServiceType', this.serviceType)
    }
  }
}
</script>

<style scoped>
.container {
  height:100%;
  width:100%;
}
.cardText {
  background-color:#ffffff;
  padding: 10px;
  display: block;
}
.img {
  height:45%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>