<template>
	<div class="Comments">
		<TextAreaAutosizing class="newComment" v-model="newCommentText">
			<button @click="createComment" :disabled="!newCommentText"><v-icon :color="newCommentText ? 'blue' : '#ccc'">mdi-send</v-icon></button>
		</TextAreaAutosizing>
		<CommentVue v-for="comment in comments" :key="comment.id"
			:modelValue="comment"
			@delete="loadComments()"
		/>
	</div>
</template>

<script lang="ts">
import CommentVue from './Comment.vue'
import TextAreaAutosizing from './TextAreaAutosizing.vue'
import CommentExecutive, { Comment } from '../../../../shared/CommentExecutive'
let exe: CommentExecutive

export default {
	components: { CommentVue, TextAreaAutosizing },
	props: {
		objectId: String,
	},
	data: () => ({
		comments: [] as Comment[],
		newCommentText:	'',
	}),
	methods: {
		async loadComments() {
			this.comments = await exe.getComments(this.objectId)
		},
		async createComment() {
			await exe.createComment({
				clientId: this.$store.state.selectedClient.sys.id,
				objectId: this.objectId,
				title: this.newCommentText,
				objectType: 'package',
				action: 'manual',
			})
			this.newCommentText = ''
			await this.loadComments()
		},
	},
	async mounted() {
		exe = new CommentExecutive({}, this)
		await this.loadComments()
	},
}
</script>

<style scoped>
.Comments { display: flex; flex-direction: column; gap: 8px; }
.newComment { margin-top: 8px; }
</style>