import { UserManager } from 'oidc-client-ts'

export function getUserManager() {
	return new UserManager({
		authority: 'https://keycloak.production.aws.skiline.cc/auth/realms/MyServices',
		client_id: 'rolloutmachine',
		redirect_uri: location.href, //redirectBase + '/myservices/redirect',
		disablePKCE: document.location.protocol == 'http:', // if not ssl

		//loadUserInfo: true,

		// does not work with popup
		//stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
		response_type: 'code',
		scope: 'openid profile',
		filterProtocolClaims: true,
	})
}
