<template>
	<v-tooltip location="bottom">
		<template #activator="{ props }">
			<v-icon color="#64c823" v-if="status === 'complete'" v-bind="props">mdi-check-circle</v-icon>
			<v-icon color="#ffb400" v-if="status === 'incomplete'" v-bind="props">mdi-alert</v-icon>
			<v-icon color="#ffb400" v-if="!status" v-bind="props">mdi-warning</v-icon>
		</template>
		<span class="tooltip">{{ itemStatusTooltip(status) }}</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'Status',
	props: {
		status: String,
	},
	methods: {
		itemStatusTooltip(status) {
			if (status === 'complete') {
				return this.$t('text.complete')
			} else if (status === 'incomplete') {
				return this.$t('text.incomplete')
			}
			return this.$t('text.incomplete')
		},
	},
}
</script>
