// ATT: actually i'd prefer this to be in the common subproject..
// ATT: i could not get this to work as a .ts format file, so i wrote it as js code..

export default class ContentfulObject {

	static transformSubObjects(o, transformer) {
		// we leave non-CFOs alone
		if (!o) return o
		if (!o.sys) return o
		// we leave links alone
		if (o.sys.linkType) return o
		// we remove all the unnecessary junk from the sys object
		let r = {
			sys: { id: o.sys.id, },
			fields: {},
		}
		for (const field in o.fields || []) {
			let i18n = o.fields[field]
			r.fields[field] = {}
			for (const locale in i18n) {
				let value = i18n[locale]
				r.fields[field][locale] = value
				if (!value) continue
				if (typeof value != 'object') continue
				if (value.sys) {
					r.fields[field][locale] = transformer(value)
					continue
				}
				if (Array.isArray(value)) {
					let arr = []
					r.fields[field][locale] = arr
					for (let i in value) {
						let item = value[i]
						arr[i] = item
						if (!item) continue
						if (typeof item != 'object') continue
						if (!item.sys) continue
						arr[i] = transformer(item)
						continue
					}
				}
			}
		}
		return r
	}

	// TODO: check if this works for rich text fields
	static cleanSys(o) {
		return ContentfulObject.transformSubObjects(o, so => ContentfulObject.cleanSys(so))
	}

	static link(o) {
		if (!o) return o
		if (!o.sys) return o
		return { sys: { id: o.sys.id, type: 'Link', linkType: 'Entry' } }
	}

	// TODO: check if this works for rich text fields
	static flatten(o) {
		return ContentfulObject.transformSubObjects(o, so => ContentfulObject.link(so))
	}
}