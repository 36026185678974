<template>
	<v-expansion-panels v-model="model">
		<v-expansion-panel elevation="0">
			<v-expansion-panel-title>
				{{ title }}
			</v-expansion-panel-title>
			<v-expansion-panel-text>
				<div v-for="marketplace of sortedMarketplaces" :key="marketplace.title" style="display: flex;">
					<div style="flex-grow: 1;">
						{{ marketplace.title }}
						<span v-if="marketplace.isHomebase">
							<v-icon size="small">mdi-home</v-icon>
						</span>
					</div>
					<div style="white-space: nowrap;" :style="{ 'color': color(marketplace.status) }">
						<v-icon :color="color(marketplace.status)">{{icon(marketplace.status)}}</v-icon>
						{{ $t('text.' + marketplace.status) }}
					</div>
				</div>
			</v-expansion-panel-text>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	props: {
		title: String,
		marketplaces: Array,
		expanded: Boolean
	},
	data() {
		return {
			model: -1
		}
	},
	computed: {
		sortedMarketplaces() {
			const homebaseMarketplace = this.marketplaces.find(marketplace => marketplace.isHomebase)
			const otherMarketplaces = this.marketplaces.filter(marketplace => !marketplace.isHomebase)
			return homebaseMarketplace ? [homebaseMarketplace, ...otherMarketplaces] : this.marketplaces
		}
	},
	methods: {
		open() {
			this.model = 0
		},
		close() {
			this.model = -1
		},
		toggle() {
			if (this.model === -1) {
				this.open()
			} else {
				this.close()
			}
		},
		color(status) {
			if (status === 'pending') return '#ff9e21'
			if (status === "reapprove") return '#ffb400'
			if (status === 'saved') return '#589ae6'
			if (status === 'active_saved') return '#589ae6'
			if (status === 'active') return '#66BB6A'
			if (status === 'deactivated') return '#f34545'
			if (status === 'declined') return '#f34545'
			if (status === "approved") return '#64c823'
			if (status === "expired") return '#737373'

			if (status === 'available') return '#66BB6A'
			if (status === 'overbooked') return '#f34545'
			if (status === 'fullybooked') return '#ff9e21'
			return '#cccccc'
		},
		icon(status) {
			if (status === 'pending') return 'mdi-clock-outline'
			if (status === "reapprove") return 'mdi-clock-outline'
			if (status === 'saved') return ''
			if (status === 'active_saved') return ''
			if (status === 'active') return 'mdi-check'
			if (status === 'deactivated') return 'mdi-close'
			if (status === 'declined') return 'mdi-close'
			if (status === "approved") return 'mdi-check'
			if (status === "expired") return ''
			return ''
		}
	},
	mounted() {
		if (this.expanded) {
			this.model = 0
		}
	},
}
</script>

<style scoped>
</style>