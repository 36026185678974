<template>
	<div>
		<Disclosure :title="$t('text.applicationAccessInfoTitle')" :error="sectionMessage.error" :message="sectionMessage.message" :lock="!userMayEditFields" data-cy="applicationAccessInfo" ref="SECTION_applicationAccessInfo" >
			<p class="subTitle" v-html="$t('text.applicationAccessDesc')"/>
			<div class="field left-border">
				<v-label>{{$t('text.servicesDesc')}}</v-label><br/>
				<div style="display: flex; flex-direction: column; gap: 5px; padding: 0 0 20px 0;">
					<ApplicationComponent v-for="component in serviceComponents" :key="component.id"
						:component="component"
						:selectedComponents="selectedComponents"
						@add-component="addSelectedComponent"
						@remove-component="removeSelectedComponent"
					/>
				</div>
				<p v-if="applicationComponentErrors.length" class="error-text">{{ applicationComponentErrors[0] }}</p>
			</div>
		</Disclosure>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import ApplicationComponent from '@/components/common/ApplicationComponent.vue'
import isEqual from 'lodash/isEqual'

export default {
	name: 'ApplicationComponentWrapper',
	components: { Disclosure, ApplicationComponent },
	mixins: [ Common ],
	props: {
		// TODO: we should not pass the whole SP in here, we only deal with the applications
		serviceProvider: { type: Object, required: true },
		updateModel: Boolean,
	},
	data: () => ({
		model: {},
		initData: {},
		// this is also called in the parent via a $ref
		selectedComponents: [],
		sectionMessage: {
			error: false,
			message: '',
		},
		serviceComponents:[],
		applicationComponentErrors: [],
	}),
	watch: {
		updateModel() {
			this.model = this.valueToModel(this.serviceProvider)
			if (this.selectedComponents?.length > 0) {
				this.model.fields.mainUserAccount.fields.applications.de = this.selectedComponents
			}
			this.setInitialComponents()
			this.setInitData()
		},
	},
	methods: {
		valueToModel(v) {
			return JSON.parse(JSON.stringify(v ?? {}))
		},
		setInitData() {
			this.initData = {
				selectedComponents: JSON.parse(JSON.stringify(this.selectedComponents))
			}
		},
		sendData() {
			// TODO: i had to add this guard because a case was discovered where this fails
			//       i cannot reproduce the case - but if it still occurs, i think this guard will lead to the application settings not being stored..
			if (this.serviceProvider.fields.mainUserAccount?.fields?.applications)
				this.serviceProvider.fields.mainUserAccount.fields.applications.de = this.selectedComponents
			else
				console.error('ApplicationComponentWrapper.sendData: serviceProvider.fields.mainUserAccount.fields.applications is undefined!')
			let data = {
				selectedComponents: JSON.parse(JSON.stringify(this.selectedComponents))
			}
			// TODO: the binding is currently too bad to actually fix a problem we have here - i am disabling this for now
			data.changed = true//!isEqual(data, this.initData)
			return data
		},
		validateAllFields() {
			let allFieldsAreValid = true
			this.resetSectionError(this.sectionMessage)
			this.applicationComponentErrors = []
			
			if (this.selectedComponents.length === 0) {
				allFieldsAreValid = false
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
				this.applicationComponentErrors.push(this.$t('text.missingFieldsError'))
			}

			return allFieldsAreValid
		},
		setInitialComponents() {
			this.selectedComponents = this.model.fields.mainUserAccount?.fields?.applications?.de ?? []
		},
		addSelectedComponent(component) {
			this.selectedComponents.push(component);

			if (this.selectedComponents.length > 0) {
				this.applicationComponentErrors = []
			}
		},
		removeSelectedComponent(component) {
			if (!component?.sys?.id) return
			if (this.selectedComponents.length > 0) {
				this.selectedComponents = this.selectedComponents.filter(el => el.sys.id !== component.sys.id)
			}
		},
	},
	created() {
		this.model = this.valueToModel(this.serviceProvider)
		this.setInitialComponents()
		this.setInitData()
	},
	async mounted() {
		this.serviceComponents = this.$store.state.spApplications
	},
}
</script>