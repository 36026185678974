<template>
	<div class="FieldWrap">
		<label v-html="title" />
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'FieldWrap',
	props: {
		title: String,
	},
}
</script>

<style scoped>
.FieldWrap { margin-top: 16px; }
</style>