<template>
	<div>
		<p class="helpText" v-html="$t('text.businessHoursExceptionHelp')"/>
		<br/>
		<p class="helpText" v-html="$t('text.businessHoursExceptionHelp2')"/>
        <div :style="error && error.message && error.message.length > 0 ? 'opacity:1;margin-top:20px;padding-bottom:20px' : 'opacity:0'">
            <div ref="errorsView" class="bg-error" style="border-radius:10px;padding:10px;">
                <v-row v-if="error">
                    <span style="color:#ffffff">{{error.message}}</span>
                </v-row>
            </div>
        </div>

		<v-date-picker
			multiple
			hide-actions
			v-model="exceptionDates"
			:min="selectedBusinessHours.fields.validFromDate.de"
			:max="selectedBusinessHours.fields.validToDate.de"
			:allowed-dates="getAllowedDates"
			allowed-dates-type="inclusive"
			:color="exception.closed ? 'red lighten-1' : 'green lighten-1'"
		/>
		<hr />
		<v-row class="px-2 py-4" align="center" justify="center" no-gutters>
			<span v-if="exception.closed" style="font-size:14pt">{{$t('text.markClosedDatesInExceptions')}}</span>
			<span v-else style="font-size:14pt; width: 80%;">{{$t('text.markOpenDatesInExceptions')}}</span>
			<v-spacer/>
			<mys-switch
				:label="exception.closed ? $t('text.closed') : $t('text.open')"
				v-model="exception.closed"
				@update:modelValue="exceptionTypeChanged(exception.closed)"
			/>
		</v-row>
	
		<hr class="mx-4" />

		<!-- Opening Hours -->
		<div class="mx-4 my-2" v-if="!exception.closed">
			<div>

				<div style="width:100%;padding:10px">
					<div>
						<v-row style="padding: 0" align="center">
							<v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
								<v-label><span class="ofrom-label">{{$t('text.openFrom')}}</span></v-label>
							</v-col>
							<v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
								<v-label>{{$t('text.openTo')}}</v-label>
							</v-col>
							<div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
							</div>
						</v-row>
						<v-row v-for="(exceptionTimes, exceptionTimeIndex) of exception.times" :key="exceptionTimeIndex" class="my-2" align="center">
							<v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" variant="outlined" density="compact" hide-details placeholder="00:00" 
							v-model="exceptionTimes.openTime" :rules="hoursRules" :error-messages="exceptionTimes.errors"
							@keyup="timeInputValidate($event, exceptionTimes)" @blur="timeInputValidate($event, exceptionTimes)"/>
							<v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" variant="outlined" density="compact" hide-details placeholder="00:00" 
							v-model="exceptionTimes.closeTime" :rules="hoursRules" :error-messages="exceptionTimes.errors"
							@keyup="timeInputValidate($event, exceptionTimes)" @blur="timeInputValidate($event, exceptionTimes)"/>
		
							<div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
								<v-btn v-if="exceptionTimeIndex === 0" style="margin-right:10px" class="gradientButton" elevation="0" @mousedown="clearTimeRow(exceptionTimeIndex)"><v-icon>mdi-close</v-icon></v-btn>
								<v-btn v-if="exceptionTimeIndex > 0" style="margin-right:10px" class="gradientButton" elevation="0" @click="removeTimeRow(exceptionTimeIndex)"><v-icon color="#fb3640">mdi-delete</v-icon></v-btn>
							</div>
						</v-row>
					</div>
				</div>

				<hr class="my-2"/>

				<div style="width:100%;padding:10px">
					<v-row>
						<v-btn v-if="!exception.closed" class="gradientButton" style="margin-right:10px" elevation="0" @click="addTimeRow()"><v-icon>mdi-plus</v-icon> {{ $t('text.addHours')}}</v-btn>
					</v-row>
				</div>

			</div>
		</div>

	</div>
  </template>
  
<script>
  export default {
	name: "BusinessHourExceptions",
	props: {
	  selectedBusinessHours: {
		type: Object,
		required: true,
	  },
	  selectedException: {
		type: Object,
		required: true,
	  },
	  error: Object
	},

	data() {
	  return {
		exception: this.selectedException,
		exceptionDates: this.selectedException.dates,
		hoursRules: [
			v => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Invalid time!'
		],
		invalidDates: []
	  }
	},

	created(){
		// check if per exception in selectedBusinessHours there are exceptions and if so create a structure with the index of the exceptions and the dates of the exceptions. This is needed to disallow the user to select a date that is already in use for an exception of the same business hours
		let allSelectedDates = []
		this.selectedBusinessHours.fields.exceptions.de.forEach((exception) => {
			if (exception.dates.length > 0) {
				exception.dates.forEach(exceptionDate => {
					allSelectedDates.push(
						exceptionDate
					)
				})
			}
		})
		// exclude the dates that are in the current exception from the list of invalid dates
		this.invalidDates = allSelectedDates.filter(exceptionDate => !this.exception.dates.includes(exceptionDate))
	},

	methods: {
		timeInputValidate(event, openingTime) {
            let key = event.keyCode || event.charCode
            
            if( key !== 8 && key !== 46 ) {
                if (event.target.value.length === 2) {
                    event.target.value = event.target.value + ':'
                } else if (event.target.value.length >= 5) {
                    event.target.value = event.target.value.substring(0,5)
                }
            }

            delete openingTime.errors
        },
		clearTimeRow(openingTimesIndex) {
			this.exception.times[openingTimesIndex].openTime = ""
			this.exception.times[openingTimesIndex].closeTime = ""
			this.$forceUpdate()
        },
        removeTimeRow(exceptionTimeIndex) {
            this.exception.times.splice(exceptionTimeIndex,1)
            this.$forceUpdate()
        },
		addTimeRow() { 
			this.exception.times.push({openTime: "", closeTime: ""})
			this.$forceUpdate()
		},
		exceptionTypeChanged(val) {
			if (val) {
				this.exception.times = [{openTime: "", closeTime: ""}]
				this.error.message = ""
			}
			this.$forceUpdate()
		},
		getAllowedDates(date) {
			if (this.invalidDates) {
				return !this.invalidDates.includes(date)
			}
			return true
		},
	},

	watch: {
		exceptionDates: function (newVal, oldVal) {
			this.exception.dates = newVal
       		this.$emit('exception-updated', this.exception)
      	},
		exception: function (newVal, oldVal) {
        	this.$emit('exception-updated', newVal)
      	},
	},
  };
</script>
  
<style scoped>
::v-deep .v-date-picker-table .v-btn {
  margin: auto !important;
}
</style>

