<script>
export default {
	data: () => ({
		// { required<1234>: [ { id: 'required<1234>', days: '2' } ] }
		childErrorMap: {},
	}),
	computed: {
		childErrors() {
			if (!this.childErrorMap) return []
			return Object.values(this.childErrorMap).filter(e => !!e)
		},
		childError() {
			if (this.childErrors.length == 1) return {
				...this.childErrors[0],
				// remove the unique-tags from the id so it can be used as a translation key
				id: this.childErrors[0].id.replace(/<\d+>$/, ''),
			}
			if (this.childErrors.length > 1) return { id: 'multipleErrors', count: this.childErrors.length }
		},
		childErrorMessage() {
			if (!this.childError) return
			return this.$t('text.field-val-' + this.childError.id, this.childError.params)
		},
	},
	methods: {
		clearAllChildErrors() {
			this.childErrorMap = {}
		},
		receiveError(e) {
			const childError = e.detail
			if (childError?.clear) {
				this.childErrorMap[childError?.id] = undefined
			}
			else {
				this.childErrorMap[childError?.id] = childError
			}
		},
		getEl() {
			let el = this.$el
			// ATT: this.$el is a #text element (probably because we are in a mixin)
			if (el.nodeName == '#text') el = el.nextElementSibling
			return el
		},
	},
	mounted() {
		// automatically register the listener on the components root element
		const el = this.getEl()
		el.addEventListener('error', this.receiveError)
	},
	beforeDestroy() {
		const el = this.getEl()
		el.removeEventListener('error', this.receiveError)
	},
}
</script>