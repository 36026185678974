<template>
	<div>
		<Disclosure
			:title="disclosureTitle"
			:error="sectionMessage.error"
			:message="sectionMessage.message"
			data-cy="skischoolSettingsForSp"
			ref="SECTION_skischoolSettingsForSp"
			:lock="skischoolSettingsForSpLocked"
		>
			<div class="field left-border">
				<v-label>{{$t('text.skischoolSystem')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="skischoolSystemErrors.length === 0"
					:error-messages="skischoolSystemErrors"
					:items="skischoolSystem"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skischoolSystemHelptext')"/>
			</div>

			<div class="field left-border">
				<v-label>{{$t('text.skischoolSportMapping')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="skischoolSportMappingErrors.length === 0"
					:error-messages="skischoolSportMappingErrors"
					:items="skischoolSportMapping"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skischoolSportMappingHelptext')"/>
			</div>

			<div class="field left-border">
				<v-label>{{$t('text.skischoolNiveauMapping')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="skischoolNiveauMappingErrors.length === 0"
					:error-messages="skischoolNiveauMappingErrors"
					:items="skischoolNiveauMapping"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skischoolNiveauMappingHelptext')"/>
			</div>
		</Disclosure>
	</div>
</template>

<script>

import Disclosure from "@/components/common/Disclosure.vue";

export default {
	name: "SkischoolSettingsForSp",
	components: {Disclosure},
	props: {
		disclosureTitle: String,
		skirentalSettingsLocked: Boolean,
	},
	data: () => ({
		sectionMessage: {
			error: false,
			message: '',
		},
		skischoolSystemErrors: [],
		skischoolSystem: [],
		skischoolSportMappingErrors: [],
		skischoolSportMapping: [],
		skischoolNiveauMappingErrors: [],
		skischoolNiveauMapping: [],
	})
}

</script>

<style>


</style>