<template>
	<div class="ColorPalette">
		<div v-for="color in colors" :key="color"
			class="ColorSwatch"
			:class="{
				selected: modelValue == color,
			}"
			:style="{ background: color }"
			@click="$emit('update:modelValue', color)"
		/>
	</div>
</template>

<script lang="ts">
export const defaultColors = [
	'#aae4fa', '#cbedb6', '#ffe6aa', '#ffd4b8', '#fbc1c1', '#e3dafb', '#eec1e6', '#dbd7d2',
	'#b0d7ed', '#b9eaed', '#cafce6', '#eff0c3', '#f7e7ce', '#abcdfc', '#eee1ef', '#f1ddcf',
]

export default {
	props: {
		modelValue: String,
		colors: {
			type: Array,
			default: () => defaultColors,
		},
	},
	data: () => ({
	}),
}
</script>

<style scoped>
.ColorPalette { display: flex; flex-wrap: wrap; gap: 12px; width: 278px; }
.ColorSwatch:hover { outline: 3px solid #00aeef; }
</style>

<style>
.ColorSwatch { border-radius: 20px; width: 24px; height: 24px; cursor: pointer; }
.ColorSwatch.selected { outline: 3px solid #00aeef; }
</style>