// loader facility to allow many components to load the same data without duplicating the requests
// also has a cache with a default TTL of 20 minutes

export type CacheItem = {
	key: string
	expiresAt: number
	value?: any
	loadingPromise?: Promise<any>
}

export const cache: { [ index: string ]: CacheItem } = {}

export async function load(key: string, func: Function, ttlMillis: number = 1000 * 60 * 20) {
	//console.log('loader.load', key)
	const now = Date.now()
	let item = cache[key]
	if (item && item.expiresAt > now) {
		//console.log('cache hit')
		if (item.loadingPromise) {
			//console.log('unfinished -> wait')
			await item.loadingPromise
		}
		//console.log('done -> return')
		return item.value
	}
	item = {
		key,
		expiresAt: now + ttlMillis,
		loadingPromise: func(),
	}
	//console.log('start')
	cache[key] = item
	item.value = await item.loadingPromise
	delete item.loadingPromise
	return item.value
}

export function clear(key: string) {
	delete cache[key]
}