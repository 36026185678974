<template>
    <div style="width:100%;padding-left:50px;padding-right:50px;z-index:999">
        <p class="helpText" v-html="$t('text.openingTimesHelp')"/>
        <div :style="error && error.message && error.message.length > 0 ? 'opacity:1;margin-top:20px;padding-bottom:20px' : 'opacity:0'">
            <div ref="errorsView" class="bg-error" style="border-radius:10px;padding:10px">
                <v-row v-if="error">
                    <span style="color:#ffffff">{{error.message}}</span>
                </v-row>
            </div>
        </div>

        <!-- "Normal" Opening Times-->
        <div v-if="!businessHoursLocal.fields.isException || businessHoursLocal.fields.isException.de===false">
            <v-row>
                <v-col>
                    <DatePicker
                        v-model="businessHoursLocal.fields.validFromDate.de"
                    >
                        <template #label>{{ $t('text.validFrom') }}</template>
                    </DatePicker>
                </v-col>

                <v-col>
                    <DatePicker
                        v-model="businessHoursLocal.fields.validToDate.de"
                        :min="businessHoursLocal.fields.validFromDate.de"
                    >
                        <template #label>{{ $t('text.validUntil') }}</template>
                    </DatePicker>
                </v-col>
            </v-row>
            
            <div :ref="openingTimesIndex" v-for="(openingTimes, openingTimesIndex) in businessHoursLocal.displayArray" :key="openingTimes.key">
                <div :class="error&&error.displayIndex&&error.displayIndex>-1 && error.displayIndex===openingTimesIndex ? 'error-card' : 'card'" v-if="!holidayTime(openingTimes)">
                    <!-- Week days -->
                    <v-row
                        v-if="!businessHoursLocal.fields.isException || businessHoursLocal.fields.isException.de===false"
                        class="pa-2"
                        align="center"
                        no-gutters
                    >
                        <span v-for="weekday in weekdays" :key="'chip_' + (weekday)">
                            <v-btn
                                @click="toggleDay(openingTimesIndex, weekday)"
                                :class="isDaySelected(openingTimes, weekday) ? 'active' :  'inactive'">
                                <v-avatar size="20px" class="check">
                                    <v-icon color="#ffffff" size="16px">mdi-check-bold</v-icon>
                                </v-avatar>
                                {{ $t('text.'+weekday).substring(0,3) }}
                            </v-btn>
                        </span>
                        <v-spacer/>
                    </v-row>

                    <hr/>

                    <!-- Mark Days as Closed -->
                    <v-row align="center" justify="space-between" no-gutters class="px-2">
                        <span style="font-size:14pt">{{$t('text.markClosedDays')}}</span>
                        <v-spacer/>
                        <mys-switch
                            v-model="openingTimes.closed"
                            @update:modelValue="toggleClosed(openingTimesIndex)" 
                        />
                    </v-row>

                    <hr/>

                    <!-- Opening Hours -->
                    <div v-if="!openingTimes.closed">
                        <v-row
                            align="center"
                            no-gutters
                            class="pa-2 pb-0"
                        >
                            <v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
                                <v-label><span class="ofrom-label">{{$t('text.openFrom')}}</span></v-label>
                            </v-col>
                            <v-col style="padding:0px; margin-right:10px; min-width: 35%; max-width: 35%">
                                <v-label>{{$t('text.openTo')}}</v-label>
                            </v-col>
                            <div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
                            </div>
                        </v-row>
                        <v-row
                            v-for="(businessTimes, businessTimeIndex) of openingTimes.times" :key="businessTimeIndex"
                            align="center"
                            no-gutters
                            class="pa-2"
                        >
                            <v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" variant="outlined" density="compact" hide-details placeholder="00:00" 
                            v-model="businessTimes.openTime" :rules="hoursRules" :error-messages="businessTimes.errors"
                            @keyup="timeInputValidate($event, businessTimes)" @blur="timeInputValidate($event, businessTimes)"/>
                            <v-text-field style="padding:0px !important; margin-right:10px; min-width: 35%; max-width: 35%" variant="outlined" density="compact" hide-details placeholder="00:00" 
                            v-model="businessTimes.closeTime" :rules="hoursRules" :error-messages="businessTimes.errors"
                            @keyup="timeInputValidate($event, businessTimes)" @blur="timeInputValidate($event, businessTimes)"/>
        
                            <div class="row" style="padding:0px; min-width: 25%; max-width: 25%">
                                <v-btn v-if="businessTimeIndex === 0" style="margin-right:10px" class="gradientButton" elevation="0" @mousedown="clearTimeRow(openingTimesIndex)"><v-icon>mdi-close</v-icon></v-btn>
                                <v-btn v-if="businessTimeIndex > 0" style="margin-right:10px" class="gradientButton" elevation="0" @click="removeTimeRow(openingTimesIndex, businessTimeIndex)"><v-icon color="#fb3640">mdi-delete</v-icon></v-btn>
                            </div>
                        </v-row>
                    </div>

                    <hr v-if="!openingTimes.closed"/>


                    <v-row class="pa-2" no-gutters>
                        <v-btn
                            v-if="!openingTimes.closed"
                            class="gradientButton"
                            style="margin-right:10px"
                            elevation="0"
                            @click="addTimeRow(openingTimesIndex)"
                            >
                                <v-icon>mdi-plus</v-icon> {{ $t('text.addHours')}}
                        </v-btn>
                        <v-btn
                            v-if="openingTimesIndex > 0"
                            class="gradientButton"
                            elevation="0"
                            @click="removeOpeningTime(openingTimesIndex)"
                        >
                            <v-icon color="#fb3640">mdi-delete</v-icon> {{ $t('text.deleteBlock')}}
                        </v-btn>
                    </v-row>
                </div>
                <br/>
            </div>

            <br/>
        </div>

        <v-btn class="gradientButton" elevation="0" style="margin-right:10px" @click="addOpeningTime()"><v-icon>mdi-plus</v-icon>{{$t('text.addOtherTimes')}}</v-btn>
    </div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import DatePicker from '@/components/datePicker/DatePicker.vue'

export default {
    
    name: 'OpeningTimes',
    mixins: [ Common ],
    components: { DatePicker },
    props: {
        businessHours: Object,
        selectedTime: Number,
        error: Object
    },

    data() {
		return {
			weekdays: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
			hoursRules: [
				v => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Invalid time!'
			],
			displayArrayKey: 999,
			businessHoursLocal: this.businessHours,
		}
    },
    watch: {
        error() {
            const el = this.$refs['errorsView']
            el.scrollIntoView({ behavior: 'smooth'})
        },
        businessHoursLocal: {
            handler: function (val) {
                this.$emit('business-hours-changed', val)
            },
            deep: true
        },
    },
    created() {
        // this.businessHoursLocal = JSON.parse(JSON.stringify(this.businessHours))
        this.businessHoursLocal.fields.validFromDate.de = this.businessHours.fields.validFromDate.de.split("T")[0]
        this.businessHoursLocal.fields.validToDate.de = this.businessHours.fields.validToDate.de.split("T")[0]
    },
    mounted() {
        this.scrollToSelectedTime()
        this.$forceUpdate()
    },
    methods: {
        scrollToSelectedTime() {
            if (this.selectedTime > 0) {
                const el = this.$refs[this.selectedTime]
                if (el) { el[0].scrollIntoView({ behavior: 'smooth'}) }
            }
        },
        isDaySelected(openingTimes, weekday) { 
            if (openingTimes.days && openingTimes.days.findIndex(x => x === weekday) > -1) {
                return true
            } else {
                return false
            }
        },
        timeInputValidate(event, openingTime) {
            let key = event.keyCode || event.charCode
            
            if( key !== 8 && key !== 46 ) {
                if (event.target.value.length === 2) {
                    event.target.value = event.target.value + ':'
                } else if (event.target.value.length >= 5) {
                    event.target.value = event.target.value.substring(0,5)
                }
            }

            openingTime.errors = ""
        },
        addTimeRow(openingTimesIndex) { 
            this.businessHoursLocal.displayArray[openingTimesIndex].times.push({openTime:'',closeTime:''})
            this.$forceUpdate()
        },
        clearTimeRow(openingTimesIndex) {
            this.displayArrayKey++

            this.businessHoursLocal.displayArray[openingTimesIndex].times[0] = {openTime:'',closeTime:''}
            this.businessHoursLocal.displayArray[openingTimesIndex].key = this.displayArrayKey
            this.$forceUpdate()
        },
        removeTimeRow(openingTimesIndex, businessTimeIndex) {
            this.businessHoursLocal.displayArray[openingTimesIndex].times.splice(businessTimeIndex,1)
            this.$forceUpdate()
        },
        addOpeningTime() {  
            this.businessHoursLocal.displayArray.push({daysLabel: "", days:[], timeLabels:"00:00-00:00", times:[{openTime:'',closeTime:''}], closed:false})
            this.$forceUpdate()
        },
        removeOpeningTime(openingTimesIndex) {
            this.businessHoursLocal.displayArray.splice(openingTimesIndex,1)
            this.$forceUpdate()
        },
        toggleClosed(openingTimesIndex) {
            this.businessHoursLocal.displayArray[openingTimesIndex].times = [{openTime:'',closeTime:''}]
            this.$forceUpdate()
        },
        toggleDay(openingTimesIndex, day) {
            let dayIndex = this.businessHoursLocal.displayArray[openingTimesIndex].days.findIndex(x => x === day)
            if (dayIndex < 0) {
                this.businessHoursLocal.displayArray[openingTimesIndex].days.push(day)
            } else {
                this.businessHoursLocal.displayArray[openingTimesIndex].days.splice(dayIndex, 1)
            }

            //If day is already specified in another opening time, remove day from other opening time
            for (let i=0; i < this.businessHoursLocal.displayArray.length; i++) {
                if (i !== openingTimesIndex) {
                    let existingDayIndex = this.businessHoursLocal.displayArray[i].days.findIndex(x => x === day)
                    if (existingDayIndex > -1) {
                        this.businessHoursLocal.displayArray[i].days.splice(existingDayIndex, 1)
                    }
                }
            }
            this.$forceUpdate()
        },
        holidayTime(openingTimes) {
            if (openingTimes.days?.length === 1 && openingTimes.days[0] === 'holidays') {
                return true
            } else {
                return false
            }
        },
    }
}
</script>

<style scoped>
.card {
    border:1px solid #c0c0c0;
    border-radius: 10px;
}
.error-card {
    border:1px solid red;
    border-radius: 10px;
}
hr { border: 1px solid #cccccc !important; height: 1px; }
.v-text-field > .v-input__control > .v-input__slot::before { border-style: none !important;}
.v-text-field { padding-bottom: 12px !important; }
.v-chip.v-size--default { 
    border-radius: 5px !important;
    background-color: #ffffff !important;
    border: thin #dddddd solid !important;
    text-decoration: none !important;
    font-weight: normal !important;
}
.active, .inactive {
    background-color: #00aeef !important;
    box-shadow: none !important;

    border-radius: 30px !important;
    border: solid 1px #009cd6 !important;
    font-size: 14pt !important;
    color: #ffffff !important;
    height: 32px !important;
    margin: 5px;
    text-transform: none !important;
    letter-spacing: 0px !important;
}
.inactive {
    background: #ffffff !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    border: thin #dddddd solid !important;
    color: #000000 !important;
}
.inactive .check,
.active .check {
    width: 16px;
    height: 16px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #f4f4f4;
    margin-right: 3px !important;
    margin-left: -10px !important;
}
.active .check {
    border-color: #008ec3;
    background-color: #009dd7;
}
</style>
