import TrackingTechnical from '../api/src/TrackingTechnical'
import Executive from './Executive'

// TODO: declare types here and import into thread.ts?
class EntityData {
	// TODO: better types
	messages: any[]
	threads: any[]
}

export type MessageType = 'STATUS' | 'AUDIT' | 'ERROR' // TODO: ...

export default class TrackingExecutive extends Executive {

	public clientId: string
	technical: TrackingTechnical

	initServer() {
		if (!this.technical)
			this.technical = new TrackingTechnical()
	}

	// TODO: restrict permissions
	async getEntityData(type: string, id: string): Promise<EntityData> {
		return this.technical.getEntityData(type, id)
	}

	// TODO: introduce the clientId as a parameter
	// TODO: restrict permissions - only with api key (will be called from CM)?
	// TODO: we have to pass all data needed to actually create a message
	async message(threadId: string, entryType: string, entryId: string, text: string, type?: MessageType) {
		return this.technical.message(threadId, entryType, entryId, text, type)
	}
}