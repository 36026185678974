<template>
	<CommonTable
		v-model:expanded="expanded"
		:headers="headers"
		:items="items"
		:total="total"
		:sortBy="sortBy"
		:limit="limit"
		:offset="offset"
		show-expand
		@update:sortBy="sortBy = $event"
		@update:limit="$emit('update:limit', $event)"
		@update:offset="$emit('update:offset', $event)"
		@click:row="item => $router.push('/packages/' + item.id.replace('PT_', ''))"
		:noDataText="$t('text.noPackagesAvailable')"
	>
		<template #[`item.packageType.de`]="{ item }">
			<v-tooltip location="bottom">
				<template #activator="{ props }">
					<v-icon color="#787777" v-bind="props">mdi-layers</v-icon>
				</template>
				<span class="tooltip">{{ item.packageType.de }}</span>
			</v-tooltip>
		</template>

		<template #[`item.name.de`]="{ item }">
			<p>{{ item.serviceProviderName.de ?? '' }}</p>
			<div>{{ cleanName(item.name.de ?? '') }}</div>
		</template>

		<!-- TODO: no categories ATM -->

		<!-- <template #[`item.mainCategory`]="{ item }">
			<div class="main-category">
				<div v-for="cat of item.mainCategory.de" :key="cat" class="category-chip">
					<p>{{ cat }}</p>
				</div>
			</div>
		</template> -->
		<!-- TODO: STATIC data, replace with real categories when ready -->
		<template #[`item.mainCategory`]>
			<div>
				<div class="main-category">
					<div class="category-chip" v-for="cat of ['STATIC-DATA', 'Category 1', 'Category 2']" :key="cat">
						<p>{{ cat }}</p>
					</div>
				</div>
			</div>
		</template>

		<template #[`item.category`]="{ item }">
			<!-- <div style="display: inline-block;" v-for="category of item.category.de" :key="category">
				<span v-if="category" class="clientAssignmentChip" :class="{ [mapPeakStatus(item.package_travel_active)]: true, }"
					:title="category ?? ''">
					{{ category }}
				</span>
			</div> -->
			<!-- TODO: STATIC data, replace with real categories when ready -->
			<div style="display: inline-block;" v-for="category of ['STATIC-DATA', 'Category 1', 'Category 2']" :key="category">
				<span v-if="category" class="clientAssignmentChip" :class="{ [mapPeakStatus(item.active.de)]: true, }"
					:title="category ?? ''">
					{{ category }}
				</span>
			</div>
		</template>

		<!-- TODO: no marketplace for now. Will this come from clientAssignment[0]? -->
		
		<!-- <template #[`item.marketplace`]="{ item }">
			<span>{{ item.marketplace.de.slice(0, 2).join(', ') }}</span>
			<span v-if="item.marketplace.de?.length > 2">, +{{ item.marketplace.de?.length
				- 2 }}</span>
		</template> -->
		<!-- TODO: static data: replace with real marketplaces -->
		<template #[`item.marketplace`]="{ item }">
			<span>
				{{ item.websites.de.map(website => website.name).slice(0, 2).join(', ') }}
				<span v-if="item.websites.de.length > 2">, +{{ item.websites.de.length - 2 }}</span>
			</span>
		</template>

		<template #[`item.active.de`]="{ item }">
			<!--
			<ApprovalStatus :status="mapPeakStatus(item.active.de)" />
			-->
			<ApprovalStatus :status="item.status.de" />
		</template>

		<!-- TODO: this status is actually regarding translations and we will not do that for phase 1 -->
		<!-- <template #[`item.status`]="{ item }">
			<Status :status="item.status" />
		</template> -->

		<template #[`item.actions`]="{ item }">
			<div class="payment-plan-actions">
				<v-btn class="my-2" variant="outlined" icon="mdi-pencil" rounded="lg"
					@click="$router.push('/packages/' + item.id.replace('PT_', ''))"
				/>
			</div>
		</template>

		<!-- Expanded Row -->
		<template #expanded-row="{ item }">
			<PackagesTableDetail :item="item" />
		</template>
	</CommonTable>
</template>

<script>
import Common from '../../../../mixins/Common.vue'
import CommonTable from '../../../../components/common/tables/Table.vue'
import ApprovalStatus from '../../../../components/common/ApprovalStatus.vue'
import PackagesTableDetail from './PackagesTableDetail.vue'

export default {
	name: 'PackagesTable',
	components: { CommonTable, ApprovalStatus, PackagesTableDetail },
	emits: [ 'update:limit', 'update:offset' ],
	props: {
		items: { type: Array, default: () => [] },
		total: Number,
		limit: Number,
		offset: Number,
	},
	mixins: [Common],
	data: () => ({
		sortBy: [],
		expanded: [],
	}),
	computed: {
		headers() {
			return [
				{ title: this.$t('text.type'), key: 'packageType.de', value: 'packageType.de' },
				{ title: this.$t('text.service'), key: 'name.de', value: 'name.de' },
				// { title: '', key: 'mainCategory', value: 'mainCategory', sortable: false },
				// { title: this.$t('text.productCategory'), key: 'category', value: 'category', sortable: false },
				{ title: this.$t('text.marketplace'), key: 'marketplace', value: 'marketplace', sortable: false },
				{ title: this.$t('text.approval'), key: 'active.de', value: 'active.de', width: '15%', },
				// TODO: no status for phase 1
				// { title: this.$t('text.status'), key: 'status', value: 'status', width: '50px', align: 'center' },
				{ title: '', key: 'actions', value: 'actions', width: '50px', sortable: false },
			]
		},
		categories() {
			const result = []

			this.items.forEach(({ id, mainCategory, secondaryCategory }) => {

				mainCategory?.de?.forEach(category => {
					result.push({
						id,
						mainCategory: category,
						subCategory: secondaryCategory?.[category]?.de,
					})
				})

			})

			return result
		},
		staticCategoriesForTesting() {
			return [
				{ id: 1, mainCategory: 'Category 1', subCategory: ['Subcategory 1'] },
				{ id: 2, mainCategory: 'Category 2', subCategory: ['Subcategory 2'] },
			]
		},
	},
	methods: {
		mapPeakStatus(status) {
			switch (status) {
				case true:
					return 'active'
				case false:
					return 'deactivated'
				default:
					return 'error'
			}
		},
		cleanName(name) {
			return name?.replace(/<.+\/>/g, '')
		},
	},
}
</script>

<style scoped lang="scss">
.tooltip { text-transform: capitalize; }
.main-category {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;

	.category-chip {
		background-color: #DADADA;
		padding: 2px 5px;
		border-radius: 5px;

		p {
			font-size: 13px;
			color: #000;
			white-space: nowrap;
		}
	}
}
</style>