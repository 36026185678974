<template>
	<div class="container">
		<div class="wrapper" :data-cy="dataCy">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		"dataCy": String,
	},
}
</script>

<style scoped>
.container {
	display: flex;
}
.wrapper {
	width: 100%;
	border-left: solid 2px #CCCCCC;
	padding-left: 13px;
}
</style>