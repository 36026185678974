export type HistoryActionType = 'SET' | 'MOV' | 'ADD' | 'DEL' | 'ORD'

// TODO: this whole undo system is probably overengineered for what we actually need.
//       we could just use it much simpler (without the auto-diffing) and put the whole
//       state into the history at the end of operations.

// TODO: HistoryActions should be very primitive, small actions.
//       batches of HistoryActions make "History Steps" that can be undone/redone
//       these "Step Markers" have to be manually added to the history

export class HistoryAction {
	constructor(public type: HistoryActionType) {}
	new: any
	old: any
	path?: string
	undo() {}
	redo() {}
}

export class HistoryStep {
	actions: HistoryAction[] = []

	constructor(public name) {
		this.name = name
	}

	undo() {
		for (const action of this.actions) action.undo()
	}

	redo() {
		for (const action of this.actions) action.redo()
	}
}

export default class History {
	steps: HistoryStep[] = []
	pointer: number = -1

	addStep(name: string) {
		// TODO: when a new step is added, the last step should be compressed into a minimal set of actions
		//       (same action, same path, ..)
		// remove any steps after the pointer:
		// in case we have undone, the redo stack is being removed with this
		this.steps.splice(this.pointer, this.steps.length - this.pointer)
		this.steps.push(new HistoryStep(name))
		this.pointer++
	}

	// TODO: should add() also actually execute the action?
	add(action: HistoryAction) {
		if (this.steps.length == 0) this.addStep('Initial')
		const step = this.steps[this.pointer]
		step.actions.push(action)
	}

	lastState: any = null
	auto(state: any) {
		let newState = JSON.parse(JSON.stringify(state))
		// recursively remove all _ prefixed properties
		const removePrivate = (obj: any) => {
			for (const key in obj) {
				if (key.startsWith('_')) delete obj[key]
				if (key == 'a') delete obj[key]
				else if (typeof obj[key] == 'object') removePrivate(obj[key])
			}
		}
		removePrivate(newState)

		if (!this.lastState) { this.lastState = newState; return }
		const r = History.diff(newState, this.lastState)
		if (!r) return
		const action = new HistoryAction(r.type)
		action.path = r.path
		action.new = r.new
		action.old = r.old
		console.log('CHANGE DETECTED', r)
		this.add(action)
		this.lastState = newState
	}

	undo() {
		if (this.pointer >= 0) return
		this.steps[this.pointer - 1].undo()
		this.pointer--
	}

	redo() {
		if (this.pointer >= this.steps.length) return
		this.steps[this.pointer].redo()
		this.pointer++
	}

	static diffArrays(n: any[], o: any[], path = ''): { type: HistoryActionType, path: string, new: any, old?: any } | undefined {
		const nja = n.map(n => JSON.stringify(n))
		const oja = o.map(o => JSON.stringify(o))
		// ADD: exactly the last item is new
		if (n.length - 1 == o.length) {
			const x = [ ...n ]
			x.pop()
			if (JSON.stringify(x) == JSON.stringify(o)) return { type: 'ADD', path, new: n }
		}
		// DEL: exactly the last item is removed
		if (n.length + 1 == o.length) {
			const x = [ ...o ]
			x.pop()
			if (JSON.stringify(x) == JSON.stringify(n)) return { type: 'DEL', path, new: n }
		}
		// TODO: should we allow arbitrary reordering?
		// ORD: any 2 flipped
		if (n.length == o.length) {
			const diffs: any[] = []
			for (let i = 0; i < n.length; i++) {
				const nj = nja[i]
				const oj = oja[i]
				if (nj != oj) diffs.push({ index: i, nj, oj, n: n[i], o: o[i] })
			}
			// exactly 2 differences -> potential flip
			if (diffs.length == 2) {
				const [ a, b ] = diffs
				const dist = Math.abs(a.index - b.index)
				if (dist == 1 && a.nj == b.oj && a.oj == b.nj) return { type: 'ORD', path, new: a.index, old: b.index }
			}
			// exactly one difference -> deeper detect
			if (diffs.length == 1) {
				const change = diffs[0]
				const r = this.diff(change.n, change.o, `${ path }[${ change.index }]`)
				if (r) return r
			}
		}
		return { type: 'SET', path, new: n, old: o }
	}

	// TODO: this should instead produce a list of minimal action instead of a single one
	// TODO: X and Y changes should actually always trigger a whole-object-change, otherwise we can not compress them
	// TODO: this may be way too slow for large objects, so we should debounce
	//       OR: only trigger this manually after a known action (especially not during dragging operations)
	// TODO: dont hold onto REFERENCES, but to COPIES in history!
	// gives the smallest possible single diff
	static diff(n: any, o: any, path = ''): { type: HistoryActionType, path: string, new: any, old?: any } | undefined {
		// simple inequality checks
		const nj = JSON.stringify(n)
		const oj = JSON.stringify(o)
		if (nj == oj) return undefined
		if (typeof n != typeof o) return { type: 'SET', path, new: n, old: o }
		if (typeof n != 'object') return { type: 'SET', path, new: n, old: o }
		if (Array.isArray(n) != Array.isArray(o)) return { type: 'SET', path, new: n, old: o }

		// TODO: also check deeper into the array objects
		// look for array changes
		if (Array.isArray(n)) {
			return History.diffArrays(n, o, path)
		}

		// look for object changes
		const keys = new Set([ ...Object.keys(n), ...Object.keys(o) ])
		const changes: any[] = []
		for (const key of keys) {
			const p = path ? `${ path }.${ key }` : key
			const r = this.diff(n[key], o[key], p)
			if (r) changes.push(r)
		}
		// on multiple changes in the same object, return the whole object
		if (changes.length > 1) return { type: 'SET', path, new: n, old: o }
		return changes[0]
	}
}
