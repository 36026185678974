<template>
	<div class="chips-selector" @click="$emit('click')">
		<div class="chips-search">
			<div class="selected-chips">
				<span v-for="chip in items" :key="chip"
					@click="$emit('click', chip)"
				>{{ chip }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChipsList',
	props: {
		// TODO: should we rather have objects?
		items: Array, // of strings
	},
}
</script>

<style scoped>
.chips-selector { position: relative; width: 100%; border: 1px solid rgba(0, 0, 0, 0.38); border-radius: 4px; margin-bottom: 0px; transition: all .3s cubic-bezier(.25, .8, .5, 1); cursor: pointer; }
.chips-selector:hover { border: 1px solid rgba(0, 0, 0, 0.86); }
.selected-chips { min-height: 48px; display: flex; align-items: center; flex-wrap: wrap; }
.selected-chips span {
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
	border: thin #dddddd solid !important;
	color: #000;
	padding: 7px 18px;
	border-radius: 10px;
	margin-left: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
	height: 36px;
	align-items: center;
	background: rgb(245, 245, 245);
	background: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(254, 254, 254, 1) 100%);
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
}
</style>