<template>
	<Application>
		<template #navbar>
			<v-btn-toggle>
				<v-btn
					class="gradientButton"
					:class="{ active: page == 'salesChannels' }"
					@click="page = 'salesChannels'"
					data-cy="salesChannels"
				>
					<span>{{ $t('text.salesChannelsInfoTitle') }}</span>
				</v-btn>
				<v-btn
					class="gradientButton"
					:class="{ active: page == 'clients' }"
					@click="page = 'clients'"
					data-cy="marketplaces"
				>
					<span>{{ $t('text.clientsTitle') }}</span>
				</v-btn>
			</v-btn-toggle>
		</template>

		<MarketplaceSalesChannelsView v-if="page == 'salesChannels'" />
		<MarketplaceClientsView v-if="page == 'clients'" />
	</Application>
</template>

<script>
import MarketplaceSalesChannelsView from './MarketplaceSalesChannelsView.vue'
import MarketplaceClientsView from './MarketplaceClientsView.vue'
import Application from '../Application.vue'

export default {
	name: "MarketplaceView",
	components: { MarketplaceSalesChannelsView, MarketplaceClientsView, Application },
	data: () => ({
		page: 'salesChannels',
	}),
}
</script>

<style scoped>
</style>