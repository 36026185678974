<template>
	<div>
		<span class="error-text">({{ $t('text.required') }})</span>
		<div v-for="(ca, c) of value" :key="ca.sys.id" style="position: relative; margin-top: 10px; font-size: larger;">
			<!--
			TODO: show approval state as color
			TODO: delete assignment - on delete keep for undelete
			TODO: ResellerCategories
			TODO: commissions?
			TODO: categories here?
			TODO: add button -> load clients, create CA or reuse
			-->
			<div :style="{ '--col': getColor(ca.fields?.status?.de) }" style="color: var(--col); border: 2px solid var(--col); padding: 2px 10px; border-radius: 8px; xxxdisplay: inline-block;">
				<!-- TODO: control for switching? -->
				<v-icon size="20px" v-if="ca.fields?.isHomebase.de">mdi-home-city-outline</v-icon>
				{{ ca.fields?.client?.de?.fields?.title?.de }}
				<!-- TODO: delete as menu from ... -->
				<v-icon size="20px" style="position: absolute; right: 10px; top: 10px;"
					:id="'actions' + c"
				>mdi-dots-horizontal</v-icon>
				<v-menu :activator="'#actions' + c">
					<v-list>
						<v-list-item
							@click="value.splice(c, 1)"
						>
							<v-list-item-title>delete</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<!--<span v-for="sca of ca.fields?.salesChannelAssignments?.de" :key="sca.sys.id"
					style="font-size: smaller; color: white; background: #999; padding: 2px 10px; border-radius: 15px; display: inline-block; margin: 4px 0;"
				>
					{{ sca.fields?.salesChannel?.de?.fields?.title?.de }}
				</span>-->
				<v-select multiple chips
					v-model="salesChannels"
					:items="items"
					:label="$t('text.salesChannels')"
				></v-select>
			</div>
		</div>
		<!--{{ help }}
		{{ reason }}-->
		<v-btn class="gradientButton" elevation="0" style="margin-top: 20px;"
			@click="initPicker"
			id="add"
			:disabled="unusedClients.length == 0 && clients.length > 0"
		>
			{{ $t('text.add') }}
		</v-btn>
		<v-menu activator="#add">
			<v-list>
				<v-list-item
					v-for="(client, c) in unusedClients"
					:key="c"
					@click="add(client)"
				>
					<v-list-item-title>{{ client.fields?.title?.de }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

// TODO: should go to a shared (FE/BE) file
//       i tried putting it in core/../util but that didnt work out..
export function uniq(arr, idfun) {
	const ids = {}
	const r = []
	for (const e of arr) {
		const id = idfun(e)
		if (!ids[id]) {
			ids[id] = true
			r.push(e)
		}
	}
	return r
}

// TODO: marketplace complexities - which can i actually assign / edit?

export default {
	name: 'ClientAssignmentsNEW',
	components: { },
	mixins: [ Common ],
	props: {
		// an array of clientAssignments
		// { fields: { checklist, client, commissions, defaultCommissions, enabledCommissions,
		//	enabledTimebasedCommissions, isHomebase, productCategories, salesChannelAssignments,
		//	serviceProviderId, status, tags, type }, sys: { id: 123, ... }
		// }
		value: Array,
	},
	data: () => ({
		items: [ 'James App', 'James Web', 'Reseller', 'My Whitelabel Shop One', 'My Whitelabel Shop Two' ],
		loading: false,
		help: '',
		reason: '',
		// TODO: bind to the SCs in the CA instead!
		salesChannels: [],
		clients: [],
		// TODO: how to maintain the actual object with all the properties not shown in the ui?
		//       maybe when deleting we could put it into a trash array and when adding we could check if its in there
		//       and if so, reuse it
		trash: [],
	}),
	computed: {
		unusedClients() {
			return this.clients.filter(x => !this.value.find(y => y.fields.client.de.sys.id === x.sys.id))
		},
	},
	methods: {
		getColor(status) {
			if (status == 'pending') return '#ff9e21'
			if (status == 'approved') return '#66BB6A'
			return 'gray'
		},
		/*
		clientIsHomebase(client) {
			return this.model[client.sys.id]?.fields.isHomebase?.de
		},
		sortMarketplaces(clients, clientAssignments) {
			let sortedClients = []
			let homebaseClient

			if (clientAssignments?.de?.length > 0) {
				for (let clientAssignment of clientAssignments.de) {
					if (!this.clientIsHomebase(clientAssignment.fields.client.de)) {
						sortedClients.push(clientAssignment.fields.client.de)
						sortedClients.sort(this.compare);
					}
					else {
						homebaseClient = clientAssignment.fields.client.de
					}

					let index = clients.findIndex(x => x.sys.id === clientAssignment.fields.client.de.sys.id);

					if (index > -1) {
						clients.splice(index, 1)
					}
				}
			}
			if (clients?.length > 0) {
				clients.sort(this.compare);
			}
			sortedClients.push(...clients)
			if (homebaseClient) {
				sortedClients.unshift(homebaseClient)
			}
			return sortedClients
		},*/
		async initPicker() {
			console.log('initPicker')
			if (this.clients.length > 0) return
			this.clients = await this.loadClients()
		},
		async getMarketplaceClientsForClient(client) {
			this.loading = true
			let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + client.sys.id)
			this.loading = false
			let mp = marketplaces?.[0]
			// eslint-disable-next-line
			marketplaces = [ ...mp?.fields?.externalClients?.de, mp?.fields?.homeClient?.de ]
				// remove nulls
				.filter(x => !!x)
				// sort by title
				.sort((a, b) => a.fields.title.de.localeCompare(b.fields.title.de))
			// remove duplicates
			return uniq(marketplaces, x => x.sys.id)
		},
		async loadClients() {
			let selectedClient = this.$store.state.selectedClient

			// TODO: by now the 2 main cases are almost the same, we should remove the repetition
			if (this.activePage == 'BusinessProfile') {
				// ATT: only the home op may assign the SP to anything else than his own client(s)
				const clients = await this.getMarketplaceClientsForClient(selectedClient)
				if (!clients?.length) throw new Error('no marketplace clients found for sp client', client)

				if (this.userIsHomeOperator) {
					this.help = this.$t('text.caClientsHelp_bp_hop')
					this.reason = this.$t('text.caClientsReason_bp_hop')
					//return this.sortMarketplaces(clients, this.serviceProvider.fields.clientAssignments)
					return clients
				}

				if (this.userIsOperator) {
					this.help = this.$t('text.caClientsHelp_bp_xop')
					this.reason = this.$t('text.caClientsReason_bp_xop')
					return clients
				}

				// the user is serviceProvider

				// we start by finding the home client of the serviceProvider
				let client = this.findHomeClient(this.serviceProvider)
				if (!client) throw new Error('home client not found for serviceProvider', this.serviceProvider)

				this.help = this.$t('text.caClientsHelp_bp_sp')
				this.reason = this.$t('text.caClientsReason_bp_sp')
				return clients
			}

			if (this.activePage == 'ServiceDesigner') {
				const clients = await this.getMarketplaceClientsForClient(selectedClient)
				if (!clients?.length) throw new Error('no marketplace clients found for sp client', client)

				if (this.userIsHomeOperator) {
					this.allowUncheckingHomebase = true
					this.help = this.$t('text.caClientsHelp_sd_hop')
					this.reason = this.$t('text.caClientsReason_sd_hop')
					return clients
				}
				if (this.userIsOperator) {
					this.allowUncheckingHomebase = false
					this.help = this.$t('text.caClientsHelp_sd_xop')
					this.reason = this.$t('text.caClientsReason_sd_xop')
					return clients
				}

				// the user is serviceProvider

				// TODO: also allow unchecking home CA
				// TODO: when checking again we need to set the isHomebase flag based on whats on the SP

				// we start by finding the home client of the serviceProvider
				let client = this.findHomeClient(this.serviceProvider)
				if (!client) throw new Error('home client not found for serviceProvider', this.serviceProvider)

				this.allowUncheckingHomebase = true
				this.help = this.$t('text.caClientsHelp_sd_sp')
				this.reason = this.$t('text.caClientsReason_sd_sp')
				return clients
			}
		},
		add(client) {
			this.value.push({
				fields: {
					// TODO: more
					client: { de: client },
					salesChannelAssignments: { de: [] },
					status: { de: 'pending' },
					isHomebase: { de: false },
				},
				sys: { id: '' },
				addl: { isNew: true },
			})
		},
	},
}
</script>