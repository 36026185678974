import { createI18n } from 'vue-i18n'

export class TranslationUtil {
	lastLoad = 0

	// loads the translations from the api and stores them in the store
	async load(store) {
		this.lastLoad = new Date().getTime()
		const res = await fetch(`/api/translations`)
		const translations = await res.json()
		if (translations.message) throw new Error(translations.message)

		await store.commit('setTranslations', translations)
		return translations
	}

	async reload(store) {
		// we only reload if the last load is older than 1 minute
		if (new Date().getTime() - this.lastLoad < 60000) return
		await this.load(store)
	}

	createI18n(store) {
		let text = {}
		for (const translation of store.state.translations) {
			text[translation.key] = { text: translation.value }
		}

		return createI18n({
			locale: 'en',
			messages: text,
			missingWarn: false,
			silentTranslationWarn: true,
		})
	}

	async getI18n(store) {
		if (!store.state.translations) {
			await this.load(store)
		}

		return this.createI18n(store)
	}
}

// filter out some warnings (setting the flags on createI18n does not seem to work..)
const originalConsoleWarn = console.warn
console.warn = (...args) => {
	if (typeof args[0] == 'string') {
		if (args[0].startsWith('[intlify] Fall back')) return
		if (args[0].startsWith('[intlify] Detected HTML')) return
	}
	originalConsoleWarn(...args)
}

export const translationUtil = new TranslationUtil()