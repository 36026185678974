<template>
	<v-menu :open-on-hover="openOnHover" location="bottom right" origin="top right" :close-on-content-click="false" v-model="isOpened">
		<template v-slot:activator="{ props }">
			<div v-bind="props" style="position: relative;">
				<div v-if="modelValue" class="dot-container stack-top"><span class="dot"></span></div>
				<v-btn data-cy="spFilter" class="gradientButton" elevation="0" size="x-small">
					<v-icon>mdi-filter</v-icon>
					<span class="d-none d-md-inline">{{ $t('text.filter') }}</span>
					<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
				</v-btn>
			</div>
		</template>
		<div class="slotContainer">
			<slot></slot>
			<div class="actionsContainer">
				<v-btn class="gradientButton" elevation="0" data-cy="clearFilter" @click="clear">{{ $t('text.clearFilter') }}</v-btn>
				<v-btn class="greenButton" elevation="0" theme="dark" data-cy="applyFilter" @click="apply">{{ $t('text.applyFilter') }}</v-btn>
			</div>
		</div>
	</v-menu>
</template>

<script>
export default {
	name: 'FilterMenu',
	props: {
		modelValue: Object,
		openOnHover: Boolean,
	},
	data: () => ({
		isOpened: false,
	}),
	methods: {
		apply() {
			this.$emit('apply')
			this.close()
		},
		clear() {
			this.$emit('clear')
			this.close()
		},
		open() {
			this.isOpened = true
			this.$emit('open')
		},
		close() {
			this.isOpened = false
			this.$emit('close')
		},
	},
}
</script>

<style scoped>
.dot-container { width: 75px; height: 75px; position: absolute; top: -5px; right: -11px; }
.dot { height: 14px; width: 14px; background-color: #ff0022; border-radius: 50%; display: inline-block; position: absolute; top: 0px; right: 5px; }
.stack-top { width: 14px; height: 14px; z-index: 9; margin: 0px; padding:0px; }
.slotContainer {
	max-width: 600px;
	background: white;
	padding: 20px;
	border-radius: 4px;
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
	display: flex;
	gap: 15px;
	flex-direction: column;
}
.actionsContainer {
	display: flex;
	gap: 10px;
	padding-top: 20px;
	border-top: 1px solid #ddd;
}
</style>