<template>
	<div>
		<v-label>{{ label }}</v-label>
		<p>{{ helpText }}</p>
		<v-select
			ref="select"
			v-model="selected"
			:items="options"
			@update:modelValue="$emit('update:modelValue', selected)"
			:placeholder="placeholder"
			:item-title="(item) => item.title?.[serviceLocale] || item.title"
			return-object
			:data-cy="dataCy"
			density="compact"
			variant="outlined"
			hide-details
			:menu-props="{ closeOnContentClick: true }"
		>
			<template v-slot:item="{ props, item }">
				<v-list-item v-bind="props">
					<template v-slot:prepend>
						<v-radio-group v-model="selected" hide-details>
							<v-radio :label="item.label" :value="item.value" />
						</v-radio-group>
					</template>
				</v-list-item>
				<v-divider />
			</template>
			<template v-slot:append-item>
				<slot name="append-option"></slot>
			</template>
		</v-select>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'RadioSelect',
	mixins: [Common],
	props: {
		modelValue: String,
		options: Array,
		placeholder: String,
		label: String,
		helpText: String,
		dataCy: String,
	},
	data() {
		return {
			selected: null,
		}
	},
	mounted() {
		if (this.modelValue) this.selected = this.modelValue
	},
}
</script>
