<template>
	<Application name="Training Library" :loading="loading" v-model:errorTitle="errorTitle" :errorDetail="errorDetail">
		<template #navbar>
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
					<v-text-field variant="outlined" density="compact" hide-details
						prepend-inner-icon="mdi-magnify"
						placeholder='Search'
						style="max-width:50%; min-width: 150px;"
						v-model="searchString" 
						@keyup="search()"
					/>
					<v-spacer/>
<!--					<v-btn-toggle mandatory v-model="view">
						<v-btn id="btnList" class='btnList active-btn' elevation="0"><v-icon>mdi-format-list-bulleted</v-icon></v-btn>
						<v-btn id="btnGrid" class="btnGrid" elevation="0"><v-icon>mdi-view-grid</v-icon></v-btn>
					</v-btn-toggle>-->
			</v-row>
		</template>

		<div>
			<v-card class="tableCard align-self-start">
				<v-data-table fixed-header
					v-if="view == 0"
					v-model="selected"
					:headers="headers"
					:items="filteredTrainingMaterials"
					item-key="name"
					class="elevation-0"
					style="border-radius: 0"
					@click:row="viewVideo"
					:item-value="item => item"
				>
					<template v-slot:item.img="{ item }">
						<v-img width="32px" height="32px" style="border-radius:5px" :src="getPattern(item.fields.pattern.de)"/>
					</template>
					<template v-slot:item.title="{ item }">
						{{ item.fields.title[selectedLocale] }}
					</template>
					<template v-slot:item.category="{ item }">
						{{ item.fields.category.de.fields.title[selectedLocale] }}
					</template>
					<template v-slot:item.created_at="{ item }">
						{{ formatDate(item.sys.createdAt) }}
					</template>
					<template #bottom></template>
				</v-data-table>
			</v-card>
			<div v-if="view == 1">
				<v-row align="center" justify="center">
					<v-col>
						<Grid 
							:items="trainingMaterials"
							:cell-size="300"
						>
							<template #cell="props">
								<div class="preview-component">
									<div class="v-grid-item-wrapper">
										<TrainingMaterial 
											:trainingMaterial="props.item" 
											@view-video="viewVideo"
										/>
									</div>
								</div>
							</template>
						</Grid>
					</v-col>
				</v-row>
			</div>
		</div>

		<!-- Video Player -->
		<div v-show="showVideoPlayer" class="dialogDiv">
			<v-card height="100%">
				<v-container>
					<v-row align="center" justify="space-between">
						<h1>{{ mediaTitle }}</h1>
						<v-btn class="gradientButton" elevation="0" @click="showVideoPlayer=false">
							<v-icon>mdi-close-circle</v-icon>
							{{ $t('text.close') }}
						</v-btn>
					</v-row>
					<br />
					<embed
						v-show="mediaType === 'pdf'"
						:src="selectedPdf"
						width="100%"
						height="650px"
						:key="selectedPdf"
					/>
					<video controls autoplay
						v-show="mediaType === 'video'"
						:src="selectedVideo"
						id="videoPlayer"
						:key="selectedVideo"
					/>
				</v-container>
			</v-card>
		</div>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Grid from '@/views/dashboard/grid/Grid.vue'
import TrainingMaterial from './TrainingMaterial.vue'
import Common from '@/mixins/Common.vue'
import Alert from '@/components/common/Alert.vue'
import Application from '../Application.vue'

export default {
	name: "TrainingLibraryView",
	mixins: [ Common ],
	components: { Loading, TrainingMaterial, Alert, Grid, Application },
	data() { return {
		loading: true,
		errorTitle: '',
		errorDetail: '',
		selected: [],
		serviceProvider: {},
		trainingMaterialEdit: null,
		trainingMaterials: [],
		thumbnailColors: [],
		thumbnailPatterns: [],
		filteredTrainingMaterials: [],
		selectedVideo: null,
		selectedPdf: null,
		mediaType: null,
		category: null,
		categories: [],
		menuCategories: [],
		categoryAll: {
			sys: { id: "showAll" },
			fields: {
				title: {
					de: "Alle Videos",
					en: "All Videos",
					fr: "Toutes les vidéos",
					it: "Tutti i video",
					nl: "Alle video's",
				}
			},
		},
		showVideoPlayer: false,
		mediaTitle: '',
		trainingMaterial: {},
		view: 0,
		loggedInUser: {},
		sort: [
			{ id: 0, title: this.$t("text.sortBy") + ": " + this.$t("text.title") },
			{ id: 1, title: this.$t("text.sortBy") + ": " + this.$t("text.length") },
			{ id: 2, title: this.$t("text.sortBy") + ": " + this.$t("text.date") },
		],
		searchString: '',
	}},
	computed: {
		headers() {
			return [
				{ title: '', key: 'img', width: '40px', sortable: false },
				{ title: this.$t('text.title'), key: "title", sortable: true },
				{ title: this.$t('text.category'), key: "category", sortable: true },
				{ title: this.$t('text.createdAt'), key: "created_at" },
			]
		},
	},
	watch: {
		showVideoPlayer() {
			if (!this.showVideoPlayer && this.mediaType == 'video') {
				var videoPlayer = document.getElementById('videoPlayer')
				videoPlayer.pause()
			}
		},
	},
	created() {
		if (this.userIsOperator) {
			window.open('https://academy.alturos.com', '_blank')
			this.$router.push("/dashboard")
		} else {
			this.loggedInUser = this.$store.state.loggedInUser
			this.getTrainingMaterials()
			this.getTrainingMaterialsCategories()
		}
		
	},
	mounted() {
		this.category = this.categoryAll
	},
	methods: {
		search(val) {
			if (val === undefined) val = this.searchString
			val = val.toLowerCase()
			if (val.length == 0) {
				this.filteredTrainingMaterials = this.trainingMaterials.filter(p =>
					(this.category == this.categoryAll || p.fields.category.de.sys.id == this.category)
				)
			}
			else {
				this.filteredTrainingMaterials = this.trainingMaterials.filter(p =>
					p.fields.title?.[this.selectedLocale]?.toLowerCase().includes(val) &&
					(this.category == this.categoryAll || p.fields.category.de.sys.id == this.category)
				)
			}
		},
		refreshMaterials() {
			this.trainingMaterialEdit = null
			this.getTrainingMaterials()
			this.getTrainingMaterialsCategories()
		},
		refreshCategories() {
			this.getTrainingMaterialsCategories()
		},
		viewVideo(e, videoMedia) {
			if (!videoMedia) return
			videoMedia = videoMedia.item
			if (!videoMedia.fields.media.de.fields.file[this.selectedLocale].url) {
				return
			}
			if (videoMedia) {
				this.mediaType = videoMedia.fields.type.de
				this.mediaTitle = videoMedia.fields.title[this.selectedLocale]
				this.showVideoPlayer = true
				if (this.mediaType === 'video') {
					this.selectedVideo = this.getAssetUrl(videoMedia.fields.media.de, this.selectedLocale)
				}
				else if (this.mediaType === 'pdf') {
					this.selectedPdf = this.getAssetUrl(videoMedia.fields.media.de, this.selectedLocale)
				}
			}
		},
		filterByCategory(category) {
			if (category.sys.id == 'showAll') {
				this.filteredTrainingMaterials = this.trainingMaterials
			}
			else {
				this.filteredTrainingMaterials = this.trainingMaterials.filter(p =>
					p.fields.category.de.sys.id == category.sys.id
				)
			}
		},
		async getTrainingMaterials() {
			this.loading = true
			try {
				const res = await this.$httpGet(`/trainingmaterial/${this.$store.state.loggedInUser.fields.type.de}`)
				this.trainingMaterials = res.trainingMaterials
				this.filteredTrainingMaterials = this.trainingMaterials
			}
			catch (error) {
				this.errorTitle = this.$t("text.ERROR")
				this.errorDetail = error.response ? error.response.data.error : error
			}
			this.loading = false
		},
		async getTrainingMaterialsCategories() {
			try {
				const res = await this.$httpGet(`/trainingmaterial-categories`)
				this.menuCategories = res.trainingMaterialCategories
				this.menuCategories.unshift(this.categoryAll)
			}
			catch (error) {
				this.errorTitle = this.$t("text.ERROR")
				this.errorDetail = error.response ? error.response.data.error : error
			}
		},
		sortGrid(val) {
			let locale = this.selectedLocale
			if (val == 0) {
				this.filteredTrainingMaterials.sort(function(a, b) {
					a = a.fields.title[locale]
					b = b.fields.title[locale]
					if (a < b) return -1
					if (a > b) return 1
					return 0
				})
			}
			else if (val == 1) {
				this.filteredTrainingMaterials.sort(function(a, b) {
					a = a.fields.duration[locale]
					b = b.fields.duration[locale]
					if (a < b) return -1
					if (a > b) return 1
					return 0
				})
			}
			else if (val == 2) {
				this.filteredTrainingMaterials.sort(function(a, b) {
					a = a.sys.createdAt
					b = b.sys.createdAt
					if (a < b) return -1
					if (a > b) return 1
					return 0
				})
			}
		},
	},

}
</script>

<style scoped>
a { text-decoration: none; }
li {
	white-space: normal;
	float: left;
	width: 100%;
	height: auto;
	word-wrap: break-word;
	padding-left: 20px;
	cursor: pointer;
}
.dialogDiv {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 90%;
	max-width: 1200px;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
	z-index: 999;
}
#videoPlayer { width: 100%; height: auto; }
.btnList { border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important; }
.btnGrid { border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important; }
.v-grid-item-wrapper { display: block;position: absolute;box-sizing: border-box;user-select: none;transform: translate3d(0px, 0px, 0px);z-index: 1;padding: 0;margin-left:5px;top: 0;width:260px;height: 250px; }
.dialog { position: fixed; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 1200px; background-color: #ffffff; border-radius: 10px; box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2); z-index: 999; }
</style>