<script>
import jwt_decode from 'jwt-decode'

export default {
	data() { return {
		now: 0,
		nowTimer: null,
	}},
	computed: {
		tokenDecoded() {
			const loggedInUser = this.$store.state.loggedInUser
			if (!loggedInUser?.kc_token) return
			return jwt_decode(loggedInUser.kc_token)
		},
		tokenExpiresInSeconds() {
			const ts = this.tokenDecoded?.exp
			return Math.floor(Math.max(0, (Number(ts + '000') - this.now) / 1000))
		},
		tokenHasExpired() {
			if (this.tokenExpiresInSeconds <= 0) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = this.$t('text.logoutDueToExpiredToken')
				return true
			}
		},
	},
	mounted() {
		this.nowTimer = setInterval(() => {
			this.now = Date.now()
		}, 1000)
	},
	beforeUnmount() {
		clearInterval(this.nowTimer)
	},
}
</script>