<template>
	<div>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<Disclosure :title="$t('text.generalSettings')" :expanded="true" style="margin-top: 65px;">
		<loading v-if="loading" :active="loading" :is-full-page="true" color="#4caf50" data-cy="loading"></loading>

		<v-label>{{$t('text.generalSettings')}}</v-label>
		<p class="helpText" v-html="$t('text.generalSettingsHelp')"/>
		<br/>
		<v-label><v-icon>mdi-lifebuoy</v-icon>{{$t('text.helpNeeded')}}</v-label>
		<p class="helpText" v-html="$t('text.helpNeededDesc')"/>
		<br/>
		<FieldSet id="roundingPrecision" infoText="roundingPrecisionHelp">
			<Field typeName="GeneralSettings" fieldName="roundingPrecision" v-model="roundingPrecision" @change="setRoundingPrecision" class="precision" />
		</FieldSet>
		<br/>
		<FieldSet id="roundingStrategy" infoText="roundingStrategyHelp" >
			<Field typeName="GeneralSettings" fieldName="roundingStrategy" v-model="roundingStrategy" @change="setRoundingStrategy" :options="roundingStrategies" class="strategy" />
		</FieldSet>
		<br/>
		<div style="display: flex;">
			<div style="flex: 1;">
				<FieldSet id="paymentPolicies" infoText="paymentPoliciesHelp">
					<p class="helpText" v-html="$t('text.paymentPoliciesDesc')"/>
				</FieldSet>
			</div>
			<div style="flex: 1;">
				<Field typeName="GeneralSettings" fieldName="individualPaymentPolicy" v-model="individualPaymentPolicy" @change="setIndividualPolicy" :showTitle="false" :clearable="true" class="policy"/>
			</div>
		</div>
		</Disclosure>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SettingsMixin from '@/mixins/SettingsMixin.vue'
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import FieldSet from '@/views/applications/packageDesigner/FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import MysSwitch from '../../../components/mys/mys-switch.vue'

export default {
	mixins: [Common, SettingsMixin],
	components: { Loading, Alert, Disclosure, FieldSet, MysSwitch, Field },
	data: () => ({
		startRecCount: 0,
		endRecCount: 25,
		pageRecCount: 25,
		totalRecCount: 0,
		loading: false,
		errorTitle: false,
		errorDetail: '',
		successTitle: false,
		successDetail: '',
		keypressTimeout: null,
		roundingPrecision: null,
		individualPaymentPolicy: null,
		roundingStrategy: null
	}),
	methods: {
		async setIndividualPolicy() {
			this.loading = true
			try {
				const clientId = this.$store.state.selectedClient.sys.id
				const client = await this.$httpPost(`/settings/individualPolicy/${clientId}`, { individualPaymentPolicy: this.individualPaymentPolicy.de })
				await this.$store.commit("setSelectedClient", client)
				this.successTitle = true
				this.successDetail = this.$t('text.paymentPoliciesSuccess')
			} catch (error) {
				this.errorTitle = true
				this.errorDetail = this.$t('text.paymentPoliciesError')
				console.error(error)
			}
			this.loading = false
		},
		// TODO: should we wait for 2 sec here or just make the API call? @change will anyway not be called while the user is typing in the input field but only when the user leaves the field
		handleKeypress() {
			// Clear any existing timeout
			clearTimeout(this.keypressTimeout)

			// Set a new timeout to call the method after 2 seconds
			this.keypressTimeout = setTimeout(this.setRoundingPrecision, 2000)
		},
		async setRoundingPrecision() {
			this.loading = true
			try {
				const clientId = this.$store.state.selectedClient.sys.id
				const client = await this.$httpPost(`/settings/roundingPrecision/${clientId}`, { roundingPrecision: this.roundingPrecision.de })
				await this.$store.commit("setSelectedClient", client)
				this.successTitle = true
				this.successDetail = this.$t('text.roundingPrecisionSuccess')
			} catch (error) {
				this.errorTitle = true
				this.errorDetail = this.$t('text.roundingPrecisionError')
			}
			this.loading = false
		},
		async setRoundingStrategy() {
			this.loading = true
			try {
				const clientId = this.$store.state.selectedClient.sys.id
				const client = await this.$httpPost(`/settings/roundingStrategy/${clientId}`, { roundingStrategy: this.roundingStrategy.de })
				await this.$store.commit("setSelectedClient", client)
				this.successTitle = true
				this.successDetail = this.$t('text.roundingStrategySuccess')
			} catch (error) {
				this.errorTitle = true
				this.errorDetail = this.$t('text.roundingStrategyError')
			}
			this.loading = false
		},
		async getRoundingStrategies() {
			this.loading = true
			try {
				const roundingStrategies = await this.$httpGet('/settings/roundingStrategies')
				// we need to add a label for the model driven dropdown to show the translatable text for the keys
				roundingStrategies.forEach(strategy => {
					strategy.label = this.$t('text.' + strategy.key)
				})
				this.roundingStrategies = roundingStrategies
			} catch (error) {
				console.error(error)
			}
			this.loading = false
		}

	},
	async mounted() {
		await this.getRoundingStrategies()
		this.roundingPrecision = this.$store.state.selectedClient.fields.settings?.roundingPrecision ?? null
		this.individualPaymentPolicy = this.$store.state.selectedClient.fields.settings?.individualPaymentPolicy ?? null
		this.roundingStrategy = this.$store.state.selectedClient.fields.settings?.roundingStrategy ?? null
		console.log('roundingPrecision', this.roundingPrecision)
		console.log('individualPaymentPolicy', this.individualPaymentPolicy)
		console.log('roundingStrategy', this.roundingStrategy)
	}
}

</script>

<style scoped>
.payment-policies-container {
	display: flex;
	align-items: center;
}

.payment-policies-container .float-right {
	margin-left: auto;
}

.strategy {
	width: 20%;
}

.precision {
	width: 5%;
}

.policy {
	float: right;
}

/* overwrite field class to remove the left border */
.Field {
	border-left: none; 
	padding-left: 0;
}
.Field.focus {
	border-left: none;
}

</style>