<template>
	<div>
		<Disclosure
			:title="disclosureTitle"
			:error="sectionMessage.error"
			:message="sectionMessage.message"
			data-cy="skirentalSettings"
			ref="SECTION_skirentalSettings"
            :lock="skirentalSettingsLocked"
		>
			<div class="field left-border">
				<v-label>{{$t('text.skirentalReduction')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="reductionErrors.length === 0"
					:error-messages="reductionErrors"
					:items="reductions"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skirentalReductionHelptext')"/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.skirentalProductClass')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="productClassErrors.length === 0"
					:error-messages="productClassErrors"
					:items="productClass"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skirentalProductClassHelptext')"/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.skirentalRentalStore')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					multiple chips
					:hide-details="skirentalStoreErrors.length === 0"
					:error-messages="skirentalStoreErrors"
					:items="skirentalStore"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skirentalRentalStoreHelptext')"/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.skirentalSportType')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="skirentalSportTypeErrors.length === 0"
					:error-messages="skirentalSportTypeErrors"
					:items="skirentalSportType"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skirentalSportTypeHelptext')"/>
			</div>
			<div class="field left-border">
				<v-label>{{$t('text.skirentalEquipmentType')}}</v-label>
				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="skirentalEquipmentTypeErrors.length === 0"
					:error-messages="skirentalEquipmentTypeErrors"
					:items="skirentalEquipmentType"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skirentalEquipmentTypeHelptext')"/>
			</div>
		</Disclosure>

	</div>
</template>

<script>

import Disclosure from "@/components/common/Disclosure.vue";
import ChipsList from "@/components/common/selectors/ChipsList.vue";
import Dialog from "@/components/common/Dialog.vue";


export default {
	name: "SkirentalSettings",
	components: {Dialog, ChipsList, Disclosure },
	// mixins: [Common],

	props: {
		disclosureTitle: String,
		skirentalSettingsLocked: Boolean,
	},

	data: () => ({
		sectionMessage: {
			error: false,
			message: '',
		},
		reductionErrors: [],
		productClassErrors: [],
		skirentalStoreErrors: [],
		skirentalSportTypeErrors: [],
		skirentalEquipmentTypeErrors: [],
		reductions: ['Reduction1', 'Reduction2'],
		productClass: ['Class1', 'Class2', 'Class3'],
		skirentalStore: ['StoreA', 'StoreB', 'StoreC'],
		skirentalSportType: ['Sport1', 'Sport2', 'Random Sport'],
		skirentalEquipmentType: ['Equipment', 'More Equipment'],

	}),
	watch: {

	},
	methods: {

	}
}
</script>