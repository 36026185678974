<template>
	<div>
		<div>
			<div class="title">{{ $t('text.individual') }}</div>
			<div class="subheading mb-4">{{ $t('text.selectSpecificServiceProviders') }}</div>
			<!-- selected service providers count-->
			<div v-if="assignedServiceProviders.length" class="subheading mb-4">{{ $t('text.serviceProvidersSet') }}: {{ assignedServiceProviders.length }}</div>
			<div v-else class="subheading mb-4">{{ $t('text.noServiceProvidersSet') }}</div>
		</div>
		<div class="button-section">
			<v-btn :disabled="assignedServiceProviders.length === 0" @click="$refs.peakServiceProvidersDialog.show = true; editMode = true" class="defaultButton gradientButton" theme="dark" elevation="0" variant="text">
				<v-icon left>mdi-pencil</v-icon>
				{{ $t('text.edit') }}
			</v-btn>
			<v-btn @click="$refs.peakServiceProvidersDialog.show = true; editMode = false" class="defaultButton gradientButton" theme="dark" elevation="0">
				<v-icon left>mdi-plus</v-icon>
				{{ $t('text.addServiceProvider') }}
			</v-btn>
		</div>
		<Dialog ref="peakServiceProvidersDialog"
			:confirmLabel="$t('text.confirmSelection')"
			:cancelLabel="$t('text.discardChanges')"
			:confirm-handler="confirmDialog"
			:width="'80%'"
			:title="$t('text.issuingParty')">
			<template #content>
				<AddEditPeakServiceProvidersDialog ref="peakServiceProvidersDialogContent" :editMode="editMode" :selectedServiceProviders="assignedServiceProviders" />
			</template>
		</Dialog>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Dialog from '@/components/common/Dialog.vue'
import AddEditPeakServiceProvidersDialog from './AddEditPeakServiceProvidersDialog.vue'

export default {
	components: {
		Dialog,
		AddEditPeakServiceProvidersDialog
	},
	mixins: [Common],
	data() {
		return {
			// TODO: this is hardcoded for now, but it will be a prop in the future (referencing the real model of the product/guest-card). This is the distinguish between the ADD and EDIT mode
			assignedServiceProviders: [],
			editMode: false
		}
	},
	computed: {
		
	},
	methods: {
		confirmDialog() {
			let selectedServiceProviders = []
			if (this.editMode) {
				selectedServiceProviders = this.$refs['peakServiceProvidersDialogContent'].serviceProviders
			} else {
				selectedServiceProviders = [...this.assignedServiceProviders, ...this.$refs['peakServiceProvidersDialogContent'].serviceProviders.filter(sp => sp.selected)]
			}
			console.log('emitting selected service providers', selectedServiceProviders)
			// TODO: either emit this to the parent component and update the real model there or directly update the real model here
			this.$emit('assigned-service-providers', selectedServiceProviders)
			this.assignedServiceProviders = selectedServiceProviders
			this.$refs.peakServiceProvidersDialog.show = false
		}
	},
	async mounted() {
	},
}
</script>

<style lang="scss" scoped>
.button-section {
	display: flex;
	justify-content: flex-end;

	&>* {
		margin-left: 10px;
	}
}
</style>