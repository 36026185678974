
export class Checklist {
	constructor() {
		return {
			sys: {
				id: ''
			},
			fields: {
				items: { de: []}
			}
		}
	}
}