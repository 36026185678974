<template>
	<ProfileColorCalendar v-model="profiles">
		<template #header>
			<v-btn @click="addProfile()">Add</v-btn>
		</template>
		<template #item="{ profile }">
			<div style="display: flex; flex-direction: column; gap: 10px; align-items: top;">
				<div v-for="time, t of profile.times" :key="t" style="display: flex; gap: 10px;">
					<input type="time" v-model="time.start" />
					-
					<input type="time" v-model="time.end" />
				</div>
			</div>
			<div>
				<button v-if="profile.deletable !== false"><v-icon>mdi-plus</v-icon></button>
			</div>
		</template>
	</ProfileColorCalendar>
</template>

<script>
import ProfileColorCalendar from './ProfileColorCalendar.vue'

export default {
	components: { ProfileColorCalendar },
	props: {
		modelValue: Object,
		salesChannels: Array,
		products: Array,
	},
	data: () => ({
		profiles: [
			{ id: 0, color: null, name: 'closed', times: [], isRemove: true },
			{ id: 1, color: '#e8f5dc', name: 'regular', times: [{ start: '09:00', end: '12:00' }, { start: '13:00', end: '16:00' }] },
			{ id: 2, color: '#feefc9', name: 'morning', times: [{ start: '09:00', end: '12:00' }] },
			{ id: 3, color: '#f2dbf6', name: 'evening', times: [{ start: '15:00', end: '20:00' }] },
		],
	}),
	methods: {
		addProfile() {
			const maxId = Math.max(...this.profiles.map(p => p.id))
			this.profiles.push({
				id: maxId + 1,
				color: '#ffffff',
				name: 'new',
				times: [],
			})
		},
	},
}
</script>

<style scoped>
</style>
