import { SqlEntityRepository } from '@mikro-orm/postgresql'
import { context } from './context-util'

// automatically loads, assigns / creates and persists an entity
export async function autoUpsert(repo: SqlEntityRepository<any>, data: any) {
	let entity = await repo.findOne(data.id)
	if (entity) {
		data.updatedBy = context.userSub
		context.em.assign(entity, data as any)
	}
	else {
		data.createdBy = context.userSub
		entity = await repo.create(data)
	}
	await context.em.persistAndFlush(entity)
	return entity
}