<template>
	<div style="margin-top: 85px;">
		<div v-if="loading" class="text-center">
			<v-progress-linear indeterminate color="primary"></v-progress-linear>
		</div>
		<!-- add two fields for the user to choose desired with and height (default should be 4k) -->
		<div class="targetDimensions">
			<v-text-field
				v-model="targetWidth"
				label="Target Width"
				type="number"
				min="1"
				max="10000"
				step="1"
				required
				suffix="px"
			></v-text-field>
			<!-- <v-spacer></v-spacer> -->
			<v-icon style="margin: 0 10px">mdi-close</v-icon>
			<v-text-field
				v-model="targetHeight"
				label="Target Height"
				type="number"
				min="1"
				max="10000"
				step="1"
				required
				suffix="px"
			></v-text-field>
		</div>
		<div v-if="showImages" class="image-container">
			<div class="image-wrapper">
				<h2>Original Image</h2>
				<img v-if="original.dataURL" :src="original.dataURL" :width="original.width" :height="original.height" class="image" />
				<p>Size: {{ (original.size / 1000000).toFixed(2) }} MB</p>
				<p>Type: {{ original.type }}</p>
				<p>Dimensions: {{ original.width }} x {{ original.height }}</p>
			</div>
			<div class="image-wrapper">
				<h2>Enhanced Image</h2>				
				<img :src="result.base64" :width="original.width" :height="original.height" class="image" />
				<p>Size: {{ (result.size / 1000000).toFixed(2) }} MB</p>
				<p>Type: {{ result.type }}</p>
				<p>Dimensions: {{ result.width }} x {{ result.height }}</p>
				<v-btn outlined @click="showUpscaledImageInFullSize = true">
					<v-icon>mdi-fullscreen</v-icon>
					Show full size
				</v-btn>
			</div>
		</div>

		<div v-if="showUpscaledImageInFullSize">
			<v-dialog v-model="showUpscaledImageInFullSize" max-width="100%" persistent>
				<v-card>
					<v-card-title>
						<span class="headline">Upscaled Image</span>
					</v-card-title>
					<v-card-text>
						<img :src="result.base64" :width="result.width" :height="result.height" class="full-size-image" />
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="showUpscaledImageInFullSize = false">Close</v-btn>
					</v-card-actions>
				</v-card>

			</v-dialog>

		</div>

		<div class="text-center" style="margin: 15px 15px 45px 15px">
			<p v-if="remainingCredits > 0">Remaining Credits: {{ remainingCredits }}</p>
			<p v-if="lastCallCostInCredits > 0">Last Call Costed: {{ lastCallCostInCredits }}</p>
		</div>
		<div style="margin-top: 15px;">
			<FileUpload ref="fileUpload" @thumbnail-created="onThumbnailCreated" />
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import FileUpload from '@/components/media/FileUpload.vue'

export default {
	components: {
		FileUpload,
	},
	data() {
		return {
			targetWidth: 3840,
			targetHeight: 2160,
			original: {},
			result: {},
			showImages: false,
			remainingCredits: 0,
			lastCallCostInCredits: 0,
			loading: false,
			showUpscaledImageInFullSize: false,
		}
	},
	methods: {
		async onThumbnailCreated(file) {
			this.original = {
				dataURL: file.dataURL,
				type: file.type,
				size: file.size,
				width: file.width,
				height: file.height,
			}
			// await this.upscaleImage(file)
			await this.upscaleImageServerSide(file)
			// await this.upscaleImageServerSideAsFormData(file)
		},
		async upscaleImage(file) {
			console.log('File sent to clipdrop:', file)
			try {
				this.loading = true
				const formData = new FormData()
				formData.append('image_file', file)
				formData.append('target_width', this.targetWidth)
				formData.append('target_height', this.targetHeight)

				const response = await axios.post(
					'https://clipdrop-api.co/image-upscaling/v1/upscale',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
							'x-api-key': '67b6038c9f47e67befe0a6e759c0c26f06e617726edb4ecbc5e6c8da1ffa5d162c9ac728df3d5246c39e81247e56d0b6',
						},
						responseType: 'arraybuffer',
					}
				)

				console.log('Response:', response)
				
				// Convert the response data to base64
				const base64 = btoa(
					new Uint8Array(response.data)
						.reduce((data, byte) => data + String.fromCharCode(byte), '')
				)

				// Set the result object with the base64 image data
				this.result = {
					base64: `data:${response.headers['content-type']};base64,${base64}`,
					type: response.headers['content-type'],
					size: response.headers['content-length'],
					width: this.targetWidth,
					height: this.targetHeight,
				}

				this.showImages = true
				this.lastCallCostInCredits = this.remainingCredits - response.headers['x-remaining-credits']
				this.remainingCredits = response.headers['x-remaining-credits']
				console.log('ORIGINAL:', this.original)
				console.log('RESULT:', this.result)
			} catch (error) {
				console.error(error)
			} finally {
				this.loading = false
			}
		},

		async upscaleImageServerSide(file) {
			console.log('File sent to server:', file)
			try {
				this.loading = true

				// send the data as a normal body with a file object that contains the dataURL of the image, height and width
				const data = {
					imageFile: {
						dataURL: file.dataURL,
						width: file.width,
						height: file.height,
					},
					targetWidth: this.targetWidth,
					targetHeight: this.targetHeight,
				}

				const response = await this.$httpPost('/clipdrop/upscale', data)

				// const response = await axios.post(
				// 	'http://localhost:8080/api/clipdrop/upscale',
				// 	 data,
				// 	{
				// 		headers: {
				// 			'Content-Type': 'multipart/form-data',
				// 			'Authorization': 'Bearer ' + this.$store.state.loggedInUser.kc_token,
				// 		},
				// 		responseType: 'arraybuffer',
				// 	}
				// )

				console.log('Response:', response)
				
				// Set the result object with the base64 image data
				this.result = {
					base64: response.data.dataURL,
					type: response.data.contentType,
					size: response.data.imageSize,
					width: response.data.width,
					height: response.data.height,
				}

				this.showImages = true
				this.lastCallCostInCredits = this.remainingCredits - response.data.remainingCredits
				this.remainingCredits = response.data.remainingCredits
				console.log('ORIGINAL:', this.original)
				console.log('RESULT:', this.result)
			} catch (error) {
				console.error(error)
			} finally {
				this.loading = false
			}
		},
		upscaleImageServerSideAsFormData(file) {
			console.log('File sent to server:', file)
			try {
				this.loading = true

				const formData = new FormData()
				formData.append('image_file', file)
				formData.append('target_width', this.targetWidth)
				formData.append('target_height', this.targetHeight)

				const response = axios.post(
					'http://localhost:8080/api/clipdrop/upscale/v2',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + this.$store.state.loggedInUser.kc_token,
						},
						responseType: 'arraybuffer',
					}
				)

				console.log('Response:', response)
				
				// Set the result object with the base64 image data
				this.result = {
					base64: `data:${response.headers['content-type']};base64,${response.data}`,
					type: response.headers['content-type'],
					size: response.headers['content-length'],
					width: this.targetWidth,
					height: this.targetHeight,
				}

				this.showImages = true
				this.remainingCredits = response.headers['x-remaining-credits']
				console.log('ORIGINAL:', this.original)
				console.log('RESULT:', this.result)
			} catch (error) {
				console.error(error)
			} finally {
				this.loading = false
			}
		}
	},
}
</script>

<style>
.dropzone {
	border: 2px dashed #0087F7;
	border-radius: 5px;
	padding: 20px;
	text-align: center;
	font-size: 1.5em;
	color: #0087F7;
	cursor: pointer;
}

.image-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.image-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
}

.image {
	max-width: 100%;
	max-height: 100%;
	/* object-fit: contain; */
	margin-bottom: 15px;
}

.full-size-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.targetDimensions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 20%;
	margin: 0 auto;
}

</style>
