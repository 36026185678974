import Sugar from 'sugar'

import sugar_de from '../../lib/Sugar/de'
import sugar_fr from '../../lib/Sugar/fr'
import sugar_it from '../../lib/Sugar/it'
import sugar_nl from '../../lib/Sugar/nl'

Sugar.Date.addLocale('de', sugar_de)
Sugar.Date.addLocale('fr', sugar_fr)
Sugar.Date.addLocale('it', sugar_it)
Sugar.Date.addLocale('nl', sugar_nl)

export default Sugar