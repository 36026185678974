export type Point = { x: number, y: number, a?: number, name?: string }
export type Curve = { p1: Point, p2: Point, p3: Point, p4: Point }
export type Seat = { name: string, pos: Point }

// TODO: move all calculations to a curve lib
// https://codepen.io/ZPiDER/pen/yLoJboq?editors=1010
export function calculateSample(curve: Curve, t: number) {
	var dx = Math.pow(1-t, 2)*(curve.p2.x-curve.p1.x) + 2*t*(1-t)*(curve.p3.x-curve.p2.x) + t * t * (curve.p4.x - curve.p3.x)
	var dy = Math.pow(1-t, 2)*(curve.p2.y-curve.p1.y) + 2*t*(1-t)*(curve.p3.y-curve.p2.y) + t * t * (curve.p4.y - curve.p3.y)
	return {
		x: Math.pow(1-t,3) * curve.p1.x + 3 * t * Math.pow(1 - t, 2) * curve.p2.x + 3 * t * t * (1 - t) * curve.p3.x + t * t * t * curve.p4.x,
		y: Math.pow(1-t,3) * curve.p1.y + 3 * t * Math.pow(1 - t, 2) * curve.p2.y + 3 * t * t * (1 - t) * curve.p3.y + t * t * t * curve.p4.y,
		dx: dx,
		dy: dy,
		d: Math.sqrt(dx * dx + dy * dy),
		a: -Math.atan2(dx, dy) + 0.5 * Math.PI,
	}
}

export function calculateSampleEqui(curve: Curve, t: number) {
	const totalLength = calculateCurveLength(curve)
	const targetLength = t * totalLength
	let currentLength = 0
	let previousPoint = calculatePointOnCurve(curve, 0)
	for (let i = 1; i <= 100; i++) {
		const currentT = i / 100
		const currentPoint = calculatePointOnCurve(curve, currentT)
		const segmentLength = calculateDistance(previousPoint, currentPoint)

		if (currentLength + segmentLength >= targetLength) {
			const remainingLength = targetLength - currentLength
			const ratio = remainingLength / segmentLength
			const interpolatedPoint = calculateLerp(previousPoint, currentPoint, ratio)
			return interpolatedPoint
		}
		currentLength += segmentLength
		previousPoint = currentPoint
	}
	return calculatePointOnCurve(curve, 1)
}

export function calculateCurveLength(curve: Curve) {
	const numSegments = 100
	let length = 0
	let previousPoint = calculatePointOnCurve(curve, 0)
	for (let i = 1; i <= numSegments; i++) {
		const t = i / numSegments
		const currentPoint = calculatePointOnCurve(curve, t)
		length += calculateDistance(previousPoint, currentPoint)
		previousPoint = currentPoint
	}
	return length
}

export function calculatePointOnCurve(curve: Curve, t: number, angleEpisilon: number | null = 0.01) {
	if (t > 1) t = 1
	if (t < 0) t = 0
	const x = Math.pow(1 - t, 3) * curve.p1.x + 3 * t * Math.pow(1 - t, 2) * curve.p2.x + 3 * t * t * (1 - t) * curve.p3.x + t * t * t * curve.p4.x
	const y = Math.pow(1 - t, 3) * curve.p1.y + 3 * t * Math.pow(1 - t, 2) * curve.p2.y + 3 * t * t * (1 - t) * curve.p3.y + t * t * t * curve.p4.y
	let a = 0
	if (angleEpisilon) {
		const p1 = calculatePointOnCurve(curve, t + angleEpisilon, null)
		const p2 = calculatePointOnCurve(curve, t - angleEpisilon, null)
		let dx = p1.x - p2.x
		let dy = p1.y - p2.y
		a = -Math.atan2(dx, dy) + 0.5 * Math.PI
	}
	return { x, y, a }
}

export function calculateDistance(point1: Point, point2: Point) {
	const dx = point2.x - point1.x
	const dy = point2.y - point1.y
	return Math.sqrt(dx * dx + dy * dy)
}

export function calculateLerp(point1: Point, point2: Point, ratio: number) {
	const x = point1.x + (point2.x - point1.x) * ratio
	const y = point1.y + (point2.y - point1.y) * ratio
	// TODO: currently we are not lerping angles - is that ok?
	const a = point1.a // + (point2.a - point1.a) * ratio
	return { x, y, a }
}

export function seatDirection(seat: Point): Point {
	if (!seat.a) return { x: 0, y: 0 }
	return {
		y: Math.cos(seat.a),
		x: -Math.sin(seat.a),
	}
}
