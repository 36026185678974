
export class Commission {
	constructor() {
		return {
			sys: {
				id: ''
			},
			fields: {
				type: '',
				validFrom: '',
				validTo: '',
				percentage: '',
				attributeSet: null,
				peakEntity: null,
				peakWebsiteId: null,
				peakId: null
			}
		}
	}
}

export class CommissionError {
	constructor(channel, validFrom, validTo, percentage) {
		return {
			channelError: channel,
			validFromError: validFrom,
			validToError: validTo,
			percentageError: percentage
		}
	}
}