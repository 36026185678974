<template>
	<StepperDialog ref="stepperDialog" v-model="model" v-model:stepId="step" group="QuickWizard"
		:order="[ steps ]"
		:onConfirm="handleConfirm"
		:disableConfirmButton="disableConfirmButton"
	>
		<Step v-if="isRailawayPackage" id="name" group="QuickWizard" icon="mdi-format-list-bulleted" class="StepWithoutStepper">
			<template #title>
				<v-toolbar-title>
					<span>{{ $t("text.packageName") }}</span>
				</v-toolbar-title>
			</template>


			<TranslateableField typeName="Package" fieldName="name" v-model="model.fields.name"
				:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales"
			/>
			<Field title="SKU">
				<SkuEditor id="pdSku"
					v-model="model.fields.sku.de"
					:parts="[
						{ id: 'prefix', defaultValue: 'PACKAGETRAVEL' },
						{ id: 'group', defaultValue: '', editable: true },
						{ id: 'location', defaultValue: '', editable: true },
					]"
				/>
			</Field>
		</Step>
		<Step v-if="isGeneralPackage" id="pricingMode" group="QuickWizard" class="StepWithoutStepper"
			icon="mdi-cog"
			confirmStep="pricingMode"
		>
			<Field
				typeName="Package"
				fieldName="pricingType"
				:title="$t('text.pricingLabel')"
				:infoText="$t('text.pricingDescription')"
				v-model="model.fields.pricingType"
			/>
		</Step>

		<template #sidebar v-if="step=='name'">
			<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>

	</StepperDialog>
</template>

<script>
import SkuEditor from '../../views/applications/packageDesigner/SkuEditor.vue';
import Step from '../../views/applications/packageDesigner/Step.vue';
import LanguageSidebar from '../common/LanguageSidebar.vue';
import StepperDialog from '../common/StepperDialog.vue';
import TranslateableField from '../fields/TranslateableField.vue';
import LanguagesNavigation from '../../mixins/LanguagesNavigation.vue'

export default {
	name: 'QuickWizardDialog',
	components: { TranslateableField, Step, StepperDialog, SkuEditor, LanguageSidebar },
	mixins: [LanguagesNavigation],
	props: {
		modelValue: Object, // Package
		isRailawayPackage: {
			type: Boolean,
			default: false,
		},
		isGeneralPackage: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		model: null,
		step: null,
		steps: [],
		disableConfirmButton: false,
	}),
	computed: {
		displayedLocales() {
			return this.languageNavigationItems?.reduce((locales, { code }) => {
				if (code !== "all" && (this.$store.state.activeTranslation === "all" || this.$store.state.activeTranslation === code)) {
					locales.push(code)
				}
				return locales
			}, [])
		},
	},
	watch: {
		modelValue(modelValue) {
			this.model = JSON.parse(JSON.stringify(modelValue))
		},
	},
	methods: {
		open() {
			this.$refs.stepperDialog.open()
		},
		checkIfHasMissingTranslations(locale) {
			return !this.model.fields.name[locale]
		},
		handleConfirm() {
			this.$emit("update:modelValue", this.model)
			return false
		},
	},
	mounted() {
		this.model = JSON.parse(JSON.stringify(this.modelValue))
		this.step = this.isRailawayPackage ? 'name' : 'pricingMode'
		this.steps = this.isRailawayPackage ? ['name'] : ['pricingMode']
	},
}
</script>

<style scoped>
.StepWithoutStepper {
	margin-top: 0px;
	position: relative;
}
</style>