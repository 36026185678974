<template>
	<div>
		<p v-html="$t('text.addExceptionHelpLong')" class="helpText" style="margin-bottom: 10px !important;" />
		<DataCalendar :dates="dates" :calendar="calendar" :limit="limit" v-if="calendar.length" ref="dataCalendar" v-model:offset="offset" v-model:extended="extended" class="BusinessHoursCalendar">
			<template #navigation>
				<span style="flex-grow: 1;"></span>
				<span class="help">{{ $t('text.addExceptionHelp') }}</span>
				<v-tooltip location="bottom">
					<template #activator="{ props }">
						<v-icon v-bind="props" style="color: #00aeef;">mdi-help-circle</v-icon>
					</template>
					<div v-html="$t('text.addExceptionHelpLong')" style="max-width: 300px;" />
				</v-tooltip>
			</template>
			<template #dayMenu="{ day }">
				<div class="item" v-if="(!day.open || day.open && day.exceptionInfo) && !addTime" @click="addTimeStart(day)">{{ $t('text.businessHoursCalendarOpeningAdd') }}</div>
				<!-- TODO: "Copy from Oct 3" if previous day is filled and current day is empty -->
				<div class="item" v-if="day.open && !day.exceptionInfo && !addTime" @click="addTimeStart(day)">{{ $t('text.businessHoursCalendarOpeningChange') }}</div>
				<div class="item" v-if="day.open && !day.exceptionInfo" @click="setClosed(day)">{{ $t('text.businessHoursCalendarOpeningClose') }}</div>
				<div class="item" v-if="!day.open && day.exceptionInfo" @click="removeException(day)">{{ $t('text.businessHoursCalendarClosureRemove') }}</div>
				<EditOpeningTime v-if="addTime"
					@cancel="addTime = null"
					@ok="addTimeComplete(day, $event)"
				/>
			</template>
			<template #day="{ day, selectedDay }">
				<div v-if="day.exceptionInfo?.closed" class="exceptionClosed">{{ $t('text.closed') }}</div>
				<template v-else>
					<div v-for="(time, t) of day.exceptionInfo?.times ?? []" :key="t" class="exceptionTimes"
						@click.stop="$refs.dataCalendar.selectedDay = day; timeMenu = true; $refs.dataCalendar.dayMenu = false; selectedTime = time"
					>
						{{ time.openTime }} - {{ time.closeTime }}
						<v-menu v-if="selectedDay?.date == day.date && selectedTime?.openTime == time.openTime"
							v-model="timeMenu"
							location="bottom center"
						>
							<template #activator="{ props }"><span v-bind="props"></span></template>
							<div class="menu">
								<div class="item" @click="deleteTime(day, time, t)">{{ $t('text.businessHoursCalendarOpeningRemove') }}</div>
							</div>
						</v-menu>
					</div>
				</template>
				<template v-if="!day.exceptionInfo">
					<div v-for="(time, t) of day.times ?? []" :key="t" style="text-align: center; font-size: smaller; color: #98926e;">
						{{ time.openTime }} - {{ time.closeTime }}
					</div>
				</template>
			</template>
		</DataCalendar>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import EditOpeningTime, { timesOverlap } from './EditOpeningTime.vue'
import DataCalendar from '../common/DataCalendar.vue'

// TODO: i dont like that we need so many $refs accesses here.
//       can we find a prettier component interaction for selectedDay and dayMenu?

// TODO: we have to handle existing exceptions that have multiple dates
//       should we split them on load? or should we actually support multiple dates?
// TODO: set limit adaptively to fit the available screen height?

export default {
	name: 'BusinessHoursCalendar',
	components: { EditOpeningTime, DataCalendar },
	props: {
		timeSpan: Object,
		timeSpans: Array,
		// if not given, we are in legacy mode and use the timespans exceptions
		exceptions: Object, // dateString -> { closed, times }
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		limit: 7 * 8, // X weeks page size
		offset: 0, // TODO: set the page containing today (if there is one)
		editing: false,
		editingTime: null,
		editingDay: null,
		addTime: false,
		selectedTime: null,
		timeMenu: false,
		extendedLimit: 7 * 52,
		extended: false,
	}),
	computed: {
		tss() {
			const tss = this.timeSpans ?? [ this.timeSpan ]
			return tss.filter(ts => ts?.fields?.businessTimes)
		},
		dates() {
			// TODO: ALWAYS start at now - 2w?
			// find min and max date
			let minDate = null
			let maxDate = null
			this.tss.forEach(ts => {
				const from = ts.fields.validFromDate.de.substring(0, 10)
				if (!minDate || from < minDate) minDate = from
				const to = ts.fields.validToDate.de.substring(0, 10)
				if (!maxDate || to > maxDate) maxDate = to
			})
			const todayDate = moment().startOf('day')
			const today = todayDate.format('YYYY-MM-DD')
			// always include today in the range
			if (today < minDate) minDate = today
			if (today > maxDate) maxDate = today

			const fromDate = moment(minDate).startOf('day')
			const toDate = moment(maxDate).endOf('day')
			const fromMonday = fromDate.subtract((fromDate.day() - 1) % 7 + 7, 'days')
			const toSunday = toDate.add(6 - (toDate.day() - 1) % 7 + 7, 'days')
			const count = toSunday.diff(fromMonday, 'days') + 1
			const offsetToday = Math.floor(todayDate.diff(fromDate, 'days') / this.limit) * this.limit
			return { today, offsetToday, minDate, maxDate, fromDate, toDate, fromMonday, toSunday, count }
		},
		calendar() {
			if (!this.tss.length) return []

// TODO: regular days can not be handled this easily on arrays of timespans
//       we will rather need some way to easily look up the regular business hours for a given date
//       maybe cache the last used timespan and only search for a different one if that does not match..
//       should work well since we progress linearly
/*			// lookup for closed weekdays
			const regularDayOpen = {}
			Object.entries(this.timeSpan.fields.businessTimes.de).forEach(([day, bt]) => {
				regularDayOpen[day] = (bt as any).times.length > 0 && (bt as any).times[0].openTime !== ''
			})*/

// TODO: do we want to support multiple overlapping timespans?
// TODO: new stuff
			/* TODO: optimization idea: have a lookup hash => array where we store start + end dates
			//       and a stack during processing to quickly find the currently relevant spans
			const bordersByDate = {} // date => timespan[]
			timeSpans.forEach(timespan => {
				if (!timespan?.fields?.businessTimes) return []
				const from = moment(timespan.fields.validFromDate.de).format('YYYY-MM-DD')
				const to = timespan.fields.validToDate.de.substring(0, 10)
				for (let day = moment(from); day <= moment(to); day = day.add(1, 'days')) {
					bordersByDate[day.format('YYYY-MM-DD')] = timespan
				}
			})*/
/* optimized linear version
			const isIn = (date, timespan) => date >= timespan.fields.validFromDate.de && date <= timespan.fields.validToDate.de
			// optimization: to avoid performing the search often
			let lastTimespan = null
			const findTimespan = (date) => {
				if (date < this.dates.minDate || date > this.dates.maxDate) return null
				// a kind of cache so we dont need to search often
				// ATT: this only works because we progress linearly
				if (lastTimespan && isIn(date, lastTimespan)) return lastTimespan
				// TODO: long gaps need to search every day
				//       actually a search could return the "next covered date" so we could skip searching until that date is reached
				// entering a new timespan -> search for it
				lastTimespan = this.timespans.find(timespan => isIn(date, timespan))
			}
			// TODO: lastTimespan could be null (no span found)
*/

			const findTimespans = (date) => this.tss.filter(ts =>
				date >= ts.fields.validFromDate.de.substring(0, 10)
				&& date <= ts.fields.validToDate.de.substring(0, 10)
			)

// TODO: could there be multiple exceptions for the same day (?)
			// lookup for closed dates
			const exceptionDayInfo = {}
			if (this.exceptions) {
				this.exceptions.forEach(ex => {
					exceptionDayInfo[ex.date] = ex
				})
			}
			else {
				// legacy mode: no exceptions given, use the timespans
				this.tss.forEach(timespan => {
					timespan.fields.exceptions.de.forEach(ex => {
						ex.dates.forEach(d => {
							exceptionDayInfo[d] = ex
						})
					})
				})
			}

			const days = []
			let d = -1
			for (let day = this.dates.fromMonday.clone(); day <= this.dates.toSunday; day = day.add(1, 'days')) {
				d++
				if (d < this.offset) continue
				if (d >= this.offset + this.limit && !this.extended) continue
				if (d >= this.offset + this.extendedLimit) continue
				const weekday = day.format('dddd').toLowerCase()
				const date = day.format('YYYY-MM-DD')
				const dateObject = day.toDate()
				const year = day.format('YYYY')
				const inRange = date >= this.dates.minDate && date <= this.dates.maxDate
				let open = false
				const times = []
				findTimespans(date).forEach(timespan => {
					const bt = timespan.fields.businessTimes.de[weekday]
					const ei = exceptionDayInfo[date]
					// closed
					if (ei?.closed === true || ei?.closed !== false && (bt.times.length == 0 || bt.times[0].openTime === '')) return
					// open
					open = true
					times.push(...bt.times)
				})
				const exceptionInfo = exceptionDayInfo[date]
				let error = timesOverlap(exceptionInfo?.times) ? 'OVERLAP' : null
				days.push({ date, open, times, inRange, exceptionInfo, dateObject, weekday, year, menu: false, error })
			}
			return days
		},
	},
	methods: {
		addTimeStart(day) {
			// TODO: if we added one before to a different day, prefill?
			this.addTime = true
		},
		addTimeComplete(day, time) {
			let ex = day.exceptionInfo
			if (!ex) {
				if (this.exceptions) {
					ex = day.exceptionInfo = { date: day.date, closed: false, times: [] }
					this.exceptions.push(ex)
				}
				else {
					// legacy mode
					if (!this.tss[0].fields.exceptions?.de) this.tss[0].fields.exceptions = { de: [] }
					ex = day.exceptionInfo = { dates: [ day.date ], closed: false, times: [] }
					this.tss[0].fields.exceptions.de.push(ex)
				}
			}
			ex.closed = false
			ex.times.push(time)
			this.addTime = false
			this.$refs.dataCalendar.dayMenu = false
		},
		deleteTime(day, time, t) {
			let ex = day.exceptionInfo
			ex.times.splice(t, 1)
			// if its the last one, delete the whole exception
			if (ex.times.length == 0) {
				if (this.exceptions) {
					const i = this.exceptions.indexOf(ex)
					this.exceptions.splice(i, 1)
				}
				else {
					// legacy mode
					const i = this.tss[0].fields.exceptions.de.indexOf(ex)
					this.tss[0].fields.exceptions.de.splice(i, 1)
				}
			}
			this.$refs.dataCalendar.dayMenu = false
		},
		setClosed(day) {
			let ex = day.exceptionInfo
			// TODO: if there is an existing exception, just set it to closed
			if (!ex) {
				if (this.exceptions) {
					ex = day.exceptionInfo = { date: day.date, closed: true, times: [] }
					this.exceptions.push(ex)
				}
				else {
					// legacy mode
					if (!this.tss[0].fields.exceptions?.de) this.tss[0].fields.exceptions = { de: [] }
					ex = day.exceptionInfo = { dates: [ day.date ], closed: true, times: [] }
					this.tss[0].fields.exceptions.de.push(ex)
				}
			}
		},
		removeException(day) {
			let ex = day.exceptionInfo
			if (!ex) return
			if (this.exceptions) {
				const i = this.exceptions.indexOf(ex)
				this.exceptions.splice(i, 1)
			}
			else {
				// legacy mode
				const i = this.tss[0].fields.exceptions.de.indexOf(ex)
				this.tss[0].fields.exceptions.de.splice(i, 1)
			}
		},
	},
	mounted() {
		this.offset = this.dates.offsetToday
	},
}
</script>

<style scoped>
.nav { display: flex; gap: 10px; align-items: center; position: sticky; top: 64px; background: white; z-index: 100; padding-top: 15px; padding-bottom: 10px; margin-top: -15px; }
.nav button { padding: 5px; border: 1px solid #999; border-radius: 5px; background-color: #fff; }
.nav button[disabled] { opacity: 0.5; cursor: default; }

.monthLabel { font-weight: bold; color: #98926e; }
.exceptionClosed,
.exceptionTimes { text-align: center; border-radius: 5px; padding: 2px 0px 2px 5px; margin-bottom: 5px; overflow: hidden; }
.exceptionClosed { background: #ddd; border: 1px solid #999; }
.exceptionTimes { background: #fe6; border: 1px solid goldenrod; box-shadow: 0 0 10px #fe6; }
.date.error .exceptionTimes { outline: 1px solid #de2d2d; border: 1px solid #de2d2d; box-shadow: 0 0 10px #de2d2d; }

.menu { padding: 5px 0; background: white; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); }
.menu > div { white-space: nowrap; cursor: pointer; padding: 5px 10px; }
.menu > .item:hover { background: #eee; }
.help { max-width: 300px; font-size: smaller; text-align: right; white-space: normal; display: inline-block; max-height: 40px; overflow: hidden; text-overflow: ellipsis; }

@media screen and (max-width: 1070px) { .help { display: none; } }
@media screen and (max-width: 960px) { .help { display: block; } }
@media screen and (max-width: 770px) { .help { display: none; } }
</style>

<style>
/* on the BusinessProfile there is an additional nav bar, that takes space. */
.ServiceDesigner .nav,
.BusinessProfile .nav { top: 120px; }
.BusinessHoursCalendar .date { background-color: #f6f6f6; }
.BusinessHoursCalendar .weekdayHead { background: transparent; }
</style>