<template>
	<DataDialog
		ref="dialog" 
		:height="height"
		:width="width"
		:title="title"
		:cancelLabel="effectiveCancelLabel"
		:confirmLabel="effectiveConfirmLabel"
		:onCancel="cancel"
		:onConfirm="confirm"
		:isValid="!error"
		:modelValue="modelValue"
		:validator="validator"
		:useWrap="useWrap"
		:disableConfirmButton="disableConfirmButton"
	>
	<!--
		:steps="steps"
	-->
		<template #title>
			<div style="display: flex; flex-direction: row">
				<slot name="title" :model="model" :wrap="wrap" />
				<StepBar2 ref="bar" :group="group" :order="order" @step="$emit('update:stepId', $event)" @steps="steps = $event" style="border: 0;" />
			</div>
		</template>
		<template v-if="$slots.sidebar" #sidebar>
			<slot name="sidebar" :model="model" :wrap="wrap" />
		</template>
		<template #content>
			<slot name="content" :model="model" :wrap="wrap" />
			<slot />
		</template>
		<template #buttons>
			<div class="error" v-if="error">
				{{ error }}
			</div>
		</template>
	</DataDialog>
</template>

<script lang="ts">
import StepBar2 from '../../views/applications/packageDesigner/StepBar2.vue'
import DataDialog from './DataDialog.vue'

export default {
	name: 'StepperDialog',
	components: { DataDialog, StepBar2 },
	props: {
		modelValue: Object,
		title: String,
		height: String,
		width: String,
		validator: Function,
		cancelLabel: String,
		confirmLabel: String,
		onCancel: Function,
		onConfirm: Function,
		useWrap: Boolean,
		group: { type: String, default: 'app' },
		order: { type: Array, default: () => [] },
		disableConfirmButton: Boolean,
		stepId: String,
	},
	data: () => ({
		model: null,
		error: null,
		wrap: { model: null },
		steps: [],
	}),
	computed: {
		effectiveCancelLabel() {
			return this.steps?.[0]?.id == this.stepId ? this.cancelLabel : this.$t('text.back')
		},
		effectiveConfirmLabel() {
			return this.steps?.[this.steps.length - 1]?.id == this.stepId ? this.confirmLabel : this.$t('text.next')
		},
	},
	methods: {
		async cancel() {
			if (this.onCancel) {
				const r = await this.onCancel()
				if (r === false) return
			}
			const index = this.steps.findIndex(s => s.id == this.stepId)
			if (index > 0) {
				this.$refs.bar.show(this.steps[index - 1])
				return false
			}
		},
		async confirm() {
			if (this.onConfirm) {
				const r = await this.onConfirm()
				if (r === false) return
			}
			const index = this.steps.findIndex(s => s.id == this.stepId)
			const confirmStep = this.steps[index].confirmStep
			const confirmStepIndex = this.steps.findIndex(s => s.id == confirmStep)
			if (confirmStepIndex !== -1) {
				this.$refs.bar.show(this.steps[confirmStepIndex])
				return false
			}
			if (index < this.steps.length - 1) {
				this.$refs.bar.show(this.steps[index + 1])
				return false
			}
		},
		open() {
			this.$refs.dialog.open()
		},
		show(stepOrId) {
			this.$refs.bar.show(stepOrId)
		},
	},
}
</script>