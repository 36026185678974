<template>
	<div style="display: flex; gap: 10px; flex-direction: column; width: 320px;" v-if="dateModel">
		<v-date-picker no-title hide-actions elevation="0"
			class="IsoDatePicker"
			v-model="dateModel"
			height="300px"
			:min="min"
			:max="max"
			:disabled="disabled"
			style="width: auto;"
		/>
		<div style="display: flex; gap: 10px; align-items: center; padding: 0 20px 20px 20px;" v-if="time">
			<label style="flex-grow: 1;">{{ $t('text.time') }}</label>
			<v-select variant="outlined" hide-details density="compact" 
				style="max-width: 120px;"
				v-model="timeModel"
				:items="times"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// supports date strings like "2023-12-31T00:00" and "2023-12-31"
		// the rest of the string is ignored, so if a timezone is specified, it will be cut off!
		modelValue: String,
		disabled: { type: Boolean, default: false },
		min: { type: String, default: '2020-01-01' },
		max: { type: String, default: '2030-12-31' },
		time: Boolean,
	},
	data: () => ({
		dateModel: null,
		timeModel: '00:00',
	}),
	computed: {
		times() {
			const r = []
			for (let h = 0; h < 24; h++) {
				for (let m = 0; m < 60; m += 30) {
					let hh = h.toString().padStart(2, '0')
					let mm = m.toString().padStart(2, '0')
					r.push(`${hh}:${mm}`)
				}
			}
			return r
		},
	},
	watch: {
		modelValue(n) {
			this.readIsoString(n)
		},
		dateModel(n) {
			this.update()
		},
		timeModel(n) {
			this.update()
		},
	},
	methods: {
		readIsoString(s) {
			if (!s) {
				const d = new Date()
				s = d.toISOString().substring(0, 10) + 'T00:00'
			}
			// this will convert the given date string into a local date so the date picker will work properly.
			// for example 2023-01-01 -> 2022-12-31T22:00:00.000Z
			this.dateModel = new Date(s.substring(0, 4), s.substring(5, 7) - 1, s.substring(8, 10))
			this.timeModel = s.substring(11, 16) ? s.substring(11, 16) : '00:00'
		},
		update() {
			const d = this.dateModel
			const YYYY = d.getFullYear()
			const MM = (d.getMonth() + 1).toString().padStart(2, '0')
			const DD = (d.getDate()).toString().padStart(2, '0')
			let s = `${ YYYY }-${ MM }-${ DD }`
			if (this.time) s += `T${ this.timeModel }`
			this.$emit('update:modelValue', s)
		},
	},
	mounted() {
		this.readIsoString(this.modelValue)
	},
}
</script>

<style>
.IsoDatePicker .v-picker-title { display: none !important; }
/* we have to style the datepicker much smaller, so 2 of them fit on the screen */
.IsoDatePicker .v-date-picker-month { min-width: 160px; }
.IsoDatePicker .v-date-picker-month__day { height: 20px; width: 20px; }
.IsoDatePicker .v-date-picker-month__day .v-btn { height: 20px; }
.IsoDatePicker .v-date-picker-month__day .v-btn__underlay { height: 20px; }
.IsoDatePicker .v-date-picker-month__day .v-btn--active .v-btn__underlay { background: #333; width: 30px; height: 30px; top: -5px; left: -5px; border-radius: 20px; z-index: 1; }
.IsoDatePicker .v-date-picker-month__day .v-btn--active .v-btn__content { z-index: 2; }
</style>