<template>
	<div class="Test">
		<input type="text" @change="validate" v-model="value" ref="field" />
		<button @click="testAppError($event, 'my error', 'something')">TEST APP ERR</button>
		<button @click="testAppSuccess($event, 'my success', 'something')">TEST APP SUC</button>
	</div>
</template>

<script>
import ChildErrorDispatcher from './ChildErrorDispatcher.vue'

export default {
	mixins: [ ChildErrorDispatcher ],
	data() {
		return {
			value: '',
		}
	},
	methods: {
		validate(e) {
			if (this.value.length < 3) {
				this.childError(e, 'tooShort', { min: 3 })
			}
			else {
				this.clearChildError(e, 'tooShort')
			}
		},
		testErrorBubble(e, error) {
			this.childError(e.target, error)
		},
		testAppError(e, error, detail) {
			this.applicationError(e, error, detail)
		},
		testAppSuccess(e, error, detail) {
			this.applicationSuccess(e, error, detail)
		},
	},
}
</script>

<style scoped>
.Test { border: 1px solid red; padding: 5px; display: flex; gap: 5px; }
.Test > * { border: 1px solid red; padding: 5px; }
</style>