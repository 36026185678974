<template>
	<div class="SectionIndex">
    <div class="section font-weight-bold">
      {{ $t('text.sidebar') }}
    </div>
		<div class="section" v-for="(section, s) of visibleSections" :key="s"
			@click="show(section)"
			:class="{
				error: section.status == 'error'
			}"
		>
			{{ $t('text.' + section.id + 'Title') }}
		</div>
	</div>
</template>

<script>
import eventBus from '@/utils/eventBus.js'

// TODO: with v-show on the steps our mechanism (CFR, ..) does not work anymore:
//       on load of the page it shows ALL of them and when we change the step it hides them all.
//       we need to find a new way to hide/show the sections in the sidebar.
//       should we use "groups" on the sections?
//          can we observe which section the registered comp is in?
//       is there a way to get called when the section is actually shown?
//       should we use a directive for that?

export default {
	name: 'SectionIndex',
	data: () => ({
		sections: [],
		modelValue: null,
		activeStepId: null,
	}),
	computed: {
		visibleSections() {
			return this.sections.filter(s => s.stepId == this.activeStepId)
		},
	},
	methods: {
		register(section) {
			// on double-registration we clear the sections - this is probably a hot-reload
			//if (this.sections.find(s => s.id == section.id)) this.sections.splice(0, this.sections.length)

			this.sections.push(section)
		},
		show(section) {
			this.$emit('update:modelValue', section.id)
			this.$emit('show', section.id)
			eventBus.$emit('section-show', section.id)
		},
		onStep(stepId) {
			this.activeStepId = stepId
			//this.sections.splice(0, this.sections.length)
		},
	},
	mounted() {
		eventBus.$on('section-register', this.register)
		eventBus.$emit('section-cfr', this)
		eventBus.$on('step-show', this.onStep)
	},
	unmounted() {
		eventBus.$off('section-register', this.register)
		eventBus.$off('step-show', this.onStep)
	},
}
</script>

<style scoped>
.section { cursor: pointer; padding: 10px 10px 10px 25px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.section:hover { background: #f9f9f9; }
.error { color: #f34545; }
</style>