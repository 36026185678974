<template>
	<div style="display: flex; gap: 10px; align-items: center;">
		<span style="flex-shrink: 0; cursor: ns-resize;"><v-icon>mdi-drag-vertical</v-icon></span>
		<span style="flex-shrink: 0;"><v-checkbox hide-details v-model="offer.selected" @update:modelValue="$emit('input', offer)" /></span>
		<span style="flex-grow: 1;">
			<v-icon v-if="offerMenuItemType == 'ticket'" style="margin-right:10px;margin-top:-3px">mdi-ticket</v-icon>
			<v-label v-if="offer.fields">{{truncate(offer.fields.title[selectedLocale], 250, '...') }}</v-label>
		</span>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
  mixins: [Common],
  props: {
		offer: {
			fields: { title: { de: '' } },
			selected: false
		},
		offerMenuItemType: String,
	},
}
</script>

<style scoped>
</style>