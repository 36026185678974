<template>
	<!-- TODO: support token somehow in AppHost -->
	<AppHost v-if="ok"
		:locale="locale"
		:token="token"
		:client="client"
		:selectedComponent="app"
		:_getUser="_getUser"
		:_getIdentity="_getIdentity"
		:_userHasAppAccess="_userHasAppAccess"
	/>
</template>

<script>
import AppHost from './AppHost.vue'
import jwt_decode from 'jwt-decode'

// test url with demo token
// http://localhost:8080/sa-app/billingManager?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXAiOiJBQ1QtT1JEIiwiYXBwcyI6WyJvcmRlcnMiLCJiaWxsaW5nLW1hbmFnZXIiXSwibmFtIjoiSG9jaHppbGxlcnRhbGVyIEpvZGVsYnViZW4iLCJpYXQiOjE1MTYyMzkwMjIsImV4cCI6MjAwMDAwMDAwMCwicHNwIjoiNTQ1IiwiZG9tIjoiemliIiwib3JkIjoiYWFlNGQ0ZjktNGQzMy00MTY5LTgzNDMtMjI4NWU3NDRhYmY1IiwiYWN0cyI6WyJOT19TSE9XIiwiVklFVyIsIkNBTkNFTCIsIkZVTEZJTEwiXX0.sOI-RLO-lWavTmRkurOiWwYGy4KgNqQmyEIDsdcLn4o

export default {
	components: { AppHost },
	props: {
		locale: String,
	},
	data: () => ({
		ok: false,
		token: null,
		client: null,
		app: null,
	}),
	methods: {
		async getClient(clientId, token) {
			const res = await this.$httpGet(`/client/${clientId}`, undefined, { token })
			// TODO: this side effect is not ideal - can we get away without committing to store?
			//await this.$store.commit('setSelectedClient', res.client)
			return res.client
		},
		async getApplications(token) {
			const allApplications = await this.$httpGet(`/applications`, undefined, { token })
			// TODO: this side effect is not ideal - can we get away without committing to store?
			//this.$store.commit('setAllApplications', allApplications)
			return allApplications
		},
		authError(error) {
			console.log('AHS.authError', error)
//			this.$router.push('/')
		},
		// delegate mys api
		_getUser() {
			return undefined
		},
		_getIdentity() {
			return this.token
		},
		_userHasAppAccess() {
			return true
		},
	},
	async mounted() {
		// TODO: test if this also works when we have a logged in user!

		this.token = this.$route.query.token
		if (!this.token) return this.authError('no token given')

		const decoded = jwt_decode(this.token)
		console.log('AHS decoded', decoded)
		if (decoded.exp < Date.now() / 1000) return this.authError('token expired')

// TODO: we dont have a clientId
// TODO: load client by token.dom? (-> client.billingDomainId)
//		const clientId = decoded.client
//		if (!clientId) return this.authError('no client in token')
//		this.client = await this.getClient(clientId, this.token)
//		console.log('AHS this.client', this.client)

		const allApplications = await this.getApplications(this.token)
		const pathname = window.location.pathname.replace('/sa-app/', '/app/').replace(/\/+$/, '')
		this.app = allApplications.find(app => app.fields.link?.de == pathname)
		console.log('AppHost auto-selected app', this.app)
		this.$store.commit('setSelectedComponent', this.app)

		if (!decoded.apps) return this.authError('token does not specify apps')
		const apps = decoded.apps
		// TODO: we should introduce a key field on the appComponent
		// TODO: remove the apps[0] fallback (benefit of doubt) once the key field is introduced
		const appKey = this.app.fields.key?.de ?? this.app.fields.appConfig.de?.appType ?? apps[0]
		if (!apps.includes(appKey)) return this.authError('token does not permit access to ' + appKey)

		// SP | PEAK-SP
		const userType = decoded.type ?? 'SP'
		// TODO "sub" is "ANONYMOUS" when type = PEAK-SP
//		const serviceProviderId = decoded.sub
//		if (!serviceProviderId) return this.authError('no sub in token')
		// TODO: load service provider if type is 'SP'? -> where to put it?
		const peakServiceProviderId = decoded.psp
		console.log('AHS peakServiceProviderId', peakServiceProviderId)

		// TODO: token auth, pass along params, etc
		// TODO: validate signature with public key at the server
		this.ok = true
	},
}
</script>

<style scoped>
</style>