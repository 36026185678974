<template>
	<div class="Comment" :class="{ byMe: modelValue.createdBy == currentUserEmail }">
		<div class="coords">
			{{ modelValue.createdBy }}
			{{ modelValue.createdAt }}
		</div>
		<div class="area" v-if="!editingComment" @click="edit">
			{{ modelValue.title }}
		</div>
		<TextAreaAutosizing v-model="editingComment.title" v-if="editingComment">
			<button @click="cancel"><v-icon>mdi-close</v-icon></button>
			<button @click="updateComment" v-if="editingComment.title != ''"><v-icon>mdi-check</v-icon></button>
			<button @click="deleteComment" v-if="editingComment.title == ''"><v-icon>mdi-delete</v-icon></button>
		</TextAreaAutosizing>
	</div>
</template>

<script lang="ts">
import TextAreaAutosizing from './TextAreaAutosizing.vue'
import CommentExecutive, { Comment } from '../../../../shared/CommentExecutive'

export default {
	components: { TextAreaAutosizing },
	props: {
		modelValue: Object,
	},
	data: () => ({
		editingComment: null,
	}),
	computed: {
		currentUserEmail() {
			return this.$store.state.loggedInUser?.fields?.email?.de
		},
	},
	methods: {
		edit() {
			this.editingComment = JSON.parse(JSON.stringify(this.modelValue))
		},
		cancel() {
			this.editingComment = null
		},
		async updateComment() {
			let exe = new CommentExecutive({}, this)
			await exe.updateComment(this.editingComment)
			this.modelValue.title = this.editingComment.title
			this.editingComment = null
		},
		async deleteComment() {
			let exe = new CommentExecutive({}, this)
			await exe.deleteComment(this.modelValue.id)
			this.$emit('delete', this.modelValue.id)
		},
	},
}
</script>

<style scoped>
.Comment {}
.coords { color: #888; font-size: 0.8em; }
.area { padding: 10px; font: inherit; white-space: pre-wrap; overflow-wrap: break-word; }
.area { background: #eee; border-radius: 4px; }
.byMe .area { background: #ddecf6; }
</style>