import ProductContentTechnical from '../api/src/ProductContentTechnical'
import Executive from './Executive'
import { Entry, I18n, ResultMessage } from './entry'
import { Field } from './entry-decorators'

export class Image {
	id: string
	url: string
}

enum ExcludeFromOptions {
	OnSiteSearch = 'on-site search',
	Map = 'map',
	DynamicGrid = 'dynamic grid',
}

export class Content {
	id: string
	name: I18n<string>
	shortDescription: I18n<string>
	description: I18n<string>
	images: Image[]
	excludeFrom: ExcludeFromOptions[]
	serviceProviderId: string
	websiteIds: number[]
	exists: boolean
}

export class ContentStatus {
	contentValid: boolean
	contentId: string
	couponValid: boolean
	couponErrors: string[]
}

export default class ProductContentExecutive extends Executive {
	public clientId: string
	technical: ProductContentTechnical

	initServer() {
		if (!this.technical)
			this.technical = new ProductContentTechnical()
	}

	// async getProductContents(ids: string[]): Promise<{ [ id: string ]: Content }> {
	// 	return this.technical.getProductContents(this.clientId, ids)
	// }

	async getProductContent(id: string): Promise<Content> {
		return this.technical.getProductContent(this.clientId, id)
	}

	async getPeakProductContentForSku(sku: string): Promise<Content> {
		return this.technical.getPeakProductContentForSku(this.clientId, sku)
	}

	async updateProductContent(content: Content): Promise<void> {
		return this.technical.updateProductContent(this.clientId, content)
	}

	async updatePeakProductContentId(sku: string, contentId: string): Promise<void> {
		return this.technical.updatePeakProductContentId(this.clientId, sku, contentId)
	}

	async validateAddedValueProductsForSkus(skus: string[]): Promise<{ [ sku: string ]: ContentStatus }> {
		if (!skus?.length) return {}
		return this.technical.validateAddedValueProductsForSkus(this.clientId, skus)
	}
}