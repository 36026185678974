<template>
	<v-row align="center" justify="center" class="StepBar">
		<ul v-if="steps?.length" class="steps">
			<li v-for="(step, index) of steps" :key="step.key" @click="setStep(step)" :data-cy="step.datacy"
				:class="{
					step: true,
					clickable,
					complete: step.status == 'complete',
					error: step.status == 'error',
				}"
			>
				<span class="divider" v-if="showDividers === true && index < steps.length-1"></span>
				<span class="icon">
					<v-icon v-if="step.icon" size="14" color="white">{{ step.icon }}</v-icon>
				</span>
				<span class="label">{{ step.label }}</span>
			</li>
		</ul>
	</v-row>
</template>

<script>
export default {
	props: {
		steps: Array,
		clickable: Boolean,
		showDividers: { type: Boolean, default: true },
	},
	watch: {
		steps: {
			deep: true,
// TODO: this breaks the UI
//			immediate: true,
			handler() {
				this.$forceUpdate()
			}
		}
	},
	methods: {
		setStep(step) {
			if (this.clickable === true) {
				this.$emit('step', step.key)
			}
		}
	},
	mounted() {
		this.$forceUpdate()
	},
}
</script>

<style scoped>
.StepBar { height: 60px; width: auto; padding-left: 320px; border-bottom: 1px solid #ddd; z-index: 99; }
@media screen and (max-width: 950px) { .StepBar { padding-left: 0; } }

ul { text-align: justify; position: relative; overflow: hidden; margin: 0; padding: 0; display: flex; gap: 10px; }
li { position: relative; width: 20%; text-align: center; padding-bottom: 20px; min-width: 120px; cursor: default; display: flex; flex-direction: column; gap: 5px;  }

.step { --col: #737373; color: var(--col); flex: 1 1 0; margin-top: 4px; position: relative; }
.step .icon { background: var(--col); width: 20px; height: 20px; border-radius: 10px; display: flex; justify-content: center; align-items: center; margin: auto; }
.step .label { font-size: 12px; white-space: nowrap; line-height: -15px; position: absolute; left: 0; right: 0; bottom: 0; }
.divider { border-top: 3px solid var(--col); position: absolute; top: 9px; width: 100%; left: 50% }

.complete { --col: #7BC043; }
.error { --col: #ff0022; }
.v-icon { position: relative; }
.clickable { cursor: pointer; }
</style>