<template>
	<Application :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail">
		<template #navbar>
			<Navbar :fetchData="getPackages" :onSearch="onSearch" :onClear="onSearch">
				<template #add-button>
					<AddNewTypeDialog ref="addNewPackageDialogRef" :showLoader="showLoader" type="package" />
					<v-btn class="blueButton" size="small" elevation="0" data-cy="addPackage"
						@click="() => $refs.addNewPackageDialogRef.getServiceTypes()">
						<v-icon>mdi-plus</v-icon>
						<span class="d-none d-lg-inline">{{ $t('text.addPackage') }}</span>
					</v-btn>
					<PackageFilters :onApplyFilter="applyFilters" />
				</template>
			</Navbar>
		</template>
		<PackagesTable
			v-model:items="packages"
			:total="total"
			:limit="limit"
			:offset="offset"
			@update:limit="updateLimit"
			@update:offset="updateOffset"
			style="margin-top: 64px;"
		/>
	</Application>
</template>

<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Common from '../../../mixins/Common.vue'
import Application from '../Application.vue'
import Navbar from './Navbar.vue'
import PackagesTable from './tables/PackagesTable.vue'
import AddNewTypeDialog from '../serviceDesigner/AddNewTypeDialog.vue'
import PackageFilters from './PackageFilters.vue'

export default {
	name: 'PackagesView',
	components: { Application, Navbar, PackagesTable, AddNewTypeDialog, PackageFilters },
	mixins: [Common],
	data() {
		return {
			packages: [],
			loading: false,
			errorTitle: '',
			errorDetail: '',
			limit: 10,
			offset: this.$store.state.offset,
			total: 0,
			filters: {},
		}
	},
	methods: {
		showLoader(value = false) {
			this.loading = value
		},
		constructPayload(data) {
			return omitBy(data, value => isEmpty(value?.toString()))
		},
		async getPackages({ total } = { total: false }) {
			try {
				this.showLoader(true)
				const clientId = this.$store.state.selectedClient.sys.id
				const searchString = this.$store.state.searchString

				const filters = this.constructPayload({ ...this.filters })
				
				const payload = this.constructPayload({ limit: this.limit, offset: Math.floor(this.offset / this.limit), searchString, ...filters })

				const promises = [this.$httpPost(`/packageTravel/packages?clientId=${clientId}`, payload)]

				if (total) promises.push(this.$httpPost(`/packageTravel/packagesCount?clientId=${clientId}`, this.constructPayload({ searchString, ...filters })))

				const [{ packages }, totalCount] = await Promise.all(promises)

				if (total) this.total = totalCount?.packages

				this.packages = packages.map(item => ({ ...item.fields, id: item?.sys?.id }))
			} catch (error: any) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			} finally {
				this.showLoader(false)
			}
		},
		updateLimit(limit) {
			this.offset = 0
			this.limit = limit
			this.getPackages()
		},
		updateOffset(offset) {
			this.offset = offset
			this.getPackages()
		},
		onSearch() {
			this.offset = 0
		},
		async applyFilters(filters) {
			this.offset = 0
			this.filters = { ...this.filters, ...filters }
			this.getPackages({ total: true })
		},
	},
	created() {
		FreshdeskWidget.methods.prefill('MyServices')
	},
	async mounted() {
		this.getPackages({ total: true })
	},
}
</script>
