<template>
	<Application :name="$t('text.registrationTitle')" data-cy="registration-view" :loading="loading" :errorDetail="errorDetail" v-model:errorTitle="errorTitle" v-model:successTitle="successTitle" :successDetail="successDetail" class="main">
		<template #navbar>
			<button @click="$router.push('/')">
				<v-icon>mdi-arrow-left</v-icon>
			</button>
		</template>
    <v-row align="center" justify="center">
      <v-card id="card" class="elevation-12">
        <v-card-text style="margin:0px;padding:0px">
          <h1>{{$t('text.forgotPassword')}}</h1>
          <p class="subTitle" v-html="$t('text.forgotPasswordText')"/>

          <v-row class="field left-border flex-column">
            <div>
              <v-card elevation="0">
                <v-label>{{$t('text.email')}}</v-label>
                <v-text-field variant="outlined" density="compact"
                  v-model="email"
                  :error-messages="emailErrors"
                  @keyup.enter="forgotPassword()"
                  required></v-text-field>
              </v-card>
            </div>
            <div>
              <v-btn class="greenButton" id="btnLogin" variant="flat" theme="dark" @click="forgotPassword()">{{ $t('text.sendToMe') }}</v-btn>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { useVuelidate } from "@vuelidate/core"
import { required, email } from "@vuelidate/validators"
import eventBus from '@/utils/eventBus.js'
import Application from '../applications/Application.vue'

export default {
	name: 'ForgotPassword',
	components: { Loading, Application },
  validations: {
    email: { required, email },
    password: { required }
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() { return {
    showLogin: false,
    selectedLocale: '',

    isLoading: false,
    fullPage: true,

    errorTitle: '',
    errorDetail: '',
    successTitle: '',
    successDetail: '',
    errorAlertVisible: false,
    successAlertVisible: false,

    email: '',
    password: ''
  }},

  computed: {
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail;
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail;
    },
    emailErrors () {
      const errors = []

      if (!this.v$.email.$dirty) return errors

      if (!this.v$.email.email) errors.push(this.$t('text.emailInvalid'))
      if (this.v$.email.required.$invalid) errors.push(this.$t('text.emailRequired'))

      return errors
    }
  },
  
  watch: {
    errorAlertVisible() {
      if (this.errorAlertVisible) {
        setTimeout(this.closeAlert, 3000)
      }
    },
    successAlertVisible() {
      if (this.successAlertVisible) {
        setTimeout(this.closeAlert, 3000)
      }
    }
  },

  mounted() {
    this.showLogin = false
  },

  beforeUnmount () {
    eventBus.$off('show-dashboard')
  },

  methods: {
    closeAlert() {
      if (this.errorAlertVisible) {
        this.errorAlertVisible = false;
      } else if (this.successAlertVisible) {
        this.successAlertVisible = false;
        this.$router.push("/");
      }
    },

    /* Server Functions */
    forgotPassword() {
      
      if (this.emailErrors.length === 0) {
        this.isLoading = true;

        this.$httpPost(`/forgot-password?email=${this.email}`)
        .then(res => {
          this.isLoading = false;
          this.successTitle = this.$t('text.SUCCESS'); 
          this.successDetail = this.$t('text.forgotPasswordSuccess'); 
          this.successAlertVisible = true;
        }).catch(error => {
          this.isLoading = false;

          this.errorTitle = this.$t('text.ERROR');  
      
          if (error.response) {
            if (error.response.data.code === "1003") {
              this.errorTitle = this.$t('text.loginPendingErrorTitle'); 
              this.errorDetail = this.$t('text.loginPendingErrorDetail'); 
            } else {
              if (error.response.data.error) {
                if (error.response.data.error === "Invalid E-mail Address and/or Password") {
                  this.errorDetail = this.$t('text.loginInvalidCredentialsError'); 
                } else {
                  this.errorDetail = error.response.data.error;
                }
              } else {
                this.errorDetail = this.$t('text.generalError'); 
              }
            } 
          } else {
            this.errorDetail = error;
          }

          this.errorAlertVisible = true;
        });
      }
    }
  }
};
</script>

<style scoped>
/* !! DON'T move the button style to the App.vue - it will mess up all the other screens !! */
.main {
  background-size: cover !important;
}
</style>
