<template>
	<StepperDialog ref="dialog" v-model="model" group="A" :order="[ 'a1', 'a2' ]">
		<button @click="cond = !cond">TOGGLE</button>
		<Step id="a1" group="A" v-if="cond">
			111
		</Step>
		<Step id="a2" group="A">
			222
		</Step>
	</StepperDialog>
</template>

<script>
import StepperDialog from '../../components/common/StepperDialog.vue'
import Step from '../applications/packageDesigner/Step.vue'

export default {
	components: { StepperDialog, Step },
	data: () => ({
		model: {},
		cond: true,
	}),
	mounted() {
		this.$refs.dialog.open()
	},
}
</script>

<style scoped>

</style>