<template>
	<Application :errorDetail="errorDetail" v-model:errorTitle="errorTitle" :successDetail="successDetail" v-model:successTitle="successTitle" :loading="loading"
		:class="{ BusinessProfile: true }" :isSideBarFixed="isSideBarFixed"
	>
		<template #navbar>
			<v-btn id="btnBack" elevation="0" style="background-color:transparent; min-width: 40px;" @click="goback()">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<h1>{{ serviceProvider.fields.title[serviceLocale] }}</h1>
		</template>

		<SideBar
			v-if="serviceProviderIsLoaded"
			:actions="sidebarActions"
			:translations="translationStatus"
			:clientStatuses="clientStatuses"
			:navigation="sections"
			:disabled="loading"
			@action="handleAction($event)"
			@edit:translations="$refs.translationWizardDialog?.open"
			displayEditTranslations
		/>

		<div class="content" style="margin-top: 60px;">
			<StepBar :steps="steps" :clickable="true" :showDividers="false" @step="handleStep($event)"
				style="position: fixed; right: 0; left: 0; top: 76px; background: white;"
			/>

			<!-- Step 1 -->
			<div style="width: 100%" v-show="currentStep===1">
				<!-- Checklist -->
				<span v-if="userIsOperator && featureEnabled('service-provider-checklist')" id="checklistInfo" />
				<Checklist v-if="serviceProviderIsLoaded" ref="checklistRef" :serviceProvider="serviceProvider" component="BusinessProfile" />
				
				<!-- My Information -->
				<span id="myInfo" />
				<MyInformation v-if="serviceProviderIsLoaded" ref="myInfo" :serviceProvider="serviceProvider" :updateModel="updateModel" @update-business-name="updateBusinessName($event)"/>

				<!-- Contact Person Info -->
				<span id="contactPersonInfo"/>
				<ContactPersonInfo v-if="serviceProviderIsLoaded" ref="contactPersonInfo" :serviceProvider="serviceProvider" :updateModel="updateModel"/>

				<!-- Business Info -->
				<span id="businessInfo"/>
				<BusinessHoursNew
					v-if="serviceProviderIsLoaded"
					ref="businessInfo"
					:showCopyOption="false"
					:businessHours="serviceProvider.fields.businessHours?.de"
					:averageDurationOfStayInMinutes="serviceProvider.fields.averageDurationOfStayInMinutes.de"
					:updateModel="updateModel"
					app="BusinessProfile"
					@toggle-business-hours="toggleHaveBusinessHours"
					@average-duration-changed="averageDurationChanged"
				/>

				<!-- Public Information -->
				<span id="publicInfo"/>
				<PublicInfo v-if="serviceProviderIsLoaded" ref="publicInfo" :serviceProvider="serviceProvider" :updateModel="updateModel" @isLoading="(val) => { loading = val}"/>

				<!-- Offer Menu -->
				<span id="offerMenu" />
				<OfferMenu v-if="serviceProviderIsLoaded" :updateModel="updateModel" ref="offerMenu"></OfferMenu>

				<!-- User Account -->
				<div v-if="userAccountVisible">
					<span id="mainUserAccount" />
					<UserAccount v-if="serviceProviderIsLoaded" ref="mainUserAccount" :serviceProvider="serviceProvider" :updateModel="updateModel" />
				</div>

				<!-- Application Access -->
				<div v-if="userIsOperator">
					<span id="applicationAccessInfo" />
					<ApplicationComponentWrapper v-if="serviceProviderIsLoaded" ref="applicationAccessInfo" :serviceProvider="serviceProvider" :updateModel="updateModel" />
				</div>

				<!-- Trust Level -->
				<div v-if="userIsOperator && serviceProviderIsLoaded">
					<span id="trustLevel" />
					<TrustLevel data-cy="trustLevel" ref="trustLevel" :serviceProvider="serviceProvider" :updateModel="updateModel" />
				</div>

				<!-- Link Service Providers -->
				<div v-if="userIsOperator && serviceProviderIsLoaded">
					<span id="linkServiceProviders" />
					<ServiceProviderLink
						v-model="serviceProvider.fields.linkedServiceProviders.de"
						:serviceProvider="serviceProvider"
						@loading="(value) => loading = value"
						@changed="changed.linkServiceProvidersChanged = true; setChanged()"
					/>
				</div>

				<!-- Sales Channels -->
				<span id="salesChannelsInfo"/>
				<ClientAssignments
					v-if="serviceProviderIsLoaded"
					ref="salesChannelsInfo"
					data-cy="clientAssignments"
					app="BusinessProfile"
					:propClientAssignments="serviceProvider.fields.clientAssignments.de"
					:serviceProvider="serviceProvider"
					:updateModel="updateModel"
					:disclosureTitle="$t('text.salesChannels')"
					@update-assignment="setChanged()" />

				<IfFeatureFlag flag="FEATURE_SKIRENTAL_SPECIFIC_SETTINGS">
					<span id="skirentalSettingsForSp"/>
					<SkirentalSettingsForSp
						v-if="serviceProviderIsLoaded"
						ref="skirentalSettingsForSp"
						:disclosureTitle="$t('text.skirentalSpecificSettings')"
						:skirentalSettingsForSpLocked="false"
					/>
				</IfFeatureFlag>

				<IfFeatureFlag flag="FEATURE_SKISCHOOL_SPECIFIC_SETTINGS">
					<span id="skischoolSettingsForSp"/>
					<SkischoolSettingsForSp
						v-if="serviceProviderIsLoaded"
						ref="skischoolSettingsForSp"
						:disclosureTitle="$t('text.skischoolSpecificSettings')"
						:skischoolSettingsForSpLocked="false"
					/>
				</IfFeatureFlag>

				<!-- Audit Log -->
				<div v-if="serviceProviderIsLoaded && showAuditLogs">
					<span id="auditLog"/><br/>
					<AuditLog ref="auditLog" :entryId="serviceProvider.sys.id" contentType="serviceProvider" :reloadLogs="reloadLogs" />
				</div>

				<!-- TODO: having the 'error' prop here, this also means the validation has to happen here?
					maybe we should have a ClientAssignmentsDisclosure to wrap that concern?
						or can a slotted comp talk to its child to get validation info?
					:error="sectionMessage.error" :message="sectionMessage.message"
					ref="SECTION_salesChannelsInfo"
				-->
				<!-- :lock="clientAssignmentLocked" -->
				<!--
				<Disclosure :title="$t('text.salesChannels')" data-cy="salesChannelsInfo">
					<ClientAssignmentsNEW
						v-if="serviceProviderIsLoaded"
						v-model="serviceProvider.fields.clientAssignments.de"
					/>
				</Disclosure>
				-->

				<div class="SystemInfo">
					<div>{{ $t('text.serviceProvider') }}: <span data-cy="spId">{{ serviceProvider.sys ? serviceProvider.sys.id : '' }}</span> / {{ serviceProviderName }}</div>
					<div>Peak ID: {{ peakId }}</div>
				</div>
			</div>

			<!-- Step 2 -->
			<div class="pa-3" style="width: 100%" v-show="currentStep===2">
				<!-- Categories -->
				<div class="pa-0">
					<span id="categoryInfo"/>
					<ProductCategoriesSelector v-if="serviceProviderIsLoaded" app="BusinessProfile" ref="categoryInfo" 
						:data-payload="serviceProvider"
						:service-provider="serviceProvider"
						:workflow-status="statusClient"
						:updateModel="updateModel"
						data-cy="productCategories"
					/>
				</div>
			</div>

			<!-- Step 3 -->
			<div class="pa-3" style="width: 100%" v-show="currentStep===3">
				<!-- Banking Info -->
				<span id="bankingInfo"/>
				<BankingInfo v-if="serviceProviderIsLoaded" ref="bankingInfo" :serviceProvider="serviceProvider" :updateModel="updateModel"/>
				
				<!-- Commissions Info -->
				<div class="pt-5" v-if="serviceProviderIsLoaded && userIsOperator">
					<span id="commissions"/>
					<CommissionsOverview ref="commissionsInfo"
						v-if="serviceProviderIsLoaded && serviceProvider.sys.id.length && clientCA?.fields"
						:serviceProvider="serviceProvider"
						:clientAssignment="clientCA"
						:commissions="commissions"
						:updateModel="updateModel" 
						@update="updateCommissions"
						@update-ca="updateClientAssignment"
						@delete="deleteCommission"
					/>
				</div>
			</div>
		</div>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			:confirmLabel="$t('text.delete')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="onDeleteConfirmed"
			:cancel-handler="onDeleteCancelled"
			:showClose="false"
			:title="$t('text.deleteServiceProvider')"
			height="360px"
			width="540px"
		>
			<template #content>
				<v-row justify="center" align="center" style="padding:10px">
					<v-row justify="center" align="center" style="padding: 10px">
						<v-col class="warning-icon-col">
							<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
						</v-col>
						<v-col class="text-col">
							<span class="dialogText">{{$t('text.confirmServiceProviderDelete')}}</span>
						</v-col>
					</v-row>
				</v-row>
			</template>
		</Dialog>

		<!-- Confirm going back -->
		<Dialog ref="unsavedChangesDialog"
			data-cy="unsavedChangesDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="confirmUnsavedChanges"
			:cancel-handler="cancelUnsavedChanges"
			:showClose="false"
			:title="$t('text.unsavedChanges')"
			height="360px"
			width="540px"
		>
			<template #content>
				<v-row justify="center" align="center" style="padding: 10px">
					<v-col class="warning-icon-col">
						<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
					</v-col>
					<v-col class="text-col">
						<span class="dialogText">{{$t('text.unsavedChangesText')}}</span>
					</v-col>
				</v-row>
			</template>
		</Dialog>

		<!-- Change Homebase -->
		<Dialog ref="changeHomebaseDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="confirmHomebaseChange"
			:cancel-handler="cancelHomebaseChange"
			:showClose="false"
			:title="$t('text.changeHomebase')"
			height="600px"
			width="800px"
			:is-valid="isHomebaseClientSelected"
		>
			<template #content>
				<HomebaseSelector ref="homebaseSelector" data-cy="homeBase" @client-selected="() => isHomebaseClientSelected = true"/>
			</template>
		</Dialog>
		<IfFeatureFlag flag="FEATURE_TRANSLATION_WIZARD">
			<TranslationsIncompleteDialog ref="translationsIncompleteDialog" :translations="translationStatus" :handleConfirm="confirmUnsavedChanges" :handleCancel="handleEditTranslations" />
			<TranslationWizardDialog ref="translationWizardDialog" :fields="translatableFields" />
		</IfFeatureFlag>
	</Application>
</template>

<script>
import Loading from 'vue-loading-overlay'
import OfferMenu from '@/components/offerMenu/OfferMenu.vue'
import ApplicationComponent from '@/components/common/ApplicationComponent.vue'
import ProductCategoriesSelector from '@/components/categories/ProductCategoriesSelector.vue'
import ClientAssignments from '@/components/clientAssignment/ClientAssignments.vue'
import Common from '@/mixins/Common.vue'
import Alert from '@/components/common/Alert.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Dialog from '@/components/common/Dialog.vue'
import BusinessHoursNew from '@/components/businesshours/BusinessHoursNew.vue'
import HomebaseSelector from '@/components/businessProfile/HomebaseSelector.vue'
import SideBar from "@/components/common/SideBar.vue"
import StepBar from "@/components/common/StepBar.vue"
import Checklist from '@/components/checklist/Checklist.vue'
import MyInformation from '@/components/businessProfile/MyInformation.vue'
import ContactPersonInfo from '@/components/businessProfile/ContactPersonInfo.vue'
import BankingInfo from '@/components/businessProfile/BankingInfo.vue'
import PublicInfo from '@/components/businessProfile/PublicInfo.vue'
import ApplicationComponentWrapper from '@/components/businessProfile/ApplicationComponentWrapper.vue'
import UserAccount from '@/components/businessProfile/UserAccount.vue'
import TrustLevel from '@/components/businessProfile/TrustLevel.vue'
import ServiceProviderLink from '@/components/businessProfile/ServiceProviderLink.vue'
import CommissionsOverview from '@/components/commissions/CommissionsOverview.vue'
import eventBus from '@/utils/eventBus.js'
import ClientAssignmentsNEW from '@/components/clientAssignment/ClientAssignmentsNEW.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Application from '../Application.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import SkirentalSettingsForSp from "@/components/businessProfile/SkirentalSettingsForSP.vue"
import IfFeatureFlag from "@/components/ifFeatureFlag/IfFeatureFlag.vue"
import SkischoolSettingsForSp from "@/components/businessProfile/SkischoolSettingsForSP.vue"
import TranslationsIncompleteDialog from '@/components/TranslationsIncompleteDialog.vue'
import TranslationWizardDialog from '@/components/TranslationWizardDialog.vue'
import TranslateableField from '../../../components/fields/TranslateableField.vue'
import AiContext from '../../../mixins/AiContext.vue'

export default {
	name: 'BusinessProfile',
	components: {
		SkischoolSettingsForSp, IfFeatureFlag, SkirentalSettingsForSp, SideBar, Loading, OfferMenu, ApplicationComponent, ProductCategoriesSelector, ClientAssignments, Alert,
		LanguageFlag, Dialog, HomebaseSelector, Checklist, MyInformation, ContactPersonInfo, BankingInfo, PublicInfo, ApplicationComponentWrapper, UserAccount, TrustLevel,
		ServiceProviderLink, StepBar, CommissionsOverview, ClientAssignmentsNEW, Disclosure, Application, AuditLog, BusinessHoursNew, TranslationsIncompleteDialog,
		TranslationWizardDialog, TranslateableField
	},
	mixins: [ Common, AiContext ],
	provide: {
		activePage: 'BusinessProfile',
	},
	data() {
		return {
			gobackToDashboard: false,
			submitButtonTheme: 'gradientButton',
			
			loading: false,
			errorTitle: '',
			errorDetail: '',
			successTitle: '',
			successDetail: '',
			runValidation: false,
			updateModel: false,

			serviceProviderIsLoaded: false,

			isApproval: false,
			statusMap: {},
			statusClient: null,
			statusClientHasMultiple: false,
			manuallyUnRestricted: false,
			sections: [],

			isHomebaseClientSelected: false,

			originalServiceProvider: {},

			// TODO: use the executive layer to initialize a new ServiceProvider from the backend so that we do not have to maintain this dummy object
			serviceProvider: {
				sys: {
					id: ''
				},
				fields:{
					title: {de:''},
					faqGroup: {de:''},
					longDescription:{de:''},
					shortDescription:{de:''},
					importantInformation:{de: ''},
					location: {de: {lat:'',lon:''}},
					contactPersonName: {de: ''},
					contactPersonEmail: {de: ''},
					contactPersonPhone: {de: ''},
					language: { de: '' },
					communicationMail: { de: '' },
					clientAssignments: { de: [] },
					accountNumber: { de: '' },
					bic: { de: '' },
					iban: { de: '' },
					erpContractorId: { de: '' },
					trustLevel: { de: '1' },
					workflowStatus: { de: '' },
					businessHours: { de:[] },
					imageGallery: { de: [] },
					mainUserAccount: { fields: { applications: { de: [] } } },
					averageDurationOfStayInMinutes: { de: 0 },
					mobileNumber: { de: '' },
				}
			},

			activeBtn:'',

			times: [],
			fromMenuOpened: [false],
			toMenuOpened: [false],

			showPickerFrom: false,
			showPickerTo: false,
			showMediaViewer: false,
			idCounter: 1,
			currentStep: 1,

			clientCA: null,
			commissions: [],

			steps: [
				{key: 1, label: this.$t('text.bpStepGeneral'), icon: 'mdi-cog', status: 'complete', datacy: 'bp-step-general'},
				{key: 2, label: this.$t('text.bpStepMarketplace'), icon: 'mdi-storefront', status: 'incomplete', datacy: 'bp-step-marketplace'},
				{key: 3, label: this.$t('text.bpStepCommissions'), icon: 'mdi-cash-multiple', status: 'incomplete', datacy: 'bp-step-commissions'}
			],

			reloadLogs: false,
			changed: {},
		}
	},
	computed: {
		translatableFields() {
			return [
				{ typeName: 'BusinessProfile', fieldName: 'title', value: this.serviceProvider?.fields?.title },
				{ typeName: 'BusinessProfile', fieldName: 'longDescription', value: this.serviceProvider?.fields?.longDescription },
				{ typeName: 'BusinessProfile', fieldName: 'shortDescription', value: this.serviceProvider?.fields?.shortDescription },
				{ typeName: 'BusinessProfile', fieldName: 'importantInformation', value: this.serviceProvider?.fields?.importantInformation },
			]
		},
		serviceProviderName() {
			return this.serviceProvider?.fields?.title?.[ this.serviceLocale ]
				?? this.serviceProvider?.fields?.title?.de
				?? ''
		},
		peakId() {
			for (const ca of this.serviceProvider?.fields?.clientAssignments?.de ?? []) {
				if (ca?.fields?.client?.de?.sys?.id === this.$store.state.selectedClient.sys.id)
					return ca.fields.externalId?.de ? ca.fields.externalId.de : ''
			}
			return ''
		},
		isCreate() {
			return !this.$store.state.selectedServiceProvider?.sys?.id
		},
		userAccountVisible() {
			if (!this.userIsOperator) return false
			if (this.isCreate) return true
			return !this.serviceProvider.fields.mainUserAccount
		},
		steps2() {
			return [
				{key: 1, label: this.$t('text.bpStepGeneral'), icon: 'mdi-cog'},
				{key: 2, label: this.$t('text.bpStepMarketplace'), icon: 'mdi-storefront'},
				{key: 3, label: this.$t('text.bpStepCommissions'), icon: 'mdi-cash-multiple'}
			]
		},
		//Sidebar Values
		sidebarActions() {
			let actions = []

			if (!this.userIsOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveInformation'), function: 'updateProfile', params: 'update', datacy: 'update'})
			}
			
			if (this.userIsOperator) {
				actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveInformation'), function: 'updateProfile', params: this.statusClient != null ? 'update' : 'approve', datacy: 'update'})

				if (!!this.statusMap['pending'] || !!this.statusMap['declined'] || !!this.statusMap['reapprove']) {
					actions.push({ icon: 'mdi-check', style: 'green', dark: true, title: this.$t('text.approveRequest'), function: 'updateProfile', params: 'approve', datacy: 'approve'})
				}

				if (!!this.statusMap['pending'] || !!this.statusMap['reapprove']) {
					actions.push({ icon: 'mdi-close-circle', style: 'red', dark: true, title: this.$t('text.declineRequest'), function: 'updateProfile', params: 'decline', datacy: 'decline'})
				}

				if (!!this.statusMap['deactivated'] || !!this.statusMap['unpublished']) {
					actions.push({ icon: 'mdi-check-circle', style: 'green', dark: true, title: this.$t('text.activateAccount'), function: 'updateProfile', params: 'activate', datacy: 'activate'})
				}

				if (this.userIsHomeOperator && Object.keys(this.statusMap).length > 0) {
					actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.$t('text.deleteServiceProvider'), function: 'confirmDelete'})
				}
				
				if (this.statusMap['approved']) {
					actions.push({ icon: 'mdi-eye-off', style: 'outline', dark: false, title: this.$t('text.deactivateAccount'), function: 'updateProfile', params: 'deactivate', datacy: 'deactivate'})
				}

				if (this.userIsHomeOperator && this.isHomebase() && this.serviceProvider.fields.userAccount?.de?.sys?.id) {
					actions.push({ icon: 'mdi-drama-masks', style: 'blue', dark: true, title: this.$t('text.operateAs'), function: 'impersonate', params: this.serviceProvider, datacy: 'impersonate'})
				}

				if (this.userIsHomeOperator && this.isHomebase()) {
					actions.push({ icon: 'mdi-home-city-outline', style: 'default', dark: false, title: this.$t('text.changeHomebase'), function: 'showChangeHomebaseDialog'})
				}
			}
			return actions
		},
		clientStatuses() {
			let statuses = []
			if (this.serviceProvider?.fields?.clientAssignments?.de?.length) {
				for (const ca of this.serviceProvider.fields.clientAssignments.de) {
					statuses.push({
						title: ca.fields.client.de.fields.clientId.de,
						status: ca.fields.status.de,
						isHomebase: ca.fields.isHomebase?.de ?? false,
					})
				}
			}
			return statuses
		},
		translationStatus() {
			return this.getTranslationStatus2(this.translatableFields, 'model')
		}
	},
	methods: {
		sendAllData() {
			const contactInfo = this.$refs['contactPersonInfo']?.sendData()
			// myInfo needs to be called after contactPersonInfo because it depends on one property of the contactPersonInfo sent via the eventBus
			const myInfo = this.$refs['myInfo']?.sendData()
			const businessHoursData = this.$refs['businessInfo']?.sendData()
			const categoryInfo = this.$refs['categoryInfo']?.sendData()
			const bankingInfo = this.$refs['bankingInfo']?.sendData()
			const publicInfo = this.$refs['publicInfo']?.sendData()
			const offerMenu = this.$refs['offerMenu']?.sendData()
			const mainUserAccountInfo = this.$refs['mainUserAccount']?.sendData()
			const applicationAccessInfo = this.$refs['applicationAccessInfo']?.sendData()
			const trustLevel = this.$refs['trustLevel']?.sendData()
			const salesChannelsInfo = this.$refs['salesChannelsInfo']?.sendData()

			return {
				contactInfo,
				myInfo,
				businessHoursData,
				categoryInfo,
				bankingInfo,
				publicInfo,
				offerMenu,
				mainUserAccountInfo,
				applicationAccessInfo,
				trustLevel,
				salesChannelsInfo
			}
		},
		updateServiceProviderFromChildren() {
			const {
				contactInfo,
				myInfo,
				businessHoursData,
				categoryInfo,
				bankingInfo,
				publicInfo,
				offerMenu,
				mainUserAccountInfo,
				applicationAccessInfo,
				trustLevel,
				salesChannelsInfo
			} = this.sendAllData()

			const data = [myInfo, businessHoursData, salesChannelsInfo, contactInfo, bankingInfo, publicInfo, trustLevel].filter(data => data !== undefined)
			for (const el of data) {
				Object.keys(el).forEach(key => {
					this.serviceProvider.fields[key] = JSON.parse(JSON.stringify(el[key]))
				})
			}

			let clientCA = this.serviceProvider.fields.clientAssignments.de.find(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
				
			//Set Commissions Info
			if (this.$refs.commissionsInfo && clientCA) {
				clientCA.fields.enabledCommissions = this.$refs.commissionsInfo.clientCA.fields.enabledCommissions
				clientCA.fields.enabledTimeBasedCommissions = this.$refs.commissionsInfo.clientCA.fields.enabledTimeBasedCommissions
				clientCA.fields.defaultCommission = this.$refs.commissionsInfo.clientCA.fields.defaultCommission
				clientCA.fields.commissions = this.$refs.commissionsInfo.clientCA.fields.commissions
			}

			//Set ClientAssignment productCategories
			if (categoryInfo) {
				if (clientCA) {
					clientCA.fields.productCategories = {de: categoryInfo.productCategories.de }
				}
			}
			//Set changed sections
			this.serviceProvider.addl = {
				contactInfoChanged: contactInfo?.changed || this.serviceProvider.sys.id === '',
				myInfoChanged: myInfo?.changed || this.serviceProvider.sys.id === '',
				businessHoursChanged: businessHoursData?.changed || this.serviceProvider.sys.id === '',
				categoryInfoChanged: categoryInfo?.changed || this.serviceProvider.sys.id === '',
				bankingInfoChanged: bankingInfo?.changed || this.serviceProvider.sys.id === '',
				// offerMenuChanged: offerMenu?.changed || this.serviceProvider.sys.id === '',
				mainUserAccountChanged: mainUserAccountInfo?.changed || this.serviceProvider.sys.id === '',
				applicationAccessChanged: applicationAccessInfo?.changed || this.serviceProvider.sys.id === '',
				trustLevelChanged: trustLevel?.changed || this.serviceProvider.sys.id === '',
				salesChannelsChanged: salesChannelsInfo?.changed || this.serviceProvider.sys.id === '',

				// new style of change tracking
				...this.changed,
			}
		},
		updateBusinessName(event) {
			this.serviceProvider.fields.title = event
		},
		handleAction(event) {
			this[event.function](event.params)
		},
		setLocale(code) {
			this.serviceLocale = code
		},
		setSections() {
			//TODO: refactor
			this.sections = []
			if (this.currentStep === 1) {
				if (this.userIsOperator) {
					if (this.featureEnabled('service-provider-checklist')) {
						this.sections.push({ id:'checklistInfo', state: 'valid' })
					}
					if (this.userAccountVisible) {
						this.sections = [
							...this.sections,
							{ id:'myInfo', state: 'valid' },
							{ id:'contactPersonInfo', state: 'valid' },
							{ id:'businessInfo', state: 'valid' },
							{ id:'publicInfo', state: 'valid' },
							{ id:'offerMenu', state: 'valid' },
							{ id:'mainUserAccount', state: 'valid' },
							{ id:'applicationAccessInfo', state: 'valid' },
							{ id:'trustLevel', state: 'valid' },
							{ id:'linkServiceProviders', state: 'valid' },
							{ id:'salesChannelsInfo', state: 'valid' },
						]
						if (!!this.$store.state.featureFlags['FEATURE_SKIRENTAL_SPECIFIC_SETTINGS'])
							this.sections.push({ id:'skirentalSettingsForSp', state: 'valid' })
						if (!!this.$store.state.featureFlags['FEATURE_SKISCHOOL_SPECIFIC_SETTINGS'])
							this.sections.push({ id:'skischoolSettingsForSp', state: 'valid' })
					} else {
						this.sections = [
							...this.sections,
							{ id:'myInfo', state: 'valid' },
							{ id:'contactPersonInfo', state: 'valid' },
							{ id:'businessInfo', state: 'valid' },
							{ id:'publicInfo', state: 'valid' },
							{ id:'offerMenu', state: 'valid' },
							{ id:'applicationAccessInfo', state: 'valid' },
							{ id:'trustLevel', state: 'valid' },
							{ id:'linkServiceProviders', state: 'valid' },
							{ id:'salesChannelsInfo', state: 'valid' },
						]
						if (!!this.$store.state.featureFlags['FEATURE_SKIRENTAL_SPECIFIC_SETTINGS'])
							this.sections.push({ id:'skirentalSettingsForSp', state: 'valid' })
						if (!!this.$store.state.featureFlags['FEATURE_SKISCHOOL_SPECIFIC_SETTINGS'])
							this.sections.push({ id:'skischoolSettingsForSp', state: 'valid' })
					}
				} else {
					this.sections = [
						{ id:'myInfo', state: 'valid' },
						{ id:'contactPersonInfo', state: 'valid' },
						{ id:'businessInfo', state: 'valid' },
						{ id:'publicInfo', state: 'valid' },
						{ id:'offerMenu', state: 'valid' },
						{ id:'salesChannelsInfo', state: 'valid' },
					]
					if (!!this.$store.state.featureFlags['FEATURE_SKIRENTAL_SPECIFIC_SETTINGS'])
						this.sections.push({ id:'skirentalSettingsForSp', state: 'valid' })
					if (!!this.$store.state.featureFlags['FEATURE_SKISCHOOL_SPECIFIC_SETTINGS'])
						this.sections.push({ id:'skischoolSettingsForSp', state: 'valid' })
				}
			} else if (this.currentStep === 2) {
				this.sections = [
					{ id:'categoryInfo', state: 'valid' }
				]
			} else if (this.currentStep === 3) {
				if (this.userIsOperator) {
					this.sections = [
						{ id:'bankingInfo', state: 'valid' },
						{ id:'commissions', state: 'valid' }
					]
				} else {
					this.sections = [
						{ id:'bankingInfo', state: 'valid' }
					]
				}
			}
			this.$nextTick(() => {
				this.validateAllFields()
			})
		},
		setActive(key) {
			if (this.$refs['SECTION_' + key]) {
				this.$refs['SECTION_' + key].toggle()
			} else if (this.$refs[key]) {
				// open the disclosures from child components
				this.$refs[key].$refs['SECTION_' + key].toggle()
			}
		},
		handleStep(selectedStep, serviceProviderUpdated = false) {
			this.updateServiceProviderFromChildren()
			this.currentStep = selectedStep
			this.setSections()
			
			for (let step of this.steps) {
				if (step.key === this.currentStep && step.status !== 'error') {
					step.status = 'complete'
				} else if (step.key !== this.currentStep && step.status !== 'error') {
					step.status = 'incomplete'
				}

				if (step.key === this.currentStep && serviceProviderUpdated) {
					step.status = 'complete'
				} else if (step.key !== this.currentStep && serviceProviderUpdated) {
					step.status = 'incomplete'
				}
			}
		},
		confirmDelete() {
			this.$refs.deleteDialog.show = true
		},
		averageDurationChanged(value) {
			this.serviceProvider.fields.averageDurationOfStayInMinutes.de = parseInt(value)
			this.setChanged()
		},
		toggleHaveBusinessHours(event) {
			if (!event || event === false) {
				this.serviceProvider.fields.businessHours.de = []
			} else {
				this.serviceProvider.fields.businessHours.de = [this.$refs.businessInfo.getNewBusinessHours(false)]

			}
			this.setChanged()
		},
		isHomebase() {
			if (this.serviceProvider.fields.clientAssignments?.de?.length > 0) {
				const clientAssignment = this.serviceProvider.fields.clientAssignments.de.find(spClientAssignment => spClientAssignment.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)

				if (clientAssignment?.fields?.isHomebase) {
					return clientAssignment.fields.isHomebase.de
				}
			}
			return false
		},
		onDeleteConfirmed() {
			this.$refs.deleteDialog.show = false
			this.deleteServiceProvider()
			return true
		},
		onDeleteCancelled() {
			this.$refs.deleteDialog.show = false
		},
		async confirmUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false
			this.$refs.translationsIncompleteDialog?.close()

			if (this.userIsOperator) {
				await this.$store.commit('setSelectedServiceProvider', null)
				await this.$store.commit('setPeakProducts', null)
			}

			if (this.gobackToDashboard === true || !this.userIsOperator) {
				this.$router.push("/")
				this.$emit('show-dashboard')
			}
			else {
				this.$router.push("/serviceProviders")
			}
			return true
		},
		cancelUnsavedChanges() {
			this.$refs.unsavedChangesDialog.show = false
		},
		handleEditTranslations() {
			this.$refs.translationsIncompleteDialog?.close()
			this.$refs.translationWizardDialog?.open()
		},
		setAllChanged(v) {
			if (v) this.setChanged()
			this.changed = {
				linkServiceProvidersChanged: v,
			}
		},
		// TODO: once we have replaced all the change tracking with the new style,
		//       we should refactor this to compute the style from the changed object
		setChanged(section) {
			this.submitButtonTheme = "blueButton white--text"
		},
		showError(detail, title) {
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
		async getServiceProviderDetail() {
			const id = this.$router.currentRoute.value?.params?.id ?? this.$store.state.selectedServiceProvider.sys.id
console.log('getServiceProviderDetail', id)
			if (!id) return

			this.loading = true
			try {
				const res = await this.$httpGet(`/serviceprovider/${ id }`)
				const serviceProvider = res.serviceProvider

				this.setServiceProviderDetails(serviceProvider)
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.showError(error.response ? error.response.data.error : error)
			}
			this.loading = false
			this.updateModel = true
			this.handleStep(1)
		},
		async setServiceProviderDetails(data) {
			if (!data.fields.language) {
				data.fields.language = { de: '' }
			}

			if (!data.fields.communicationMail) {
				data.fields.communicationMail = { de: '' }
			}

			if (!data.fields.location) {
				data.fields.location = { de: { lat:'', lon:'' } }
			}

			if (!data.fields?.faqGroup?.de) {
				data.fields["faqGroup"] = {de: ""}
			}

			if (!data.fields.shortDescription.de) {
				data.fields.shortDescription.de = "";
			}

			if (!data.fields.longDescription?.de) {
				data.fields.longDescription = { de: "" }
			}

			if (!data.fields.importantInformation) {
				data.fields["importantInformation"] = {de: ""}
			}

			if (data.fields.businessHours?.de?.length) {
				const bhs = data.fields.businessHours
				for (var ts of bhs.de) {
					ts.fields.validFromDate.de = ts.fields.validFromDate.de.substr(0, 10)
					ts.fields.validToDate.de = ts.fields.validToDate.de.substr(0, 10)
				}
				data.fields.businessHours = bhs
			}

			// Set ClientAssignment productCategories
			this.clientCA = data.fields.clientAssignments.de.find(x => x && x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
			data.fields.productCategories = { de: this.clientCA.fields.productCategories?.de ?? [] }

// TODO: i had to add serialize/parse because before we got an error in the console because data seems to contain some array that cant be cloned..
//       "Failed to execute 'put' on 'IDBObjectStore': [object Array] could not be cloned"
//       probably we should not put the SP into the store anyhow, but only the sys.id..
//       -> check where we actually use the SP from the store and find better solutions
			data = JSON.parse(JSON.stringify(data))
			await this.$store.commit('setSelectedServiceProvider', data)
			this.serviceProvider = data
			this.bindStatus(this.serviceProvider)

			if (this.$store.state.loggedInUser.fields?.type?.de === 'operator') {
				if (this.statusMap['approved']) {
					this.isApproval = false
				} else {
					this.isApproval = true
				}
			}

			//Set Commissions
			this.commissions = this.clientCA?.fields?.commissions?.de ?? []

			this.originalServiceProvider = JSON.parse(JSON.stringify(this.serviceProvider.fields))

			this.$nextTick(() => {
				// for some reason we have to trigger the CA refresh here
				this.$refs.salesChannelsInfo?.refreshModel?.()

				this.validateAllFields()
			})
			this.sleep(1000).then(() => { 
				this.validateAllFields()
			})
			this.updateModel = !this.updateModel
		},
		async updateProfile(action) {
			this.updateModel = false
			this.updateServiceProviderFromChildren()
			this.runValidation = true
			// ATT: these actions update without validation because the services are not visible after this anyway
			//      and they will have to go through a validation process later to become visible again.
			if (action === 'deactivate' || action === 'decline' || action === 'unpublish') {
				if (action === 'deactivate') {
					this.updateStatusAndBind('deactivated')
				}
				if (action === 'decline') {
					this.updateStatusAndBind('declined')
				}
				if (action === 'unpublish') {
					this.updateStatusAndBind('unpublished')
				}

				return await this.updateServiceProvider(action)
			}

			//Merge business hours and exceptions
			if (this.$refs.businessInfo) {
				if (this.$refs.businessInfo.haveBusinessHours !== true) {
					this.serviceProvider.fields.businessHours.de = []
				}
			}

			try {
				const isValid = await this.validate(action)
				if (isValid === false) {
					return this.showError(this.$t('text.missingFieldsError'))
				}
			}
			catch (e) {
				return this.showError(e)
			}

			if (action === 'approve' || action === 'activate') {
				this.updateStatusAndBind('approved')
			}
			else if (action === 'update') {
				// here the state can only be 'approved' we keep that.
			}
			else {
				throw new Error('unsupported action ' + action)
			}

			// prepare request payload
			if (this.$refs['applicationAccessInfo']?.selectedComponents?.length) {
				this.serviceProvider.enabled = true
				this.serviceProvider.roles = this.$refs['applicationAccessInfo'].selectedComponents
				if (!this.serviceProvider.mainUserAccount && this.$refs.mainUserAccount) {
					this.serviceProvider.mainUserAccount = {
						name: this.$refs.mainUserAccount.mainUserAccount.name,
						email: this.$refs.mainUserAccount.mainUserAccount.email,
						password: this.$refs.mainUserAccount.mainUserAccount.password,
						repeatPassword: this.$refs.mainUserAccount.mainUserAccount.repeatPassword
					}
				}
			}
			else if (Object.keys(this.serviceProvider.fields.mainUserAccount).length > 0) {
				this.serviceProvider.enabled = true
				this.serviceProvider.roles = this.serviceProvider.fields.mainUserAccount.fields.applications?.de

				if (!this.serviceProvider.mainUserAccount) {
					this.serviceProvider.mainUserAccount = {
						name:'',
						email:'',
						password:'',
						repeatPassword:''
					}
				}
			}

			this.serviceProvider.selectedLocale = this.selectedLocale

// TODO: why are we doing this?
//       why are we noit sending refs, but instead ids?
			this.serviceProvider.fields.imageGallery.de = this.serviceProvider.fields.imageGallery.de?.map(image => {
				if (image?.fields?.media?.de?.sys) {
					return image.fields.media.de.sys.id
				}
				return image?.sys?.id
			}) ?? []

			//Remove unnecessary data before submit
			if (this.serviceProvider.fields.faqGroup && this.serviceProvider.fields.faqGroup?.de && this.serviceProvider.fields.faqGroup.de?.fields) {
				this.serviceProvider.fields.faqGroup.de.fields.faqs = [];
			}

			if (this.serviceProvider.fields.importantInformation?.de) {
				this.serviceProvider.fields.importantInformation.de = this.convertLink(this.serviceProvider?.fields?.importantInformation?.de)
			}

			// Offer Menu
			if (this.$refs.offerMenu) {
				if (!this.serviceProvider?.fields?.offerMenu?.de) {
					this.serviceProvider.fields["offerMenu"] = {de: this.$refs.offerMenu.offerMenu}
				} else {
					this.serviceProvider.fields.offerMenu.de = this.$refs.offerMenu.offerMenu
				}
			}
			
			if (this.$refs.offerMenu?.showOfferMenu === false && this.serviceProvider.fields.offerMenu?.de?.sys) {
				this.serviceProvider.fields.offerMenu.de.fields.offerMenuItems = {de:[]}
			}
			console.log(this.serviceProvider.fields.offerMenu)
			if (this.isCreate) {
				await this.createServiceProvider()
				// TODO: We need some kind of a model update here for the back button not to trigger a popup on registration
			}
			else {
				await this.updateServiceProvider(action)
			}
		},
		async updateServiceProvider(action) {
			// Optimise Payload
			delete this.serviceProvider.fields.clients
			delete this.serviceProvider.fields.reductions
			delete this.serviceProvider.fields.ticketTypes

			if (this.$refs?.checklistRef?.checklist) {
				this.serviceProvider.fields.checklist = this.$refs.checklistRef.checklist
			}

			if (this.commissions?.length) {
				for (let i=0; i < this.serviceProvider.fields.clientAssignments.de.length; i++) {
					if (this.serviceProvider.fields.clientAssignments.de[0].sys.id === this.clientCA.sys.id) {
						this.serviceProvider.fields.clientAssignments.de[0].fields.commissions = { de: this.commissions }
					}
				}
			}
			
			var data = {
				action: action,
				serviceProvider: this.serviceProvider,
				clientId: this.$store.state.selectedClient.sys.id,
				isOperator: this.$store.state.loggedInUser.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation,
				clientStatus: this.statusClient
			}
			data.serviceProvider = this.cfoClean(data.serviceProvider)
			// serviceprovider also contains some other keys that are being cleaned out by cfoClean
			for (const key in this.serviceProvider) {
				if (key === 'sys' || key === 'fields') continue
				data.serviceProvider[key] = this.serviceProvider[key]
			}
			data.serviceProvider.roles = this.$refs['applicationAccessInfo']?.selectedComponents ?? this.serviceProvider.fields.mainUserAccount.fields.applications?.de

			this.loading = true
			try {
				const res = await this.$httpPut('/serviceprovider', data)
				this.submitButtonTheme = "gradientButton"
				const serviceProvider = res.serviceProvider
				await this.setServiceProviderDetails(serviceProvider)
				this.setAllChanged(false)

				this.reloadLogs = !this.reloadLogs

				if (action == 'create') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_CREATED')
					this.successDetail = this.$t('text.createServiceProviderSuccess')
				}
				if (action == 'update') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_UPDATED')
					this.successDetail = this.$t('text.profileUpdatedSuccess')
				}
				if (action == 'approve') {
					this.successTitle = this.$t('text.ACCOUNT_APPROVED')
					this.successDetail = this.$t('text.accountApprovedSuccess')
				}
				if (action == 'activate') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_UPDATED')
					this.successDetail = this.$t('text.accountApprovedSuccess')
				}
				if (action == 'unpublish') {
					this.successTitle = this.$t('text.BUSINESS_PROFILE_UPDATED')
					this.successDetail = this.$t('text.profileUpdatedSuccess')
				}
				if (action == 'decline') {
					this.successTitle = "ACCOUNT DECLINED"
					this.successDetail = "The service provider account has been declined"
				}
				if (action == 'deactivate') {
					this.successTitle = "ACCOUNT DEACTIVATED"
					this.successDetail = "The service provider account has been deactivated"
				}
				if (action == 'changeHomebase') {
					this.successTitle = this.$t('text.HOMEBASE_CHANGED')
					this.successDetail = this.$t('text.homebaseChangeSuccess')
				}
			}
			catch (error) {
				this.showError(error?.error)
			}
			this.loading = false
		},
		async createServiceProvider() {
			// Optimise Payload
			delete this.serviceProvider.fields.clients
			delete this.serviceProvider.fields.reductions
			delete this.serviceProvider.fields.ticketTypes

			this.serviceProvider.mainUserAccount = this.$refs['mainUserAccount'].mainUserAccount

			const data = {
				serviceProvider: this.serviceProvider,
				clientId: this.$store.state.selectedClient.sys.id,
				autolinkUser: true,
				mainUserAccount: this.$refs['mainUserAccount'].mainUserAccount,
				roles: this.$refs['applicationAccessInfo'].selectedComponents,
				language: this.selectedLocale,
				images: this.images,
				tags: this.$store.state.loggedInUser.fields.canSeeObjectsWithTags?.de ? this.$store.state.loggedInUser.fields.canSeeObjectsWithTags.de : []
			}

			this.loading = true
			try {
				const res = await this.$httpPost('/serviceprovider', data)
				this.submitButtonTheme = "gradientButton"

				const serviceProvider = res.serviceProvider
				this.setServiceProviderDetails(serviceProvider)

				this.reloadLogs = !this.reloadLogs

				this.successTitle = this.$t('text.BUSINESS_PROFILE_CREATED')
				this.successDetail = this.$t('text.createServiceProviderSuccess')
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
		},
		async deleteServiceProvider() {
			this.loading = true

			try {
				if (!this.statusMap.declined && !this.statusMap.deactivated) {
					//Deactivate account before delete if it is approved/pending
					// Optimise Payload
					delete this.serviceProvider.fields.clients
					delete this.serviceProvider.fields.reductions
					delete this.serviceProvider.fields.ticketTypes

					var data = {
						action: "deactivate",
						serviceProvider: this.serviceProvider,
						clientId: this.$store.state.selectedClient.sys.id,
					}

					try {
						const resDeactivate = await this.$httpPut('/serviceprovider', data)
					}
					catch (error) {
						this.showError(error.response ? error.response?.data.error : error)
					}
				}

				const resDelete = await this.axios.delete(`/serviceProvider`,
				{
					headers: this.requestHeaders(),
					data: {
						serviceProviderId: this.serviceProvider.sys.id,
						clientId: this.$store.state.selectedClient.sys.id,
						dryRun: false
					}
				})

				this.successTitle = this.$t('text.SERVICE_PROVIDER_DELETED')
				this.successDetail = this.$t('text.serviceProviderDeletedSuccess')

				this.loading = false

				this.sleep(2000).then(() => { this.$router.push("/serviceProviders") });
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
		},
 		async validate(action) {
			const allValid = await this.validateAllFields(action)

			if (allValid) {
				// we need to update the stepper to highlight the current step with green and make other steps grey if they had errors before
				this.handleStep(this.currentStep, true)
				this.updateServiceProviderFromChildren()
			}

			return allValid
		},
		async validateAllFields(action) {
			const isValidGeneralInfo = this.$refs['myInfo']?.validateAllFields()
			const isValidContactPersonInfo = await (this.$refs['contactPersonInfo']?.validateAllFields())
			const isValidBusinessInfo = this.$refs['businessInfo']?.validateAllFields()
			const isValidUserAccount = this.userAccountVisible ? this.$refs['mainUserAccount']?.validateAllFields() : true
			const isValidApplicationComponent = this.userIsOperator ? this.$refs['applicationAccessInfo']?.validateAllFields() : true
			const isValidSalesChannels = this.$refs['salesChannelsInfo']?.validateAllFields()
			const isValidCategories = this.$refs['categoryInfo']?.validateAllFields(action)
			const isValidCommissions = this.userIsMainOperator && this.serviceProvider.sys.id.length ? this.$refs['commissionsInfo']?.validateAllFields() : true
// TODO: why was this only being validated when the sp id is set?
			const isValidPublicInfo = this.userIsMainOperator /*&& this.serviceProvider.sys.id.length*/ ? this.$refs['publicInfo']?.validateAllFields() : true

			const allValid = !this.runValidation ||
			(	isValidGeneralInfo &&
				isValidContactPersonInfo &&
				isValidBusinessInfo &&
				isValidUserAccount &&
				isValidApplicationComponent &&
				isValidCategories &&
				isValidSalesChannels &&
				isValidCommissions &&
				isValidPublicInfo
			)
			//MYS-3231: Set error indicator in sidebar
			let myInfo = this.sections.find(section => section.id === 'myInfo')
			if (myInfo) { myInfo.state = !isValidGeneralInfo ? 'error' : 'valid' }

			let contactPersonInfo = this.sections.find(section => section.id === 'contactPersonInfo')
			if (contactPersonInfo) { contactPersonInfo.state = !isValidContactPersonInfo ? 'error' : 'valid' }

			let publicInfo = this.sections.find(section => section.id === 'publicInfo')
			if (publicInfo) { publicInfo.state = (!isValidBusinessInfo || !isValidPublicInfo) ? 'error' : 'valid' }

			let mainUserAccount = this.sections.find(section => section.id === 'mainUserAccount')
			if (mainUserAccount) { mainUserAccount.state = !isValidUserAccount ? 'error' : 'valid' }

			let applicationAccessInfo = this.sections.find(section => section.id === 'applicationAccessInfo')
			if (applicationAccessInfo) { applicationAccessInfo.state = !isValidApplicationComponent ? 'error' : 'valid' }
				
			let salesChannelsInfo = this.sections.find(section => section.id === 'salesChannelsInfo')
			if (salesChannelsInfo) { salesChannelsInfo.state = !isValidSalesChannels ? 'error' : 'valid' }
			
			let categoryInfo = this.sections.find(section => section.id === 'categoryInfo')
			if (categoryInfo) { categoryInfo.state = !isValidCategories ? 'error' : 'valid' }

			let commissions = this.sections.find(section => section.id === 'commissions')
			if (commissions) { commissions.state = !isValidCommissions ? 'error' : 'valid' }


			//Set error indicator in stepbar
			if (!isValidGeneralInfo || !isValidContactPersonInfo || !isValidBusinessInfo || !isValidUserAccount || !isValidApplicationComponent || !isValidSalesChannels) {
				this.steps[0].status = 'error'
			} 
			if (typeof isValidCategories !== 'undefined' && !isValidCategories) {
				this.steps[1].status = 'error'
			} 
			if (typeof isValidCommissions !== 'undefined' && !isValidCommissions) {
				this.steps[2].status = 'error'
			}

			return allValid
		},
		updateStatusAndBind(status) {
			const object = this.serviceProvider
			this.updateStatus(object, status)
			this.bindStatus(object)
		},
		bindStatus(object) {
			this.statusMap = this.getStatusMap(object)
			this.statusClient = this.getStatusClient(object)
			this.statusClientHasMultiple = Object.keys(this.statusMap).length > 1
		},
		goback(gobackToDashboard) {
			this.gobackToDashboard = gobackToDashboard ?? false
			const {
				contactInfo,
				myInfo,
				businessHoursData,
				categoryInfo,
				bankingInfo,
				publicInfo,
				offerMenu,
				mainUserAccountInfo,
				applicationAccessInfo,
				trustLevel,
				salesChannelsInfo
			} = this.sendAllData()

// console.log('myInfo.changed: ' + myInfo?.changed)
// console.log('contactInfo.changed: ' + contactInfo?.changed)
// console.log('businessHoursData.changed: ' + businessHoursData?.changed)
// console.log('categoryInfo.changed: ' + categoryInfo?.changed)
// console.log('bankingInfo.changed: ' + bankingInfo?.changed)
// console.log('publicInfo.changed: ' + publicInfo?.changed)
// console.log('offerMenu.changed: ' + offerMenu?.changed)
// console.log('mainUserAccountInfo.changed: ' + mainUserAccountInfo?.changed)
// console.log('applicationAccessInfo.changed: ' + applicationAccessInfo?.changed)
// console.log('trustLevel.changed: ' + trustLevel?.changed)
// console.log('salesChannelsInfo.changed: ' + salesChannelsInfo?.changed)

			if (
				// TODO: we should get rid of this old change tracking and replace that with use of this.changed based on changed events
				myInfo?.changed === true || 
				contactInfo?.changed === true || 
				businessHoursData?.changed === true || 
				categoryInfo?.changed === true || 
				bankingInfo?.changed === true || 
				publicInfo?.changed === true || 
				offerMenu?.changed === true ||
				mainUserAccountInfo?.changed === true ||
				// TODO: we are currently forcing this flag to true because the binding does not work correctly
				//applicationAccessInfo?.changed === true ||
				trustLevel?.changed === true ||
				// TODO: this is not working currently - it jumps to true even though its not changed
				//salesChannelsInfo?.changed === true ||

				// new style of change tracking
				Object.values(this.changed).some(x => x === true) === true ||
				false
			) {

				this.toggleLeaveDialog()
			}
			else {
				if (!this.userIsOperator) {
					this.$router.push("/dashboard")
				} else {
					this.$router.push("/serviceProviders")
				}
			}
		},
		toggleLeaveDialog() {
			if (this.$store.state.featureFlags.FEATURE_TRANSLATION_WIZARD && this.$refs.translationsIncompleteDialog?.isIncomplete) {
				return this.$refs.translationsIncompleteDialog.open()
			}

			this.$refs.unsavedChangesDialog.show = true
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		showChangeHomebaseDialog() {
			this.$refs.changeHomebaseDialog.show = true
		},
		async confirmHomebaseChange() {
			const data = {
				serviceProviderId: this.serviceProvider.sys.id,
				newHomebase: this.$refs.homebaseSelector.selectedClient.sys.id,
				loggedInUser: this.$store.state.loggedInUser.fields.email.de
			}

			this.loading = true
			try {
				await this.$httpPost('/homebase', data)

				await this.getServiceProviderDetail("changeHomebase")
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			this.loading = false
			this.$refs.changeHomebaseDialog.show = false
		},
		cancelHomebaseChange() {
			this.isHomebaseClientSelected = false
			this.$refs.changeHomebaseDialog.show = false
		},
		//MYS-3357 Commission Functions
		updateClientAssignment(clientAssignment) {
			this.clientCA = clientAssignment
			const index = this.serviceProvider.fields.clientAssignments.de.findIndex(x => x.fields.client.de.sys.id === this.$store.state.selectedClient.sys.id)
			this.serviceProvider.fields.clientAssignments.de[index] = clientAssignment
			this.updateModel = false
			this.updateModel = true
		},
		async updateCommissions(commissions) {
			if (this.clientCA?.sys?.id) {
				const res = await this.$httpGet(`/commissions/${ this.clientCA.sys.id }`)
				this.clientCA.fields.commissions = { de: res }
			}
			this.commissions = this.clientCA?.fields?.commissions?.de ?? []

			this.updateModel = false
			this.updateModel = true
		},
		async deleteCommission(commission) {
			this.loading = true
			try {
				const res = await this.$httpDelete('/commission', {
					clientAssignmentId: this.clientCA?.sys?.id,
					commission: commission,
				})
				this.updateCommissions(res)
				this.loading = false
			}
			catch (error) {
				this.showError(error)
			}
		},
	},
	async mounted() {
		this.loading = true
		this.setSections()

		if (!this.$store.state.locales?.length) {
			await this.$root.app.getLocales()
		}

		// the user has navigated via url
		if (this.$router.currentRoute.value?.params?.id) {
			// TODO: this should rather happen generically in the Application component
			const apps = this.$store.state.loggedInUser.fields.applications.de
			const app = apps?.find?.(app => app.fields.link.de == '/serviceProviders')
			await this.$store.commit('setSelectedComponent', app)

			await this.getServiceProviderDetail()
		}
		else {
			// existing SP
			if (this.$store.state.selectedServiceProvider?.sys?.id) {
				if (this.$store.state.loggedInUser.sys.id !== this.$store.state.selectedServiceProvider.sys.id) {
					await this.getServiceProviderDetail()
					this.setAllChanged(false)
				}
				// new SP
				else {
					await this.setServiceProviderDetails(this.$store.state.selectedServiceProvider)
					this.setAllChanged(true)
				}
			}
			else {
				// New Service Provider
				this.manuallyUnRestricted = true
			}
		}

		if (!this.serviceProvider.fields.linkedServiceProviders) {
			this.serviceProvider.fields.linkedServiceProviders = { de: [] }
		}

		this.clientCA = this.serviceProvider.fields.clientAssignments.de.find(ca => ca?.fields?.client?.de?.fields?.shopClientId?.de === this.$store.state.selectedClient.fields.shopClientId.de)

		this.serviceProviderIsLoaded = true
		this.steps[0].status = 'complete'
		this.loading = false
	},
	async beforeUnmount() {
		eventBus.$off('confirm-delete')
		eventBus.$off('close-delete')

		if (this.$store.state.isImpersonation) return
		if (this.$store.state.isLinkedProvider) return
		if (!this.$store.state.loggedInUser?.fields?.type?.de === 'operator') return
		if (this.$store.state.loggedInUser?.fields?.type?.de != 'serviceprovider') return
		await this.$store.commit('setSelectedServiceProvider', this.$store.state.loggedInUser.fields.serviceProvider.de)
	},
}
</script>

<style scoped >
.container-grid { margin-left: 380px; padding: 20px; }
hr { margin-top: -20px; color: #efefef; }
a { text-decoration: none; }
.error { color: #ff0022; }
.weekdayCard {
	width: 100% !important;
	height: 48px !important;
	line-height: 48px !important;
	background-color: #f2f2f2 !important;
	padding-left: 10px !important;
	font-size: 14pt !important;
}
.theme--light.v-card { box-shadow: none !important; }
.ofrom-label {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	padding: 3px;
}
.serviceLocale { position: absolute; z-index: 10; margin-top: 32px; margin-left: -32px; zoom: 0.8; }

.warning-icon-col {
  flex: 1;
  text-align: center;
}

.text-col {
  flex: 2;
  text-align: center;
  
  width: 268px;
  height: 110px;
  margin: 8px 0 0;
  font-family: Inter;
  font-size: 17px;
  font-weight: normal;
  font-stretch:normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.42px;
  color: #9da0a5;

  text-align: left;
}

.content { padding-top: 65px; width: 100%; }
.showImpersonationInfo .content { padding-top: 90px; width: 100%; }

h1 { flex-shrink: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 13pt; font-weight: bold; }

.SystemInfo { color: gray; font-size: smaller; }
</style>