<template>
	<div>
		<v-btn small
			data-cy="previousPage"
			variant="text"
			icon="mdi-chevron-left"
			class="ma-0"
			size="small"
			:disabled="offset === 0"
			@click="previousPage()"
		/>
		<div class="count">
			{{ paginatorText }}
		</div>
		<v-btn small
			variant="text"
			icon="mdi-chevron-right"
			data-cy="nextPage"
			class="ma-0"
			size="small"
			:disabled="!hasMore || guessPaginationEnd"
			@click="nextPage()"
		/>
	</div>
</template>

<script>
export default {
	name: 'TablePaginator',
	emits: ['update:offset'],
	props: {
	offset: {
		type: Number,
		default: 0
	},
	limit: {
		type: Number,
		default: 10
	},
	total: Number,
	results: {
		type: Array,
		default() {
		return []
		}
	}
},

data: () => ({
	hasMore: null,
	count: null
}),

mounted() {
	this.calculatePagination(this.results)
},

watch: {
	results(results) {
		this.calculatePagination(results)
	},
	offset() {
		this.calculatePagination(this.results)
	},
	total() {
		this.calculatePagination(this.results)
	}
},

methods: {
	nextPage() {
		this.$emit('update:offset', this.offset + this.limit)
	},
	previousPage() {
		this.$emit('update:offset', Math.max(0, this.offset - this.limit))
	},
	calculatePagination(results) {
		const page = Math.ceil((this.offset) / this.limit) + 1;
		const totalPages = Math.ceil(this.total / this.limit)
		this.hasMore = page < totalPages;
		this.count = Math.min(this.limit, results?.length)
	}
},

computed: {
	paginatorText() {
		const pageStart = this.count === 0 ? 0 : this.offset + 1
		const pageEnd = Math.min(this.offset + this.count, this.offset + this.limit)
		const pageText = pageStart + '-' + pageEnd
		return pageText + ' ' + (this.total == 99999 ? '' : this.$t('text.paginationOf') + ' ' + this.total)
	},
	guessPaginationEnd() {
		return this.total == 99999 && this.results.length < this.limit
	}
}
}
</script>

<style scoped>
.count {
	display: inline-block;
	line-height: initial;
}
</style>
