<template>
	<div class="ContingentSlotStandalone">
		<!-- TODO: format date -->
		<div class="date">{{ day.date }}</div>
		<div class="slot">
			<ContingentSlot
				:style="{ opacity: modelValue.total == 0 ? 0.5 : 1 }"
				:modelValue="modelValue"
				:day="day"
				:dates="dates"
				:dayExpanded="dayExpanded"
				:dayVisited="dayVisited"
			>
				<slot name="menu" />
			</ContingentSlot>
			<ContingentSlotFree class="remaining" :modelValue="modelValue" />
		</div>
	</div>
</template>

<script lang="ts">
import ContingentSlot from './ContingentSlot.vue'
import ContingentSlotFree from './ContingentSlotFree.vue'

export default {
	components: { ContingentSlot, ContingentSlotFree },
	name: 'ContingentSlotStandalone',
	props: {
		modelValue: Object, // slot
		day: Object,
		dates: Object,
		dayExpanded: Object,
		dayVisited: Object,
	},
}
</script>

<style scoped>
.ContingentSlotStandalone { display: flex; gap: 4px; }
.date { min-width: 70px; font-size: 12px; padding: 2px; }
.slot { flex: 1; display: flex; flex-direction: column; gap: 4px; }
</style>