class Entry {
	sys!: { id: string }
	fields!: { [ id: string ]: any }
}

class Meta {
	expiresAt!: number
}

class CacheItem {
	entry!: Entry
	meta!: Meta
}

export default class EntryCache {
	entries: { [ id: string ]: CacheItem } = {}

	async get(id) {
		if (this.entries[id] && this.entries[id].meta.expiresAt > Date.now()) {
			return this.entries[id].entry
		}
		// TODO: load entry
		// TODO: put into cache
	}

	// TODO: bulk loading
	// TODO: function to conveniently fill the cache from an array
}