<template>
	<v-container fluid style="padding-bottom: 70px;">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading v-model:active="loading" :is-full-page="true" color="#4caf50" data-cy="loading" />
		<v-card class="tableCard">
			<v-data-table fixed-header disable-sort disable-pagination
				:headers="headers"
				:items="items"
				:items-per-page="100"
				@click:row="select"
				:item-value="item => item"
			>
				<template v-slot:item.title="{ item }">
					{{ item.fields.title[selectedLocale] }}
				</template>
				<template v-slot:item.status="{ item }">
					<v-chip :color="getColor(item.status)" dark>{{ getStatusText(item.status) }}</v-chip>
				</template>
				<template #bottom></template>
			</v-data-table>
			<Dialog ref="dialog"
				:confirm-label="$t('text.save')"
				:cancel-label="$t('text.cancel')"
				:confirm-handler="dialogConfirm"
				:cancel-handler="dialogCancel"
				:title="selectedItem ? selectedItem.fields.title.de : ''">
				<template #content>
					<div>
						<label>Make this marketplace available to all your service providers. Your service providers will then be able to publish their services to this marketplace.</label>
						<mys-switch
							:disabled="selectedItem.active"
							v-model="selectedItem.active"
							:label="$t('active')"
							data-cy="active"
						/>
						<div style="color: darkorange;">When activating this, you can not set it back later!</div>
					</div>
				</template>
			</Dialog>
		</v-card>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Dialog from '@/components/common/Dialog.vue'
import Alert from '@/components/common/Alert.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'MarketplaceClientsView',
	components: { Dialog, Loading, Alert },
	mixins: [ Common ],
	data() {
		return {
		items: [],
		loading: false,
		selectedItem: null, // CFObject<client>
		myMarketplace: null,
		errorTitle: '',
		errorDetail: '',
	}},
	computed: {
		headers () {
			return [
				{ title: this.$t('text.id'), key: "fields.clientId.de" },
				{ title: this.$t('text.marketplace'), key: "title" },
				{ title: this.$t('text.status'), width: '200', key: "status" },
			]
		},
	},
	watch: {
		selectedItem(item) {
			this.$refs.dialog.show = !!item
		}
	},
	async mounted() {
		await this.loadEntries()
	},
	methods: {
		async loadEntries() {
			this.loading = true

			try {
				const myClientSysId = this.$store.state.selectedClient.sys.id

				// load all clients
				let clients = await this.$httpGet('/clients', {
					contentType: 'client',
				})

				// load my marketplace
				let marketplaces = await this.$httpGet('/marketplace?clientSysId=' + myClientSysId)
				this.myMarketplace = marketplaces?.[0]

				// mark marketplaces with their status
				let mpClientLookup = {}
				if (this.myMarketplace) {
					for (let client of this.myMarketplace.fields.externalClients.de) {
						mpClientLookup[client.sys.id] = client
					}
				}
				for (let client of clients) {
					client.status = 'INACTIVE'
					if (mpClientLookup[client.sys.id]) client.status = 'ACTIVE'
					if (this.myMarketplace.fields.homeClient.de.sys.id == client.sys.id) client.status = 'HOME'
					client.active = client.status != 'INACTIVE'
				}

				this.items = clients
			}
			catch (e) {
				console.error(e)
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = e.message
			}
			this.loading = false
		},
		getColor(status) {
			if (status == 'HOME') return '#6699FF'
			if (status == 'ACTIVE') return '#33CC66'
			if (status == 'INACTIVE') return '#999999'
		},
		getStatusText(status) {
			return this.$t('text.'+status.toLowerCase()).toUpperCase()
		},
		select(e, line) {
			this.selectedItem = JSON.parse(JSON.stringify(line.item))
		},
		async dialogConfirm() {
			const newExternalClients = []
			let found = false
			// search for selectedItem in externalClients
			for (let client of this.myMarketplace.fields.externalClients.de) {
				if (this.selectedItem.sys.id == client.sys.id) {
					found = true
					// user deselected the switch -> do not add item to new list
					if (!this.selectedItem.active) continue
				}
				newExternalClients.push(client)
			}
			if (!found && this.selectedItem.active) {
				newExternalClients.push({ sys: { id: this.selectedItem.sys.id, type: 'Link', linkType: 'Entry' } })
			}
			this.myMarketplace.fields.externalClients.de = newExternalClients
			
			// TODO: make both of these a general plugin facility
			// TODO: cleanup object for save
			const link = function(o) {
				if (!o?.sys) return o
				return { sys: { id: o.sys.id, type: 'Link', linkType: 'Entry' } }
			}
			// TODO: Gerhard mentioned that this has errors - check back with him!
			const flatten = function(cfo) {
				let r = { sys: cfo.sys, fields: {}, metadata: cfo.metadata }
				for (let field in cfo.fields ?? []) {
					// simple values
					r.fields[field] = cfo.fields[field]
					let value = cfo.fields[field].de
					// single-refs
					if (value?.fields) {
						r.fields[field] = { de: link(value) }
					}
					// array-refs
					if (Array.isArray(value)) {
						r.fields[field] = { de: [] }
						for (const i in value) {
							let elem = value[i]
							if (elem?.fields) elem = link(elem)
							r.fields[field].de.push(elem)
						}
					}
				}
				return r
			}

			this.loading = true
			let mp = flatten(this.myMarketplace)
			await this.$httpPut('/marketplace/' + mp.sys.id, mp)
			this.loading = false

			await this.loadEntries()

			return true
			/*// TODO: prevent dialog when myMarketplace is null!
			let marketplaceId = this.myMarketplace.sys.id
			// TODO: this doesnt actually update the selected marketplace, but it updates the home marketplace
			this.myMarketplace
			// await this.$httpPut('/marketplace/' + marketplaceId, this.selectedItem)
			// TODO: when activated, we need to add to the home MP's externals
			const newExternalClients = []
			let found = false
			for (let client of this.myMarketplace.fields.externalClients.de) {
				let mp = this.marketplaceLookup[client.sys.id]
				if (!mp) continue
				// user deselected the switch -> do not add item
				if (this.selectedItem.active) {
					found = true
					continue
				}
				newExternalClients.push(client)
			}
			// TODO: add if not found
			if (!found) {
				newExternalClients.push({ sys: { id: this.selectedItem.fields.homeClient.de.sys.id, type: 'Link', linkType: 'Entry' } })
			}
		
			// TODO: set into object

			this.selectedItem = null
//			let client = await this.$httpGet('/client/' + clientId)
			// TODO: this weirdness should be sorted out at the server - but we'd also need to track down all other dependent places
//			client = client.client
			// TODO: at this point we should also write into the loggedInUser
			//       because if she is an admin, he can switch clients and that will take the client from the user
//			await this.$store.commit('setSelectedClient', client)
			// TODO: reload list
			// TODO: do we need to commit something new to store? maybe the marketplace should be in store..
			this.loadEntries()*/
		},
		dialogCancel() {
			// TODO: ?
		},
	},
}
</script>