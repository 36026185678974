import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/views/dashboard/Dashboard.vue'
import Login from '@/views/main/Login.vue'
import ForgotPassword from '@/views/main/ForgotPassword.vue'
import Registration from '@/views/main/Registration.vue'

import ServiceProvidersView from '@/views/applications/serviceProviders/ServiceProvidersView.vue'
import BusinessProfile from '@/views/applications/serviceProviders/BusinessProfile.vue'

import SettingsView from '@/views/applications/settings/SettingsView.vue'
import ProductCategoryView from '@/views/applications/settings/ProductCategoryView.vue'
import ProductCategoryDetailView from '@/views/applications/settings/ProductCategoryDetailView.vue'

import ServicesView from '@/views/applications/serviceDesigner/ServicesView.vue'
import ServiceDesigner from '@/views/applications/serviceDesigner/ServiceDesigner.vue'
import PackagesView from '@/views/applications/packageDesigner/PackagesView.vue'
import PackageDesigner from '@/views/applications/packageDesigner/PackageDesigner.vue'

import FAQListView from '@/views/applications/faqs/FAQListView.vue'
import FAQDetailView from '@/views/applications/faqs/FAQDetailView.vue'

import OrdersView from '@/views/applications/orders/OrdersView.vue'
import OrderDetailView from '@/views/applications/orders/OrderDetailView.vue'

import MediaGallery from '@/views/applications/mediaGallery/MediaGallery.vue'
import TrainingLibraryView from '@/views/applications/trainingLibrary/TrainingLibraryView.vue'
import VideoPlayer from '@/views/applications/trainingLibrary/VideoPlayer.vue'

import MarketplaceView from '@/views/applications/marketplace/MarketplaceView.vue'
import TagManagerView from '@/views/applications/tagManager/TagManagerView.vue'

import MyOrganisationView from '@/views/applications/myOrganisation/MyOrganisationView.vue'

import DeepLink from '@/components/deepLink/DeepLink.vue'
import AppHost from '@/views/applications/appHost/AppHost.vue'
import AppHostStandalone from '@/views/applications/appHost/AppHostStandalone.vue'

import ValidityCheckerView from '@/views/applications/validityChecker/ValidityCheckerView.vue'
import BusinessHoursStandalone from '@/components/businesshours/BusinessHoursStandalone.vue'

import Playground from '@/views/Playground.vue'

// we use meta.root to indicate which application this actually is (see selectedComponent handling in App)
const routes = [
	{ path: "/", component: Login, meta: { requiresAuth: false } },
	{ path: "/auth", component: Login, meta: { requiresAuth: false } },
	{ path: "/forgotPassword", component: ForgotPassword, meta: { requiresAuth: false } },
	{ path: "/registration", component: Registration, meta: { requiresAuth: false } },
	{ path: "/dashboard", component: Dashboard },
	{ path: "/videoPlayer", component: VideoPlayer },
	{ path: "/d/:path(.*)", component: DeepLink, props: true },
	{ path: "/app/:appName",  component: AppHost, props: true },
	{ path: "/app/:appName/:path(.*)", component: AppHost, props: true },
	{ path: "/sa-app/:appName", component: AppHostStandalone, props: route => ({ token: route.query.token }), meta: { requiresAuth: false } },
	{ path: "/sa-app/:appName/:path(.*)", component: AppHostStandalone, props: route => ({ token: route.query.token }), meta: { requiresAuth: false } },
	{ path: "/settings", component: SettingsView, props: route => ({ viewType: route.query.viewType }), meta: { root: '/settingsView' } },
	{ path: "/settingsView", component: SettingsView, props: route => ({ viewType: route.query.viewType }) },
	{ path: "/serviceProviders", component: ServiceProvidersView },
	{ path: "/serviceProviders/:id", component: BusinessProfile, meta: { root: '/serviceProviders' } },
	{ path: "/businessProfile", component: BusinessProfile, meta: { root: '/serviceProviders' } },
	{ path: "/services", component: ServicesView },
	{ path: "/services/:id", component: ServiceDesigner, meta: { root: '/services' } },
	{ path: "/service-designer", component: ServiceDesigner, meta: { root: '/services' } },
	{ path: "/packages", component: PackagesView },
	{ path: "/packages/:id", component: PackageDesigner, meta: { root: '/packages' }, props: true },
	{ path: "/faq-list", component: FAQListView },
	{ path: "/faq-detail", component: FAQDetailView },
	{ path: "/orders", component: OrdersView },
	{ path: "/orderDetail", component: OrderDetailView },
	{ path: "/mediaGallery", component: MediaGallery },
	{ path: "/trainingLibrary", component: TrainingLibraryView },
	{ path: "/marketplace", component: MarketplaceView },
	{ path: "/tagManager", component: TagManagerView },
	{ path: "/myOrg", component: MyOrganisationView },
	{ path: "/validityChecker", component: ValidityCheckerView },
	{ path: "/productCategory", component: ProductCategoryView, meta: { root: '/settingsView' } },
	{ path: "/productCategoryDetail", component: ProductCategoryDetailView, props: route => ({ categoryId: route.query.categoryId }), meta: { root: '/settingsView' } },
	{ path: "/businessHours", component: BusinessHoursStandalone, props: route => ({ token: route.query.token }), meta: { requiresAuth: false } },
	{ path: "/playground", component: Playground },
]

export default createRouter({
	history: createWebHistory(),
	routes: routes,
})
