<template>
	<div class="ContingentSlot"
		:class="{
			soldout: free == 0,
			available: free > 0,
			overbooked: free < 0,
			past: day.date < dates.today,
			menuOpen,
		}"
		:title="title"
		@click="click"
	>
		<span class="time">{{ time }}</span>
		<span class="amounts">
			<span class="booked">{{ used }}</span>
			/
			<span class="total">{{ modelValue.total }}</span>
		</span>
		<!-- TODO: in some views we actually dont want to show the menu .. prop? -->
		<v-menu v-model="menuOpen" location="bottom center" activator="parent">
			<slot name="menu" />
		</v-menu>
	</div>
</template>

<script lang="ts">
export default {
	name: 'ContingentSlot',
	props: {
		modelValue: Object, // slot
		day: Object,
		dates: Object,
		dayExpanded: Object,
		dayVisited: Object,
	},
	data: () => ({
		menuOpen: false,
	}),
	computed: {
		used() {
			return this.modelValue.used - this.modelValue.overbookFree
		},
		free() {
			return this.modelValue.total - this.used
		},
		time() {
			if (this.modelValue.time == '00:00') return this.$t('text.allday')
			return this.modelValue.time
		},
		title() {
			return this.day.date + ' ' + this.time + '\n'
				+ this.modelValue.used + ' / ' + this.modelValue.total + ' reserved'
				+ (this.free <= 0 ? '\nSOLD OUT' : '')
		},
	},
	methods: {
		click() {
			this.$emit('click', this.day, this.modelValue)
		},
	},
}
</script>

<style scoped>
.ContingentSlot { --radius: 5px; border-radius: var(--radius); display: flex; }
.ContingentSlot .time,
.ContingentSlot .amounts { padding: 2px 5px; font-size: 12px; text-align: center; white-space: nowrap; }
.time { min-width: 50px; color: white; border-radius: var(--radius) 0 0 var(--radius); }
.amounts { flex: 1; }
.booked { font-weight: bold; }
.past { filter: grayscale(0.5); opacity: 0.8; }
.menuOpen { outline: 2px solid var(--col-selected); }

.available { background: #e5f6da; color: #28500e; }
.available .time { background: #7ed148; }
.soldout { background: #f4f4f4; color: #424242; }
.soldout .time { background: #a8a8a8; }
.overbooked { background: #fde0e0; color: #611c1c; }
.overbooked .time { background: #f34545; }
/*
.soldout { background: #fff2d4; color: #664800; }
.soldout .time { background: #ffc12b; }
*/
</style>