<template>
  <div v-if="show" :class="{backdrop}">
    <div class="dialog-div" style="height:300px;width:525px">
      <v-toolbar elevation="0" color="white" style="width:100%" class="dialog-header">
        <v-toolbar-title style="margin-left:20px"> {{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-divider style="border-color:#dedede"/>

      <v-layout row wrap class="scrollable dialog-content">
        <v-col class="d-flex overflow-x-auto ma-0" style="padding:10px;">
          <v-row justify="center" align="center" style="padding:10px">
            <v-col cols="1">
              <span v-if="type==='warning'">
                <img src="@/assets/icons/warning-large.png" style="width:72px;height:72px"/>
              </span>
              <span v-if="type==='error'">
                <img src="@/assets/icons/error-large.png" style="width:72px;height:72px"/>
              </span>
              <span v-if="type==='success'">
                <img src="@/assets/icons/success-large.png" style="width:72px;height:72px"/>
              </span>
              <span v-if="type==='loading'">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="light-blue-lighten-1"
                  indeterminate
                ></v-progress-circular>
              </span>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="8">
              <p class="text-h6">{{messageTitle}}</p>
              <p v-html="messageText"></p>
            </v-col>
          </v-row>
        </v-col>
      </v-layout>

      <v-divider/>
      <v-toolbar elevation="0" color="white" style="width:100%" height="60" class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn class="gradientButton" theme="dark" elevation="0" v-if="cancelLabel"
          @click="cancel()"
          data-cy="message-cancel"
        >{{ cancelLabel }}</v-btn>
        <v-btn class="greenButton" theme="dark" elevation="0"  v-if="showRetry" 
          @click="confirm()"
          data-cy="message-retry"
        >{{ $t('text.retry') }}</v-btn>
        <v-btn class="greenButton" theme="dark" elevation="0"  v-if="confirmLabel" 
          @click="confirm()"
          data-cy="message-confirm"
        >{{ confirmLabel }}</v-btn>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageDialog",

  props: {
    title: {
      type: String
    },
    type: {
      type: String
    },
    messageTitle: {
      type: String
    },
    messageText: {
      type: String
    },
    showRetry: {
      type: Boolean
    },
    header: {
      type: Boolean,
      default: true
    },
    Footer: {
      type: Boolean,
      default: true
    },
    confirmLabel: {
      type: String
    },
    cancelLabel: {
      type: String
    },
    confirmHandler: {
      type: Function
    },
    cancelHandler: {
      type: Function
    },
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      backdrop: true,
			closeOnOuterClick: false,
    }
  },
	watch: {
		show(n) {
			this.fixZindexes(n)
		}
	},
  methods: {
    confirm() {
      let close = true
      if (this.confirmHandler && typeof this.confirmHandler === 'function') {
        close = this.confirmHandler();
      }
      if (close) {
        this.closeDialog();
      }
    },

    cancel() {
      if (this.cancelHandler && typeof this.cancelHandler === 'function') {
        this.cancelHandler();
      }
      this.closeDialog();
    },

    closeDialog() {
      if (this.closeHandler && typeof this.closeHandler === 'function') {
        this.closeHandler();
      }
    },
		fixZindexes(n) {
			// raise/restore the whole parent stack's z-index to avoid overlaying siblings
			for (let el = this.$parent; el; el = el.$parent) {
				if (!el?.$el) continue
				if (n) {
					el.$el.style['z-index-backedup'] = true
					el.$el.style['z-index-backup'] = el.$el.style['z-index']
					el.$el.style['z-index'] = 999
				}
				else {
					if (el.$el.style?.['z-index-backedup']) 
						el.$el.style['z-index'] = 1 //el.$el.style['z-index-backup']
				}
			}
		}
	},
	beforeUnmount () {
		this.fixZindexes(false)
	},
}
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  height: calc(100vh - 64px);
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 64px;
  left: 0;
}

.dialog-div {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 1100px;
  height: min(700px, calc(100vh - 20px));
  max-width: calc(100vw - 20px);

  background-color: #ffffff;
  border-radius: 10px !important;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;

  i {
    margin-right: 5px;
  }

  .dialog-header {
    max-height: 64px;
  }

  .dialog-footer {
    max-height: 60px;

    .gradientButton {
      height: 40px !important;
      min-height: 40px !important;
      margin-right: 10px;
      color: #000;
    }

    .greenButton {
      height: 40px !important;
      margin-right: 10px;
    }

    .redButton {
      height: 40px !important;
      margin-right: 10px;
    }
  }
}

.dialogDivPrint {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  height: 100%;

  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.beta-top .corner {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 0px;
    height: 0px;
    border-top: 75px solid #ff7f29;
    border-left: 75px solid transparent;
    z-index: 9;
}

.beta-top .beta {
    position: absolute;
    right: 5px;
    top: 15px;
    text-transform: uppercase;
    color: white;
    font-size: 12pt;
    font-weight: bold;
    z-index: 10;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); 
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -sand-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
