<template>
	<Dialog ref="dialog"
		:title="title"
		:show-close="false"
		:confirmLabel="$t('text.saveChanges')"
		:cancelLabel="$t('text.discardChanges')"
		:deleteLabel="canDelete ? $t('text.deleteOption') : undefined"
		:confirm-handler="confirm"
		:cancel-handler="cancel"
		:delete-handler="del"
		:isValid="valid"
	>
		<template #content>
			<div style="width: 100%;">
				<div v-for="(locale, l) in locales" :key="locale" style="display: flex; gap: 10px;">
					<LanguageFlag :modelValue="locale.code" style="margin-top: 17px;" />
					<v-text-field :ref="'locale' + l"
						variant="outlined"
						v-model.trim="model[locale.code]"
						:error-messages="getErrors(model[locale.code])"
					/>
				</div>
				<p class="helpText" v-html="$t('text.itemMissingLocale')"/>
			</div>
		</template>
	</Dialog>
</template>

<script lang="ts">
import Common from '../mixins/Common.vue'
import Dialog from './common/Dialog.vue'
import LanguageFlag from './common/LanguageFlag.vue'

// TODO: handle default locale?

export default {
	name: 'I18nStringDialog',
	mixins: [ Common ],
	components: { Dialog, LanguageFlag },
	props: {
		title: String,
		modelValue: Object,
		canDelete: Boolean,
	},
	data: () => ({
		model: null,
	}),
	computed: {
		valid() {
			if (!this.model) return false
			return this.locales.every(l => this.model[l.code])
		},
	},
	methods: {
		cancel() {
			this.$emit('cancel')
		},
		confirm() {
			this.$emit('update:modelValue', this.model)
			this.$emit('confirm', this.model)
		},
		del() {
			this.$emit('delete')
		},
		getErrors(value) {
			if (!value) return [ this.$t('text.required') ]
			return []
		},
/*
		showErrors: {
			get() {
				return (value) => {
					if (value === '' && value !== undefined) {
						return this.$t('text.itemMissingLocale')
					}
				}
			},
			set() {},
		},
*/
		open() {
			this.$refs.dialog.show = 1
		},
		close() {
			this.$refs.dialog.show = 0
		},
	},
	mounted() {
		if (this.modelValue) {
			this.model = JSON.parse(JSON.stringify(this.modelValue))
		}
		else {
			const m = {}
			this.locales.forEach(l => m[l.code] = null)
			this.model = m
		}
		this.$nextTick (() => {
			this.open()
		})
		window.setTimeout(() => {
			this.$refs.locale0?.[0]?.focus()
		}, 300)
	},
}
</script>

<style scoped>
</style>