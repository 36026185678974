<template>
	<Application :loading="loading" v-model:error-title="errorTitle" :error-detail="errorDetail" v-model:success-title="successTitle" :success-detail="successDetail" :isSideBarFixed="isSideBarFixed">
		<template #navbar>
			<v-row align="center" class="flex-nowrap" style="white-space:nowrap; overflow:hidden;">
				<v-btn id="btnBack" elevation="0" style="background-color:transparent;" @click="goback()">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				<v-row justify="center">
					<h1>{{ faqGroup.fields.title[serviceLocale] ? faqGroup.fields.title[serviceLocale] : $t('text.titleNewFAQ') }}</h1>
				</v-row>
			</v-row>
		</template>

		<SideBar :actions="sidebarActions" :translations="translations" @action="handleAction($event)" />
		<div style="margin-top: 64px;">
			<v-row>
				<v-col cols="12" :lg="showPreview ? 6 : 12">
					<v-card style="padding: 5px 15px;">
						<div class="field left-border">
							<v-label>{{$t('text.templateTitle')}} ({{$t('text.required')}})</v-label>
							<LanguageFlag v-model="serviceLocale" class="langFlag" />
							<v-text-field variant="outlined" density="compact" required persistent-hint
								v-model="faqGroup.fields.title[serviceLocale]"
								@keydown="changeStatus"
								:hint="$t('text.FAQHelp')"
								:error-messages="titleErrors"
							/>
						</div>
						<div v-for="(faqComponent, f) in faqGroup.fields.references.de" :key="faqComponent.sys.id">
							<FAQComponent :faq-component="faqComponent" :index="f"
								@status-changed="changeStatus"
								@remove-question="removeQuestion(f)"
								style="margin-top: 15px; border-top: 1px solid #ddd; padding-top: 10px;"
							/>
						</div>
						<v-btn id="addQuestion" class="gradientButton" elevation="0" @click="addQuestion" style="margin-top: 10px;">
							<v-icon start>mdi-plus</v-icon>
							<span>{{$t('text.addQuestion')}}</span>
						</v-btn>
					</v-card>
				</v-col>

				<v-col cols="12" :lg="showPreview ? 6 : 12" v-if="showPreview">
					<div id="preview" class="content pl-5">
						<h1>{{ faqGroup.fields.title[serviceLocale] }}</h1>
						<v-expansion-panels>
							<v-expansion-panel v-for="faq of faqGroup.fields.references.de" :key="faq.id"
								elevation="0"
								style="border-top: 1px solid #ddd;"
							>
								<v-expansion-panel-header>
									<h3>{{ faq.fields.question[serviceLocale] }}</h3>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div style="line-height:30px !important; white-space: pre-wrap;">
										{{ faq.fields.answer[serviceLocale] }}
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</v-col>
			</v-row>
		</div>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			:confirmLabel="$t('text.delete')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="onDeleteConfirmed"
			:cancel-handler="onDeleteCancelled"
			:showClose="false"
			:title="$t('text.deleteFAQ')" 
			height="250px"
			width="800px"
		>
			<template #content>
				<p style="margin:10px">{{$t('text.confirmFAQDelete')}}</p>
			</template>
		</Dialog>
	</Application>
</template>

<script>
import FAQComponent from './FAQComponent.vue'
import Common from '@/mixins/Common.vue'
import FreshdeskWidget from '@/utils/FreshdeskWidget.js'
import Dialog from '@/components/common/Dialog.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import SideBar from '@/components/common/SideBar.vue'
import Application from '../Application.vue'

export default {
	name: 'FAQDetailView',
	components: { SideBar, FAQComponent, LanguageFlag, Dialog, Application },
	mixins: [ Common ],
	data: () => ({
		title: '',
		status: 'Published',
		statusChanged: false,
		toggle_exclusive: 4,
		showPreview: false,
		faqGroup: {
			sys: { id:'' },
			fields: {
				title: {},
				references: { de: [] },
			},
		},
		previewSectionOpen: {},
	}),
	computed: {
		titleErrors() {
			if (this.faqGroup.fields.title[this.locales[0].code] === '') {
				return this.$t('text.missingFieldsError')
			}
			return ""
		},
		sidebarActions() {
			let actions = []

			actions.push({ icon: 'mdi-check', style: 'blue', dark: true, title: this.$t('text.saveChanges'), function: 'submit'})
			
			if (this.faqGroup?.sys?.id !== '') {
				actions.push({ icon: 'mdi-delete', style: 'red', dark: true, title: this.$t('text.deleteFAQ'), function: 'confirmDelete'})
			}

			actions.push({ icon: 'mdi-monitor-eye', style: 'none', dark: false, title: this.showPreview ? this.$t('text.hidePreview') : this.$t('text.showPreview'), function: 'togglePreview'})
			
			return actions
		},
		translations() {
			const tss = [
				this.getTranslationStatus('faqGroup', this.faqGroup),
				...this.faqGroup.fields.references.de.map(faq => this.getTranslationStatus('faq', faq)),
			]
			return this.averageTranslationStatuses(tss)
		},
	},
	methods: {
		handleAction(event) {
			this[event.function](event.params)
		},
		setLocale(code) {
			this.serviceLocale = code
		},
		confirmDelete() {
			this.$refs.deleteDialog.show = true
		},
		onDeleteConfirmed() {
			this.$refs.deleteDialog.show = false
			this.deleteFAQ()
			return  true
		},
		onDeleteCancelled() {
			this.$refs.deleteDialog.show = false
		},
		getColor () {
			const status = this.status.toLowerCase()
			if (status === 'changed') return '#3c80cf'
			if (status === 'saved') return '#589ae6'
			if (status === 'pending') return '#ff9e21'
			if (status === 'published') return '#66BB6A'
		},
		changeStatus() {
			this.status = 'Changed'
			this.statusChanged = true
		},
		togglePreview() {
			this.showPreview = !this.showPreview
			if (this.showPreview && !this.isSideBarFixed) {
				this.$nextTick(() => { document.getElementById('preview').scrollIntoView({ behavior: 'smooth'}) })
				this.$refs.sidebar.closeSidebar();
			}
		},
		getNewQuestion() {
			return {
				sys: { id: 'id_faq_' + Math.random() },
				fields: {
					question: {},
					answer: {},
				},
			}
		},
		addQuestion() {
			this.changeStatus()
			if (!this.faqGroup.fields.references.de) {
				this.faqGroup.fields.references.de = []
			}
			this.faqGroup.fields.references.de.push(this.getNewQuestion())
		},
		removeQuestion(index) {
			if (this.faqGroup.fields.references.de.length > 1) {
				this.faqGroup.fields.references.de.splice(index, 1)
			}
			else {
				this.faqGroup.fields.references.de[0].question = ''
				this.faqGroup.fields.references.de[0].answer = ''
			}
		},
		submit() {
			if (this.faqGroup.sys.id !== '') {
				this.updateFAQ()
			}
			else {
				this.createFAQ()
			}
		},
		showError(detail, title) {
			this.errorTitle = title ?? this.$t('text.ERROR')
			this.errorDetail = detail
		},
		async updateFAQ() {
			this.loading = true
			this.showPreview = false
			try {
				if (this.validate()) {
					for (var faq of this.faqGroup?.fields?.references?.de ?? []) {
						for (var locale of this.locales) {
							if (!faq.fields.answer[locale.code]) continue
							if (!(faq.fields.answer[locale.code]).indexOf("\r\n") < 0) continue
							faq.fields.answer[locale.code] = (faq.fields.answer[locale.code]).replace("\r\n", "\n").replace("\r", "\n")
						}
					}
					const res = await this.$httpPut('/faq', this.faqGroup)
					await this.getFAQDetail("update", res.faqGroupId)
				} else {
					this.showError(this.$t('text.faqValidationError'))
				}
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				if (error.response) {
					this.showError(error.response.data?.error)
				}
			}
			this.loading = false
		},
		async createFAQ() {
			this.loading = true;
			this.showPreview = false;

			try {
				const isValid = this.validate()
				if (isValid) {
				for (var faq of this.faqGroup?.fields?.references?.de ?? []) {
					for (var locale of this.locales) {
					// TODO: why is this line different than in updateFAQ?
					if (!faq.fields.answer[locale.code]) continue
					faq.fields.answer[locale.code] = (faq.fields.answer[locale.code]).replace(/\n\r?/g, '\r\n');
					}
				}
				const requestData = {
					serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
					faqGroup: this.faqGroup,
				}
				const res = await this.$httpPost('/faq', requestData)
				await this.getFAQDetail("create", res.faqGroupId)
				} else {
					this.showError(this.$t('text.faqValidationError'))
				}
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.data.error : error
			}
			this.loading = false
		},
		async getFAQDetail(action, faqGroupId) {
			this.loading = true
			try {
				const res = await this.$httpGet(`/faq?faqId=${faqGroupId}`)
				await this.$store.commit('setSelectedFAQGroup', res.faqGroup)
				this.faqGroup = res.faqGroup
				this.status = 'published'
				this.statusChanged = false

				if (action === 'create') {
					this.successTitle = this.$t('text.FAQ_CREATED')
					this.successDetail = this.$t('text.faqCreateSuccess')
				}
				if (action === 'update') {
					this.successTitle = this.$t('text.FAQ_UPDATED')
					this.successDetail = this.$t('text.faqUpdateSuccess')
				}
			}
			catch (error) {
				if (error.response?.status === 401) this.$emit("show-login")
				console.error(error)
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.data.error : error
			}
			this.loading = false
		},
		async deleteFAQ() {
			this.loading = true
		
			try {
				const res = await this.axios.delete('/faq-group', 
				{
					headers: this.requestHeaders(),
					data: {
						serviceProviderId: this.$store.state.selectedServiceProvider.sys.id,
						faqGroupId: this.faqGroup.sys.id
					} 
				})

				if (res?.message === 'cannot delete linked entry') {
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = this.$t('text.faqInUseError')
				} else {
					this.successTitle = this.$t('text.FAQ_DELETED')
					this.successDetail = this.$t('text.faqDeletedSuccess')
					this.sleep(2000).then(() => { this.$router.push("/faq-list") });
				}
			}
			catch (error) {
				this.showError(error.response ? error.response?.data.error : error)
			}
			
			this.loading = false
		},
		validate() {
			let isValid = true

			for (const locale of this.locales) {
				const faqs = this.faqGroup?.fields?.references?.de ?? []
				for (const faq of faqs) {
					const { question, answer } = faq.fields
					if ((question[locale.code] || answer[locale.code]) && !this.faqGroup.fields.title[locale.code]) {
						isValid = false
						break
					}
					if ((question[locale.code] && !answer[locale.code]) || (!question[locale.code] && answer[locale.code])) {
						isValid = false
						break
					}
				}
				if (!isValid) {
					break
				}
			}
			return isValid
		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		goback() {
			this.$router.back()
		},
	},
	created () {
		FreshdeskWidget.methods.prefill('MyServices')
	},
	mounted() {
		this.defaultLocale = this.locales[0]
		if (this.$store.state.selectedFAQGroup && Object.keys(this.$store.state.selectedFAQGroup).length > 0) {
			this.faqGroup = this.$store.state.selectedFAQGroup;
		}
		else {
			this.faqGroup = {
				sys: { id: '' },
				fields: {
					title: {},
					references: {
						de: [ this.getNewQuestion() ],
					},
				},
			}
		}
	},
}
</script>

<style scoped>
a { font-size: 10pt; }
hr { color: #efefef; }
h3 { color: black; line-height: 40px; }
p { color: dimgray; font-size: 14pt; }
.list-group-item { cursor: move; cursor: -webkit-grabbing; }
#preview {
  background-color: #ffffff;
  border-radius: 10px;
}
.contentCard {
  border-radius: 20px;
  background-color: #ffffff;
  margin-left: 10px;
  margin-right: 20px;
  min-height: 100px;
  padding: 10px;
  text-align: left;
}
.langFlag { position: absolute; margin-top: 29px; margin-left: -29px; zoom: 0.8; }
</style>