<template>
	<div style="width:100%">
		<loading v-model:active="loading" :is-full-page="true" color="#4caf50" data-cy="loading" />

		<!-- Select Attribute Set -->
		<div class="pa-0" v-if="!selectedAttributeSet">
			<v-label>{{ $t('text.chooseCategory') }}</v-label>
			<p class="text" v-html="$t('text.categoryCommissionHelp')"/>

			<div v-if="attributeSets?.length">
				<v-radio-group v-model="selectedAttributeSet" class="pa-10">
					<div v-for="attributeSet of attributeSets" :key="attributeSet.attribute_set_id">
						<v-radio :label="attributeSet.attribute_set_name" :value="attributeSet" class="text" />
					</div>
				</v-radio-group>
			</div>
		</div>

		<!-- Commissions -->
		<template v-if="selectedAttributeSet">
			<v-label>{{ $t('text.categoryBasedCommission') }}</v-label>
			<p class="text" v-html="$t('text.categoryBasedCommissionHelpText')"/>

			<div style="margin-left: -15px; margin-right: -15px;">
				<v-col class="mt-5 pa-0">
					<v-row class="pa-0" style="background-color:#F4F4F4;">
						<span class="text pa-3 pl-4">{{ selectedAttributeSet.attribute_set_name }}</span>
						<v-spacer/>
						<v-btn class="none mt-0" elevation="0" @click="addRow()"><v-icon color="#646464">mdi-plus</v-icon></v-btn>
					</v-row>
					<v-divider/>
					<v-row class="pa-3" style="background-color: #F4F4F4; border-bottom: 1px solid #dddddd; margin-bottom: 10px;">
						<span style="width: 30%;" class="pl-1">{{ $t('text.salesChannel') }}</span>
						<span style="width: 25%;" class="pl-3">{{ $t('text.validFrom') }}</span>
						<span style="width: 25%;" class="pl-3">{{ $t('text.validUntil') }}</span>
						<span style="width: 15%; text-align: right;" class="pr-3">{{ $t('text.commission') }}</span>
						<span style="width: 5%;"></span>
					</v-row>
					<v-row v-if="!model || model.length === 0" class="comms-row pa-5">
						{{ $t('text.noCategoryBasedCommissions') }}
					</v-row>
					<div v-for="commission of model" :key="commission.sys.id" class="pt-2">
						<CommissionLineItem
							:commission="commission"
							:commissionErrors="commissionErrors"
							:datesDisabled="datesDisabled"
							@delete-row="deleteRow"
						/>
					</div>
				</v-col>
			</div>
			<div v-if="errorMessage.length" class="inline-error">{{ errorMessage }}</div>
		</template>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import CommissionLineItem from './CommissionLineItem.vue'
import Common from '@/mixins/Common.vue'
import { Commission } from '@/models/commission.ts'
import { CommissionType } from '@/plugins/enum.js'
import isEqual from 'lodash/isEqual'

export default {
  	name: 'CategoryCommission',
	components: { Loading, CommissionLineItem },
	mixins: [ Common ],
	props: {
		existingCommissions: Array,
		commissionErrors: Object,
		datesDisabled: Boolean,
	},
	data() { return {
		loading: false,
		errorMessage: '',

		model: [],
		attributeSets: this.$store.state.attributeSets ?? [],
		selectedAttributeSet: null,
	}},
	watch: {
		selectedAttributeSet() {
			this.$emit('select-attribute-set')

			if (this.selectedAttributeSet) {
				this.addRow()
			} else {
				this.model = []
			}
		},
		existingCommissions: {
			deep: true,
			handler() {
				this.model = JSON.parse(JSON.stringify(this.existingCommissions ?? []))
			},
		},
		model: {
			deep: true,
			handler() {
				this.validateCommissions()
			},
		},
	},
	methods: {
		sendData() {
			let data = {
				commissions: this.model
			}

			data.changed = !isEqual(data, this.initData)

			return data
		},
		addRow() {
			const newCommission = new Commission()
			const nr = this.model?.length ? this.model.length : '0'
			newCommission.sys.id = `id_${ nr }`
			newCommission.fields.type = CommissionType.CATEGORY
			newCommission.fields.attributeSet = {id: this.selectedAttributeSet.attribute_set_id, name: this.selectedAttributeSet.attribute_set_name}
			this.model.push(newCommission)

			this.validateCommissions()
		},
		deleteRow(id) {
			const index = this.model.findIndex(comm => comm.sys.id === id)
			
			if (index > -1) {
				this.model.splice(index, 1)
			}

			this.validateCommissions()
		},
		validateCommissions() {
			//Check if there are any commissions defined
			if (!this.model.length) {
				this.$emit('enable-confirm', false)
				this.errorMessage = this.$t('text.commissionMissing')
				return
			}

			this.errorMessage = ''
			let isValid = true

			for (const item of this.model) {
				if (!item.fields.percentage.length) {
					isValid = false
				}
			}

			this.$emit('enable-confirm', isValid)
		},
		async getAttributeSets() {
			if (this.attributeSets.length === 0) {
				this.loading = true
				try {
					const response = await this.$httpGet(`/peak/attribute-sets/${this.$store.state.selectedClient.fields.installationId.de}`)
					this.attributeSets = response?.attributeSets
				}
				catch (error) {
					console.error('cannot load attribute sets', error)
				}
				this.loading = false
			}
		}
	},
	async mounted() {
		await this.getAttributeSets()
	},
}
</script>

<style scoped>
.text { color: black !important; font-family: 'Inter', sans-serif !important; font-size: 13pt !important; }
.close { position:absolute; right: 0px; } 
.comms-row { background-color: #ffffff; }
.inline-error {
	background-color: transparent;
  	color: #F34545;
	font-family: 'Inter', sans-serif;
	font-size: 12pt;
}
</style>