<template>
	<div class="LoadingOverlay" v-if="show">
		<v-overlay v-model="overlay" persistent class="LoadingOverlay-overlay" scroll-strategy="none">
			<Loading :active="true" :is-full-page="true" color="#4caf50" data-cy="loading" />
		</v-overlay>
	</div>
</template>

<script lang="ts">
import 'vue-loading-overlay/dist/css/index.css'
import Loading from 'vue-loading-overlay'
import eventBus from '../../utils/eventBus.js'

export default {
	components: { Loading: Loading as any },
	data: () => ({
		loadingCount: 0,
		loadingComponents: {},
		overlay: true,
		show: false,
	}),
	methods: {
		startLoading(uid, key, messageKey) {
			if (!this.loadingComponents[uid]) this.loadingComponents[uid] = {}
			if (this.loadingComponents[uid][key]) return
			this.loadingComponents[uid][key] = messageKey || true
			this.loadingCount++
			this.updateLater()
		},
		stopLoading(uid, key) {
			if (!this.loadingComponents[uid]?.[key]) return
			// when a component unmounts, it will call stopLoading('*') to clear all its loading states
			if (key === '*') {
				for (const key in this.loadingComponents[uid]) {
					delete this.loadingComponents[uid][key]
					this.loadingCount--
					this.updateLater()
				}
				return
			}
			delete this.loadingComponents[uid][key]
			this.loadingCount--
			this.updateLater()
		},
		// we debounce the state with this, so the overlay doesn't flicker
		updateLater() {
			if (this.show == this.loadingCount > 0) return
			window.setTimeout(() => {
				this.show = this.loadingCount > 0
			}, 50)
		},
	},
	async mounted() {
		eventBus.$on('startLoading', this.startLoading)
		eventBus.$on('stopLoading', this.stopLoading)
	},
	beforeDestroy() {
		eventBus.$off('startLoading', this.startLoading)
		eventBus.$off('stopLoading', this.stopLoading)
	},
}
</script>

<style scoped>
.LoadingOverlay { position: fixed; inset: 0; }
</style>

<style>
.LoadingOverlay-overlay { z-index: 9998 !important; }
/* if we want to show the header, we'd need to set top: 60px */
.LoadingOverlay-overlay .v-overlay__scrim { top: 0px; }
.LoadingOverlay-overlay .vl-overlay { top: 0px; }
.LoadingOverlay-overlay .v-overlay__content { contain: initial !important; }
</style>