<template>
	<table class="table">
		<tr>
			<td v-for="header of headers" :key="header">
			<th>
				<p>{{ header }}</p>
			</th>
			</td>
		</tr>
		<tr v-for="product of displayedItems" :key="product.included_product_key">
			<td>
				<v-icon color="#787777">mdi-ticket</v-icon>
			</td>

			<td>
				<div>
					<!-- TODO: no product location in the response ATM -->
					<!-- <p>{{ product.location }}</p> -->
					<p>{{ product.fields.name?.de }}</p>
				</div>
			</td>

			<td>
				<!-- TODO: no marketplaces ATM -->
				<!-- <div style="display:inline-block" v-for="category of product.category[selectedLocale]" :key="category">
					<span v-if="category" class="clientAssignmentChip" :class="{ [product.approval]: true, }"
						:title="category ?? ''">
						{{ category }}
					</span>
				</div> -->
			</td>

			<td>
				<!-- TODO: translation status, not workflow status -->
				<!-- <Status :status="product.status" /> -->
			</td>

			<td>
				<v-icon @click="$router.push(`/packages/${packageId}`)">mdi-chevron-right</v-icon>
			</td>
		</tr>

		<tr v-if="!showMoreProducts && items?.slice(2)?.length">
			<td>
				<v-icon color="#787777">mdi-ticket</v-icon>
			</td>
			<td>
				+{{ items.length - 2 }} {{ $t('text.more') }}
			</td>
			<td></td>
			<td>
				<p class="show-more" @click="showMore">{{ $t('text.showMore') }}</p>
			</td>
		</tr>
	</table>
</template>

<script>
import Common from '@/mixins/Common.vue'
import Status from '@/components/common/tables/Status.vue'

export default {
	name: 'IncludedProductsTable',
	components: { Status },
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		packageId: String,
	},
	mixins: [Common],
	data() {
		return {
			showMoreProducts: false,
		}
	},
	computed: {
		displayedItems() {
			console.log('items', this.items)
			return this.showMoreProducts ? this.items : this.items.slice(0, 2)
		},
		headers() {
			return [this.$t('text.type'), this.$t('text.service'), this.$t('text.marketplace'), this.$t('text.status')]
		}
	},
	methods: {
		showMore() {
			this.showMoreProducts = true
		},
	},
	mounted() {
		console.log('items', this.items)
	},
}
</script>

<style scoped lang="scss">
.table {
	width: 100%;
	border-collapse: collapse;

	tr {
		cursor: default;
	}

	tr:first-child {
		border-bottom: 1px solid grey;

		td {
			padding-bottom: 8px;
		}
	}

	tr:not(:first-child) td {
		padding-top: 8px;
	}

	.show-more {
		color: #75b9ef;
		font-weight: bold;
		cursor: pointer;
	}
}
</style>
