<template>
	<div v-if="$store.state.featureFlags.FEATURE_AI_TRANSLATIONS && aiContext" class="aiTranslation"
		:class="{
			aiLoading: !hasMultiFields && loading,
			hasActivator: $slots['activator'],
		}"
	>
		<div class="loadingText" v-if="!hasMultiFields && loading">
			{{ $t('text.automaticallyTranslating') }}
		</div>
		<div class="activator">
			<slot name="activator">
				<div class="d-flex align-center">
					<IconAi :color="loading ? '#8F8F8F' : undefined" class="activator__icon"/>
				</div>
			</slot>
			<v-menu :activator="loading ? null : 'parent'" class="aiTranslation__langMenu">
				<v-list>
					<v-list-item v-if="!featureEnabled('AI-translations')">
						<ProFeatureTag />
					</v-list-item>
					<v-list-item>
						<v-list-item-subtitle>{{ $t('text.translateFrom') }}</v-list-item-subtitle>
						<v-select
							v-model="selectedFromLocale"
							:items="translateFromLocales"
							variant="outlined"
							density="compact"
							@click.stop
						>
							<template #selection="{ item }">
								<LanguageFlag v-model="item.title" class="mr-3" />
								{{ $t('text.' + getLocaleName(item.title)) }}
							</template>
							<template #item="{ item, props }">
								<v-list-item v-bind="props" v-if="item.title !== locale" >
									<template #title></template>
									<LanguageFlag v-model="item.title" class="mr-3" />
									{{ $t('text.' + getLocaleName(item.title)) }}
								</v-list-item>
							</template>
						</v-select>
					</v-list-item>
					<v-divider />
					<v-list-item @click="translateWithAi" :disabled="!selectedFromLocale || !featureEnabled('AI-translations')">
						<template #prepend>
							<IconAi color="#424242" />
						</template>
						<v-list-item-title>{{ $t('text.translateWithAi') }}</v-list-item-title>
					</v-list-item>
					<v-list-item @click="$refs.settingsDialog.open()" :disabled="!featureEnabled('AI-translations')">
						<template #prepend>
							<v-icon>mdi-cog</v-icon>
						</template>
						<v-list-item-title>{{ $t('text.aiSettings') }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<DialogV2
			ref="settingsDialog"
			:title="$t('text.translationSettings')"
			:onConfirm="confirmTranslationSettings"
			:disableConfirmButton="$store.state.translationSettings === null"
			@close="translationSettingsModel = $store.state.translationSettings"
		>
			<template #content>
				<TranslationSettings v-model="translationSettingsModel" />
			</template>
		</DialogV2>
	</div>
</template>

<script lang='ts'>
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Common from '../../mixins/Common.vue'
import IconAi from '@/components/common/icons/IconAi.vue'
import DialogV2 from '@/components/common/DialogV2.vue'
import TranslationSettings from '@/components/common/TranslationSettings.vue'
import type { TranslationResponse } from '../../../../shared/freddy'
import { AiTextLoadingItem } from '../../mixins/AiContext.vue'
import Toast from '../../mixins/Toast.vue'
import ProFeatureTag from '@/components/common/ProFeatureTag.vue'

export default {
	components: { TranslationSettings, DialogV2, IconAi, LanguageFlag, ProFeatureTag },
	mixins: [ Toast, Common ],
	inject: {
		aiContext: {
			default: undefined
		}
	},
	props: {
		locale: String,
		// { typeName, fieldName, value }
		fields: Array,
	},
	data: () => ({
		selectedFromLocale: null,
		translationSettingsModel: null
	}),
	computed: {
		hasMultiFields() {
			return this.fields?.length > 1
		},
		translateFromLocales(): string[] {
			const languageCodes = new Set<string>()
			for (const field of this.fields) {
				for (const locale in field.value) {
					if (field.value[locale]?.length > 0 &&
						field.value[locale] !== '<p></p>' &&
						this.$store.state.locales.some(sl => sl.code === locale) &&
						locale !== this.activeTranslation) {
						languageCodes.add(locale)
					}
				}
			}
			return Array.from(languageCodes)
		},
		loadingItem(): AiTextLoadingItem | undefined {
			if (!this.fields?.length) return
			if (this.hasMultiFields) return
			if (!this.aiContext) return
			return (this.aiContext as any).aiContext_getLoadingItem(this.fields[0].id, this.locale)
		},
		loading() {
			return this.loadingItem?.status === 'LOADING'
		},
		serviceProviderId() {
			return this.$store.state.selectedServiceProvider.sys.id
		},
		activeTranslation() {
			// for multiple field translation no this.locale is given
			return this.locale ?? this.$store.state.activeTranslation
		},
		defaultLocaleCode() {
			return this.$store.state.locales?.find(l => l.default === true)?.code
		},
		storeTranslationSettings() {
			return this.$store.state.translationSettings
		}
	},
	watch: {
		translateFromLocales(n) {
			this.setDefaultSelectedFromLocale()
		},
		storeTranslationSettings(n) {
			this.translationSettingsModel = n
		}
	},
	methods: {
		setDefaultSelectedFromLocale() {
			// set locale to translate from to defaultLocale as default and the next available if the field itself has the defaultLocale
			if (this.locale !== this.defaultLocaleCode && this.translateFromLocales.includes(this.defaultLocaleCode)) this.selectedFromLocale = this.defaultLocaleCode
			else this.selectedFromLocale = this.translateFromLocales.find(l => l !== this.locale)
		},
		getLocaleName(lang: string): string {
			const mappedStoreLocaleName = this.$store.state.locales.find(l => l.code === lang)?.name
			return mappedStoreLocaleName ? this.$t(mappedStoreLocaleName) : lang
		},
		async translateWithAi() {
			if (!this.aiContext) return

			let toLocales = []
			if (this.locale) {
				toLocales = [this.locale]
			} else if (this.activeTranslation === 'all') {
				toLocales = this.$store.state.locales
				.filter(l => l.code !== this.selectedFromLocale)
				.map(l => l.code)
			} else {
				toLocales = [this.activeTranslation]
			}

			await (this.aiContext as any).aiContext_translate(
				this.fields,
				this.selectedFromLocale,
				toLocales,
				(toLocale: String, r: TranslationResponse, error = null) => {
					if (error) {
						this.showErrorToast('aiTranslationError')
						return
					}
					r.translations.forEach(t => {
						const index = this.fields.findIndex((f: any) => f.id === t.key)
						this.fields[index].value[toLocale] = t.toText
					})
				}
			)
		},
		async confirmTranslationSettings() {
			if (this.translationSettingsModel === null || !this.serviceProviderId) {
				return
			}
			try {
				const res = await this.$httpPost('/aiSettings', { translationSettings: this.translationSettingsModel, serviceProviderId: this.serviceProviderId })
				this.$store.commit('setTranslationSettings', this.translationSettingsModel)
			}
			catch (error) {
				console.log(error)
			}
		},
	},
	mounted() {
		this.setDefaultSelectedFromLocale()
		if (this.aiContext) {
			this.translationSettingsModel = this.$store.state.translationSettings
		}
	},
}
</script>

<style scoped lang='scss'>
.aiTranslation {
	&:not(.hasActivator) {
		pointer-events: none;
		padding: 8px;
		position: absolute;
		inset: 0;
		.activator {
			position: absolute;
			right: 8px;
			bottom: 0;
			height: 39px;
			display: flex;
			align-items: center;
			&__icon {
				pointer-events: all; cursor: pointer;
			}
		}
	}
	&.aiLoading {
		background: #eee; border: 1px solid #ddd; border-radius: 6px; pointer-events: all;
	}
	> .loadingText {
		position: absolute; left: 8px; bottom: 8px; cursor: default; font-size: 0.875rem;
	}
}
</style>

<style lang='scss'>
.aiTranslation {
	&__langMenu {
		.v-input__details {
			display: none;
		}
		.v-list {
			padding: 0;
			&-item__prepend .v-list-item__spacer {
				width: 10px;
			}
		}
	}
}

.TextField,
.TiptapField,
.LongTextField {
	&.localized {
		.v-toolbar__content,
		.input {
			padding-right: 40px !important;
		}
	}
}
</style>
