<template>
	<div class="SkuEditor" :class="{ disabled }">
		<div v-for="part, p in parts" :key="part.id" class="part">
			<label v-if="part.editable">
				{{ $t('text.' + (id ?? 'sku') + '-' + part.id) }}
			</label>
			<div class="editable" v-if="part.editable">
				<input type="text" class="input"
					:data-cy="'sku-part-' + part.id"
					:class="{ beeping: beeping == part.id }"
					v-model="model[part.id]"
					:placeholder="getPlaceholder(part)"
					:style="{ width: ((model[part.id] ?? '').length + 3) + 'ch', minWidth: `${model[part.id] ? 5 : getPlaceholder(part).length + 3}ch` }"
					:disabled="disabled"
					@beforeinput="e => beforeInput(e, part.id)"
					@input="e => onInput(e, part.id)"
					:maxlength="32"
				/>
				<span v-if="p < parts.length - 1">.</span>
			</div>
			<div v-else class="fixed">
				{{ part.defaultValue }}<span v-if="p < parts.length - 1">.</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		modelValue: String,
		parts: { type: Object, default: [
			{ id: 'prefix', defaultValue: 'PACKAGETRAVEL' },
			{ id: 'group', defaultValue: '', editable: true },
			{ id: 'place', defaultValue: '', editable: true },
		] },
		disabled: Boolean,
		id: String,
	},
	data: () => ({
		model: {},
		beeping: null,
		disallowed: /[^A-Za-z0-9_\-]/g,
		disallowedLastPart: /[^A-Za-z0-9_\-\.]/g,
	}),
	computed: {
		lastPart() {
			return this.parts[this.parts.length - 1]
		},
	},
	watch: {
		modelValue(n) { this.model = this.valueToModel(n) },
		model: {
			deep: true,
			handler(n) {
				const r = this.parts.map(part => n[part.id] ?? part.defaultValue)
				this.$emit('update:modelValue', r.join('.'))
			},
		},
	},
	methods: {
		getPlaceholder(part) {
			return part.placeholder || part.id.toUpperCase()
		},
		// TODO: test copy & paste
		beforeInput(e, partId) {
			// theck if the input character would be filtered
			const before = e.data ?? ''
			const after = before
				.replace(partId != this.lastPart.id ? this.disallowed : this.disallowedLastPart, '')
			if (before != after) {
				e.preventDefault()
				this.beep(partId)
				return false
			}
		},
		// fix some more things after-the-fact
		onInput(e, partId) {
			var ss = e.target.selectionStart
			var se = e.target.selectionEnd
			e.target.value = e.target.value
				.toUpperCase()
				.replace(partId != this.lastPart.id ? this.disallowed : this.disallowedLastPart, '')
			e.target.selectionStart = ss
			e.target.selectionEnd = se
			this.model[partId] = e.target.value
		},
		beep(partId) {
			this.beeping = partId
			setTimeout(() => {
				this.beeping = null
			}, 150)
		},
		valueToModel(n) {
			if (!n) return {}
			const parts = n.split('.')

			// add any missing parts
			while (parts.length < this.parts.length) parts.push('')

			// JS does not give the remainder in the last part, we have to accumulate them..
			let remainder = ''
			while (parts.length >= this.parts.length) remainder = parts.pop() + (remainder ? '.' : '') + remainder
			if (remainder) parts.push(remainder)

			const r = {}
			this.parts.forEach((part, i) => r[part.id] = parts[i])
			return r
		},
	},
	mounted() {
		this.model = this.valueToModel(this.modelValue)
	},
}
</script>

<style scoped lang="scss">
.SkuEditor { display: flex; align-items: end; }
.part { display: flex; flex-direction: column; gap: 2px; }
label { color: #67728a; font-size: 14px; }
.input,
.editable {
	font-family: monospace;
	display: flex;
	align-items: flex-end;

	span { padding: 4px 0; }
}
.fixed { font-family: monospace; }
.fixed { padding: 4px 0; color: #67728a; }
.disabled .input { color: #999; pointer-events: none; }
/* TODO: how could we inherit the styles of other inputs? */
.input { border: 1px solid #ccc; border-radius: 5px; padding: 4px 8px; }
.beeping { outline: 2px solid red; }
</style>