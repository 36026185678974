<template>
	<StepperDialog ref="dialog" v-model="configurableBergbahnProduct" v-model:stepId="step" group="ConfigurableBergbahnProduct" :order="[ 'products', 'general' ]"
		:onConfirm="handleConfirm"
		:onCancel="handleCancel"
		:disableConfirmButton="disableConfirmButton"
		width="90vw"
		height="95vh"
	>
		<template #title>
			<v-toolbar-title>
				<span>{{ $t("text.newConfigurableProduct") }}</span>
			</v-toolbar-title>
		</template>

		<Step id="products" group="ConfigurableBergbahnProduct" icon="mdi-shape" class="stepWithoutStepper">
			<div style="border-bottom: 1px solid rgb(221, 221, 221);" class="px-8 py-3">				
				<div class="d-flex align-center ga-8">
					<div class="d-flex ga-4 flex-grow-1">
						<v-text-field
							variant="outlined"
							density="compact"
							clearable
							hide-details
							prepend-inner-icon="mdi-magnify"
							:placeholder="$t('text.searchPeakSpPlaceholder')"
							v-model="filters.searchString"
							@keyup.enter="search"
							@update:modelValue="filters.searchString = $event"
							@click:clear="clear"
							data-cy="search"
						/>
						<v-btn class="blueButton" theme="dark" elevation="0" @click="search" data-cy="searchButton">
							{{ $t('text.search') }}
						</v-btn>
					</div>
					<ProductFilters :onApplyFilter="applyFilters" />
				</div>
			</div>
			<div class="content">
				<p class="title">{{ $t('text.chooseProducts') }}</p>
				<p>{{ $t('text.chooseProductsForConfigurableMRWHelp') }}</p>
			</div>
			<ProductsTable v-model:items="products"
				:total="99999"
				:limit="filters.limit" @update:limit="updateLimit"
				:offset="filters.offset" @update:offset="updateOffset"
				:loading="loading"
				v-model:selected="selected"
			/>
		</Step>
		<Step id="general" group="ConfigurableBergbahnProduct" :forceStatus="requiredFieldsAreFilled ? 'complete' : null" icon="mdi-cog" :disabled="!selectedProducts.length" class="stepWithoutStepper">
			<div class="gapped">
				<FieldSet id="globalSetting" mdiIcon="earth" ></FieldSet>
				<label>{{ $t('text.generalSettings') }}</label>
				<TranslateableField typeName="AddedValue" fieldName="name" v-model="configurableBergbahnProduct.name"
					:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales" :requiredLocales="['de']"
					dataCy="configurable-bregbahn-product-name"
				/>
				<Field title="SKU" required>
					<SkuEditor id="pdSku"
						v-model="configurableBergbahnProduct.sku.de"
						:parts="[
							{ id: 'prefix', defaultValue: 'BERGBAHN' },
							{ id: 'group', defaultValue: 'ANGEBOT' },
							{ id: 'sku', defaultValue: '', editable: true, placeholder: $t('text.enterSku') },
						]"
					/>
				</Field>
				<FieldSet id="salesChannels" >
					<!-- TODO: disable websites in the picker that are not included within the websites of all selected virtual products -->
					<!-- TODO: show a helper text next to the dropdown options as specified in the designs "Used by all selected Products"/ "Used by XXX Product"-->
					<v-select
						v-model="configurableBergbahnProduct.websites.de"
						:items="this.injectedData.salesChannels"
						:placeholder="$t('text.chooseSalesChannels')"
						multiple
						chips
						hide-details
						density="compact"
						variant="outlined"
						return-object
						:item-title="item => item.name"
						:item-value="item => item"
					>
						<template #selection="{ item }">
							<v-chip dark>{{ item }}</v-chip>
						</template>
						 <template v-slot:item="{ props, item }">
							<v-list-item
								v-bind="props"
								:subtitle="getSalesChannelMessage(item)"
								:disabled="getSalesChannelStatus(item).disabled"
							>
								<v-list-item-title>{{ item.name }}</v-list-item-title>
							</v-list-item>
						</template>
					</v-select>
				</FieldSet>
			</div>
		</Step>

		<template #sidebar v-if="step=='general'">
			<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>
	</StepperDialog>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import Field from '@/components/fields/Field.vue'
import DataDialog from '../../../../components/common/DataDialog.vue'
import StepperDialog from '../../../../components/common/StepperDialog.vue'
import LanguageSidebar from '../../../../components/common/LanguageSidebar.vue'
import Step from '../Step.vue'
import ProductsTable from '../tables/ProductsTable.vue'
import Common from '../../../../mixins/Common.vue'
import LanguagesNavigation from '../../../../mixins/LanguagesNavigation.vue'
import ProductFilters from '../ProductFilters.vue'
import FieldSet from '../FieldSet.vue'
import SkuEditor from '../SkuEditor.vue'
import TranslateableField from '../../../../components/fields/TranslateableField.vue'
import Toast from '../../../../mixins/Toast.vue'
import Loading from '@/mixins/Loading.vue'

export default {
	components: { Field, DataDialog, StepperDialog, LanguageSidebar, Step, ProductsTable, ProductFilters, FieldSet, SkuEditor, TranslateableField },
	mixins: [Common, LanguagesNavigation, Toast, Loading],
	inject: [ 'injectedData' ],
	props: {
		type: {
			type: String,
			required: true,
		},
		products: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			configurableBergbahnProduct: {
				name: { de: '', en: '', fr: '', it: '', nl: '' },
				sku: { de: '' },
				websites: { de: [] },
			},
			step: null,
			products: [],
			selected: [],
			selectedProducts: [],
			filters: { searchString: '', limit: 10, offset: 0 },
		}
	},
	computed: {
		disableConfirmButton() {
			if (this.step == 'products') return !this.selectedProducts.length

			return !this.requiredFieldsAreFilled
		},
		requiredFieldsAreFilled() {
			const isSkuValid = this.configurableBergbahnProduct.sku.de && this.configurableBergbahnProduct.sku.de?.toUpperCase() !== 'BERGBAHN.ANGEBOT.'

			return this.configurableBergbahnProduct.name.de && isSkuValid && this.configurableBergbahnProduct.websites.de.length
		},
		displayedLocales() {
			return this.languageNavigationItems?.reduce((locales, { code }) => {
				if (code !== "all" && (this.$store.state.activeTranslation === "all" || this.$store.state.activeTranslation === code)) {
					locales.push(code)
				}
				return locales
			}, [])
		},
	},
	watch: {
		selected: {
			deep: true,
			immediate: true,
			handler: function(selected) {
				if (!selected.some(item => Number.isInteger(item))) {
					this.selectedProducts = selected
					return
				}

				this.selectedProducts = selected.map(item => {
					const product = this.products.find(({ id }) => id === item || id === item?.product_id)

					if (!product) return {}

					return product
				})
			},
		},
	},
	methods: {
		async open() {
			this.$refs.dialog.open()
			await this.getProducts()
		},
		async handleConfirm() {
			if (this.step == 'general') {
				await this.loadingOverlay(async () => {
					try {
						const serviceProviderData = {
							id: this.$store.state.selectedServiceProvider.sys.id,
							packageAssignmentMode: this.$store.state.selectedServiceProvider.fields.mainUserAccount?.fields.packageDesignerConfig?.de?.packageAssignmentMode?.de,
							firstLinkedProductSku: this.selectedProducts[0].product_sku,
						}
						const payload = {
							configurableBergbahnProduct: {
								...this.configurableBergbahnProduct,
								sku: this.configurableBergbahnProduct.sku.de,
								websites: this.configurableBergbahnProduct.websites.de.map(website => website.id),
								validFrom: this.injectedData.packageDetails?.fields?.startDate?.de,
								validTo: this.injectedData.packageDetails?.fields?.endDate?.de,
								taxClass: this.injectedData.packageDetails?.fields?.taxClass?.de,
								// from (stop)
								// to (stop)
							},
							virtualProducts: this.selectedProducts.map(product => product.id),
							serviceProviderData
						}
			
						const productId = await this.$httpPost(`/packageTravel/configurableBergbahnProduct?clientId=${this.injectedData.clientId}`, payload)
						this.showSuccessToast()
						this.$emit('finished', productId)
					}
					catch (e) {
						console.log('error creating configurable BERGBAHN product', e)
						this.showErrorToast()
					}
				}, 'createConfigurableBergbahnProduct')
			}
		},
		handleCancel() {
			this.configurableBergbahnProduct = {
				name: { de: '', en: '', fr: '', it: '', nl: '' },
				sku: { de: '' },
				websites: { de: [] },
			}
		},
		checkIfHasMissingTranslations(locale) {
			return !this.configurableBergbahnProduct.name[locale]
		},
		search() {
			this.filters.offset = 0
			this.getProducts()
		},
		clear() {
			this.filters.offset = 0
			this.filters.categories = []
			this.filters.priceRangeFrom = null
			this.filters.priceRangeTo = null
			this.filters.salesChannels = []
			this.filters.status = []
			this.filters.searchString = ''
			this.getProducts()
		},
		updateLimit(limit) {
			this.filters.offset = 0
			this.filters.limit = limit
			this.getProducts()
		},
		updateOffset(offset) {
			this.filters.offset = offset
			this.getProducts()
		},
		async applyFilters(filters) {
			this.filters = { ...this.filters, ...filters }
			this.getProducts()
		},
		async getProducts() {
			await this.loadingOverlay(async () => {
				try {
					const productFilters = { ...omitBy(this.filters, value => isEmpty(value?.toString())) }
					productFilters.productType = 'MRW_VIRTUAL'
					const clientId = this.injectedData.clientId

					const r = await this.$httpPost(`/packageTravel/products?clientId=${clientId}`, { ...productFilters, type: this.type })
					this.products = r.products.map(item => ({
						...item,
						activities: omitBy(item.activities, isEmpty), id: item.product_id,
						product_name: item.product_name,
					}))
				}
				catch (error) {
					console.log('error fetching products', error)
					this.errorTitle = this.$t('text.ERROR')
					this.errorDetail = error.response ? error.response.error : error
				}
			}, 'getBergbahnProducts')
		},
		salesChannelIsNotShared(salesChannel) {
			// TODO: use translation strings for the messages
			// If only one product is selected, all sales channels should be enabled
			if (this.selectedProducts.length <= 1) {
				return {
					disabled: false,
					message: this.$t('text.allSelectedProductsUsingChannel'),
					productsUsingChannel: this.selectedProducts.map(product => product.product_name)
				}
			}

			// Get the ID of the sales channel to be checked
			const salesChannelId = salesChannel.value.id

			// Extract the websites from each selected product
			const selectedWebsites = this.selectedProducts.map(product => ({
				productName: product.product_name,
				websites: product.product_websites
			}))

			// Get the list of products using the sales channel
			const productsUsingChannel = selectedWebsites
			.filter(product => product.websites.some(website => website.product_website_id === salesChannelId))
			.map(product => product.productName)

			// Check if the sales channel is present in all selected websites
			const isShared = productsUsingChannel.length === this.selectedProducts.length

			// Return the object with disabled status and message
			return {
				disabled: !isShared,
				message: isShared ? this.$t('text.allSelectedProductsUsingChannel') : productsUsingChannel.length ? `${this.$t('text.productsUsingThisChannel')}: ${productsUsingChannel.join(', ')}` : this.$t('text.noProductsUsingChannel'),
				productsUsingChannel
			}
		},
		getSalesChannelStatus(salesChannel) {
			return this.salesChannelIsNotShared(salesChannel)
		},
		getSalesChannelMessage(salesChannel) {
			const status = this.getSalesChannelStatus(salesChannel)
			return status.message
		},

	},
	async mounted() {
		console.log('Configurable Bergbahn Dialog mounted')
		// await this.getProducts()
	},
}
</script>

<style scoped lang="scss">
.stepWithoutStepper { margin-top: 0px; position: relative; }
.gapped { display: flex; flex-direction: column; gap: 16px; }
.content {
	p {
		font-size: 12px;
		line-height: 16px;
		color: black;
	}

	.title {
		font-size: 17px;
		line-height: 22px;
		font-weight: bold;
	}
	padding: 16px 0;
}
</style>