<template>
	<div class="EditOpeningTime">
		<div style="display: flex; gap: 10px; align-items: center;">
			<v-text-field hide-details variant="outlined" density="compact" ref="openTime"
				v-model="time.openTime"
				style="flex-grow: 1; min-width: 80px;"
				v-mask="'##:##'"
				placeholder="00:00"
				:errorMessages="openErrors"
				@keypress="e => {
					if (e.key == ':') { if (time.openTime.length == 1) { time.openTime = '0' + time.openTime } }
					if (e.key == 'Escape') cancel()
					if (e.key == 'Enter') { time.openTime = pad(time.openTime); $refs.closeTime.focus() }
				}"
			/>
			<span>-</span>
			<v-text-field hide-details variant="outlined" density="compact" ref="closeTime"
				v-model="time.closeTime"
				style="flex-grow: 1; min-width: 80px;"
				v-mask="'##:##'"
				:errorMessages="closeErrors"
				placeholder="23:59"
				@keypress="e => {
					if (e.key == ':') { if (time.openTime.length == 1) { time.openTime = '0' + time.openTime } }
					if (e.key == 'Escape') cancel()
					if (e.key == 'Enter') ok()
				}"
			/>
		</div>
		<div style="display: flex; gap: 10px; margin-top: 10px;">
			<v-btn class="gradientButton" elevation="0" @click="cancel" style="width: 50%; flex-shrink: 1;">{{ $t('text.cancel') }}</v-btn>
			<v-btn class="greenButton" elevation="0" @click="ok" style="width: 50%; flex-shrink: 1;" :disabled="!valid">{{ $t('text.ok') }}</v-btn>
		</div>
	</div>
</template>

<script lang="ts">
// ATT: this comp does not actually allow editing

export function timesOverlap(times) {
	if (!times) return false
	if (times?.length <= 1) return false
	for (const t1 in times) {
		const time1 = times[t1]
		if (!time1) continue
		for (const t2 in times) {
			const time2 = times[t2]
			if (!time2) continue
			if (t2 <= t1) continue
			if (time1.openTime >= time2.closeTime) continue
			if (time1.closeTime <= time2.openTime) continue
			return true
		}
	}
	return false
}

export default {
	name: 'EditOpeningTime',
	data: () => ({
		time: { openTime: '', closeTime: '' },
	}),
	computed: {
		valid() {
			return this.time.openTime.length == 5
				&& !this.openErrors
				&& !this.closeErrors
				&& this.time.closeTime.length >= 1
				&& this.time.openTime < this.time.closeTime
		},
		openErrors() {
			return this.getTimeErrors(this.time.openTime)
		},
		closeErrors() {
			return this.getTimeErrors(this.time.closeTime)
		},
	},
	methods: {
		getTimeErrors(time) {
			const parts = time.split(':')
			if (isNaN(parts[0])) return 'hours only numbers'
			if (Number(parts[0]) > 23) return 'hours too large'
			if (parts.length > 1) {
				if (isNaN(parts[1])) return 'minutes only numbers'	
				if (Number(parts[1]) > 59) return 'minutes too large'
			}
		},
		cancel() {
			this.$emit('cancel')
		},
		ok() {
			this.time.closeTime = this.pad(this.time.closeTime)
			if (!this.valid) return
			this.$emit('ok', this.time)
		},
		pad(s: string, ) {
			const padding = '00:00'
			if (!s) return padding
			if (s.length == 1) s = '0' + s
			for (let i = s.length; i < padding.length; i++)
				s += padding[i]
			return s
		},
	},
	mounted() {
		window.setTimeout(() => {
			this.$refs.openTime.focus()
		}, 200)
	},
}
</script>

<style scoped>
.EditOpeningTime >>> .v-field--error { background: rgb(250, 228, 228) !important; }
</style>