<template>
	<div
		style="color: var(--color-blue); cursor: pointer;"
		@click="$router.push('/settings?viewType=faq')"
	>
		{{ $t('text.faqs') }}
		<v-icon>mdi-arrow-right</v-icon>
		<v-menu location="bottom center" origin="top center" open-on-hover>
			<template #activator="{ props }">
				<v-icon v-bind="props">mdi-information</v-icon>
			</template>
			<v-card style="padding: 16px; max-width: 300px;">
				<h3 style="line-height: initial; margin-bottom: 5px; font-size: inherit;">Where have my FAQs gone?</h3>
				The FAQ feature has been <b>moved to the Settings App</b> to separate service creation from additional features.
			</v-card>
		</v-menu>
	</div>
</template>

<script>
export default {
}
</script>

<style scoped>
</style>