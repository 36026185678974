<template>
	<div class="ColorPicker">
		<v-menu v-model="menu" offset-y>
			<template #activator="{ props }">
				<div class="ColorSwatch"
					:class="{ selected: menu }"
					:style="{ background: modelValue }"
					v-bind="props"
				/>
			</template>
			<ColorPalette v-model="model" />
		</v-menu>
	</div>
</template>

<script lang="ts">
import ColorPalette from './ColorPalette.vue'
import { defaultColors } from './ColorPalette.vue'

export default {
	components: { ColorPalette },
	props: {
		modelValue: String,
		colors: {
			type: Array,
			default: () => defaultColors,
		},
	},
	data: () => ({
		model: null,
		menu: false,
	}),
	watch: {
		modelValue() { this.model = this.modelValue },
		model() { this.$emit('update:modelValue', this.model) },
	},
	mounted() {
		this.model = this.modelValue
		if (this.model == null) this.$emit('update:modelValue', '#cccccc')
	},
}
</script>

<style scoped>
/* uses global styles defined in ColorPalette.vue */
.ColorPalette { background: white; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); padding: 12px; width: 300px; border-radius: 4px; outline: 1px solid #ddd; }
</style>