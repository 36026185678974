import CommentTechnical from '../api/src/CommentTechnical'
import { requestGlobal } from '../core/src/utils/cls'
import Executive from './Executive'

export class Comment {
	clientId: string
	objectId: string
	// TODO: add UI decorators
	title: string

	id?: string
	// TODO: what are accespable values?
	objectType?: string
	message?: string
	action?: string
	pinned?: boolean
	createdBy?: string
	updatedBy?: string
	// TODO: if we really want to support Date we would have to code something into the infrastructure
	createdAt?: Date
	updatedAt?: Date
}

export default class CommentExecutive extends Executive {

	technical: CommentTechnical

	initServer() {
		if (!this.technical) this.technical = new CommentTechnical()
	}

	async createComment(comment: Comment): Promise<Comment> {
		// TODO: restrict permissions
		comment.createdBy = requestGlobal.userEmail
		// TODO: shouldnt this be null?
		comment.updatedBy = requestGlobal.userEmail
		if (!comment.createdAt) comment.createdAt = new Date()
		if (!comment.updatedAt) comment.updatedAt = new Date()
		return await this.technical.upsertComment(comment)
	}

	async updateComment(comment: Comment): Promise<Comment> {
		comment.updatedAt = new Date()
		// TODO: only allow same user that has created it?
		// TODO: prevent changing attributes that are forbidden (createdBy, objectId, ...)
		// TODO: get from token
		//       requestGlobal.authTokenPayload...) ?
		//       or email?
		//       userId would be best, but i think the token does not contain the user id..
		comment.updatedBy = requestGlobal.userEmail
		return await this.technical.upsertComment(comment)
	}

	async deleteComment(id: string): Promise<void> {
		// TODO: only allow same user that has created it?
		return await this.technical.deleteComment(id)
	}

	async getComments(objectId): Promise<Comment[]> {
		// TODO: restrict permissions
		//       how to determine permissions?
		//       - any user role can comment
		//       - user has to have permission for that client
		//       - client has to have the feature
		//       - user has to own the object OR be operator?
		// TODO: permission utils
		//       we have to load the user and her permitted clients
		//       loading the user is expensive - this should probably be cached - user token as cache key?
		//       we probably also have to have the clients loaded for this in a cache..
		//       common permission utils
		//       - hasClient(clientId)
		//       - hasApplication(applicationId)
		//       - canSeeObjectsWithTag(tag)
		//       - isOperator()
		//         if (!requestGlobal.authTokenPayload.isOperator) throw new Error('Unauthorized')
		//       - hasFeature(feature)
		//       can we do this as decorators? should be a prime example for a wrapper.
		return await this.technical.searchComments({ objectId, order: 'createdAt DESC' })
	}
}