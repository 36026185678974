<template>
	<div class="HoverOverlay">
		<!--
			:style="{ top: pos.top + 'px', left: pos.left + 'px' }"
		<v-overlay 
		-->
		<v-tooltip v-model="overlay"
			:target="target"
			location-strategy="connected"
			location="start top"
			origin="auto"
			style="width: 500px;"
		>
			<h1 v-if="$slots.title"
				style="flex: 1; margin: 0; padding: 0; font-size: larger; font-weight: bold; line-height: 100%; margin-bottom: 10px;"
			>
				<slot name="title" />
			</h1>
			<slot />
		</v-tooltip>
	</div>
</template>

<script>
// TODO: auto position based on size

export default {
	props: {
		// DOM element
		target: Object,
		// TODO: expose location?
	},
	data: () => ({
		overlay: true,
	}),
}
</script>

<style scoped>
.HoverOverlay { z-index: 999999; position: absolute; }
.v-overlay-container { border: 1px solid red; }
</style>

<style>
.HoverOverlayOverlay { z-index: 9999 !important; }
.HoverOverlayOverlay .v-overlay__scrim { display: none; }
.HoverOverlayOverlay .v-overlay__content { XXinset: 0; pointer-events: none; }
.HoverOverlayOverlay .v-overlay__content > * { pointer-events: all; }
</style>