<template>
	<div class="table-footer">
		<div>{{ $t('text.itemsPerPage') }}</div>
		<v-select
			density="compact"
			variant="underlined"
			class="limit-select"
			menu-icon="mdi-chevron-down"
			:model-value="limit"
			:items="limits"
			:item-value="(item) => item"
			:item-title="(item) => item"
			@update:modelValue="updateLimit"
		/>
		<v-spacer />
		<TablePaginator
			:offset="offset"
			:limit="limit"
			:total="total"
			:results="results"
			@update:offset="updateOffset"
		/>
	</div>
</template>

<script>
import TablePaginator from './TablePaginator.vue'
export default {
	components: { TablePaginator },
	props: {
		results: { type: Array, default() { return [] } },
		limit: { type: Number, default: 10 },
		total: { type: Number, default: 0 },
		offset: { type: Number, default: 0 },
	},
	computed: {
		limits() {
			return [10, 25, 50];
		}
	},
	methods: {
		updateOffset(offset) {
			this.$emit('update:offset', offset)
		},

		updateLimit(limit) {
			this.$emit('update:limit', limit)
		},
	},
}
</script>

<style lang="scss">
.table-footer {
	height: 60px;
	display: flex;
	align-items: center;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	padding: 20px 32px 20px 32px;
	box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
	background-color: #f4f4f4;

	.limit-select {
		--v-field-padding-start: 0px !important;
		max-width: 52px;
		margin-left: 24px;

		.v-field__append-inner {
			padding-top: 16px;

			>.v-icon--size-default {
				font-size: 20px;
			}
		}

		.v-field__input {
			padding-inline-start: 0px !important
		}

		.v-input__details {
			min-height: 0;
		}
	}
}
</style>