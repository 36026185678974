<template>
	<div>
		<Disclosure :title="$t('text.guestCardLocationTitle')" :error="sectionMessage.error" :message="sectionMessage.message"
			data-cy="guestCardLocationInfo" ref="SECTION_guestCardLocationInfo">
			<div class="field left-border">
				<div>
					<div class="title">{{ $t('text.issuingParties') }} <span class="error-text">({{ $t('text.required') }})</span> </div>
					<div class="subheading mb-4">{{ $t('text.issuingPartiesDesc') }}</div>
				</div>

				<div class="my-8 d-flex align-center">
					<div class="radio-group">
						<v-radio v-model="selectedOptionType" class="radio-option" :label="$t('text.choosePerRegion')" value="region" @click="toggleOption('region')"></v-radio>
						<div v-show="selectedOptionType === 'region'" class="optionContent">
							<div class="title">{{ $t('text.region') }}</div>
							<div class="subheading mb-4">{{ $t('text.guestCardRegionDesc') }}</div>
							<div class="mb-8">
								<v-select multiple chips hide-details
									ref="vSelectRegion"
									v-model="selectedRegions"
									:items="regions"
									:item-title="item => item.region_name"
									:item-value="item => item.region_name"
									:placeholder="$t('text.chooseRegion')"
									variant="outlined"
									density="compact"
								>
									<template #selection="{ item }">
										<v-chip :color="item.color" dark>{{ item.region_name }}</v-chip>
									</template>
								</v-select>
								<div v-if="selectedRegions.length === 0" class="error-message">{{ $t('text.selectAtLeastOne') }}</div>
							</div>
						</div>
						<hr>
						<v-radio v-model="selectedOptionType" class="radio-option" :label="$t('text.choosePerCity')" value="city" @click="toggleOption('city')"></v-radio>
						<div v-show="selectedOptionType === 'city'" class="optionContent">
							<div class="title">{{ $t('text.City') }}</div>
							<div class="subheading mb-4">{{ $t('text.guestCardCityDesc') }}</div>
							<div class="mb-8">
									<v-select multiple chips hide-details
										ref="vSelectCity"
										:placeholder="$t('text.chooseCity')"
										variant="outlined"
										density="compact"
										v-model="selectedCities"
										:items="cities"
										:item-title="item => item.serviceprovider_city"
										:item-value="item => item.serviceprovider_city"
									>
										<template #selection="{ item }">
											<v-chip :color="item.color" dark>{{ item.serviceprovider_city }}</v-chip>
										</template>
									</v-select>
									<div v-if="selectedCities.length === 0" class="error-message">{{ $t('text.selectAtLeastOne') }}</div>
								</div>
						</div>
						<hr>
						<v-radio v-model="selectedOptionType" class="radio-option" :label="$t('text.choosePerServiceProvider')" value="serviceProviders" @click="toggleOption('serviceProviders')"></v-radio>
						<div v-show="selectedOptionType === 'serviceProviders'" class="optionContent">
							<PeakServiceProviders @assigned-service-providers="(sps) => selectedServiceProviders = sps" />
							<div v-if="selectedServiceProviders.length === 0" class="error-message">{{ $t('text.selectAtLeastOne') }}</div>
						</div>
					</div>
				</div>
			</div>

		</Disclosure>
	</div>
</template>

<script>
import Disclosure from '@/components/common/Disclosure.vue'
import PeakServiceProviders from './PeakServiceProviders.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: "GuestCardLocation",
	components: { Disclosure, PeakServiceProviders },
	mixins: [ Common ],
	props: {
		product: Object,
		productIsExternal: Boolean,
		updateModel: Boolean
	},
	data() {
		return {
			model: {},
			sectionMessage: {
				error: false,
				message: ''
			},

			regions: [],
			cities: [],

			// TODO: maybe instead of binding to a data property in the select field, we will need to bind to the actual property in the model (revise this after the model is ready)
			selectedCities: [],
			selectedRegions: [],
			selectedServiceProviders: [],
			// TODO: maybe we could set this directly on the model as well
			selectedOptionType: '',
		}
	},

	methods: {
		// TODO: send these through with provide or as props so that we don't need to re-fetch them in the filters of the service provider dialog
		toggleOption(option) {
			// TODO: try to do this instead (maybe execute on change)
			// this.selectedRegions = []
			// this.selectedCities = []
			// this.selectedServiceProviders = []
			switch(option) {
				case 'region':
					this.selectedOptionType = option
					this.selectedCities = []
					this.selectedServiceProviders = []
					break
				case 'city':
					this.selectedOptionType = option
					this.selectedRegions = []
					this.selectedServiceProviders = []
					break
				case 'serviceProviders':
					this.selectedOptionType = option
					this.selectedCities = []
					this.selectedRegions = []
					break
				default:
					break
			}
		},
		async getRegions() {
			try {
				const currentClientId = this.$store.state.selectedClient.sys.id
				const response = await this.$httpGet(`/packageTravel/regions?clientId=${currentClientId}`)
				this.regions = response.regions
			} catch (e) {
				this.showError(e.response ? e.response?.data.error : e)
				if (e.response?.status == 401) return this.$emit("show-login")
			}
		},
		async getCities() {
			try {
				const currentClientId = this.$store.state.selectedClient.sys.id
				const response = await this.$httpGet(`/packageTravel/cities?clientId=${currentClientId}`)
				this.cities = response.cities
			} catch (e) {
				this.showError(e.response ? e.response?.data.error : e)
				if (e.response?.status == 401) return this.$emit("show-login")
			}
		},
		// TODO: atm I wrote the method name by the same convention that we are using in all other UI components. Integrate this properly in the validation mechanism to NOT allow the save button to be clicked if this method returns false
		validateAllFields() {
			this.resetSectionError(this.sectionMessage)

			if (!selectedRegions.length && !selectedCities.length && !selectedServiceProviders.length) {
				this.setSectionError(this.sectionMessage, this.$t('text.missingFieldsError'))
				return false
			}

			return true
		}
	},
	
	provide() {
		const injectedData = {}
		Object.defineProperty(injectedData, 'regions', {
			enumerable: true,
			get: () => this.regions,
		})
		Object.defineProperty(injectedData, 'cities', {
			enumerable: true,
			get: () => this.cities,
		})
		return { injectedData }
	},

	async mounted() {
		await this.getRegions()
		await this.getCities()
	}

}
</script>

<style scoped>
.optionContent {
	margin-left: 3rem;
	margin-top: 1rem;
}
.radio-option {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.radio-group {
	width: 100%;

}
.error-message {
	color: red;
	font-size: 0.8rem;
	margin-top: 0.5rem;
}
</style>