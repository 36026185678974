import { ClassDefinition, Column, ColumnDefinition, FieldType, metadata } from './entry-decorators'

// TODO: i copied this here from api/src/models. when finished it should be removed there.
// TODO: i started to write a new version "Entry2" at the end of this file. it has different
//       functionality and goals, but in the end the 2 should be merged into one.
// TODO: for complete functionality we need both, good support at the client and at the server.
//       part of this will be that a transport object can be fully reconstructed:
//       the client should be able to send an object to the server, and at the server we should
//       reconstruct the class structure fully - OR 'fake it' if possible via proxies.
//       same is true for the direction server -> client
//       we have to make this happen without introducing vulnerabilities:
//       any functionality must be guarded and separated from these transfer object classes.
// TODO: use reviver like:
//       JSON.parse(text, (key, value) => { if (value?.sys?.type == 'Link') return Entry.proxy(value) })
//       we have to change the http read for this to .text() + JSON.parse
//       and also a replacer like:
//       JSON.stringify(obj, (key, value) => { if (value instanceof Entry) return value.toJSON() })
// TODO: support caching + injection for serialize/deserialize?
// TODO: define a standard transport model?
//       this is a bit against the idea of just using regular methods in the executives
//       however, a simple return type if often not enough.
//       maybe we could have a generic class like this:
export class Transfer<T> {
	entry: Entry<T>
	linkedEntries: { [ id: string ]: Entry<any> }
	// and here we could implement some convenience for this
}
// TODO: we will probably have to introduce some general type hinting
//       .sys.content_type.sys.id with .type shorthand?
// TODO: how to make sure that there are no circular refs?
//       somehow enforce that all roots are in linkedEntries, not in sub objects
//       add a JSON circular check/replacer/error to the proxy?
// TODO: we should also find a good way to do open-connection-streaming-responses via multipart
//       this would allow us to send information about the current state of the request to the client
//       and 
//       (also see RolloutMachine for an example of this)
//       use generators for this?
//       https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Generator
export class ResultMessage { constructor(public message) {} }
export interface ResultStream<T> extends AsyncGenerator<ResultMessage | T> {}
class MyResult {
	constructor(public message) {}
}
class MyExecutive {
	async *myMethod1(): ResultStream<MyResult> {
// TODO: why does the linter not complain here? actually we should only be able to yield certain types..
//       maybe we need a new linter version?
//		yield 'a'
		yield new ResultMessage('hello')
		yield new MyResult('world')
	}
// TODO: since generators dont seem super well supported maybe we should rather use a callback interface..
//       this way the types would be better enforced
//       however, the method signature is not as nice
//       calling it could be quite nice though
	async myMethod2(callback: (m: ResultMessage) => void): Promise<MyResult> {
		return null
	}
}
// TODO: also implement a server -> client call somehow?

// ATT: when modifying this file, use TDD and keep api/test/tdd-entry.ts running!

// this entry framework allows us to interact with contentful entries in a type-safe way,
// while giving us an easy way to define the schemas.
// Proxy is used for some casting magic, automatic defaults, etc.
// every field is a I18n<T> type.

export type I18n<T> = {
	de: T | null
	en?: T | null
	fr?: T | null
	it?: T | null
	nl?: T | null
}
export type I18nString = I18n<string>
export type I18nNumber = I18n<number>
export type I18nDate = I18n<Date>

export type Link<T> = T | {
	sys: {
		type: 'Link'
		linkType: string
		id: string
	}
}

export type EmbeddedEntry<T> = Entry<T>

// TODO: rename to ContentTypeLink
class ContentType {
	sys: {
		id: string
		type: 'Link'
		linkType: 'ContentType'
	}
}

export class Sys {
	id: string
	content_type: /*Link<*/ContentType/*>*/
	createdAt?: Date
	createdBy?: string
	updatedAt?: Date
	updatedBy?: string
// TODO: do we need these? i think not.
//	publishedAt?: Date
//	publishedBy?: string
//	publishedVersion?: number
//	firstPublishedAt?: Date
	version?: number
}

export type Fields = {
	[ fieldName: string ]: I18n<any>
}

export class Entry<T> {
	@Column()
	id: string

	@Column({ type: 'jsonb' })
	fields: T

	@Column({ type: 'jsonb' })
	sys: Sys

	constructor(idOrOptions: string | { [ prop: string ]: any }) {
		this.sys = Entry.guessSys(idOrOptions, this.constructor.name)
		this.fields = (idOrOptions as any)?.fields ?? typeof idOrOptions == 'object' ? idOrOptions : {} as any
		return Entry.proxy(this as any)
	}

	static guessSys(idOrOptions: string | { [ prop: string ]: any }, className: string) {
		const sys: Sys = { id: undefined, content_type: { sys: { id: undefined, type: 'Link', linkType: 'ContentType' } } }
		if (typeof idOrOptions === 'string') sys.id = idOrOptions
		else
			sys.id =
				idOrOptions?.sys?.id
				?? idOrOptions?.id
				?? 'NEW' + Math.random().toString(36).substring(16)
		sys.content_type.sys.id =
			(idOrOptions as any)?.sys?.content_type?.sys?.id
			?? className
		return sys
	}

	static proxy(json: { id?: string, sys: { id?: string, content_type: { sys: { id: string, type?: string, linkType?: string } } }, fields: { [ fieldName: string ]: I18n<any> } }): Entry<any> {
		// allow for plain object params
		if (!json.fields) json = { fields: json } as any
		json.sys = Entry.guessSys(json, this.constructor.name)
		// allow for non-locale prop values as options-input: auto wrap
		for (const f in json.fields) {
			if (json.fields[f]?.de) continue
			//if (typeof json.fields[f])
			json.fields[f] = { de: json.fields[f] }
		}

		const type = json.sys.content_type.sys.id
		json.sys.content_type.sys.type = 'Link'
		json.sys.content_type.sys.linkType = 'ContentType'
		const md = metadata[type]
		const con = md.class.constructor
		Object.setPrototypeOf(json, con.prototype)

		json.fields = new Proxy(json.fields, {
			get: function (target, prop, receiver) {
				if (typeof prop == 'symbol') prop = prop.toString()
				// TODO: implement this somehow?
				//if (prop == 'getOwnPropertyNames') return (target as any).getOwnPropertyNames
				if (prop == 'toJSON') return (target as any).toJSON
				// TODO: we may only want to autocreate fields if they are in the schema
				// TODO: we may need something similar for other lcoales?
				if (prop == 'de') return undefined
				if (target[prop] === undefined) {
					// TODO: proxy instead - i'd like to have a .schema prop that gives the schena for this field
					target[prop] = { de: undefined }
				}
				// returns proxy around something like { de: 'test' }
				return new Proxy(target[prop], {
					get: function (target, prop, receiver) {
						// finally returns the actual value
						const v = target[prop]
						// TODO: also catch when this is an unresolved link + offer .resolve() function
						if (v?.sys?.content_type?.sys?.type == 'Link') {
							return Entry.proxy(v)
						}
						return target[prop]
					}
				})
			},
		}) as any

		return new Proxy(json, {
			get: function (target: any, prop, receiver) {
				// js standard props
				if (prop == 'toJSON') return target.toJSON
				if (prop == 'constructor') return target.constructor

				// normal props
				if (prop == 'sys') return target.sys
				if (prop == 'fields') return target.fields
				if (prop == 'metadata') return target.metadata
				if (prop == 'addl') return target.addl

				// magic direct id access
				if (prop == 'id') return target.sys.id

				// magic direct field access
				const f = md.fields[prop as string]
				if (f) return target.fields[prop as string]

				return target[prop]
				/*
				// returns proxy around something like { de: 'test' }
				// TODO: allow .dl access here
				// TODO: allow .all access here?
				return new Proxy(target[prop], {
					get: function (target, prop, receiver) {
						// finally returns the actual value
						const v = target[prop]
						if (v?.sys?.type == 'Link') {
							return Entry.proxy(v)
						}
						return target[prop]
					}
				})
				*/
			},
			set: function (target, prop, value, receiver): boolean {
				if (prop == 'id') return target.sys.id = value

				// magic direct field access
				const f = md.fields[prop as string]
				if (f) return target.fields[prop as string] = value
				
				return target[prop] = value
			},
			// TODO: is this implementation ok or should it be more capable?
			ownKeys: function (target): string[] {
				return Object.keys(target)
			},
		}) as any
	}

	// ORM DB modeling & tools

	@Column({ type: 'timestamp without time zone' })
	updatedAt: Date

	static guessDbTypeFor(t: FieldType, column: string): string {
		if (column == 'id') return 'varchar(64)'
		if (t == undefined) return 'varchar(255)'
		switch (t) {
			case 'Symbol': return 'varchar(255)'
			case 'Text': return 'text'
			case 'Number': return 'numeric'
			case 'Integer': return 'integer'
			case 'Date': return 'timestamp without time zone'
			case 'Location': return 'jsonb'
			case 'Boolean': return 'boolean'
			case 'Array': return 'text'
			case 'Link': return 'text'
			case 'RichText': return 'jsonb'
			case 'Object': return 'text'
		}
		throw new Error(`guessDbTypeFor cannot guess for ${ t }.`)
	}

	static createQuery(): string {
		const md = metadata[this.name]
		const lines: any[] = []
		const addLine = (md: ClassDefinition, c: string) => {
			const column = md.columns[c]
			const field = md.fields[c]
//			console.log(c, column)
			let type = column.type ?? this.guessDbTypeFor(field?.type, c)
			// TODO: the field would actually also allow setting the default also - but do we really need that?
			//       i think we could get away with not having defaults in the db
			//       and probably it would also be fine if we dont have not null fields
			const def = column.default ? ` default ${ column.default }` : ''
			const key = column.id == 'id' ? ' primary key' : ''
			lines.push(`\t${ column.id } ${ type }${ def }${ key }`)
		}
		for (const c in metadata.Entry?.columns ?? {}) {
			addLine(metadata.Entry, c)
		}
		for (const c in md.columns) {
			addLine(md, c)
		}
		// TODO: primary key
		// TODO: statically add the default cols here or declare + decorate them in Entry?
		//       if so, we need to add those here
		let r = `create table public.${ md.table?.name } (\n${ lines.join(',\n')}\n)`
		return r
	}

	// TODO: deep traversal of fields
	forEachChildEntry(
		fn: (entry: Entry<any>, prop: string, parent: Entry<any>, seenBefore: boolean) => void,
		oncePerEntry = false,
		visited: Set<Entry<any>> = new Set(),
	) {
		// TODO: traversing the fields will also look at .fields, .sys, .metadata, .addl, and all top level columns (?)
		//       we probably dont want that
		for (const f in this.fields) {
			console.log(this.constructor.name, '.', f)
			const field = this.fields[f] as I18n<any>
			if (field?.de?.sys) {
				// TODO: do we EVER have real multilang fields? i think we actually always have .de for any sub objects
				const entry = field.de as Entry<any>
				const seenBefore = visited.has(entry)
				if (oncePerEntry && seenBefore) continue
				visited.add(entry)
				entry.forEachChildEntry(fn, oncePerEntry, visited)
				fn(entry, f, this, seenBefore)
			}
		}
	}

	static fromRow(row: { [ column: string ]: any }): Entry<any> {
		if (!row.sys?.content_type?.sys?.id) throw new Error('missing row.sys.content_type.sys.id')
		// TODO: if the column name differs from the column id, we have to map it here
		// TODO: for each column
		const md = metadata[row.sys.content_type.sys.id]
		const o = {}
		const setValue = (column: ColumnDefinition, value: any) => {
			if (!column.name) return
			// TODO: do more type conversions here? json?, ..
			if (column.type?.startsWith?.('timestamp'))
				o[column.name] = value ? new Date(value) : value
			else
				o[column.name] = value
		}
		for (const c in metadata.Entry?.columns ?? {}) {
			const column = metadata.Entry?.columns[c]
			setValue(column, row[c])
		}
		for (const c in md.columns) {
			const column = md.columns[c]
			setValue(column, row[c])
		}
		const entry = Entry.proxy(o as any)
		entry.sys.updatedAt = row.updated_at
		return entry
	}

	toRow() {
		const row: Entry<any> = {} as any
		const setValue = (column: ColumnDefinition, value: any) => {
			if (!column.id || !column.name) return
			// unwrap the i18n object
			if (!['id', 'fields', 'metadata', 'sys'].includes(column.name) && value?.de) {
				value = value.de
			}
			// TODO: do more type conversions here? json?
			if (column.type?.startsWith?.('timestamp'))
				row[column.id] = value?.toISOString?.()
			else
				row[column.id] = value
		}
		for (const c in metadata.Entry?.columns ?? {}) {
			const column = metadata.Entry?.columns[c]
			if (!column?.name) continue
			// the proxy automatically reads from fields
			setValue(column, this[column.name])
		}
		const md = metadata[this.sys.content_type.sys.id]
		for (const c in md.columns) {
			const column = md.columns[c]
			if (!column?.name) continue
			setValue(column, this[column.name])
		}
		// TODO: should this concern really be here? maybe it should rather be in the persist method
		//row.sys.updatedAt = new Date()
		;(row as any).updated_at = row.sys.updatedAt
		return row
	}
}

// TODO: check if the proxy results return proper vue reactive objects
//       i suspect that this is not the case.
//       could we live without the proxy magic in favour of vue reactivity and a simpler framework implementation?

// this is more versatile than Entry.proxy:
// it will allow wrapping of ANY object, and will still offer magic access
// where it detects that the object is an entry.
// BUT: it is unclassed currently
export class Entry2<T> {
	id: string
	fields: T
	sys: Sys

	constructor(id: string, fields: T, sys: Sys) {
		this.id = id
		this.fields = fields
		this.sys = sys
	}

	static proxy(json: any, entry: any = undefined, level: undefined | 'fields' | 'field' | 'sys' | 'metadata' | 'addl' = undefined): any {
		//console.log('proxy', level, json)
		const proxy = Entry2.proxy
		// TODO: this doesnt work, as when we return some { NO } object, it will not compare to the original like o.a == o.a or o.a == 1
		// TODO: handle cases where target is a NO
		// we will even wrap undefined objects to prevent NPEs
		if (!json || typeof json != 'object') return json //json = { NO: json, TY: typeof json }
		return new Proxy(json, {
			get: function(target, prop, receiver): any {
				//console.log('E2.get', prop, target)

				// Vue uses this to print a value in rendering
				// TODO: why does this work? when i use stringify, the ui will show an escaped string,
				//       but like this, it shows a nice object in JSON format
				if (prop == 'toJSON') return () => target//{ return JSON.stringify(target) }

				// this is being used for string coercions like (o + '') - we rely in this in handle()
				if (prop == Symbol.toPrimitive) return () => {
					if (typeof target == 'object') return '[object Object]'
					return target
				}
				//target//{ return JSON.stringify(target) }

				//if (prop == 'toString') return () => 'STRING'
				//if (prop == Symbol.toStringTag) return () => 'TST'

				//console.log('.', prop)
				if (target?.sys) {
					if (prop == 'id') return target.sys.id
					if (prop == 'type') return target.sys.content_type.sys.id
					if (prop == 'fields') return proxy(target.fields, target, 'fields')
					if (prop == 'sys') return proxy(target.sys, target, 'sys')
					if (prop == 'metadata') return proxy(target.metadata, target, 'metadata')
					if (prop == 'addl') return proxy(target.addl, target, 'addl')
					return proxy(target.fields?.[prop], target, 'field')
				}
				if (level == 'fields') return proxy(target?.[prop], entry ?? target, 'field')
				// TODO: create actual class instance (or similar) if child is an entry?
				if (level == 'field') return proxy(target?.[prop], entry ?? target, 'field')
				if (level == 'sys' && prop == 'id') return target?.[prop]
				//if (typeof prop == 'symbol') return target?.[prop]
				const v = target?.[prop]
				if (typeof v == 'function') return v.bind(target)
				return proxy(target?.[prop], entry ?? target)
				// TODO: magic resolve with linked entries?
			},
			/*
			set: function(target, prop, value, receiver): boolean {
				...
			},
			ownKeys: function(target): string[] {
				console.log('E2.ownKeys', target)
				return Object.keys(target)
			},
			getOwnPropertyDescriptor(target, p) {
				console.log('E2.getOwnPropertyDescriptor', p, target)
				//return {configurable: false, enumerable: false}
				return Reflect.getOwnPropertyDescriptor(target, p)
			},
			*/
		})
	}

	toJSON() {
		return JSON.stringify(this)
	}
}
