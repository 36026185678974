<template>
	<FilterMenu @clear="clearFilter" @apply="applyFilter">
		<FilterFieldWrap dataCy="statusFilter">
			<span>{{ $t('text.status') }}</span>
			<v-select :placeholder="$t('text.allLabel')" variant="outlined" density="compact" v-model="selectedStatuses"
				:items="statusOptions" multiple chips hide-details>
				<template #selection="{ item }">
					<v-chip :color="item.color" dark>{{ $t('text.' + item.id) }}</v-chip>
				</template>
			</v-select>
		</FilterFieldWrap>
	</FilterMenu>
</template>

<script lang="ts">
import Common from '@/mixins/Common.vue'
import FilterMenu from '@/components/common/FilterMenu.vue'
import FilterFieldWrap from '@/components/common/FilterFieldWrap.vue'

export default {
	name: 'ProductFilters',
	components: { FilterMenu, FilterFieldWrap },
	mixins: [Common],
	props: {
		onApplyFilter: Function,
	},
	data() {
		return {
			loading: false,
			selectedCategories: [],
			selectedPriceRange: { from: null, to: null },
			selectedSalesChannels: [],
			selectedStatuses: [],
			productCategoryOptions: [],
			salesChannelsOptions: [],
		}
	},
	computed: {
		statusOptions() {
			return [
				{ title: this.$t('text.activated'), value: 'active', color: 'green' },
				{ title: this.$t('text.deactivated'), value: 'deactivated', color: 'red' },
				{ title: this.$t('text.archived'), value: 'archived', color: 'grey' },
				{ title: this.$t('text.draft'), value: 'draft', color: 'blue' },
			]
		},
		clientId() {
			return this.$store.state.selectedClient.sys.id
		},
	},
	methods: {
		showLoader(loading = false) {
			this.loading = loading
		},
		clearFilter() {
			this.selectedStatuses = []

			this.applyFilter()
		},
		applyFilter() {
			this.onApplyFilter?.({
				status: this.selectedStatuses,
			})
		},
	},
	async mounted() {
	},
}
</script>
