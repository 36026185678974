<template>
	<DialogV2 ref="dialog"
		:title="$t('text.translationWizardTitle')"
		:confirmLabel="$t('text.confirmSelection')"
		:onConfirm="confirmTranslations"
		@close="aiContext_cancel"
	>
		<template #sidebar>
			<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>

		<template #content>
			<div class="translation-wizard-inputs">
				<!-- TODO: what is ticketOnly? we should eliminate this concern here.. -->
				<TranslateableField v-for="field of model" :key="field.typeName + '-' + field.fieldName"
					:typeName="field.typeName"
					:fieldName="field.fieldName"
					:modelValue="field.localValue"
					:locales="displayedLocales"
					:ticketOnly="ticketOnly"
				/>
			</div>
		</template>
		<template #buttons-left v-if="$store.state.featureFlags.FEATURE_AI_TRANSLATIONS">
			<AITranslation :fields="fieldsForTranslation">
				<template #activator="{ props }">
					<v-btn v-bind="props" class="gradientButton" theme="dark" elevation="0">
						<template #prepend>
							<IconAi color="#424242" />
						</template>
						{{ $t('text.autoTranslateAllFields') }}
						<template #append>
							<div class="divider"></div>
							<v-icon>mdi-chevron-down</v-icon>
						</template>
					</v-btn>
				</template>
			</AITranslation>
		</template>
	</DialogV2>
</template>

<script lang="ts">
import Common from '../mixins/Common.vue'
import LanguagesNavigation from '../mixins/LanguagesNavigation.vue'
import DialogV2 from './common/DialogV2.vue'
import LanguageSidebar from './common/LanguageSidebar.vue'
import TranslateableField from './fields/TranslateableField.vue'
import AITranslation from './common/AITranslation.vue'
import IconAi from './common/icons/IconAi.vue'
import AiContext from '../mixins/AiContext.vue'

type I18n = { [ locale: string ]: any }

type Field = {
	typeName: string,
	fieldName: string,
	value: I18n,
}

type LocalField = Field & { localValue: I18n }

export default {
	props: {
		fields: Array<Field>,
		ticketOnly: Boolean,
	},
	mixins: [ Common, LanguagesNavigation, AiContext ],
	components: { IconAi, AITranslation, DialogV2, LanguageSidebar, TranslateableField },
	emits: [ 'update:modelValue', 'confirm' ],
	data: () => ({
		currentStep: 1,
		model: undefined as undefined | LocalField[],
	}),
	computed: {
		activeTranslation() {
			return this.$store.state.activeTranslation
		},
		displayedLocales() {
			return this.languageNavigationItems.reduce((locales, { code }) => {
				if (code !== "all" && (this.activeTranslation === "all" || this.activeTranslation === code)) {
					locales.push(code)
				}

				return locales
			}, [])
		},
		translationStatuses() {
			const fields = this.model.map(f => ({ ... f, value: f.localValue }))
			return this.getTranslationStatus2(fields, 'model')
		},
		fieldsForTranslation() {
			return this.model.map(field => {
				const type = this.$store.state.fieldModels.find(({ name }) => name === field.typeName)
				const fieldDef = type?.fields[field.fieldName]
				return { field: fieldDef, id: field.typeName + '-' + field.fieldName, value: field.localValue }
			})
		}
	},
	methods: {
		open() {
			this.copyToLocal()
			if (this.$refs?.dialog) return this.$refs.dialog.open()
		},
		close() {
			return this.$refs.dialog.close()
		},
		checkIfHasMissingTranslations(locale) {
			const state = this.translationStatuses.find(s => s.locale.code === locale)
			return state && state.percentage < 100
		},
		confirmTranslations() {
			this.copyToGlobal()
			this.$emit('confirm')
		},
		// we create local work-objects that basically just contain the original field objects
		// and a copy of the i18n object.
		// [ { typeName, fieldName, value: { de, en, .. } }, .. ] ->
		// [ { field: { typeName, fieldName, value: { de, en, .. } }, global, local }, .. ]
		copyToLocal() {
			this.model = (this.fields as Field[] ?? []).map(field => {
				const localValue = JSON.parse(JSON.stringify(field.value))
				return { ...field, localValue }
			})
		},
		// we copy the values of each i18n object back to the global model.
		// this works because we always get the i18n objects passed in by reference,
		// so changing their properties will update the global model.
		copyToGlobal() {
			for (const field of this.model ?? []) {
				for (const locale in field.localValue) {
					field.value[locale] = field.localValue[locale]
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.translation-wizard-inputs {
	display: flex;
	position: relative;
	flex-direction: column;
	flex-grow: 1;

	&.all {
		.input-field > .input-field-content {
			padding: 16px 0;
		}
	}
}
.divider { border-left: 1px solid #b0b0b033; height: 38px; margin: 0 10px; }
</style>