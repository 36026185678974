<template>
	<div v-if="commission?.fields" style="display: flex; gap: 10px; align-items: center;">
		<div style="width: 30%; flex-grow: 1;">
			<v-select variant="outlined" density="compact" hide-details 
				:disabled="disabled || isEdit"
				v-model="commission.fields.peakWebsiteId"
				:items="salesChannels"
				:item-title="item => item?.fields?.title?.de"
				:item-value="item => item?.fields?.peakId?.de ?? item?.fields?.magentoWebsiteId?.de"
				:error-messages="salesChannelError" 
				@update:modelValue="emitInput=true"
			/>
		</div>
		<div style="width: 25%;">
			<v-menu :close-on-content-click="false"
				v-model="validFromOpened"
			>
				<template v-slot:activator="{ props }">
					<v-text-field variant="outlined" density="compact" hide-details readonly 
						:disabled="disabled || datesDisabled"
						:value="formatDateTime(commission.fields.validFrom)"
						v-bind="props"
						:error-messages="validFromError"
					/>
				</template>
				<v-card>
					<!-- ATT: we dont store instants here, but we store date-time-strings like '2023-01-01' or '2023-01-01T00:00' -->
					<IsoDatePickerWithoutTz v-model="commission.fields.validFrom" :min="today" time />
				</v-card>
			</v-menu>
		</div>
		<div style="width: 25%;">
			<v-menu :close-on-content-click="false"
				v-model="validToOpened"
				transition="scale-transition"
			>
				<template v-slot:activator="{ props }">
					<v-text-field variant="outlined" density="compact" hide-details readonly
						:disabled="disabled || datesDisabled"
						:value="formatDateTime(commission.fields.validTo)"
						v-bind="props"
						:error-messages="validToError"
					/>
				</template>
				<v-card>
					<IsoDatePickerWithoutTz v-model="commission.fields.validTo" :min="commission.fields.validFrom" time />
				</v-card>
			</v-menu>
		</div>
		<v-text-field variant="outlined" density="compact" hide-details style="width: 75px;"
			:disabled="disabled"
			v-model="commission.fields.percentage" 
			:error-messages="percentageError"
			@keypress="checkPercentage(commission.fields.percentage, $event)"
			@keyup="checkPercentage(commission.fields.percentage, $event)" 
			@update:modelValue="emitInput=true"
			append-inner-icon="mdi-percent"
		/>
		<div style="width: 25px;">
			<v-icon v-if="showDelete" @click="deleteItem()" color="#000000">mdi-delete</v-icon>
		</div>
	</div>
</template>

<script>
import Common from '@/mixins/Common.vue'
import { CommissionType } from '@/plugins/enum.js'
import DatePicker from '@/components/datePicker/DatePicker.vue'
import IsoDatePickerWithoutTz from '../businesshours/IsoDatePickerWithoutTz.vue'

export default {
	name: 'ChannelCommissionItem',
	mixins: [ Common ],
	components: { DatePicker, IsoDatePickerWithoutTz },
	props: {
		commission: Object,
		disabled: Boolean,
		datesDisabled: Boolean,
		isEdit: Boolean,
		commissionErrors: Object
	},
	data() { return {
		validFromOpened: false,
		validToOpened: false,
		salesChannels: [],
		today: new Date().toISOString(),
		salesChannelError: '',
		validFromError: '',
		validToError: '',
		percentageError: '',
		emitInput: true
	}},
	watch: {
		commissionErrors: {
			deep: true,
			immediate: true, 
			handler() {
				this.handleErrors()
			}
		}
	},
	computed: {
		showDelete() {
			return this.commission.sys.id.indexOf('id_') == 0
		},
	},
	methods: {
		formatDateTime(d) {
			if (!d) return ''
			return d.split('T')[0] + ', ' + d.split('T')[1].substring(0, 5)
		},
		deleteItem() {
			this.$emit('delete-row', this.commission.sys.id)
		},
		setSalesChannels() {
			this.salesChannels = JSON.parse(JSON.stringify(this.$store.state.selectedClient.fields.salesChannels.de))

			if (this.commission.fields.type === CommissionType.CATEGORY) {
				this.salesChannels.unshift({fields: {title:{de:this.$t('text.categoryDefault')},peakId:{de:''}}})
			}
		},
		handleErrors() {
			const commissionError = this.commissionErrors[this.commission.sys.id]

			if (commissionError) {
				this.salesChannelError = commissionError.channelError
				this.validFromError = commissionError.validFromError
				this.validToError = commissionError.validToError
				this.percentageError = commissionError.percentageError
			}
		}
	},
	mounted() {
		this.setSalesChannels()
	},
}
</script>

<style scoped>
</style>