<script>
export default {
	data: () => ({
		state: '',
		activeLink: '',
		offset: 0,
		limit: 15,
		searchString: '',
		serviceProviderClicked: false,
		sections: [
			{ id: 'serviceProviders',    state: 'valid', preventDefault: true },
			{ id: 'ticketType',          state: 'valid', preventDefault: true, operatorAllowed: true },
			{ id: 'reduction',           state: 'valid', preventDefault: true, operatorAllowed: true },
			{ id: 'checklist',           state: 'valid', preventDefault: true },
			{ id: 'categories',          state: 'valid', preventDefault: true, path: '/productCategory' },
			{ id: 'marketplaceSettings', state: 'valid', preventDefault: true },
			{ id: 'commissions',         state: 'valid', preventDefault: true },
		],
		errorTitle: '',
		errorDetail: '',
		errorAlertVisible: false,
		successTitle: '',
		successDetail: '',
	}),
	computed: {
		isOperator() {
			return this.$store.state.loggedInUser?.fields?.type?.de === 'operator' && !this.$store.state.isImpersonation
		},
		states() {
			if (this.userIsOperator) {
				return ["serviceProviders", "ticketType", "reduction", "checklist", "categories", "marketplaceSettings", "generalSettings"]
			} else {
				return ["ticketType", "reduction", "faq"]
			}
		},
	},
	methods: {
		changeState(state) {
			this.state = state
			this.itemType = state
			this.offset = 0
			this.searchString = ''
		},
		// this is triggered by the sidebar actions
		handleAction(event) {
			// function is typically "setActive"
			this[event.function](event.params)
		},
		setActive(key) {
			const section = this.sections.find(x => x.id == key)
			this.activeLink = key
			this.state = key
			this.itemType = key
			this.serviceProviderClicked = false

			if (section?.path) {
				// TODO: why does this not navigate?
				this.$router.push(section?.path)
			}
			// avoid redundant navigation to current location
			else if (this.$router.currentRoute.value.fullPath !== '/settings') {
				this.$router.push({ path: '/settings', query: { viewType: key } })
			}
			this.changeState(key)
		},
		setSections() {
			if (!this.userIsOperator) {
				if (!this.$store.state.featureFlags.FEATURE_FAQ_IN_SERVICES) {
					this.sections.push({ id: 'faq', state: 'valid', preventDefault: true, operatorAllowed: true })
				}
				
				this.sections = this.sections.filter(a => a.operatorAllowed)
			}
			else {
				if (this.$store.state.featureFlags.FEATURE_GENERAL_SETTINGS) {
					this.sections.push({ id: 'generalSettings', state: 'valid', preventDefault: true, operatorAllowed: true })
				}
			}
		},
	}
}
</script>
