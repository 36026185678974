<template>
  <Loading data-cy="loading"/>
</template>

<script>
 import Loading from 'vue-loading-overlay';
 

export default {
  name: 'DeepLink',

  components: {
    Loading
  },

  methods: {
    async getClient(clientId) {
      let res = await this.$httpGet(`/client/${clientId}`,{ headers: { Authorization: `Bearer ${this.$store.state.loggedInUser.kc_token}`}})
      return res.client
    },
    
    async getServiceProvider(serviceProviderId) {
      let res = await this.$httpGet(`/serviceprovider/${serviceProviderId}`,{ headers: { Authorization: `Bearer ${this.$store.state.loggedInUser.kc_token}`}})
      return res.serviceProvider
    },

    async getOrder(orderId) {
      let res = await this.$httpGet(`/order?clientId=${client.sys.id}&orderId=${orderId}`,{ headers: { Authorization: `Bearer ${this.$store.state.loggedInUser.kc_token}`}})
      return res.order
    }
  },

  async mounted() {
    let path = this.$router.currentRoute.value.fullPath;

    let m;
    // /d/order/CM-ea34a383-b82e-41df-9a57-840bd00050fc
    // /d/order/3GTRLpRbTTl2JLbzYTYWGl
    let ordersPattern = /\/d\/order\/(?<orderId>[^/]+)/gm;

    if ((m = ordersPattern.exec(path))) {
      console.log("ordersPattern")
      await this.$store.commit('setSelectedOrder', await this.getOrder(m.groups.orderId));
      this.$router.push('/orderDetail');
    }

    // /d/3sAttOLwrenKLs4ID6rtFN/service/CM-ea34a383-b82e-41df-9a57-840bd00050fc
    // /d/4jET717zjLEuIjWQSUrmEl/service/3GTRLpRbTTl2JLbzYTYWGl
    let servicePattern = /\/d\/(?<client>[^/]+)\/service\/(?<service>[^/]+)/gm;

    if ((m = servicePattern.exec(path))) {
      await this.$store.commit('setSelectedClient', await this.getClient(m.groups.client));
      await this.$store.commit('setSelectedProduct', {sys:{id:m.groups.service}});
      this.$router.push('/serviceDetail');
    }

    // /d/5ljghp2lCKhqyK3MA27Iw0/serviceProvider/CM-7bb50861-2dd6-409d-9c2e-cec4b31297d5
    let serviceProviderPattern = /\/d\/(?<client>[^/]+)\/serviceProvider\/(?<serviceProvider>[^/]+)/gm;

    if ((m = serviceProviderPattern.exec(path))) {
      await this.$store.commit('setSelectedClient', await this.getClient(m.groups.client));
      await this.$store.commit('setSelectedServiceProvider', await this.getServiceProvider(m.groups.serviceProvider));
      this.$router.push('/businessProfile');
    }
  },
};
</script>


