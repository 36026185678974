<template>
    <div
        ref="milestonePreview"
        :class="{
            'milestones-preview': true,
            'highlight-payment': highlightPayment,
            'highlight-cancellation': highlightCancellation
        }"
    >
        <div class="timeline-spacer start" />
        <template
            v-for="(milestone, index) in milestones"
            :key="index"
        >
            <!-- Timeline Arrow -->
            <div
                v-if="!(milestone.type === 'DAYS_AFTER_PURCHASE' && milestone.days === 0)"
                :class="{
                    'timeline-arrow': true,
                    'payment': milestone.payment || milestone.type === 'consumption',
                    'cancellation': milestone.cancellation || milestone.type === 'consumption',
                }"
            />

            <!-- Timeline Divider -->
            <div
                v-if="milestone.type === 'divider'"
                class="timeline-divider"
            />

            <!-- Timeline Milestone -->
            <div
                v-else
                :class="{
                    'milestone-item': true,
                    [milestone.type]: true,
                }"
            >
                <div class="milestone-item-title">
                    {{ getTitle(milestone, index) }}
                </div>
                <div class="milestone-icon-container">
                    <!-- Purchase/Consumption Default -->
                    <div
                        v-if="milestone.type === 'purchase' || milestone.type === 'consumption'"
                        :class="{
                            'milestone-icon': true,
                            [milestone.type]: true
                        }"
                    >
                        <v-icon
                            :icon="milestone.icon"
                            size="x-small"
                        />
                    </div>
                    <!-- 
                        Display purchase default icon if cancellations are highlighted and there is
                        no cancellation milestone at purchase
                    -->
                    <div
                        v-else-if="highlightCancellation
                            && !milestone.cancellation
                            && milestone.type === 'DAYS_AFTER_PURCHASE'
                            && milestone.days === 0"
                        class="milestone-icon purchase"
                    >
                        <v-icon
                            icon="mdi-cart"
                            size="x-small"
                        />
                    </div>

                    <!-- Payment/Cancellation Milestone Icons -->
                    <template v-else>
                        <div
                            v-if="milestone.payment"
                            class="milestone-icon payment"
                            @mouseenter="toggleHighlight('payment', true)"
                            @mouseleave="toggleHighlight('payment', false)"
                        >
                            <v-icon :icon="milestone.payment.icon" />
                        </div>
                        <div
                            v-if="milestone.cancellation"
                            class="milestone-icon cancellation"
                            @mouseenter="toggleHighlight('cancellation', true)"
                            @mouseleave="toggleHighlight('cancellation', false)"
                        >
                            <v-icon :icon="milestone.cancellation.icon" />
                        </div>
                    </template>
                </div>
                <div
                    v-show="!!getPercentage(milestone)"
                    :class="{
                        'milestone-item-subtitle': true,
                        'payment': !!milestone.payment,
                        'cancellation': !!milestone.cancellation
                    }"
                    :value="getPercentage(milestone)"
                >
                    <div>{{ getSubtitle(milestone) }}</div>
                    <div class="milestone-percentage">
                        {{ getPercentage(milestone) + " %" }}
                    </div>
                </div>
            </div>
            <div :class="{
                'timeline-spacer': true,
                'end': index === (milestones.length - 1)
            }" />
        </template>
    </div>
</template>

<script>
import milestones from './policyMilestones';

export default {
    mixins: [milestones],
    props: {
        paymentPlan: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            highlightCancellation: false,
            highlightPayment: false,
        }
    },

    methods: {
        getTitle(milestone, index) {
            const days = milestone?.days;
            const type = milestone?.type;
            if (index === 0 || (type === "DAYS_AFTER_PURCHASE" && days === 0)) {
                return this.$t("text.atPurchase")
            } else if (index === (this.milestones.length - 1)) {
                return this.$t("text.consumption")
            } else if (type === "PAY_AT_SERVICE_PROVIDER") {
                return this.$t("text.atServiceProvider");
            }

            let title;
            switch (type) {
                case "DAYS_AFTER_PURCHASE":
                    title = this.$t("text.daysAfterPurchase", { value: days })
                    break;
                case "DAYS_BEFORE_CONSUMPTION":
                    title = this.$t("text.daysPriorToConsumption", { value: days })
                    break;
            }
            return title;
        },

        getSubtitle(milestone) {
            const isAtPurchase = milestone.type === 'DAYS_AFTER_PURCHASE' && milestone.days === 0;
            if (this.highlightCancellation && (milestone?.cancellation || isAtPurchase)) {
                return this.$t("text.cancellation");
            } else if (this.highlightPayment) {
                if (milestone?.payment) {
                    return this.$t("text.payment");
                } else if (milestone.type === "consumption") {
                    return this.$t("text.total");
                }
            }
            return undefined;
        },

        getPercentage(milestone) {
            const isAtPurchase = milestone.type === 'DAYS_AFTER_PURCHASE' && milestone.days === 0;
            const cancellation = milestone?.cancellation;
            const payment = milestone?.payment;
            if (this.highlightCancellation && (cancellation || isAtPurchase)) {
                const percentage = cancellation?.amount?.valuePercent ?? 0;
                return percentage;
            } else if (this.highlightPayment) {
                if (payment) {
                    const percentage = payment?.amount?.valuePercent ?? 0;
                    return percentage;
                } else if (milestone.type === "consumption") {
                    return this.paymentTotal;
                }
            }
            return undefined;
        },

        toggleHighlight(type, highlight) {
            this.highlightPayment = type === "payment" && highlight;
            this.highlightCancellation = type === "cancellation" && highlight;
            const milestonePreviewEl = this.$refs.milestonePreview;
            if (this.highlightPayment) {
                this.$nextTick(() => {
                    //render subtotal values between payment milestones
                    const paymentMilestoneElements = milestonePreviewEl.querySelectorAll(".milestone-item-subtitle.payment");
                    if (paymentMilestoneElements.length > 1) {
                        const milestonePreviewRect = milestonePreviewEl.getBoundingClientRect();
                        this.subtotals.forEach((subtotal, index) => {
                            const payMileEl1 = paymentMilestoneElements[index];
                            const payMileEl2 = paymentMilestoneElements[index + 1];
                            if (payMileEl1 && payMileEl2) {

                                //Calculate position of subtotal element relative to the payment milestones
                                const rect1 = payMileEl1.getBoundingClientRect();
                                const rect2 = payMileEl2.getBoundingClientRect();
                                const left = rect1.right - milestonePreviewRect.left;
                                const right = rect2.left - milestonePreviewRect.left;

                                let subtotalEl = document.createElement("div");
                                subtotalEl.style.bottom = "0";
                                subtotalEl.style.left = left + "px";
                                subtotalEl.style.maxWidth = (right - left) + "px";
                                subtotalEl.classList.add('milestone-subtotal');

                                //Add title and percentage
                                let titleEl = document.createElement("div");
                                titleEl.innerHTML = this.$t("text.subtotal");
                                subtotalEl.appendChild(titleEl);

                                let percentageEl = document.createElement("div");
                                percentageEl.innerHTML = subtotal + " %";
                                subtotalEl.appendChild(percentageEl);
                                milestonePreviewEl.appendChild(subtotalEl);
                            }
                        })
                    }
                })
            } else {
                //If payment milestones are hidden, remove all subtotal elements
                const subtotalElements = milestonePreviewEl.querySelectorAll(".milestone-subtotal");
                subtotalElements.forEach(el => milestonePreviewEl.removeChild(el));
            }
        }
    },
}
</script>

<style lang="scss">
$payment-color: #00aeef;
$payment-background: #d4f1fc;
$cancellation-color: #f34545;
$cancellation-background: #fde0e0;
$timeline-color: #f4f4f4;
$consumption-color: #64c823;

.milestones-preview {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    position: relative;
    padding-top: 24px;
    padding-bottom: 28px;

    &.highlight-payment {
        >.timeline-spacer:not(.start):not(.end) {
            background: $payment-color;
        }

        >.timeline-divider {
            border-color: $payment-color;
        }

        >.timeline-arrow.payment {
            display: block;
            border-left-color: $payment-color;
        }


        >.milestone-item .milestone-icon.cancellation {
            z-index: 0;
            opacity: 0.38;
        }

        >.milestone-item {
            z-index: 3;
            opacity: 1;

            >.milestone-item-subtitle {
                display: flex;

                .milestone-percentage {
                    color: $payment-color;
                }

            }

            &.consumption>.milestone-item-subtitle>.milestone-percentage {
                color: $consumption-color
            }
        }
    }

    &.highlight-cancellation {
        >.timeline-spacer:not(.start):not(.end) {
            background: $cancellation-color;
        }

        >.timeline-divider {
            border-color: $cancellation-color;
        }

        >.timeline-arrow.cancellation {
            display: block;
            border-left-color: $cancellation-color;
        }

        >.milestone-item .milestone-icon.payment {
            z-index: 0;
            opacity: 0.38;
        }

        >.milestone-item {
            z-index: 3;
            opacity: 1;

            >.milestone-item-subtitle {
                display: flex;

                .milestone-percentage {
                    color: $cancellation-color;
                }
            }
        }
    }

    >.timeline-spacer {
        display: flex;
        flex-grow: 1;
        height: 4px;
        background: $timeline-color;

        &.start,
        &.end {
            max-width: 32px;
        }
    }

    >.timeline-divider {
        display: flex;
        flex-grow: 1;
        margin: 4px;
        height: 0;
        border-top: 4px solid $timeline-color;
        border-top-style: dotted;
        max-width: 64px;
    }

    >.timeline-arrow {
        display: none;
        width: 0;
        height: 0;
        margin-left: -8px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 12px solid;
    }

    >.milestone-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.PAY_AT_SERVICE_PROVIDER {
            &+.timeline-spacer {
                max-width: 100px;
            }
        }

        .milestone-item-title {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.01px;
            text-align: center;
            color: #000;
            position: absolute;
            top: 0;
        }

        >.milestone-icon-container {
            display: flex;
            flex-direction: row;

            >.milestone-icon+.milestone-icon {
                z-index: 0;
                margin-left: -16px;
            }

            >.milestone-icon {
                z-index: 3;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                border: 4px solid #fff;
                background-color: $timeline-color !important;
                color: #8f8f8f;

                &.payment {
                    cursor: pointer;
                    background-color: $payment-background !important;
                    color: $payment-color;
                }

                &.cancellation {
                    cursor: pointer;
                    background-color: $cancellation-background !important;
                    color: $cancellation-color;
                }

                &.consumption {
                    background-color: #e5f6da !important;
                    color: $consumption-color;
                }

                >.v-icon {
                    font-size: 14px;
                }
            }
        }


        >.milestone-item-subtitle {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            height: 16px;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.01px;
            text-align: center;
            color: #000;
        }
    }

    >.milestone-subtotal {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        height: 16px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.01px;
        text-align: center;
        color: #9da0a5;

    }

}
</style>