<template>
	<div>
		<p v-html="$t('text.locationDesc')"/>
		<v-btn elevation="0" theme="dark" class="bg-blue" @click="geocode(address)" v-if="addressString">
			<v-icon size="14" start>mdi-crosshairs</v-icon>
			{{ $t('text.find', { address: addressString }) }}
		</v-btn>
		<div class="mapWrap">
			<div ref="map" class="map" />
			<div class="crosshair">
				<svg style="width: 50px; height: 50px; position: absolute; left: -24.5px; top: -20px;" viewBox="0 0 20 35" xmlns="http://www.w3.org/2000/svg">
					<g transform="translate(-814.59595,-274.38623)">
						<g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
							<path xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" sodipodi:nodetypes="sscccccsscs" inkscape:connector-curvature="0" id="path4337-3" d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="display:inline;opacity:1;fill:#ff4646;fill-opacity:1;stroke:#d73534;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
							<circle r="3.0355" cy="288.25278" cx="823.03064" id="path3049" style="display:inline;opacity:1;fill:#590000;fill-opacity:1;stroke-width:0" />
						</g>
					</g>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

// TODO: key from env
const API_KEY = 'AIzaSyAxeE-DWlZZrrvf8lZRjEimC2iJfDKjviA'
let resolveInitPromise
let rejectInitPromise

const initPromise = new Promise((resolve, reject) => {
	resolveInitPromise = resolve
	rejectInitPromise = reject
})

function gmapsInit() {
	if (!!window.google) return window.google
	window.initMap = () => resolveInitPromise(window.google)

	const script = document.createElement('script')
	script.async = true
	script.defer = true
	script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`
	script.onerror = rejectInitPromise
	document.querySelector('head').appendChild(script)
	return initPromise
}

// TODO: replace this with reactive validation
export function ekLocation(value) {
	var latitude = value.lat.toString() ?? ""
	var longitude = value.lon.toString() ?? ""
	var decimalLength = 0
	if (latitude == '0') return 'text.latitudeError'
	if (longitude == '0') return 'text.longitudeError'
	const latitudeRegex = /^(\+|-)?(?:90(?:(?:[.,]0{1,20})?)|(?:[0-9]|[1-8][0-9])(?:(?:[.,][0-9]{1,20})?))$/
	const longitudeRegex = /^(\+|-)?(?:180(?:(?:[.,]0{1,20})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:[.,][0-9]{1,20})?))$/
	if (latitude && !latitude.match(latitudeRegex)) {
		decimalLength = (latitude.substring(latitude.replace(',', '.').indexOf("."), latitude.length)).length
		if (decimalLength > 20)
			return 'text.locationDecimalError'
		else
			return 'text.latitudeError'
	}
	else if (longitude && !longitude.match(longitudeRegex)) {
		decimalLength = (longitude.substring(longitude.replace(',', '.').indexOf("."), longitude.length)).length
		if (decimalLength > 20)
			return 'text.locationDecimalError'
		else
			return 'text.longitudeError'
	}
	return null
}

export default {
	name: 'LocationField',
	mixins: [ field ],
	inject: [ 'address' ],
	props: {
		modelValue: Object,
		disabled: Boolean,
		dataCy: { type: String, default: '' },
	},
	data: () => ({
		model: null,
		errorDetail: null,
		map: null, // GMaps object
		geocoder: null,
		google: null,
	}),
	computed: {
		addressString() {
			if (!this.address?.fields?.streetAddress?.de
				|| !this.address.fields.zipCode?.de
				|| !this.address.fields.city?.de) return

			return (this.address.fields.streetAddress.de + ' '
				+ this.address.fields.zipCode.de + ' '
				+ this.address.fields.city.de).trim()
		},
		modelEmpty() {
			return !this.modelValue.lat && !this.modelValue.lon
		},
	},
	watch: {
		// TODO
		modelValue(n) {
			this.model = n
//			this.validate()
		},
	},
	methods: {
		async geocode(address) {
			if (!address) {
				const google = this.google
				if (this.modelValue.lat && this.modelValue.lon) {
					this.map.setCenter(new google.maps.LatLng(this.modelValue.lat, this.modelValue.lon))
					this.map.setZoom(6)
				}
				else {
					this.map.setCenter(new google.maps.LatLng(47, 11))
					this.map.setZoom(3)
					this.modelValue.lat = ''
					this.modelValue.lon = ''
				}
			}
			else {
				this.geocoder?.geocode({ address: typeof address !== 'string' ? this.addressString : address }, (results) => {
					if (results?.length > 0) {
						this.map.setCenter(results[0].geometry.location)
						this.map.fitBounds(results[0].geometry.viewport)
					}
				})
			}
			this.$emit('changed')
		},
		setCenter() {
			this.map.setCenter({ lat: Number(this.modelValue.lat), lng: Number(this.modelValue.lon) })
		},
		async autogeocode() {
			// we dont autogeocode if we have a location already
			if (!this.modelEmpty) return

			// we dont autogeocode if we dont have a full address
			if (!this.addressString) return

			await this.geocode(this.addressString)
		},
	},
	async mounted() {
		this.model = this.modelValue
		try {
			const google = await gmapsInit()
			this.google = google
			this.geocoder = new google.maps.Geocoder()
			this.map = new google.maps.Map(this.$refs.map, {
				streetViewControl: false,
				fullscreenControl: false,
			})
			this.map.addListener('center_changed', () => {
				const pos = this.map.getCenter()
				this.modelValue.lat = (pos.lat() + '').substring(0, 16)
				this.modelValue.lon = (pos.lng() + '').substring(0, 16)
				this.$emit('changed')
			})
			this.map.setZoom(12)
			if (this.modelEmpty)
				this.geocode(this.addressString)
			else
				this.setCenter()
		}
		catch (e) {
			console.error(e)
		}
	},
}
</script>

<style scoped>
div { color: gray; }
.mapWrap { position: relative; margin-top: 10px; }
.map { height: 400px }
.crosshair { position: absolute; width: 0px; height: 60px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); pointer-events: none; }
.crosshair::before { content: ""; position: absolute; width: 60px; height: 0px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); }
</style>