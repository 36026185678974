<template>
	<div class="BusinessHoursTimeTable">
		<div v-for="weekday of weekdays" :key="'h-' + weekday" class="weekday"
			:class="{
				error: weekdayError(weekday),
			}"
		>
			<div class="weekdayHead">{{ $t('text.' + weekday) }}</div>
			<template v-for="(time, t) of timeSpan.fields.businessTimes.de[weekday].times">
				<div v-if="time.openTime == ''" :key="t" class="timeClosed">{{ $t('text.closed') }}</div>
				<div v-if="time.openTime != ''" :key="t" class="timeOpen">
					<button @click="remove(weekday, time, t)" style="float: right;"><v-icon>mdi-delete</v-icon></button>
					{{ time.openTime }} - {{ time.closeTime }}
				</div>
			</template>
			<v-menu
				v-model="menuOpen[weekday]"
				location="bottom center"
				:close-on-content-click="false"
			>
				<template #activator="{ props }">
					<v-icon v-bind="props" v-if="!menuOpen[weekday]" class="plus">mdi-plus</v-icon>
					<v-icon v-bind="props" v-if="menuOpen[weekday]" class="plus">mdi-plus-circle</v-icon>
				</template>
				<div class="menu">
					<EditOpeningTime
						@ok="addTimeComplete(weekday, $event)"
						@cancel="menuOpen[weekday] = false"
					/>
					<div style="padding: 0 10px;">
						<button v-if="weekday != 'monday' && !weekdayEmpty(previousWeekday(weekday)) && weekdayEmpty(weekday)"
							style="margin: 5px 0; font-size: smaller;"
							@click="copy(previousWeekday(weekday), weekday)"
						>
							<v-icon>mdi-content-copy</v-icon>
							{{ $t('text.copy') }} {{ $t('text.' + previousWeekday(weekday)) }}
						</button>
					</div>
				</div>
			</v-menu>
		</div>
	</div>
</template>

<script>
import EditOpeningTime, { timesOverlap } from './EditOpeningTime.vue'

export default {
	name: 'BusinessHoursTimeTable',
	components: { EditOpeningTime },
	props: {
		timeSpan: { type: Object, required: true },
	},
	data: () => ({
		weekdays: [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday' ],
		selectedTimes: null,
		menuOpen: {},
	}),
	methods: {
		weekdayData(weekday) {
			return this.timeSpan.fields.businessTimes.de[weekday]
		},
		weekdayError(weekday) {
			const times = this.weekdayData(weekday).times
			return timesOverlap(times) ? 'OVERLAP' : null
		},
		weekdayEmpty(weekday) {
			const wdd = this.weekdayData(weekday)
			return wdd.times.length == 1 && wdd.times[0].openTime == ''
		},
		addTimeComplete(weekday, time) {
			const wdd = this.weekdayData(weekday)
			// if there is an "empty" time, remove it
			if (this.weekdayEmpty(weekday)) {
				wdd.times.splice(0, 1)
			}
			wdd.times.push(time)
			this.menuOpen[weekday] = false
		},
		remove(weekday, time, t) {
			const wdd = this.weekdayData(weekday)
			wdd.times.splice(t, 1)
			// if there are no times left, add an empty one
			if (wdd.times.length == 0) {
				wdd.times.push({ openTime: '', closeTime: '' })
			}
		},
		previousWeekday(weekday) {
			const index = this.weekdays.indexOf(weekday)
			if (index == 0) return this.weekdays[6]
			return this.weekdays[index - 1]
		},
		copy(from, to) {
			const fromData = this.weekdayData(from)
			const toData = this.weekdayData(to)
			toData.times = JSON.parse(JSON.stringify(fromData.times))
			this.menuOpen[to] = false
		},
	},
}
</script>

<style scoped>
.BusinessHoursTimeTable { display: flex; flex-wrap: wrap; font-size: smaller; text-align: center; }

.weekday { width: calc(100% / 7); box-sizing: border-box; padding-right: 10px; }
.weekdayHead { width: auto; text-align: center; font-weight: 600; margin-bottom: 5px; overflow: hidden; }
.timeClosed,
.timeOpen { text-align: center; border-radius: 5px; padding: 2px 0px 2px 5px; margin-bottom: 5px; overflow: hidden; }
.timeClosed { background: #ddd; border: 1px solid #999; }
.timeOpen { background: #fe6; border: 1px solid goldenrod; box-shadow: 0 0 10px #fe6; }
.weekday:hover .plus { color: #00aeef; }
.weekday.error .timeOpen { outline: 1px solid #de2d2d; border: 1px solid #de2d2d; box-shadow: 0 0 10px #de2d2d; }

.menu { padding: 5px 0; background: white; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); }
.menu > div { white-space: nowrap; cursor: pointer; padding: 5px 10px; }
.menu > .item:hover { background: #eee; }

@media screen and (max-width: 780px) {
	.weekday { padding-right: 5px; }
}
</style>