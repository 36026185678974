<template>
	<div class="Step" v-show="visible" :id="id" :class="{ disabled: disabled }">
		<slot></slot>
	</div>
</template>

<script>
import eventBus from '@/utils/eventBus.js'
import ChildErrorReceiver from './ChildErrorReceiver.vue'

export default {
	name: 'Step',
	mixins: [ ChildErrorReceiver ],
	props: {
		id: String,
		icon: { type: String, default: 'mdi-wallet-membership' },
		forceStatus: String,
		group: { type: String, default: 'app' },
		disabled: { type: Boolean, default: false },
		confirmStep: { type: String }
	},
	data: () => ({
		visible: false,
		status: 'complete',
	}),
	watch: {
		forceStatus(n) {
			this.updateStatus()
		},
		childErrorMessage(n) {
			// TODO: how to set the complete status? in validation also?
			this.updateStatus()
		},
	},
	methods: {
		register(stepBar, group) {
			if (!group) group = this.group
			if (group != this.group) return
			eventBus.$emit('step-register', this, this.group)
		},
		show(id, group) {
			if (group != this.group) return
			this.visible = id == this.id
			if (this.visible)
				window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		updateStatus() {
			if (this.forceStatus !== undefined) {
				this.status = this.forceStatus
				return
			}
			this.status = this.childErrorMessage ? 'error' : 'complete'
		},
	},
	mounted() {
		this.updateStatus()
		eventBus.$on('step-show', this.show)
		this.register()
		eventBus.$on('step-cfr', this.register)
	},
	unmounted() {
		eventBus.$off('step-show', this.show)
		eventBus.$off('step-cfr', this.register)
		eventBus.$emit('step-deregister', this, this.group)
	},
}
</script>

<style scoped>
.Step { margin-top: 60px; }
.Step.disabled { 	
	pointer-events: none;
	opacity: 0.5; 
}
</style>