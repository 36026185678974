<template>
	<div>
		<Disclosure
			:title="disclosureTitle"
			:error="sectionMessage.error"
			:message="sectionMessage.message"
			data-cy="skirentalSettingsForSp"
			ref="SECTION_skirentalSettingsForSp"
            :lock="skirentalSettingsForSpLocked"
		>
			<div class="field left-border">
				<v-label>{{$t('text.skirentalSystem')}}</v-label>

				<v-select
					variant="outlined" density="compact" return-object
					:hide-details="skirentalSystemErrors.length === 0"
					:error-messages="skirentalSystemErrors"
					:items="skirentalSystem"
					:item-title="item => item"
					data-cy="reduction"
				/>
				<p class="helpText" v-html="$t('text.skirentalSystemHelptext')"/>
			</div>


			<div class="field left-border">
				<v-label>{{$t('text.skirentalAvailableStores')}}</v-label>
				<v-data-table
					:items="skirentalStores"
					:mobile-breakpoint="700"
					item-key="name"
				    :item-value="item => item"
					:headers="[]"
				>
					<template v-slot:item="{ item }">
						<tr>
							<td style="display: flex; justify-content: space-between; align-items: center;">
								<div style="text-align: left;">{{ item.skirentalStoreName.de }}</div>
								<div>
									<v-btn @click="editItem(item)" style="margin: 5px;">
										<v-icon>
											mdi-pencil
										</v-icon>
									</v-btn>
									<v-btn @click="deleteItem(item)" style="margin: 5px; color: red;">
										<v-icon>
											mdi-delete
										</v-icon>
									</v-btn>
								</div>
							</td>
						</tr>
					</template>
					<template #bottom>
						<v-btn
							prepend-icon="mdi-plus"
							style="width: 15%"
							@click="addStorePopup"
						>
							{{$t('text.skirentalAddStore')}}
						</v-btn>
					</template>
				</v-data-table>
				<Dialog ref="dialogAddStore"
			        :confirmLabel="$t('text.ok')"
			        :cancelLabel="$t('text.cancel')"
	                :confirmHandler="emitUpdate"
		            :cancelHandler="cancelSelection"
			        :showClose="false"
			        :title="$t('text.skirentalAddStore')"
				>
					<template #content>
						<div class="addStore">
							<div class="field left-border">
								<TextField
									:title="$t('text.skirentalStoreExternalId')"
									v-model="skirentalStore.externalId.de"
								/>

								<TextField
									:title="$t('text.skirentalStoreName')"
									v-model="skirentalStore.skirentalStoreName.de"
								/>

								<TextField
									:title="$t('text.placeHolderAddress')"
									v-model="skirentalStore.address.streetAddress.de"
								/>
								<div style="display: flex; gap: 15px;">
									<TextField
										style="flex-grow: 1;"
										:title="$t('text.placeHolderPostCode')"
										v-model="skirentalStore.address.zipCode.de"
									/>

									<TextField
										style="flex-grow: 1;"
										:title="$t('text.placeHolderCityTown')"
										v-model="skirentalStore.address.city.de"
									/>
								</div>

								<FieldWrap :title="$t('text.placeHolderCountry')">
									<v-select variant="outlined" density="compact" return-object
									          style="margin-top: 3px;"
									          hide-details
									          :placeholder="$t('text.placeHolderCountry')"
									          :error-messages="countryErrors"
									          :items="countryNames"
									          :item-title="item => item?.[selectedLocale] || $t('text.placeHolderCountry')"
									          v-model="skirentalStore.address.country.de"
									/>
								</FieldWrap>

								<TextField
									:title="$t('text.skirentalStoreInformation')"
									v-model="skirentalStore.skirentalStoreInformation.de"
								/>
								<p class="helpText" v-html="$t('text.skirentalAddStoreHelptext')"/>
							</div>
						</div>
					</template>
				</Dialog>

				<Dialog ref="dialogEditStore"
				        :confirmLabel="$t('text.ok')"
				        :cancelLabel="$t('text.cancel')"
				        :confirmHandler="emitUpdate"
				        :cancelHandler="cancelSelection"
				        :showClose="false"
				        :title="$t('text.skirentalEditStore')"
				>
					<template #content>
						<div class="addStore">
							<div class="field left-border">
								<TextField
									:title="$t('text.skirentalStoreExternalId')"
									v-model="currentlyEditingStore.externalId.de"
								/>

								<TextField
									:title="$t('text.skirentalStoreName')"
									v-model="currentlyEditingStore.skirentalStoreName.de"
								/>

								<TextField
									:title="$t('text.placeHolderAddress')"
									v-model="currentlyEditingStore.address.streetAddress.de"
								/>
								<div style="display: flex; gap: 15px;">
									<TextField
										style="flex-grow: 1;"
										:title="$t('text.placeHolderPostCode')"
										v-model="currentlyEditingStore.address.zipCode.de"
									/>

									<TextField
										style="flex-grow: 1;"
										:title="$t('text.placeHolderCityTown')"
										v-model="currentlyEditingStore.address.city.de"
									/>
								</div>

								<FieldWrap :title="$t('text.placeHolderCountry')">
									<v-select variant="outlined" density="compact" return-object
								          style="margin-top: 3px;"
								          hide-details
								          :placeholder="$t('text.placeHolderCountry')"
								          :error-messages="countryErrors"
								          :items="countryNames"
								          :item-title="item => item?.[selectedLocale] || $t('text.placeHolderCountry')"
							              v-model="currentlyEditingStore.address.country.de"
									/>
								</FieldWrap>

								<TextField
									:title="$t('text.skirentalStoreInformation')"
									v-model="currentlyEditingStore.skirentalStoreInformation.de"
								/>
								<p class="helpText" v-html="$t('text.skirentalAddStoreHelptext')"/>
							</div>
						</div>
					</template>
				</Dialog>
			</div>
		</Disclosure>

	</div>
</template>

<script>

import Disclosure from "@/components/common/Disclosure.vue";
import Dialog from "@/components/common/Dialog.vue";
import TextField from "@/components/common/TextField.vue";
import FieldWrap from "@/components/common/FieldWrap.vue";
import Common from "@/mixins/Common.vue";


export default {
	name: "SkirentalSettingsForSp",
	components: {
		FieldWrap, TextField, Dialog, Disclosure
	},
	mixins: [Common],

	props: {
		disclosureTitle: String,
		skirentalSettingsLocked: Boolean,
	},

	computed: {
		countryNames() {
			return this.countries.map(item => item.name)
		},
	},

	data: () => ({
		sectionMessage: {
			error: false,
			message: '',
		},
		countries: [],
		skirentalSystemErrors: [],
		skirentalSystem: ['Rentmax', 'System2', 'Some other system'],
		skirentalStores: [
			{
				externalId: {
					de: '987'
				},
				skirentalStoreName: {
					de: 'Store1 Alturos',
				},
				address: {
					streetAddress: {
						de: 'Lakeside B03'
					},
					zipCode: {
						de: '9020'
					},
					city: {
						de: 'Klagenfurt'
					},
					country: {
						de: {
							de: "Österreich",
							en: "Austria",
							fr: "Autriche",
							it: "Austria",
						}
					}
				},
				skirentalStoreInformation: {
					de: 'This is a store representing one Alturos office.',
				}
			}, {
				externalId: {
					de: '123'
				},
				skirentalStoreName: {
					de: 'Store2 Lakeside',
				},
				address: {
					streetAddress: {
						de: 'Heuplatz 1'
					},
					zipCode: {
						de: '9020'
					},
					city: {
						de: 'Klagenfurt'
					},
					country: {
						de: {
							de: "Österreich",
							en: "Austria",
							fr: "Autriche",
							it: "Austria",
						}
					}
				},
				skirentalStoreInformation: {
					de: 'This is a store representing another Alturos office.',
				},
			}
		],
		currentlyEditingStore: {},
		skirentalStore: {
			externalId: {
				de: ''
			},
			skirentalStoreName: {
				de: '',
			},
			address: {
				streetAddress: {
					de: ''
				},
				zipCode: {
					de: ''
				},
				city: {
					de: ''
				},
				country: {
					de: '',
				}
			},
			skirentalStoreInformation: {
				de: '',
			}
		},
	}),
	watch: {

	},
	methods: {
		editItem(item) {
			this.currentlyEditingStore = item
			this.$refs.dialogEditStore.show = true
		},
		deleteItem(item) {
			console.log("DELETE", item)
		},
		addStorePopup() {
			this.$refs.dialogAddStore.show = true
		}
	},

	mounted() {
		this.countries = this.getCountries()
	},
}
</script>

<style scoped>
.addStore {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-left: 10%;
	padding-right: 10%;
}
</style>